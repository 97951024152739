import { useRef } from 'react';

const fieldsToPreserve = ['userLogState', 'cartContents'];

export const useDataLayerData = () => {
    const dataRef = useRef({});
    const getStoredData = (keys = []) => {
        let result = {};
        keys.map(key => {
            if (typeof dataRef.current[key] !== 'undefined') {
                result[key] = dataRef.current[key];
            }
        });
        return result;
    }
    const addStoredData = (data) => {
        dataRef.current = {...dataRef.current, ...data};
        /**
         * for some reason the events "page_view" and "page_view_start" have same variable - pageDetails,
         * but different content.
         * So when we need a page title not to be overriden - store it in a separate variable here - currentPageTitle
         */
        if (data.pageDetails?.pageName) {
            dataRef.current.currentPageTitle = data.pageDetails?.pageName;
        }
    }
    const resetStoredData = () => {
        dataRef.current = getStoredData(fieldsToPreserve);
    }
    
    return {
        getStoredData,
        addStoredData,
        resetStoredData
    };
}