import React from 'react';
import PropTypes from 'prop-types';

const BE = props => {
    const { color, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            height={size}
            width={size}
            {...otherProps}
        >
            <path
                fill="#FFDA44"
                d="M345 15.9C317.3 5.6 287.3 0 256 0c-31.3 0-61.3 5.6-89 15.9v480.2c27.7 10.3 57.7 15.9 89 15.9 31.3 0 61.3-5.6 89-15.9V15.9z"
            ></path>
            <path
                fill="#D80027"
                d="M345 15.9v480.2c97.5-36.2 167-130 167-240.1S442.5 52.1 345 15.9z"
            ></path>
            <path d="M167 15.9C69.5 52.1 0 145.9 0 256s69.5 203.9 167 240.1V15.9z"></path>
        </svg>
    );
};

BE.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

BE.defaultProps = {
    color: 'currentColor',
    size: '18'
};

export default BE;
