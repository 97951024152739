import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { string, object, func } from 'prop-types';
import { Alert, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Form } from 'informed';
import {
    AuthStore,
    signIn,
    useGlobalOptions
} from '@corratech/context-provider';
import { combine, isRequired, TextInput } from '@corratech/form-components';
import { Field as CorraField } from '../Field';
import { useReCaptchaStoreConfig } from 'ModulesPath/Checkout/useReCaptchaStoreConfig';
import { ReCaptcha } from 'react-recaptcha-v3';
import loadReCaptcha from '@corratech/google-recaptcha-v3/src/loadReCaptcha';

export const CheckoutLoginForm = props => {
    const {
        css,
        className,
        email,
        setAuthStateIsLoading,
        passwordAutoComplete,
        setEnteredPassword,
        enteredPassword,
        joinRewards,
        setJoinRewards
    } = props;

    const [t] = useTranslation();

    const { authState, dispatch } = useContext(AuthStore);

    //ReCaptcha
    const options = useGlobalOptions();
    const { actionV3PlaceSingIn } = useReCaptchaStoreConfig();
    const [reCaptchaTokenSingIn, setReCaptchaTokenSingIn] = useState(null);

    let recaptchaV3Publickey = '';
    let isV3PlaceSingInCaptchaEnable = false;

    if (options.storeConfig && options.storeConfig.recaptcha_v3_public_key) {
        recaptchaV3Publickey = options.storeConfig.recaptcha_v3_public_key;
    }

    if (actionV3PlaceSingIn && actionV3PlaceSingIn === 'recaptcha_v3') {
        isV3PlaceSingInCaptchaEnable = true;
    }

    const verifyReCaptchaTokenSingInCallback = token => {
        setReCaptchaTokenSingIn(token);
        hideErrorMessage();
        setInterval(function() {
            resetReCaptchaTokenSingIn();
        }, 110 * 1000);
    };

    const resetReCaptchaTokenSingIn = () => {
        if (window.grecaptcha !== undefined) {
            window.grecaptcha
                .execute(recaptchaV3Publickey, { action: 'sing_in' })
                .then(function(token) {
                    setReCaptchaTokenSingIn(token);
                });
        }
    };

    useEffect(() => {
        if (null !== authState.error) {
            resetReCaptchaTokenSingIn();
        }
    }, [authState]);

    const onPasswordChange = value => {
        setEnteredPassword(value);
        hideErrorMessage();
    };

    const hideErrorMessage = () => {
        dispatch({
            type: 'SET_AUTH_ERROR',
            error: null
        });
    };

    const handleSubmit = formState => {
        signIn({
            credentials: {
                username: email,
                password: formState.password,
                reCaptchaToken: reCaptchaTokenSingIn
            },
            dispatch: dispatch
        });
    };

    return (
        <div
            aria-labelledby={'checkout-password-form-heading'}
            className={'checkout-password-form ' + (className || '')}
            css={css}
        >
            {isV3PlaceSingInCaptchaEnable &&
                loadReCaptcha(recaptchaV3Publickey)}
            {isV3PlaceSingInCaptchaEnable && (
                <ReCaptcha
                    action="sing_in"
                    sitekey={recaptchaV3Publickey}
                    verifyCallback={verifyReCaptchaTokenSingInCallback}
                />
            )}
            <Form onSubmit={!joinRewards && handleSubmit}>
                <CorraField label={t(`Password`)}>
                    <TextInput
                        field="password"
                        type="password"
                        autoComplete="new-password"
                        placeholder={t(`Password`)}
                        id="password"
                        aria-required="true"
                        validate={combine([
                            {
                                fn: isRequired,
                                text: t(props.requiredText)
                            }
                        ])}
                        validateOnChange
                        autoFocus={passwordAutoComplete}
                        onValueChange={onPasswordChange}
                    />
                </CorraField>

                <div>
                    {null !== authState.error && (
                        <Alert variant="danger">
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: authState.error
                                }}
                            />
                        </Alert>
                    )}
                </div>
                <div className={'actions-toolbar'}>
                    <Button size="lg" variant="primary" type="submit">
                        {t('Sign In')}
                    </Button>
                    <Link
                        to={{
                            ...location,
                            search: `?email=${email}&forgotpassword=true`
                        }}
                        className={'password-remind'}
                        title={t('Forgot Your Password?')}
                    >
                        {t('Forgot Your Password?')}
                    </Link>
                </div>
            </Form>
        </div>
    );
};

CheckoutLoginForm.defaultProps = {
    requiredText: 'Password is required',
    passwordAutoComplete: true
};

CheckoutLoginForm.propTypes = {
    className: string,
    css: object,
    email: string,
    requiredText: string,
    setAuthStateIsLoading: func
};
