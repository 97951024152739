import React, { useEffect, useState, lazy, Suspense } from 'react';
import { TrackSearchContent } from 'ModulesPath/FacebookConversion/TrackSearchContent';
import ErrorView from 'ModulesPath/ErrorView/';
import { DebugBearMonitoring } from 'ModulesPath/DebugBear/DebugBearMonitoring';
import { ConstructorPLPManager } from 'ModulesPath/Catalog/Constructor/ConstructorPLPManager';
import { useConstructorConfig } from 'ModulesPath/SearchBar/useConstructorConfig';
import LoadingIndicator from 'ModulesPath/LoadingIndicator';

const ConstructorSearchResult = lazy(() =>
    import('ModulesPath/Catalog/Constructor/ConstructorSearchResult')
);

export const Search = (props) => {
    const [SearchResult, setSearchResult] = useState(null);
    const [PLPManager, setPLPManager] = useState(null);
    const [loadingComponents, setLoadingComponents] = useState(true);

    const {
        constructorSearchEnable,
        constructorSearchProductResultPerPage,
        constructorSearchNetworkTimeout,
        constructorApiKey,
        enableVisibilityPreFilter,
        constructorConfigLoaded,
    } = useConstructorConfig();

    useEffect(() => {
        if (constructorConfigLoaded && !constructorSearchEnable) {
            setLoadingComponents(true);
            Promise.all([
                import('ModulesPath/Catalog/SearchResult'),
                import('ModulesPath/Catalog/PLP/PLPManager'),
            ])
                .then(([SearchResultModule, PLPManagerModule]) => {
                    setSearchResult(() => SearchResultModule.default);
                    setPLPManager(() => PLPManagerModule.PLPManager);
                })
                .catch((err) => {
                    console.error('Failed to load SearchResult or PLPManager:', err);
                })
                .finally(() => setLoadingComponents(false));
        }
    }, [constructorConfigLoaded, constructorSearchEnable]);

    const url = new URL(window.location.href);
    const searchQuery = url.searchParams.get('query');
    const callTrackSearchContent = TrackSearchContent();

    useEffect(() => {
        if (searchQuery) {
            callTrackSearchContent(searchQuery);
        }
    }, [searchQuery]);

    if (!searchQuery) {
        return <ErrorView notFound={true} />;
    }

    useEffect(() => {
        DebugBearMonitoring('Search');
    }, []);

    if (!constructorConfigLoaded) {
        return <LoadingIndicator />;
    }

    if (!constructorSearchEnable && loadingComponents) {
        return <LoadingIndicator />;
    }

    return (
        <>
            {constructorSearchEnable ? (
                <ConstructorPLPManager
                    prompts={{
                        isCategory: false,
                        constructorApiKey,
                        constructorSearchProductResultPerPage,
                        constructorSearchNetworkTimeout,
                        enableVisibilityPreFilter,
                    }}
                >
                    <ConstructorSearchResult {...props} />
                </ConstructorPLPManager>
            ) : (
                SearchResult &&
                PLPManager && (
                    <Suspense fallback={<LoadingIndicator />}>
                        <PLPManager>
                            <SearchResult {...props} />
                        </PLPManager>
                    </Suspense>
                )
            )}
        </>
    );
};
