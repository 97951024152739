import { useContext } from 'react';
import { SetNotificationStore } from '../NotificationContexts';

export const useSetNotification = () => {
    const setNotification = useContext(SetNotificationStore);

    return {
        setNotification: (variant, content, duration) => {
            setNotification({ variant, content, duration });
        },
        clearNotification: () => {
            setNotification({
                variant: null,
                content: null,
                duration: null
            });
        }
    };
};
