import React, { useState } from 'react';
import { object, array, func, string } from 'prop-types';
import { CartEditItemOption } from '../CartEditItemOption/CartEditItemOption';
import { getMatchingVariant } from '../util';
import classNames from 'classnames';

export const CartEditItemOptions = props => {
    const {
        selectedValues,
        onSelectionChange,
        configItem,
        colorAttrCode
    } = props;

    const { configurable_options: options, variants } = configItem;

    const [optionSelections, setOptionsSelection] = useState(new Map());

    const [selectedMap, setSelectedMap] = useState(new Map());

    const handleVariantChange = optionSelection => {
        const { sku, product } = getMatchingVariant(variants, optionSelection);

        onSelectionChange(sku, product);
    };

    if (optionSelections.size === 0) {
        for (const _ref of options) {
            const { attribute_code, attribute_id, values } = _ref;
            optionSelections.set(attribute_code, values[0].value_index);
        }
    }

    for (const { option_label, value_label } of selectedValues) {
        selectedMap.set(option_label, value_label);
    }

    return options.map(option => {
        const optionClass = classNames({
            'option-wrapper': true,
            [`option-wrapper-${option.attribute_code}`]: true
        });

        const optionListClass = classNames({
            'option-list': true,
            [`option-list-${option.attribute_code}`]: true
        });

        return (
            <div className={optionClass} key={option.attribute_id}>
                <h3 className={'option-title'}>{option.label}</h3>

                <ul className={optionListClass}>
                    <CartEditItemOption
                        selectedLabel={selectedMap.get(option.label)}
                        option={option}
                        handleVariantChange={handleVariantChange}
                        optionSelections={optionSelections}
                        colorAttrCode={colorAttrCode}
                    />
                </ul>
            </div>
        );
    });
};

CartEditItemOptions.propTypes = {
    configItem: object,
    onSelectionChange: func,
    selectedValues: array,
    colorAttrCode: string
};
