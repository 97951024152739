import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-apollo';
import Skeleton from 'react-loading-skeleton';
import { object, func, string } from 'prop-types';
import { useAddToWishlist } from '@corratech/add-to-wishlist';
import { AuthStore, CartStore } from '@corratech/context-provider';
import removeItemFromCart from '../queries/removeItemFromCart.graphql';
import { SweatIcon } from '../Icons';

export const MoveCartItemToWishlist = props => {
    const { item, setMessage, className, promptText, successMessage } = props;

    const [t] = useTranslation();

    const addToWishlist = useAddToWishlist();

    const { cartState, dispatch } = useContext(CartStore);

    const { authState } = useContext(AuthStore);

    const [removeCartItem] = useMutation(removeItemFromCart);

    const [isLoading, setIsLoading] = useState(false);

    const moveCartItemToWishlist = () => {
        setIsLoading(true);

        addToWishlist(parseInt(item.product.id), item.quantity)
            .then(() => {
                removeCartItem({
                    variables: {
                        cartId: cartState.cartId,
                        itemId: item.id
                    }
                })
                    .then(res => {
                        dispatch({
                            type: 'SET_CART',
                            cart: res.data.removeItemFromCart.cart
                        });
                        setIsLoading(false);
                        if (setMessage)
                            setMessage(
                                `${item.product.name}${t(successMessage)}`
                            );
                    })
                    .catch(e => {
                        setIsLoading(false);
                        console.log(e);
                    });
            })
            .catch(e => {
                setIsLoading(false);
                console.log(e);
            });
    };

    if (authState && authState.token && authState.user.id) {
        return (
            <div className={`move-to-wishlist ${className || ''}`}>
                {!isLoading ? (
                    <Link
                        to={'#'}
                        title={t(promptText)}
                        onClick={moveCartItemToWishlist}
                    >
                        {t(promptText)}
                    </Link>
                ) : (
                    <Skeleton width={120} height={18} />
                )}
            </div>
        );
    }
    return null;
};

MoveCartItemToWishlist.propTypes = {
    item: object.isRequired,
    setMessage: func,
    className: string,
    promptText: string,
    successMessage: string
};

MoveCartItemToWishlist.defaultProps = {
    promptText: 'Move to Wishlist',
    successMessage: '  has been moved to your wish list.'
};
