import { deepValue } from "@corratech/tag-manager";

export const getCategoryName = (data) => {
    let categories = deepValue(data, ['categoryList']) || deepValue(data, ['categories']);
    if (!Array.isArray(categories)) {
        return '';
    }
    let categoryName = '';
    categories.map((item) => {
        if (!categoryName && !!item.name) {
            categoryName = item.name;
        }
    });

    return categoryName;
};