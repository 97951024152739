import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SelectInputFormik, TextInputFormik } from '@corratech/form-components';
import { Field, Form, Formik } from 'formik';
import * as yup from 'yup';
import { Info } from 'react-feather';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { AutoSubmit } from '../AutoSubmit';
import { Field as CorraField } from '../Field';
import QUERY_GET_COUNTRY from './graphql/QUERY_GET_COUNTRY.graphql';
import './addressForm.less';
import {
    AuthStore,
    LoaderStore,
    useGlobalOptions
} from '@corratech/context-provider';

export function AddressForm({
    initialValues,
    onFormSubmit,
    autoSubmitOff,
    authMode,
    setEditingAddress
}) {
    const [t] = useTranslation();
    const { authState } = useContext(AuthStore);

    const LoadingIndicator = useContext(LoaderStore);
    const options = useGlobalOptions();
    const { storeConfig } = options;

    const {
        loading: loadingCountry,
        error: errorCountry,
        data: countryData
    } = useQuery(QUERY_GET_COUNTRY);

    const getRegionOptionsForSelectedCountry = (
        countryData,
        currentCountryCode
    ) => {
        if (countryData && countryData.countries) {
            const currentCountry = countryData.countries.find(
                country => country.id === currentCountryCode
            );

            let items = [];

            if (currentCountry && currentCountry.available_regions) {
                items = [
                    {
                        value: '',
                        label: t('Please select a region, state or province.')
                    }
                ];
                currentCountry.available_regions
                    .sort(function(a, b) {
                        const textA = a.name.toUpperCase();
                        const textB = b.name.toUpperCase();
                        return textA < textB ? -1 : textA > textB ? 1 : 0;
                    })
                    .map(region => {
                        items.push({
                            value: region.code,
                            label: region.name
                        });
                    });
            }

            return items;
        } else {
            return {
                value: '',
                label: t('Please select a region, state or province.')
            };
        }
    };

    const formRegExp = {
        alphabets: new RegExp(/^[a-z\-'\s]+$/i),
        street: new RegExp(
            /^[ \w]{3,}([A-Za-z]\.)?([ \w]*\#\d+)?(\r\n| )[ \w]{3,}/
        ),
        city: new RegExp(/^[a-z\-\s]+$/i),
        zip: {
            us: new RegExp(/(^\d{5}$)|(^\d{5}-\d{4}$)/),
            uk: new RegExp(
                /^[a-zA-Z]{1,2}([0-9]{1,2}|[0-9][a-zA-Z])\s*[0-9][a-zA-Z]{2}$/
            )
        },
        phone: new RegExp(
            /^[\\+]?((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
        )
    };

    const validationSchema = yup.object().shape({
        firstname: yup
            .string()
            .required(t('First Name is required'))
            .test(
                'alphabets',
                t('First Name must only contain letters'),
                value => {
                    return formRegExp.alphabets.test(value);
                }
            ),
        lastname: yup
            .string()
            .required(t('Last Name is required'))
            .test(
                'alphabets',
                t('Last Name must only contain letters'),
                value => {
                    return formRegExp.alphabets.test(value);
                }
            ),
        street1: yup.string().required(t('Street Address is required')),
        city: yup.string().required(t('City is required')),
        region: yup.string().required(t('State/Province is required')),
        postcode: yup.string().required(t('ZIP/POSTAL CODE is required')),
        countryCode: yup.string().required(t('Country is required')),
        telephone: yup
            .string()
            .required(t('Phone Number is required'))
            .matches(formRegExp.phone, t('Phone number is not valid'))
    });

    if (loadingCountry) {
        return <LoadingIndicator />;
    }

    return (
        <Formik
            enableReinitialize={true}
            initialValues={
                initialValues
                    ? {
                          ...initialValues,
                          company: initialValues.company
                              ? initialValues.company
                              : '',
                          region: initialValues.region.code,
                          countryCode: initialValues.country.code,
                          street1: initialValues.street[0],
                          street2: initialValues.street[1]
                              ? initialValues.street[1]
                              : '',
                          save_in_address_book: false
                      }
                    : {
                          firstname: !!authState.user.firstname
                              ? authState.user.firstname
                              : '',
                          lastname: !!authState.user.lastname
                              ? authState.user.lastname
                              : '',
                          company: '',
                          street1: '',
                          street2: '',
                          city: '',
                          region: '',
                          postcode: '',
                          countryCode: storeConfig.general_country_default
                              ? storeConfig.general_country_default
                              : 'GB',
                          telephone: '',
                          save_in_address_book: false
                      }
            }
            onSubmit={values =>
                onFormSubmit({
                    variables: {
                        ...values,
                        street: [values.street1, values.street2]
                    }
                })
            }
            validationSchema={validationSchema}
        >
            {({ values, submitForm, isValid, dirty, setFieldValue }) => (
                <>
                    <Form>
                        <CorraField
                            label="First Name"
                            labelText={t(`first_name`)}
                            required={true}
                        >
                            <Field
                                name="firstname"
                                component={TextInputFormik}
                                type="text"
                                id="first_name"
                            />
                        </CorraField>

                        <CorraField
                            label="Last Name"
                            labelText={t(`last_name`)}
                            required={true}
                        >
                            <Field
                                name="lastname"
                                component={TextInputFormik}
                                type="text"
                                id="last_name"
                            />
                        </CorraField>

                        <CorraField label="Company" labelText={t(`company`)}>
                            <Field
                                name="company"
                                component={TextInputFormik}
                                type="text"
                                id="company"
                            />
                        </CorraField>

                        <CorraField
                            label="Street Address"
                            labelText={t(`street_address_1`)}
                            required={true}
                        >
                            <Field
                                name="street1"
                                component={TextInputFormik}
                                type="text"
                                id="street_address_1"
                            />
                        </CorraField>

                        <CorraField
                            label="Apartment / Suite / Other (optional)"
                            labelText={t(`street_address_2`)}
                        >
                            <Field
                                name="street2"
                                component={TextInputFormik}
                                type="text"
                                id="street_address_2"
                            />
                        </CorraField>

                        <CorraField
                            label="City"
                            labelText={t(`city`)}
                            required={true}
                        >
                            <Field
                                name="city"
                                component={TextInputFormik}
                                type="text"
                                id="city"
                            />
                        </CorraField>
                        <div className={'stateZipCodeWrapper'}>
                            <CorraField
                                label="State/Province"
                                labelText={t(`stateProvince`)}
                                required={true}
                            >
                                {getRegionOptionsForSelectedCountry(
                                    countryData,
                                    values.countryCode
                                ).length > 0 ? (
                                    <Field
                                        name="region"
                                        component={SelectInputFormik}
                                        items={getRegionOptionsForSelectedCountry(
                                            countryData,
                                            values.countryCode
                                        )}
                                    />
                                ) : (
                                    <Field
                                        name="region"
                                        component={TextInputFormik}
                                        type="text"
                                    />
                                )}
                            </CorraField>
                            <CorraField
                                label="Zip/Postal Code "
                                labelText={t(`zipPostalCode`)}
                                required={true}
                            >
                                <Field
                                    name="postcode"
                                    component={TextInputFormik}
                                    type="text"
                                    id="zipPostalCode"
                                />
                            </CorraField>
                        </div>

                        <CorraField label="Country">
                            <Field
                                name="countryCode"
                                component={SelectInputFormik}
                                items={countryData.countries
                                    .sort(function(a, b) {
                                        const textA = a.full_name_locale
                                            ? a.full_name_locale.toUpperCase()
                                            : '';
                                        const textB = b.full_name_locale
                                            ? b.full_name_locale.toUpperCase()
                                            : '';
                                        return textA < textB
                                            ? -1
                                            : textA > textB
                                            ? 1
                                            : 0;
                                    })
                                    .map(country => ({
                                        value: country.id,
                                        label: country.full_name_locale
                                    }))}
                                handleOnChange={() =>
                                    setFieldValue('region', '')
                                }
                            />
                        </CorraField>

                        <CorraField
                            label="Phone Number"
                            labelText={t(`phoneNumber`)}
                            required={true}
                        >
                            <Field
                                name="telephone"
                                component={TextInputFormik}
                                type="text"
                                id="phoneNumber"
                            />
                            <div className={'wrapping-svg'}>
                                <OverlayTrigger
                                    placement="top-start"
                                    overlay={props => (
                                        <Tooltip
                                            {...props}
                                            id={`tooltip-account-info`}
                                            show={props.show.toString()}
                                        >
                                            {t('For delivery questions.')}
                                        </Tooltip>
                                    )}
                                >
                                    <Button variant="success" className="tooltip-btn"><Info size={16} /></Button>
                                </OverlayTrigger>
                            </div>
                        </CorraField>
                        {authMode && (
                            <div className={'customCheck'}>
                                <CorraField
                                    isChecked={values['save_in_address_book']}
                                    labelText={'save_in_address_book'}
                                    label="Save in Address Book"
                                >
                                    <Field
                                        name="save_in_address_book"
                                        component={TextInputFormik}
                                        type="checkbox"
                                        id="save_in_address_book"
                                    />
                                </CorraField>
                            </div>
                        )}

                        {autoSubmitOff && (
                            <div className="button-container">
                                {!!setEditingAddress && (
                                    <div className={'step-btn-block'}>
                                        <Button
                                            variant="link"
                                            onClick={() =>
                                                setEditingAddress(false)
                                            }
                                        >
                                            {t(`Cancel`)}
                                        </Button>
                                    </div>
                                )}
                                <div className={'step-btn-block'}>
                                    <Button
                                        size="lg"
                                        variant="primary"
                                        type="submit"
                                    >
                                        {t(`Update`)}
                                    </Button>
                                </div>
                            </div>
                        )}
                    </Form>
                    {!autoSubmitOff && (
                        <AutoSubmit
                            submitForm={submitForm}
                            timeToDebounce={1000}
                            dirty={dirty}
                            values={values}
                            isValid={isValid}
                        />
                    )}
                </>
            )}
        </Formik>
    );
}
