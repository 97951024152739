import {
    getAdvanced,
    getBackgroundImages,
    getVerticalAlignment
} from '../../utils';

export default node => {
    return {
        tabName: node.getAttribute('data-tab-name'),
        tabUrl: node.getAttribute('data-tab-url'),
        minHeight: node.style.minHeight,
        fastlyParamsMob: node.getAttribute('data-mobile-fastly-params')
            ? node.getAttribute('data-mobile-fastly-params')
            : null,
        fastlyParamsDesktop: node.getAttribute('data-desktop-fastly-params')
            ? node.getAttribute('data-desktop-fastly-params')
            : null,
        ...getVerticalAlignment(node),
        backgroundColor: node.style.backgroundColor,
        ...getBackgroundImages(node),
        ...getAdvanced(node)
    };
};
