import {
    CART_PAGE,
    CHECKOUT_PAGE,
    PDP_PAGE,
    PLACE_ORDER,
    PLP_PAGE,
    SEARCH,
    cartItemMapping,
    getCategoryPath,
    getCategoryName,
    getMapProductDetails,
    getCartItemPrice,
    getConfigurableVariant
} from './mapping';

const cartItemMappingValue = cartItemMapping(
    getCategoryPath,
    getCartItemPrice,
    getConfigurableVariant
);

const mapProductDetails = getMapProductDetails(getCategoryPath);

export const defaultMapping = {
    PDP_PAGE: PDP_PAGE(getCategoryPath, getCategoryName),
    ADD_TO_CART: {
        ...cartItemMappingValue,
        ...CART_PAGE(cartItemMappingValue),
        quantityChange: ['quantityChange']
    },
    REMOVE_TO_CART: {
        ...cartItemMappingValue,
        ...CART_PAGE(cartItemMappingValue),
        quantityChange: ['quantityChange']
    },
    PLP_PAGE: PLP_PAGE(mapProductDetails),
    PRODUCT_CLICK: {
        id: ['id'],
        name: ['name'],
        sku: ['sku'],
        price: ['price_range', 'minimum_price', 'regular_price', 'value'],
        currency_code: ['price_range', 'minimum_price', 'regular_price', 'currency'],
        product_type: ['__typename'],
        category: ['category', 'name']
    },
    PROMOTION_VIEW: {
        id: ['id'],
        name: ['name']
    },
    CUSTOMER_EVENTS: false,
    RESET_DATALAYER: false,
    SEARCH: SEARCH(mapProductDetails),
    CART_PAGE: CART_PAGE(cartItemMappingValue),
    CHECKOUT_PAGE: CHECKOUT_PAGE(cartItemMappingValue),
    PLACE_ORDER: PLACE_ORDER(cartItemMappingValue),
    PAGE_VIEW: false,
    PAGE_VIEW_DATA: false,
    TRIGGER_IMMEDIATELY: false
}
