import { Util } from '@magento/peregrine';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { object } from 'prop-types';
import { useMutation, useLazyQuery } from 'react-apollo';
import { AuthStore } from './AuthContext';
import createEmptyCart from './mutations/createEmptyCart.graphql';
import cartQuery from './queries/cartQuery.graphql';
import { queryCustomerCart } from './queries/queryCustomerCart';
import { mutationMergeCarts } from './mutations/mutationMergeCarts';

const { BrowserPersistence } = Util;
const storage = new BrowserPersistence();

export const CartStore = React.createContext();

let getCustomerCart = null;
let refreshCart = null;

const initialState = {
    cartId: '',
    cart: {
        isBillingSameAsShipping: true,
        requireGiftMessage: false
    },
    shouldOpenDrawer: false,
    placedOrder: null,
    isLoading: false
};

function reducer(state, action) {
    switch (action.type) {
        case 'SET_ALL':
            storage.setItem('cartId', action.cartId);
            storage.setItem('cart', { ...state.cart, ...action.cart });
            return {
                ...state,
                cartId: action.cartId,
                cart: { ...state.cart, ...action.cart }
            };
        case 'SET_CART_ID':
            storage.setItem('cartId', action.cartId);
            return { ...state, cartId: action.cartId };
        case 'SET_CART':
            storage.setItem('cart', { ...state.cart, ...action.cart });
            return { ...state, cart: { ...state.cart, ...action.cart } };
        case 'SHOULD_OPEN_DRAWER':
            return { ...state, shouldOpenDrawer: action.shouldOpenDrawer };
        case 'CLEAR_CART':
            storage.removeItem('cart');
            storage.removeItem('cartId');
            return {
                cartId: '',
                cart: {
                    isBillingSameAsShipping: true,
                    requireGiftMessage: false
                },
                shouldOpenDrawer: false,
                placedOrder: null
            };
        case 'PLACED_ORDER':
            storage.removeItem('cart');
            storage.removeItem('cartId');
            return {
                cartId: '',
                cart: {
                    isBillingSameAsShipping: true,
                    requireGiftMessage: false
                },
                shouldOpenDrawer: false,
                placedOrder: action.placedOrder
            };
        case 'CLEAR_PLACED_ORDER':
            return {
                ...state,
                placedOrder: null
            };
        case 'OVERRIDE_CART_DATA':
            storage.setItem('cart', action.cartState.cart);
            storage.setItem('cartId', action.cartState.cartId);
            return action.cartState;
        case 'SET_LOADING':
            return {
                ...state,
                isLoading: action.isLoading
            };
        default:
            return state;
    }
}

export const CartProvider = props => {
    const { cartQuery, customerCartQuery, mergeCartMutation } = props;
    const [cartState, dispatch] = useReducer(reducer, initialState);
    const { authState, dispatch: authStateDispatch } = useContext(AuthStore);

    const [mergeCarts] = useMutation(mergeCartMutation);

    /**
     * Manage the global loading handler
     * @param state {Boolean}
     */
    const setLoading = isLoading => {
        dispatch({ type: 'SET_LOADING', isLoading });
    };

    const [getCart] = useLazyQuery(cartQuery, {
        fetchPolicy: 'no-cache',
        onCompleted: res => {
            dispatch({
                type: 'SET_CART',
                cart: {
                    ...res.cart,
                    authenticated: !!authState.token ? true : false
                }
            });
            setLoading(false);
        },
        onError: () => {
            setLoading(false);
        }
    });

    const [customerCart] = useLazyQuery(customerCartQuery, {
        fetchPolicy: 'network-only',
        onCompleted: res => {
            if (!!cartState.cart.items && cartState.cart.items.length > 0) {
                mergeCarts({
                    variables: {
                        sourceCartId: cartState.cartId,
                        destinationCartId: res.customerCart.id
                    }
                })
                    .then(resMerge => {
                        dispatch({
                            type: 'SET_ALL',
                            cartId: res.customerCart.id,
                            cart: {
                                ...resMerge.data.mergeCarts,
                                shipping_addresses: null,
                                billing_address: null,
                                authenticated: true
                            }
                        });
                        setLoading(false);
                    })
                    .catch(() => {
                        setLoading(false);
                    });
            } else {
                dispatch({
                    type: 'SET_ALL',
                    cartId: res.customerCart.id,
                    cart: {
                        ...res.customerCart,
                        shipping_addresses: null,
                        billing_address: null,
                        authenticated: true
                    }
                });
                setLoading(false);
            }
        },
        onError: () => {
            setLoading(false);
        }
    });
    useEffect(() => {
        getCustomerCart = customerCart;
    }, [customerCart]);

    const [makeCart] = useMutation(createEmptyCart);

    // Initializing cart
    // 1. check if cartId is in the localStorage, if yes set cartState with cartId
    // 2. else make a new cart id and set cartState with cartId
    useEffect(() => {
        if (cartState.cartId === '') {
            if (storage.getItem('cartId')) {
                dispatch({
                    type: 'SET_CART_ID',
                    cartId: storage.getItem('cartId')
                });
            } else if (!props.stopMakeCart) {
                setLoading(true);
                makeCart()
                    .then(makeCartRes => {
                        dispatch({
                            type: 'SET_ALL',
                            cartId: makeCartRes.data.createEmptyCart,
                            cart: {
                                authenticated: !!authState.token
                            }
                        });
                        setLoading(false);
                    })
                    .catch(() => {
                        setLoading(false);
                    });
            }
        }
    }, [cartState.cartId]);

    useEffect(() => {
        // Expose the clear-cart dispatch event to an outside function
        // This will allow the error-handling middleware to reset the cart
        // This clear-cart also triggers new-cart creation
        refreshCart = () => {
            dispatch({
                type: 'CLEAR_CART'
            });
        };
    }, [makeCart, dispatch]);

    const urlParams = new URLSearchParams(window.location.search);

    // if localStorage has cart (products in the cart) -> populate cart with them
    useEffect(() => {
        //setting the auth token to local storage is handled in AuthContext.js, this if condition is just to fetch the customer cart on-app-start if url has `aToken`
        if (urlParams.has('aToken')) {
            setLoading(true);
            customerCart();
        } else if (storage.getItem('cartId')) {
            setLoading(true);
            getCart({
                variables: {
                    cartId: storage.getItem('cartId'),
                    isSignedIn: !!authState.token
                }
            });
        }
    }, []);

    useEffect(() => {
        // login happened
        if (authState.token) {
            if (authState.justSignedIn && !props.stopMakeCart) {
                setLoading(true);
                customerCart();
                authStateDispatch({
                    type: 'SET_AUTH_TOKEN',
                    ...authState,
                    justSignedIn: false
                });
            }
        }

        // logout
        if (!authState.token) {
            if (authState.justLogout) {
                dispatch({
                    type: 'CLEAR_CART'
                });
                authStateDispatch({
                    type: 'SIGN_OUT',
                    ...authState,
                    justLogout: false
                });
            }
        }
    }, [authState]);

    return (
        <CartStore.Provider value={{ cartState, dispatch }}>
            {props.children}
        </CartStore.Provider>
    );
};

export { getCustomerCart, refreshCart };

CartProvider.defaultProps = {
    customerCartQuery: queryCustomerCart,
    cartQuery: cartQuery,
    mergeCartMutation: mutationMergeCarts
};

CartProvider.propTypes = {
    cartQuery: object,
    customerCartQuery: object,
    mergeCartMutation: object
};
