import gql from 'graphql-tag';

export const mutationMergeCarts = gql`
    mutation MergeCarts($sourceCartId: String!, $destinationCartId: String!) {
        mergeCarts(
            source_cart_id: $sourceCartId
            destination_cart_id: $destinationCartId
        ) {
            is_virtual
            messages {
                text
                type
                is_item_message
            }
            items {
                id
                product {
                    id
                    name
                    sku
                    stock_status
                    thumbnail {
                        label
                        url
                    }

                    price {
                        regularPrice {
                            amount {
                                currency
                                value
                            }
                        }
                    }
                    special_price
                    url_key
                }
                ... on ConfigurableCartItem {
                    item_stock_status
                    configurable_options {
                        id
                        option_label
                        value_id
                        value_label
                    }
                }
                ... on GiftCardCartItem {
                    customizable_options {
                        label
                        values {
                            value
                        }
                    }
                    giftcard_options {
                        giftcard_amount {
                            value
                            currency
                        }
                        giftcard_sender_name
                        giftcard_recipient_name
                        giftcard_sender_email
                        giftcard_recipient_email
                        giftcard_message
                    }
                }
                prices {
                    price {
                        value
                    }
                }
                quantity
            }
            prices {
                grand_total {
                    value
                    currency
                }
                applied_taxes {
                    label
                    amount {
                        value
                        currency
                    }
                }
                subtotal_including_tax {
                    value
                    currency
                }
                subtotal_excluding_tax {
                    value
                    currency
                }
                subtotal_with_discount_excluding_tax {
                    value
                    currency
                }
            }
            applied_store_credit {
                applied_balance {
                    currency
                    value
                }
                current_balance {
                    currency
                    value
                }
                enabled
            }
        }
    }
`;
