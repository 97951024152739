import React, { useState, useEffect, useCallback } from 'react';
import './QuantitySelector.less';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useGlobalOptions } from '@corratech/context-provider';
import debounce from "lodash.debounce";

export const QuantitySelector = props => {
    const { quantity, setQuantity, loading, minQuantity, showRemovePopup } = props;
    const projectConfig = useGlobalOptions();
    const [ qty, setQty ] = useState(quantity);
    const [ qtyDebounce, setQtyDebounce] = useState(false);

    const maximum =
        projectConfig &&
        projectConfig['quantity-selector'] &&
        projectConfig['quantity-selector'].maximum
            ? projectConfig['quantity-selector'].maximum
            : 1000;

    const [t] = useTranslation();

    if (!!props.maxQuantity && process.env.NODE_ENV === 'development') {
        console.log(
            "The maxQuantity quantity selector prop has been deprecated. Please use projectConfig['quantity-selector'].maximum instead."
        );
    }

    const increment = () => {
        if (quantity < maximum) {
            setQtyDebounce(false);
            setQty(quantity + 1);
        }
    };

    const decrement = () => {
        if (quantity > minQuantity) {
            setQtyDebounce(false);
            setQty(quantity - 1);
        }
        //This is for showing the removing item from cart popup when quantity is 1 and user clicks on decrement button again
        if(quantity == 1){
            if(showRemovePopup) showRemovePopup();
        }
    };

    const setQuantityDirectly = event => {
        const input = parseInt(event.target.value);
        setQtyDebounce(true);
        if (input > maximum) {
            setQty(maximum);
        } else {
            setQty(input);
        }
    };

    const focusOutEvent = event => {
        const input = parseInt(event.target.value);
        if (isNaN(input) || input < 0) {
            setQtyDebounce(false);
            setQty(minQuantity);
        }
    };

    const updateQty = useCallback(
        debounce(qty => {
            setQty(qty);
            setQuantity(qty);
        }, 500),
        []
      );

    useEffect(() => {
        if(qty !== '' && qty !== quantity){
            if(qtyDebounce){
                updateQty(qty);
            } else {
                setQuantity(qty);
            }
        }
    },[qty]);

    useEffect(() => {
        if(quantity !== qty)
            setQty(quantity);
    },[quantity]);

    return (
        <div className={'qty-selector-items'}>
            <button
                type="button"
                className={
                    'qty-btn decrement-btn' +
                    (quantity === minQuantity ? ' min-qty-reached' : '')
                }
                onClick={decrement}
                disabled={loading || !quantity}
            >
                <span className={'sr-only'}>
                    {t('Decrease product item quantity')}
                </span>
                <span className={'icon-style'} aria-hidden="true">
                    –
                </span>
            </button>
            <input
                className={'quantity-input'}
                type="number"
                id="qty"
                title={t('Quantity')}
                value={qty}
                placeholder={props.placeholder}
                onChange={setQuantityDirectly}
                onBlur={focusOutEvent}
                name={props.name}
                disabled={loading}
            />
            <label className={'qty-label sr-only'} htmlFor="qty">
                {t('Quantity')}
            </label>
            <button
                type="button"
                className={
                    'qty-btn increment-btn' +
                    (quantity === maximum ? ' max-qty-reached' : '')
                }
                onClick={increment}
                disabled={loading}
            >
                <span className={'sr-only'}>
                    {t('Increase product item quantity')}
                </span>
                <span className={'icon-style'} aria-hidden="true">
                    +
                </span>
            </button>
        </div>
    );
};

QuantitySelector.defaultProps = {
    loading: false,
    minQuantity: 1,
    placeholder: '',
    name: 'product-qty'
};

QuantitySelector.propTypes = {
    setQuantity: PropTypes.func.isRequired,
    quantity: PropTypes.number.isRequired,
    minQuantity: PropTypes.number,
    loading: PropTypes.bool,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    showRemovePopup: PropTypes.func
};
