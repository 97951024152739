import React from 'react';
import PropTypes from 'prop-types';

const CH = props => {
    const { color, size, ...otherProps } = props;

    return (
        <svg
            version="1.2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 48 48"
            width={size}
            height={size}
            {...otherProps}
        >
            <defs>
                <image
                    width="48"
                    height="48"
                    id="img1"
                    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAMAAABg3Am1AAAAAXNSR0IB2cksfwAAAIRQTFRFAAAA1Sse1Sse1Sse1Sse1Sse1Sse1Sse1Sse1Sse1Sse1Sse1Sse1Sse1Sse1Sse1Sse1Sse1Sse1Sse1Sse1Sse1Sse1Sse1Sse1Sse1Sse1Sse1Sse4GBW6ZCJ1Sse1Sse1Sse65qU////1Sse1ise9s/M1Sse1Sse1Ssd1Cse1SseM+Y+sQAAACx0Uk5TABtmpcji9f0Tc8n8/8p0FAI0lO6Vde0BMb0EYOf//wN28v//9gH/ZTABATMR6WYmAAABTElEQVR4nJWW6XaCMBCFww4yVhZBkEVAq7Z9//crAWUHc++fnJPcL4FkMhPGppJkRdV0w9A1VZGl2fBEprWzaSB7/3VYdzuu59NM/tF1lv2HIJzbGyRYWuUUxct2rjg6Tf3nJH2PZnmn7N2XJuex/1KU3XTVtVPVdZbFZTR/caNtgG7FYI3vpJ9/DaAy6f8jSukzQGnU7ed4f9YAil+76wQkBlDQnqDriwKh2wBHEgXI435zGj8bgG/WgEXiAFk1sEeAXX1fbASwJSbzNqsGuvfAfdjfRKLMFN7kVwHl3KkwFQNUpmGAxnQM0JmBAQYOwJ8E/zS8rfDBgaHxwIMPDm/8AsFXlHnigNemmVAUeKUZOJHBqVI4GT+77P0jlu5/BwUl0D8Bo4JSl6wAK1l4UazL7hMruwwu7PwE/7CnA9f8cWKZ6+5W0mPz+fMPCGqhuem43gEAAAAASUVORK5CYII="
                />
            </defs>
            <style></style>
            <use href="#img1" x="0" y="0" />
        </svg>
    );
};

CH.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

CH.defaultProps = {
    color: 'currentColor',
    size: '18'
};

export default CH;
