import React, { useState, useContext } from 'react';
import { bool, node, shape, string } from 'prop-types';
import { OptionsStore } from '@corratech/context-provider';
import classNames from 'classnames/bind';
import style from './Field.less';
let examineClasses = classNames.bind(style);

export const Field = props => {
    const { children, label, labelText } = props;

    const fcOptions = useContext(OptionsStore)['form-components'];
    const focusEventEnabled = !!fcOptions && fcOptions.focusEventEnabled;

    const RequiredSymbol = () => {
        return props.required ? (
            <span className={examineClasses('requiredSymbol')}>*</span>
        ) : null;
    };

    const [fieldFocus, setFieldFocus] = useState(false);
    const [enableErrorClass, setEnableErrorClass] = useState(false);

    let elements = null;
    let child = children.length > 1 ? children[0] : children;
    if (focusEventEnabled && child.type.displayName === 'asField(TextInput)') {
        elements = React.cloneElement(child, {
            focusEventEnabled: focusEventEnabled,
            setFieldFocus: setFieldFocus,
            setEnableErrorClass: setEnableErrorClass
        });
    } else {
        elements = children;
    }

    return (
        <div
            className={examineClasses(
                `field-wrapper ${focusEventEnabled ? ' can-focus' : ''} ${
                    enableErrorClass ? ' error-input' : ''
                } ${fieldFocus ? ' show-label is-focused' : ''}`
            )}
        >
            <label htmlFor={labelText} className={examineClasses('label')}>
                {label}
                <RequiredSymbol />
            </label>
            {elements}
        </div>
    );
};

Field.propTypes = {
    children: node,
    classes: shape({
        label: string,
        root: string
    }),
    label: node,
    required: bool
};
