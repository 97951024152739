import { cartItemMapping } from './cartItemMapping';

export const CHECKOUT_PAGE = (itemMapping = cartItemMapping()) => {
    return {
        step: ['step'],
        description: ['description'],
        action: ['action'],
        currency: ['cart', 'prices', 'grand_total', 'currency'],
        productsDetails: {
            data_source: ['cart', 'items'],
            mapping: itemMapping,
            strict: true
        }
    };
}