import React, { useEffect } from 'react';
import { Util } from '@magento/peregrine';
const { BrowserPersistence } = Util;
const storage = new BrowserPersistence();

export default function useListrakAbandonedCart() {
    try {
        /**
         * fetch cartID from the listrak url and setting in storage
         */
        let params = new URLSearchParams(location.search);
        let urlCartId = params.get('ltkMeta1');
        if (urlCartId && urlCartId != '') {
            storage.setItem('cartId', urlCartId);
        }
    } catch (e) {
        console.warn(e);
    }
}
