import gql from 'graphql-tag';

const MUTATION_SET_GUEST_EMAIL_ON_CART = gql`
    mutation setGuestEmailOnCart($cartId: String!, $email: String!) {
        setGuestEmailOnCart(input: { cart_id: $cartId, email: $email }) {
            cart {
                email
            }
        }
    }
`;

export default MUTATION_SET_GUEST_EMAIL_ON_CART;
