import { useEffect } from 'react';
import { createScript } from './utils/createScript';
import { useGlobalOptions } from '@corratech/context-provider';

export default function useKlarna() {
    const options = useGlobalOptions();

    const config = {
        enabled: options.storeConfig.klarna_enabled,
        enabledStore: options.storeConfig.klarna_enabled_in_stores,
        sdkUrl: options.storeConfig.klarna_placement_sdk_url,
        clientId: options.storeConfig.klarna_placement_client_id,
        environment: options.storeConfig.klarna_placement_environment,
        storeCode: options.storeConfig.code
    };

    const storeArray =
        config.enabledStore !== '' ? config.enabledStore.split(',') : [];

    if (config.enabled == 0 && !storeArray.includes(config.storeCode))
        return null;

    useEffect(() => {
        const klarnaGlobalEl = document.getElementById('klarna-global');

        if (config.enabled != 0 && storeArray.includes(config.storeCode)) {
            if (
                config.sdkUrl &&
                config.clientId &&
                klarnaGlobalEl == null
            ) {
                document.body.appendChild(
                    createScript(
                        config.sdkUrl,
                        config.clientId,
                        config.environment,
                        'klarna-global'
                    )
                );
            }
        }
        return () => {
            if (klarnaGlobalEl !== null) {
                document.getElementById('klarna-global').remove();
            }
        };
    }, [document]);
}
