import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CartStore } from '@corratech/context-provider';
import { getCurrencySymbol } from '../util/getCurrencySymbol';

export const RenderAppliedCredits = ({ className }) => (
    <div
        className={'render-applied-credits' + className ? ' ' + className : ''}
    >
        <RenderGiftCardData />
        <br />
        <RenderStoreCreditData />
    </div>
);

export const RenderGiftCardData = () => {
    const [t] = useTranslation();
    const { cartState } = useContext(CartStore);
    const giftCards = cartState.cart.applied_gift_cards;
    let totalBalance = 0;
    if (giftCards && giftCards.length > 0) {
        giftCards.forEach(card => (totalBalance += card.applied_balance.value));
        if (totalBalance > 0) {
            return (
                <div
                    className={'applied-gc-balance'}
                    style={{ marginBottom: 10 }}
                >
                    {t('Applied')}{' '}
                    {getCurrencySymbol(giftCards[0].applied_balance.currency) +
                        totalBalance.toFixed(2)}{' '}
                    {giftCards.length === 1
                        ? t('from a gift card to your order.')
                        : t('from gift cards to your order.')}
                </div>
            );
        }
    }
    return null;
};

export const RenderStoreCreditData = () => {
    const [t] = useTranslation();
    const { cartState } = useContext(CartStore);
    const storeCreditData = cartState.cart.applied_store_credit;
    if (
        storeCreditData &&
        storeCreditData.applied_balance &&
        storeCreditData.applied_balance.value > 0
    ) {
        return (
            <div className={'applied-sc-balance'}>
                {t('Applied')}{' '}
                {getCurrencySymbol(storeCreditData.applied_balance.currency) +
                    storeCreditData.applied_balance.value.toFixed(2)}{' '}
                {t('from store credit to your order.')}
            </div>
        );
    } else {
        return null;
    }
};
