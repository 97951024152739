import React from 'react';

export const VisaIcon = () => {
    return (
        <svg role="img" aria-label="Payment Method Visa Icon" xmlns="http://www.w3.org/2000/svg" width="20" height="8" viewBox="0 0 20 8"
             fill="none">
            <path
                d="M7.59817 0.788479L4.97717 7.23053H3.25114L1.98174 2.12551C1.96088 1.87952 1.81519 1.66289 1.59817 1.55517C1.09018 1.30332 0.553234 1.11798 0 1.00353V0.816529H2.73973C3.1126 0.816417 3.43018 1.09397 3.48858 1.47102L4.17352 5.21096L5.85388 0.825879L7.59817 0.788479ZM14.2922 5.12681C14.2922 3.42514 12.0091 3.33164 12.0274 2.5743C12.0274 2.34056 12.2466 2.09746 12.7123 2.03201C13.2579 1.97985 13.807 2.08002 14.3014 2.32186L14.5845 0.956777C14.1003 0.767646 13.5867 0.669459 13.0685 0.666931C11.4703 0.666931 10.3288 1.54582 10.3288 2.7987C10.3288 3.73368 11.1324 4.24793 11.7443 4.55647C12.3562 4.86502 12.5845 5.07071 12.5845 5.36056C12.5845 5.79065 12.0822 5.987 11.6164 5.987C11.037 5.99888 10.4645 5.85716 9.95434 5.5756L9.64384 6.98743C10.2253 7.22194 10.8454 7.3394 11.4703 7.33338C13.1689 7.33338 14.274 6.47319 14.2831 5.12681H14.2922ZM18.5023 7.23053H20L18.6941 0.788479H17.3242C17.0222 0.789394 16.7511 0.978142 16.6393 1.26532L14.21 7.23053H15.9087L16.2466 6.29554H18.3196L18.5023 7.23053ZM16.6758 4.94916L17.5251 2.52755L18.0457 4.93047L16.6758 4.94916ZM9.88128 0.788479L8.54795 7.23053H6.9589L8.29224 0.788479H9.88128Z"
                fill="#1A1F71"/>
        </svg>
)};