/** @jsx jsx */
import React, { Fragment } from 'react';
import { jsx } from '@emotion/core';
import { RichContent } from '@corratech/rich-content';
import { ChevronLeft, ChevronRight } from 'react-feather';
import { LeftIcon } from '../../../../Icons';
/**
 * Slider Previous Icon
 */
const NextIcon = props => {
    const { className, style, onClick, size } = props;
    return (
        <div className={className} style={{ ...style }} onClick={onClick}>
            <ChevronRight size={size} />
        </div>
    );
};

/**
 * Slider Previous Icon
 */
const PrevIcon = props => {
    const { className, style, onClick, size } = props;
    return (
        <div className={className} style={{ ...style }} onClick={onClick}>
            <ChevronLeft size={size} />
        </div>
    );
};

export const SubMenuCMS = props => {
    const {
        item,
        setActiveSubMenuItem,
        menuLevel,
        setMenuLevel,
        activeSubmenu,
        setActiveSubmenu,
        setActiveSubMenuItemChild,
        isTab
    } = props;

    const contentType = {
        column: {
            type: 'pushmenu-column',
            classes: 'pushmenu-column',
            props: {
                setActiveSubMenuItem: setActiveSubMenuItem,
                menuLevel: menuLevel,
                setMenuLevel: setMenuLevel,
                setActiveSubMenuItemChild: setActiveSubMenuItemChild
            }
        },
        tabs: {
            type: 'pb-accordion',
            classes: 'pb-accordion'
        }
    };

    const tabContentType = {
        tabs: {
            type: 'pb-accordion',
            classes: 'pb-accordion'
        }
    };

    const menuContentLevel3 =
        menuLevel == 3 &&
        item.map((header, i) => (
            <Fragment>
                <div className={'subcat-select-top-2'}>
                    {header.props.data.contentType === 'heading' ? (
                        <div
                            className={'menu-back'}
                            onClick={() => {
                                setMenuLevel(2);
                            }}
                        >
                            <button className={'menu-back'}>
                                <LeftIcon width={5} color={'#000'} />
                            </button>
                            {header}
                        </div>
                    ) : (
                        <div>{header}</div>
                    )}
                </div>
            </Fragment>
        ));
    //TODO: Add `isFeatured` to level 2 categories in backend
    if (item) {
        if (!isTab) {
            return (
                <RichContent
                    html={item.menu_content}
                    extend={true}
                    config={tabContentType}
                />
            );
        } else if (item.menu_content && menuLevel == 2) {
            return isTab ? (
                <div className={'pm-level2-category'}>
                    <RichContent
                        html={item.menu_content}
                        extend={true}
                        config={contentType}
                    />
                </div>
            ) : (
                ''
            );
        } else if (menuLevel == 3) {
            return <div className={'level-3-menu'}>{menuContentLevel3}</div>;
        }
    }
    return null;
};
