import React from 'react';
import detectPageBuilder from './PageBuilder/detectPageBuilder';
import PageBuilder from './PageBuilder';
import PageBuilderExtend from './PageBuilder/PageBuilderExtend';
import { mergeClasses } from './PageBuilder/classify';
import defaultClasses from './richContent.css';
import { shape, string, bool, object } from 'prop-types';

const toHTML = str => ({ __html: str });

/**
 * RichContent component.
 *
 * This component serves as the pool to determine which type of content is being rendered
 * and pass the data off to the correct system.
 *
 * @typedef RichContent
 * @kind functional component
 *
 * @param {Object} props React component props
 *
 * @returns {React.Element} A React component that renders Heading with optional styling properties.
 */
const RichContent = props => {
    const { html, config, extend, isLazyLoad } = props;
    const classes = mergeClasses(defaultClasses, props.classes);

    if (detectPageBuilder(html)) {
        if (extend) {
            return (
                <div className={classes.root}>
                    <PageBuilderExtend
                        masterFormat={html}
                        contentType={config}
                        isLazyLoad={isLazyLoad}
                    />
                </div>
            );
        } else {
            return (
                <div className={classes.root}>
                    <PageBuilder masterFormat={html} isLazyLoad={isLazyLoad} />
                </div>
            );
        }
    }

    return (
        <div className={classes.root} dangerouslySetInnerHTML={toHTML(html)} />
    );
};

/**
 * Props for {@link RichContent}
 *
 * @typedef props
 *
 * @property {Object} classes An object containing the class names for the RichContent
 * @property {String} classes.root CSS class for the root container element
 * @property {String} html Content
 */
RichContent.propTypes = {
    classes: shape({
        root: string
    }),
    html: string,
    extend: bool,
    config: object,
    isLazyLoad: bool
};

RichContent.defaultProps = {
    isLazyLoad: true
};

export default RichContent;
