import gql from 'graphql-tag';

export const addSimpleProductToWishlist = gql`
    mutation addProductToWishlist($items: [WishlistProduct]!) {
        addProductToWishlist(input: { items: $items }) {
            items_count
            name
            sharing_code
            updated_at
            items {
                id
                qty
                description
                added_at
                product {
                    sku
                    name
                }
            }
        }
    }
`;
