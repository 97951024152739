import React, { Fragment } from 'react';
import {
    arrayOf,
    node,
    number,
    oneOfType,
    shape,
    string,
    bool
} from 'prop-types';
import { BasicSelect, Option, asField } from 'informed';
import { FieldIcons } from '../Field';
import { Message } from '../Message';
import defaultClasses from './select.less';
import classNames from 'classnames/bind';
let examineClasses = classNames.bind(style);

import Icon from '../Icon';
//import { FiChevronDown as ChevronDownIcon } from 'react-icons/fi';
import { ChevronDown as ChevronDownIcon } from 'react-feather';
import style from '@corratech/form-components/Message/Message.less';

const Select = props => {
    const {
        classes,
        fieldState,
        items,
        message,
        enableError,
        setErrorClass,
        disableArrow,
        ...rest
    } = props;
    const options = items.map(({ label, value }) => (
        <Option key={value} value={value}>
            {label || (value != null ? value : '')}
        </Option>
    ));
    const className = fieldState.error
        ? examineClasses('input-select field-required-error')
        : examineClasses('input-select');

    if (enableError) {
        fieldState.error ? setErrorClass(true) : setErrorClass(false);
    }

    const arrow = disableArrow ? '' : <Icon src={ChevronDownIcon} size={25} />;

    return (
        <Fragment>
            <div className={'select-box-wrapper'}>
                <FieldIcons after={arrow}>
                    <BasicSelect
                        data-cs-mask
                        {...rest}
                        fieldState={fieldState}
                        className={className}
                    >
                        {options}
                    </BasicSelect>
                </FieldIcons>
            </div>
            <Message fieldState={fieldState}>{message}</Message>
        </Fragment>
    );
};

Select.propTypes = {
    classes: shape({
        input: string
    }),
    field: string.isRequired,
    fieldState: shape({
        value: oneOfType([number, string])
    }),
    items: arrayOf(
        shape({
            label: string,
            value: oneOfType([number, string])
        })
    ),
    message: node,
    disableArrow: bool
};

export default asField(Select);
