import React from 'react';
import parseStorageHtml from './parseStorageHtml';
import ContentTypeFactory from './factory';
import renderContentType from './changeContentType';
import { string, object } from 'prop-types';

const PageBuilderExtend = ({ masterFormat, contentType = {}, isLazyLoad }) => {
    const data = parseStorageHtml(masterFormat);
    const { isHidden, ...props } = data;

    if (isHidden) {
        return null;
    }

    return renderContentType(data, contentType).children.map((child, i) => {
        return (
            <ContentTypeFactory key={i} data={child} isLazyLoad={isLazyLoad} />
        );
    });
};

PageBuilderExtend.propTypes = {
    masterFormat: string,
    contentType: object
};

export default PageBuilderExtend;
