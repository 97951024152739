// check if address is valid
export const isAddressValid = address => {
    if (address) {
        return !!(
            !!('firstname' in address && address.firstname) &&
            !!('lastname' in address && address.lastname) &&
            !!('city' in address && address.city) &&
            !!('street' in address && address.street[0]) &&
            !!('country' in address && address.country) &&
            !!('region' in address && address.region) &&
            !!('postcode' in address && address.postcode) &&
            !!('telephone' in address && address.telephone)
        );
    } else {
        return false;
    }
};
