function SearchEvents(tagData) {
    const {
        searchTerm,
        currencyCode,
        productsDetails = [],
        isSearchResult = false
    } = tagData;

    if (isSearchResult) {
        return {
            event: 'search results',
            'search query': searchTerm,
            'search results': productsDetails.length,
            ...(productsDetails.length
                ? {
                      ecommerce: {
                          currencyCode: currencyCode,
                          impressions: productsDetails
                      }
                  }
                : {})
        };
    }
}
export { SearchEvents };
