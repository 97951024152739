import React from 'react';
import PropTypes from 'prop-types';

const RU = props => {
    const { color, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            height={size}
            width={size}
            {...otherProps}
        >
            <circle fill="#F0F0F0" cx="256" cy="256" r="256"></circle>
            <path
                fill="#0052B4"
                d="M496.1 345c10.3-27.7 15.9-57.7 15.9-89s-5.6-61.3-15.9-89H15.9C5.6 194.7 0 224.7 0 256s5.6 61.3 15.9 89L256 367.3 496.1 345z"
            ></path>
            <path
                fill="#D80027"
                d="M256 512c110.1 0 203.9-69.5 240.1-167H15.9c36.2 97.5 130 167 240.1 167z"
            ></path>
        </svg>
    );
};

RU.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

RU.defaultProps = {
    color: 'currentColor',
    size: '18'
};

export default RU;
