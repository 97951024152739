import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { AuthStore, LoaderStore } from '@corratech/context-provider';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { useAddToWishlist } from './useAddToWishlist';

export const AddToWishlist = props => {
    const { callback, productId, redirectOnWishlist, qty, children } = props;
    const LoadingIndicator = useContext(LoaderStore);
    const [t] = useTranslation();
    const { authState, dispatch } = useContext(AuthStore);
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const addToWishlist = useAddToWishlist();

    if (loading) {
        return <LoadingIndicator />;
    }

    if (error) {
        return <p>{t('An error occurred. Please try again.')}</p>;
    }

    const addProductHandle = () => {
        if (!authState || !authState.token) {
            history.push(`/login/wishlist/${productId}/${qty}`);
        } else {
            addToWishlist(productId, qty).then(() => {
                if (callback) callback();

                if (redirectOnWishlist) {
                    history.push('/my-account/wishlist/addedItem');
                }
            });
        }
    };

    return (
        <Link
            to={'#'}
            onClick={addProductHandle}
            className={`add-to-wishlist-icon ${
                props.className ? props.className : ''
            }`}
            title={t('Add To Wishlist')}
        >
            <button>{children}</button>
        </Link>
    );
};

AddToWishlist.propTypes = {
    callback: PropTypes.func,
    redirectOnWishlist: PropTypes.bool,
    qty: PropTypes.number,
    productId: PropTypes.number.isRequired
};

AddToWishlist.defaultProps = {
    redirectOnWishlist: true,
    qty: 1
};
