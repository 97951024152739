import React, { useState, useEffect, useContext, Fragment } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { bool, string, object, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import qs from 'qs';
import { ForgotPasswordForm, ResetPasswordForm, ArrowLeft } from '../';
import {
    OverlayStore,
    AuthStore,
    useGlobalOptions
} from '@corratech/context-provider';

import { Button } from 'react-bootstrap';
import classNames from 'classnames';
let isSliderOpen = false;

const LoginForm = React.lazy(() =>
    import('ModulesPath/Register/LoginForm/LoginForm')
);

const RegistrationForm = React.lazy(() =>
    import('ModulesPath/Register/RegistrationForm/RegistrationForm')
);

export const SlideSteps = props => {
    const [t] = useTranslation();

    let location = useLocation();

    let history = useHistory();
    const projectConfig = useGlobalOptions();
    const {
        css,
        className,
        isOpenSidebar,
        setIsOpenSidebar,
        termsAndConditionsHandle,
        isLoyality,
        removeLoyaltyWelcomeButtons
    } = props;

    const { callbackDispatch, overlayDispatch } = useContext(OverlayStore);

    const [email, setEmail] = useState('');

    const [isEmailAvailable, setIsEmailAvailable] = useState(null);

    const [showLogin, setShowLogin] = useState(false);

    const [showRegister, setShowRegister] = useState(false);

    const [showForgotPassword, setShowForgotPassword] = useState(false);

    const [showResetPassword, setShowResetPassword] = useState(false);

    const [useSearchQuery, setUseSearchQuery] = useState(false);

    const [resetToken, setResetToken] = useState('');

    const { authState, dispatch } = useContext(AuthStore);

    const accountFormStepsClasses = classNames('account-form-steps', {
        open: isOpenSidebar
    });

    const isClient = typeof window === 'object';
    useEffect(() => {
        if (!isClient) {
            return false;
        }
        function handleResize() {
            if (!(window.innerWidth <= projectConfig.viewport.mobile)) {
                if (isSliderOpen) {
                    overlayDispatch({ type: 'SHOW' });
                }
            }
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    useEffect(() => {
        if (isOpenSidebar == false) {
            dispatch({
                type: 'TOGGLE_LOGIN_FORM',
                showLogin: false
            });
        }
        overlayDispatch({ type: isOpenSidebar ? 'SHOW' : 'HIDE' });
        if (isOpenSidebar) {
            isSliderOpen = true;
        } else {
            isSliderOpen = false;
        }
        if (!isOpenSidebar) {
            callbackDispatch({
                type: 'PUSH',
                data: () => setIsOpenSidebar(isOpenSidebar)
            });
        }
    }, [isOpenSidebar]);

    useEffect(() => {
        if (
            showLogin ||
            showRegister ||
            showForgotPassword ||
            (showResetPassword && '' === resetToken)
        ) {
            showAvailableForm();
        }
    }, [email]);

    useEffect(() => {
        if (useSearchQuery) {
            setShowForgotPassword(true);
            setIsOpenSidebar(true);
            setUseSearchQuery(false);
            history.push('/checkout');
        }
    }, [useSearchQuery, showForgotPassword]);

    useEffect(() => {
        if (location.search) {
            //replace first ? for prase
            const searchQuery = location.search.replace('?', '');
            const { email, token, resetpassword, forgotpassword } = qs.parse(
                searchQuery
            );

            if (resetpassword && email) {
                setResetToken(token);
                updateEmail(email);
                setIsOpenSidebar(true);
                setShowResetPassword(true);
            }

            if (forgotpassword && email) {
                updateEmail(email);
                setUseSearchQuery(true);
            }
        }
    }, [location.search]);

    const setEmailAvailable = ({ status }) => {
        setIsEmailAvailable(status);

        if (status) {
            setShowRegister(true);
        } else {
            setShowLogin(true);
        }
    };

    const updateEmail = email => {
        setEmail(email);
    };

    const backClick = event => {
        event.preventDefault();
        if (showLogin || showRegister) {
            setShowRegister(false);
            setShowLogin(false);
        }

        if (showForgotPassword) {
            setShowLogin(true);
            setShowForgotPassword(false);
        }

        if (showResetPassword) {
            showAvailableForm();
        }
    };

    const forgotPasswordClick = event => {
        event.preventDefault();
        setShowRegister(false);
        setShowLogin(false);
        setShowForgotPassword(true);
    };

    const signInClick = event => {
        event.preventDefault();
        setShowLogin(true);
        setShowRegister(false);
        setShowForgotPassword(false);
    };

    const showAvailableForm = () => {
        setShowLogin(false);
        setShowRegister(false);
        setShowForgotPassword(false);
        setShowResetPassword(false);
    };

    // useEffect(() => {
    //     // setIsOpenSidebar(authState.showLogin);
    // }, [authState.showLogin]);

    return (
        <div
            className={accountFormStepsClasses + ' ' + (className || '')}
            css={css}
        >
            <div
                className={`${
                    showRegister
                        ? 'loyalty-register-form'
                        : showLogin
                        ? 'account-login-loyalty-form '
                        : showForgotPassword
                        ? ''
                        : 'account-login-form'
                }    account-draw-inner `}
            >
                <div className={'header'}>
                    {(showLogin ||
                        showRegister ||
                        showForgotPassword ||
                        showResetPassword) && (
                        <Button
                            variant="link"
                            className={'return-button'}
                            onClick={backClick}
                        >
                            <ArrowLeft />
                            <span>{t(`Back`)}</span>
                        </Button>
                    )}
                    <div className={'title'} role="heading" aria-level="2">
                        {isLoyality ? (
                            <Fragment>
                                <span id="account-login-form-heading">
                                    {t(`Thank you`)}
                                </span>
                            </Fragment>
                        ) : (
                            <Fragment>
                                {showForgotPassword ? (
                                    t(`Account`)
                                ) : (
                                    <Fragment>
                                        <span id="account-login-form-heading">
                                            {t(`Sign In `)}
                                        </span>
                                        <span className="title-divider">/</span>
                                        <span id="account-register-form-heading">
                                            {t(` Register`)}
                                        </span>
                                    </Fragment>
                                )}
                            </Fragment>
                        )}
                    </div>
                    <Button
                        variant="link"
                        className={'close-sidebar'}
                        onClick={() => setIsOpenSidebar(false)}
                    >
                        <span>{t(`Close`)}</span>
                    </Button>
                </div>
                <Fragment>
                    {!showRegister &&
                        !showForgotPassword &&
                        !showResetPassword && (
                            <LoginForm
                                updateEmail={updateEmail}
                                setEmailAvailable={setEmailAvailable}
                                enteredEmail={email}
                                forgotPasswordClick={forgotPasswordClick}
                                showLogin={showLogin}
                                setIsOpenSidebar={setIsOpenSidebar}
                            />
                        )}

                    {showRegister && (
                        <RegistrationForm
                            updateEmail={updateEmail}
                            enteredEmail={email}
                            termsAndConditionsHandle={termsAndConditionsHandle}
                            backClick={backClick}
                            setIsOpenSidebar={setIsOpenSidebar}
                            storeId={8}
                        />
                    )}

                    {showForgotPassword && (
                        <ForgotPasswordForm
                            updateEmail={updateEmail}
                            enteredEmail={email}
                            signInClick={signInClick}
                            loginText={''}
                        />
                    )}

                    {showResetPassword && (
                        <ResetPasswordForm
                            resetToken={resetToken}
                            enteredEmail={email}
                        />
                    )}
                </Fragment>
            </div>
        </div>
    );
};

export default SlideSteps;

SlideSteps.propTypes = {
    className: string,
    setIsOpenSidebar: func.isRequired,
    css: object,
    isOpenSidebar: bool,
    termsAndConditionsHandle: func
};
