import React, { useEffect, useContext, useState } from 'react';
import { object, string, bool, number, func } from 'prop-types';
import { CartStore } from '@corratech/context-provider';
import { CartItem } from './CartItem';
import { useMutation } from 'react-apollo';
import updateCartItems from 'ModulesPath/App/Queries/updateCartItems.graphql';
import { Alert, Modal } from 'react-bootstrap';
import { AlertCircle as ErrorIcon } from 'react-feather';
import { getTrackingOverride } from 'UtilPath/orderGroove';

export const CartItemList = props => {
    const {
        className,
        css,
        hasPopup,
        imageWidth,
        disableControls,
        shoppingCart,
        canEdit,
        setIsEditingItem,
        setEditItem,
        setShowMessage,
        hasConfirmation,
        qtyLoaderProps,
        enableWishlist,
        setWishlistMessage,
        CartItem,
        displayAsShoppingCart,
        removeItemFromCartGraphql,
        updateCartItemsGraphql,
        setQtyUpdating,
        setIsOGPresent,
        cartItemsId,
        cartQuery
    } = props;
    const { cartState, dispatch } = useContext(CartStore);
    const [giftCardSubscribedError, setGiftCardSubscribedError] = useState(
        false
    );
    const [showModal, setShowModal] = useState(false);

    const [updateItemQuantity] = useMutation(updateCartItemsGraphql, {
        fetchPolicy: 'no-cache',
        variables: {
            cartId: cartState.cartId
        },
        onCompleted: res => {
            setQtyUpdating(false);
            cartQuery();
            setQtyUpdating(true);
        },
        onError: res => {
            setQtyUpdating(false);
            console.warn(res);
            if (res?.graphQLErrors) {
                for (var idx = 0; idx < res.graphQLErrors.length; idx++) {
                    if (
                        res.graphQLErrors[idx]?.extensions?.category ===
                        'graphql-giftcard-subscribed'
                    ) {
                        setGiftCardSubscribedError(
                            res.graphQLErrors[idx]?.message
                        );
                        clickOptOutButton();
                        setShowModal(true);
                    }
                }
            }
        }
    });

    function clickOptOutButton() {
        const optOutButtons = document.querySelectorAll('og-optout-button');

        if (optOutButtons.length > 0) {
            optOutButtons[0].click();
        } else {
            console.log('Button not found!');
        }
    }

    useEffect(() => {
        if (giftCardSubscribedError) {
            setGiftCardSubscribedError('');
        }
    }, []);

    useEffect(() => {
        var minicart = document.getElementsByClassName('minicart-wrapper');
        if (minicart.length) {
            var elements = minicart[0].getElementsByTagName('og-offer');
            var observer = new MutationObserver(function(mutations) {
                mutations.forEach(function(mutation) {
                    // return when user addToCart
                    if (mutation.attributeName === 'frequency') {
                        return;
                    }

                    if (mutation.type === 'attributes') {
                        if (mutation.target.product.id) {
                            let isSubscribed = false;
                            if (mutation.target['subscribed'] != false) {
                                isSubscribed = true;
                            }
                            setQtyUpdating(true);
                            if (mutation.oldValue) {
                                cartQuery();
                            } else {
                                updateItemQuantity({
                                    variables: {
                                        quantity:
                                            mutation.target.dataset.lineQty,
                                        itemId: mutation.target.dataset.lineId,
                                        isSubscription: isSubscribed,
                                        ogOptions: getTrackingOverride(mutation.target.product.id)
                                    }
                                });
                            }
                        }
                    }
                });
            });

            for (let i = 0; i < elements.length; i++) {
                observer.observe(elements[i], {
                    attributes: true, //configure it to listen to attribute changes
                    attributeOldValue: true
                });
            }
        }
        return () => {
            if (typeof observer !== 'undefined') {
                observer.disconnect();
            }
        };
    }, [cartState?.cart?.items?.length]);

    return (
        <CartStore.Consumer>
            {value => (
                <div
                    className={'cart-item-list-wrapper' + (className || '')}
                    css={css}
                >
                    {giftCardSubscribedError && (
                        <Modal
                            show={showModal}
                            onHide={() => {
                                setShowModal(false);
                            }}
                        >
                            <Modal.Header
                                closeButton
                                className="checkout-agreements-modal"
                            ></Modal.Header>
                            <Modal.Body>
                                <Alert variant={'danger'}>
                                    <ErrorIcon size={24} color={'#B70020'} />
                                    <p>{giftCardSubscribedError}</p>
                                </Alert>
                            </Modal.Body>
                        </Modal>
                    )}

                    <div className={'cart-item-list'}>
                        {value.cartState.cart
                            ? value.cartState.cart.items
                                ? value.cartState.cart.items.map(
                                      (item, index) => {
                                          return (
                                              <CartItem
                                                  hasConfirmation={
                                                      hasConfirmation
                                                  }
                                                  item={item}
                                                  key={item.id}
                                                  imageWidth={imageWidth}
                                                  currencyCode={
                                                      value.cartState.cart
                                                          .prices.grand_total
                                                          .currency
                                                  }
                                                  disableControls={
                                                      disableControls
                                                  }
                                                  shoppingCart={!!shoppingCart}
                                                  canEdit={!!canEdit}
                                                  setIsEditingItem={
                                                      setIsEditingItem
                                                  }
                                                  setEditItem={setEditItem}
                                                  qtyLoaderProps={
                                                      qtyLoaderProps
                                                  }
                                                  enableWishlist={
                                                      enableWishlist
                                                  }
                                                  setWishlistMessage={
                                                      setWishlistMessage
                                                  }
                                                  displayAsShoppingCart={
                                                      displayAsShoppingCart
                                                  }
                                                  removeItemFromCartGraphql={
                                                      removeItemFromCartGraphql
                                                  }
                                                  updateCartItemsGraphql={
                                                      updateCartItemsGraphql
                                                  }
                                                  setQtyUpdating={
                                                      setQtyUpdating
                                                  }
                                              />
                                          );
                                      }
                                  )
                                : null
                            : null}
                    </div>
                </div>
            )}
        </CartStore.Consumer>
    );
};

CartItemList.propTypes = {
    className: string,
    css: object,
    disableControls: bool,
    shoppingCart: bool,
    hasConfirmation: bool,
    canEdit: bool,
    imageWidth: number,
    setIsEditingItem: func,
    setEditItem: func,
    qtyLoaderProps: object,
    enableWishlist: bool,
    setWishlistMessage: func,
    CartItem: func,
    displayAsShoppingCart: bool,
    setQtyUpdating: func
};

CartItemList.defaultProps = {
    CartItem: CartItem,
    displayAsShoppingCart: false,
    updateCartItemsGraphql: updateCartItems
};
