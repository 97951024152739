import React from 'react';
import { useTranslation } from 'react-i18next';
import { useWindowSize } from '@magento/peregrine';
import { Copyright, SocialLinks, SubscribeForm, ContactBlock } from '../';
import PropTypes from 'prop-types';
import { CmsBlockGroup } from '@corratech/cms-display';
import { useGlobalOptions } from '@corratech/context-provider';
import { useCopyright, useNewsletterSubscription } from '@corratech/bll/footer';

require('./Footer.less');

export const Footer = props => {
    const [t] = useTranslation();
    const windowSize = useWindowSize();

    const { socialLinks } = props;

    const options = useGlobalOptions();

    const isMobile = windowSize.innerWidth <= (options.viewport.mobile || 767);

    const copyright = (
        <Copyright
            blockId={'footer_copyright'}
            useCopyright={props.useCopyright || useCopyright}
        />
    );

    const socialLinksArray = [
        {
            link: 'https://www.instagram.com/',
            class: 'icon-instagram',
            label: 'Instagram',
            icon: <div> Instagram Icon </div>
        },
        {
            link: 'https://www.youtube.com/',
            class: 'icon-youtube',
            label: 'Youtube',
            icon: <div> YouTube Icon </div>
        },
        {
            link: 'https://www.facebook.com/',
            class: 'icon-facebook',
            label: 'Facebook',
            icon: <div> Facebook Icon </div>
        },
        {
            link: 'https://twitter.com/',
            class: 'icon-twitter',
            label: 'Twitter',
            icon: <div> Twitter Icon </div>
        }
    ];

    return isMobile ? (
        <footer
            css={props.css}
            className={`footer-container ${props.className || ''}`}
        >
            <SubscribeForm
                title={t(`SIGN UP FOR OUR NEWSLETTER`)}
                inputPlaceholder={t('Email Address')}
                className={'footer-top-wrapper'}
                useNewsletterSubscription={
                    props.useNewsletterSubscription || useNewsletterSubscription
                }
            />
            <div className={'footer-content-wrapper'}>
                <div className={`footer-links-wrapper container-width`}></div>
            </div>

            <SocialLinks
                title={t('CONNECT WITH US')}
                icons={socialLinks ? socialLinks : socialLinksArray}
                className={'footer-top-wrapper'}
            />

            {copyright}
        </footer>
    ) : (
        <footer css={props.css} className={'footer-container'}>
            <div className={'footer-content-wrapper'}>
                <div className={'footer-top-wrapper'}>
                    <div className={'footer-inner-content container-width'}>
                        <div className={'footer-top-content -left'}>
                            <SubscribeForm
                                title={t(`SIGN UP FOR OUR NEWSLETTER`)}
                                inputPlaceholder={t('Email Address')}
                                inputLabel={t(`Email Address`)}
                                useNewsletterSubscription={
                                    props.useNewsletterSubscription ||
                                    useNewsletterSubscription
                                }
                            />
                        </div>
                        <div className={'footer-top-content'}>
                            <SocialLinks
                                title={t('CONNECT WITH US')}
                                icons={socialLinksArray}
                            />
                        </div>
                    </div>
                </div>
                <div className={`footer-links-wrapper container-width`}>
                    <CmsBlockGroup
                        identifiers={[
                            props.footerLinksBlockId || 'footer_links_block'
                        ]}
                    />
                    <ContactBlock
                        contactText={props.contactText || ''}
                        contactTitle={props.contactTitle || 'Contact Us'}
                        phoneNumber={props.phoneNumber || '123-456-7890'}
                    />
                </div>
            </div>
            {copyright}
        </footer>
    );
};

Footer.propTypes = {
    icons: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.string,
            class: PropTypes.string,
            label: PropTypes.string,
            icon: PropTypes.element
        })
    ),
    useCopyright: PropTypes.func,
    useNewsletterSubscription: PropTypes.func
};
