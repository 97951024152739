import React from 'react';
import { string, number, oneOfType } from 'prop-types';

export const EditIcon = props => {
    const { color, size, ...otherProps } = props;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...otherProps}
        >
            <path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34" />
            <polygon points="18 2 22 6 12 16 8 16 8 12 18 2" />
        </svg>
    );
};

EditIcon.propTypes = {
    color: string,
    size: oneOfType([string, number])
};

EditIcon.defaultProps = {
    color: 'currentColor',
    size: '24'
};
