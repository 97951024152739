import { cartItemMapping } from './cartItemMapping';

export const PLACE_ORDER = (itemMapping = cartItemMapping()) => {
    return {
        items: ['cart', 'items'],
        actionField: {
            id: ['placeOrder', 'order', 'increment_id'],
            affiliation: ['placeOrder', 'order', 'status'],
            revenue: ['placeOrder', 'order', 'grand_total', 'value'],
            tax: ['placeOrder', 'order', 'tax_amount', 'value'],
            shipping: ['placeOrder', 'order', 'shipping_amount', 'value'],
            discount: ['placeOrder', 'order', 'discount_amount', 'value'],
        },
        productsDetails: {
            data_source: ['cart', 'items'],
            mapping: itemMapping,
            strict: true
        }
    };
}