import React from 'react';
import PropTypes from 'prop-types';

const LeftIcon = props => {
    const { color, size, width, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 5.85 10.71"
            width={width}
        >
            <path
                fill="none"
                stroke={color}
                strokeLinejoin="round"
                d="M5.5 0.35L0.5 5.35 5.5 10.35"
            ></path>
        </svg>
    );
};

LeftIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

LeftIcon.defaultProps = {
    color: 'currentColor',
    size: '24',
    width: '5'
};

export default LeftIcon;
