import React, { useEffect, useState, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery } from 'react-apollo';
import { useScript } from '@corratech/bll/script';
import Skeleton from 'react-loading-skeleton';
import createTokenQuery from '@corratech/checkout/PaymentMethods/BraintreePayment/createBraintreeClientToken.graphql';
import { getRegionId } from './ApplePayHelper';
import QUERY_GET_COUNTRY from '@corratech/checkout/forms/AddressForm/graphql/QUERY_GET_COUNTRY.graphql';
import placeOrderMutation from '@corratech/checkout/graphql/placeOrder.graphql';
import {
    CartStore,
    LoaderStore,
    useSetNotification,
    OverlayStore,
    isSignedIn
} from '@corratech/context-provider';
import setApplePayPaymentMethod from './Query/setApplePayPaymentMethod.graphql';
import MUTATION_SET_GUEST_EMAIL_ON_CART from '@corratech/checkout/forms/CheckoutAccountForm/graphql/MUTATION_SET_GUEST_EMAIL_ON_CART.JS';
import { useDataLayerAction } from '@corratech/tag-manager';
import setShippingAddressesOnCart from './Query/setShippingAddressesOnCart.graphql';
import setBillingAddressOnCart from './Query/setBillingAddressOnCart.graphql';
import setShippingMethodsOnCart from './Query/setShippingMethodOnCart.graphql';
import resetQuoteAddress from './Query/resetQuoteAddress.graphql';
import { TrackInitiateCheckout } from 'ModulesPath/FacebookConversion/TrackInitiateCheckout';
import { Util } from '@magento/peregrine';
const { BrowserPersistence } = Util;
const storage = new BrowserPersistence();

require('./ApplePay.less');
let shippingMethodsObj = {};

const ApplePayButton = ({ brainTreeApplePayScriptsReady }) => {
    const [getBraintreeToken, { data: clientTokenData }] = useMutation(
        createTokenQuery
    );
    const braintreeApplePaySetupComplete =
        brainTreeApplePayScriptsReady && !!clientTokenData;

    const firstRender = React.useRef(true);
    if (firstRender.current) {
        getBraintreeToken();
        firstRender.current = false;
    }

    const [showLoader, setShowLoader] = useState(false);
    const [applePaySession, setApplePaySession] = useState(null);
    const [selectedShippingMethod, setSelectedShippingMethod] = useState(null);
    const { cartState, dispatch } = useContext(CartStore);
    const dataLayerAction = useDataLayerAction();
    const LoadingIndicator = useContext(LoaderStore);
    const { setNotification, clearNotification } = useSetNotification();
    const { overlayDispatch } = React.useContext(OverlayStore);
    let applePayInstance = {};

    const payReq = {
        total: {
            label: 'Total',
            amount: cartState.cart.prices.grand_total.value
        },
        requiredShippingContactFields: [
            'postalAddress',
            'name',
            'email',
            'phone'
        ],
        requiredBillingContactFields: ['postalAddress', 'name']
    };

    const { data: countryData } = useQuery(QUERY_GET_COUNTRY, {
        fetchPolicy: 'cache-and-network'
    });

    const [setGuestEmail] = useMutation(MUTATION_SET_GUEST_EMAIL_ON_CART, {
        onCompleted: res => {
            //when the guest email is set, update the cart in context with the guest email from server
            dispatch({
                type: 'SET_CART',
                cart: {
                    email: res.setGuestEmailOnCart.cart.email
                }
            });
        }
    });

    useEffect(() => {
        return () => {
            clearNotification();
        };
    }, []);

    // Mutation to set braintree payment info on the cart
    const [setBraintreePayment] = useMutation(setApplePayPaymentMethod, {
        variables: {
            cartId: cartState.cartId
        }
    });

    const setErrorNotification = err => {
        setNotification('danger', err.message, 3600 * 1000 * 24);
        overlayDispatch({ type: 'HIDE' });
    };

    /*
     *  Setting the updated shipping method
     */
    const [setChoosedShippingMethod] = useMutation(setShippingMethodsOnCart, {
        onCompleted: res => {
            const updatedCart = cartState.cart;
            updatedCart.prices = res.setShippingMethodsOnCart.cart.prices;
            dispatch({
                type: 'SET_CART',
                cart: updatedCart
            });
            const grandTotalAmt = res.setShippingMethodsOnCart.cart.prices.grand_total.value.toFixed(
                2
            );
            const taxTotalAmt =
                cartState.cart.prices.applied_taxes.length > 0
                    ? cartState.cart.prices.applied_taxes[0].amount.value.toFixed(
                          2
                      )
                    : null;

            //Pass shipping methods back
            applePaySession.completeShippingMethodSelection(
                ApplePaySession.STATUS_SUCCESS,
                {
                    label: 'Total',
                    amount: grandTotalAmt
                },
                [
                    {
                        type: 'final',
                        label: 'Shipping',
                        amount: selectedShippingMethod.amount
                    },
                    ...(taxTotalAmt
                        ? [
                              {
                                  type: 'final',
                                  label: 'Tax',
                                  amount: taxTotalAmt
                              }
                          ]
                        : [])
                ]
            );
        },
        onError: error => {
            applePaySession && applePaySession.abort();
            setErrorNotification(error);
            setShowLoader(false);
            console.log('Error : setShippingMethodsOnCart');
        }
    });

    /*
     *  Setting the shipping method and display methods on apple flyout
     */
    const [setShippingMethod] = useMutation(setShippingMethodsOnCart, {
        onCompleted: res => {
            const updatedCart = cartState.cart;
            updatedCart.prices = res.setShippingMethodsOnCart.cart.prices;
            dispatch({
                type: 'SET_CART',
                cart: updatedCart
            });
            const grandTotalAmt = res.setShippingMethodsOnCart.cart.prices.grand_total.value.toFixed(
                2
            );
            const taxTotalAmt =
                cartState.cart.prices.applied_taxes.length > 0
                    ? cartState.cart.prices.applied_taxes[0].amount.value.toFixed(
                          2
                      )
                    : null;
            let availableShipping =
                cartState.cart.shipping_addresses[0].available_shipping_methods;
            let shippingMethods = [];
            // Format shipping methods array.
            for (let i = 0; i < availableShipping.length; i++) {
                if (typeof availableShipping[i].method_code !== 'string') {
                    continue;
                }

                let method = {
                    label: availableShipping[i].method_title,
                    detail: '',
                    amount: parseFloat(
                        availableShipping[i].amount.value
                    ).toFixed(2),
                    identifier: availableShipping[i].method_code
                };

                // Add method object to array.
                shippingMethods.push(method);
                shippingMethodsObj[availableShipping[i].method_code] =
                    availableShipping[i];
            }

            //Pass shipping methods back
            applePaySession.completeShippingContactSelection(
                ApplePaySession.STATUS_SUCCESS,
                shippingMethods,
                {
                    label: 'Total',
                    amount: grandTotalAmt
                },
                [
                    {
                        type: 'final',
                        label: 'Shipping',
                        amount: availableShipping[0].amount.value
                    },
                    ...(taxTotalAmt
                        ? [
                              {
                                  type: 'final',
                                  label: 'Tax',
                                  amount: taxTotalAmt
                              }
                          ]
                        : [])
                ]
            );
        },
        onError: error => {
            applePaySession && applePaySession.abort();
            setErrorNotification(error);
            setShowLoader(false);
            console.log(`error`, error);
            console.log('Error : setShippingMethodsOnCart');
        }
    });

    /*
     *  Setting Shipping address
     * Initial address setting with dummy values for first name, last name etc
     */
    const [setShippingAddress] = useMutation(setShippingAddressesOnCart, {
        variables: {
            cartId: cartState.cartId
        },
        onCompleted: res => {
            dispatch({
                type: 'SET_CART',
                cart: {
                    ...res.setShippingAddressesOnCart.cart
                }
            });

            let firstShippingMethod =
                res.setShippingAddressesOnCart.cart.shipping_addresses[0]
                    .available_shipping_methods[0];
            setShippingMethod({
                variables: {
                    cartId: cartState.cartId,
                    carrierCode: firstShippingMethod.carrier_code,
                    methodCode: firstShippingMethod.method_code
                }
            });
        },
        onError: error => {
            window.console.log(error);
        }
    });

    /*
     *  Setting Full Shipping address
     *  Adding full address with first name and last name based on
     *  onpaymentauthorized event.
     */
    const [setFullShippingAddress] = useMutation(setShippingAddressesOnCart, {
        variables: {
            cartId: cartState.cartId
        },
        onCompleted: res => {
            dispatch({
                type: 'SET_CART',
                cart: {
                    ...res.setShippingAddressesOnCart.cart
                }
            });
        },
        onError: error => {
            applePaySession && applePaySession.abort();
            setErrorNotification(error);
            setShowLoader(false);
            window.console.log(error);
        }
    });

    /*
     *  Setting Billing address
     */
    const [setBillingAddress] = useMutation(setBillingAddressOnCart, {
        variables: {
            cartId: cartState.cartId
        },
        onCompleted: res => {
            dispatch({
                type: 'SET_CART',
                cart: {
                    ...res.setBillingAddressOnCart.cart
                }
            });
        },
        onError: error => {
            const result = {
                status: ApplePaySession.STATUS_FAILURE,
                errors: [
                    new ApplePayError(
                        'billingContactInvalid',
                        'postalAddress',
                        'Invalid address'
                    )
                ]
            };
            applePaySession.completePayment(result);
            setShowLoader(false);
            window.console.log(error);
        }
    });

    /*
     *  Place order mutation
     */
    const [resetAddress] = useMutation(resetQuoteAddress, {
        variables: {
            cartId: cartState.cartId
        },
        onCompleted: res => {
            if (res) {
                dispatch({
                    type: 'SET_CART',
                    cart: res.resetQuoteAddress.cart
                });
            }
        },
        onError: error => {
            setShowLoader(false);
        }
    });

    /*
     *  Setting Payment method
     */
    const setupBrainTreePayments = (nonce, session) => {
        setBraintreePayment({
            variables: {
                braintreeNonce: nonce
            }
        })
            .then(res => {
                handlePlaceOrder();
                session.completePayment(ApplePaySession.STATUS_SUCCESS);
            })
            .catch(r => {
                session.completePayment(ApplePaySession.STATUS_FAILURE);
                session.abort();
                setErrorNotification(r);
                console.error('Braintree ApplePay Unable to take payment', r);
                return false;
            });
    };

    const handlePlaceOrder = () => {
        setShowLoader(true);
        placeOrder();
    };

    /*
     *  Place order mutation
     */
    const [placeOrder] = useMutation(placeOrderMutation, {
        variables: {
            cartId: cartState.cartId
        },
        onCompleted: res => {
            dispatch({
                type: 'SHOULD_OPEN_DRAWER',
                shouldOpenDrawer: false
            });
            dataLayerAction({
                type: 'PLACE_ORDER',
                data: {
                    ...cartState,
                    actionField: res
                }
            });
            dataLayerAction({
                type: 'TRIGGER_IMMEDIATELY',
                data: {
                    eventName: 'purchase'
                }
            });
            storage.setItem('minicart-payment-method', 'applepay');
            dispatch({
                type: 'PLACED_ORDER',
                placedOrder: res
            });
            setShowLoader(false);
        },
        onError: error => {
            setErrorNotification(error);
            setShowLoader(false);
            console.error(error);
        }
    });

    const setUpApplePayInstance = () => {
        try {
            braintree.client
                .create({
                    authorization:
                        clientTokenData &&
                        clientTokenData.createBraintreeClientToken
                })
                .then(function(clientInstance) {
                    return braintree.applePay.create({
                        client: clientInstance
                    });
                })
                .then(function(applePayInst) {
                    // Set up your Apple Pay button here
                    if (applePayInst) {
                        applePayInstance = applePayInst;
                    } else {
                        console.error(
                            'Braintree ApplePay Error creating applePayInstance:',
                            applePayInst
                        );
                    }
                })
                .catch(function(err) {
                    // Handle error
                    console.log('Error in Apple Pay Button');
                });
        } catch (err) {
            console.error(err);
            console.log('Error in Apple Pay');
        }
    };

    const validateAddress = address => {
        let isAddressValid = true;
        if (!/\S/.test(address.givenName)) {
            isAddressValid = false;
        } else if (!/\S/.test(address.familyName)) {
            isAddressValid = false;
        } else if (!/\S/.test(address.addressLines[0])) {
            isAddressValid = false;
        }
        return isAddressValid;
    };

    const validateAddresses = (shippingContact, billingContact, session) => {
        let isShippingAddressValid = validateAddress(shippingContact);
        let isBillingAddressValid = validateAddress(billingContact);
        let isValid = true;
        if (!isShippingAddressValid) {
            session.completePayment(
                ApplePaySession.STATUS_INVALID_SHIPPING_POSTAL_ADDRESS
            );
            isValid = false;
        } else if (!isBillingAddressValid) {
            session.completePayment(
                ApplePaySession.STATUS_INVALID_BILLING_POSTAL_ADDRESS
            );
            isValid = false;
        }
        return isValid;
    };

    const callTrackInitiateCheckout = TrackInitiateCheckout();
    const fbTrackInitiateCheckout = () => {
        callTrackInitiateCheckout();
    };

    const dummy = 'test';
    useEffect(() => {
        if (brainTreeApplePayScriptsReady && typeof braintree !== 'undefined') {
            setUpApplePayInstance();
            let el = document.createElement('div');
            el.className = 'apple-pay-button apple-pay-button-black';
            el.title = 'Pay with Apple Pay';
            el.alt = 'Pay with Apple Pay';
            el.innerHTML = 'Apple Pay';
            el.addEventListener('click', function(e) {
                e.preventDefault();
                try {
                    // Payment request object
                    var paymentRequest = applePayInstance.createPaymentRequest(
                        payReq
                    );
                    if (!paymentRequest) {
                        console.log(
                            "We're unable to take payments through Apple Pay at the moment. Please try an alternative payment method."
                        );
                        console.error(
                            'Braintree ApplePay Unable to create paymentRequest',
                            paymentRequest
                        );
                        return;
                    }
                    // Init apple pay session
                    try {
                        var session = new ApplePaySession(1, paymentRequest);
                        setApplePaySession(session);
                        // Track FB initiate Checkout
                        fbTrackInitiateCheckout();
                    } catch (err) {
                        console.error(
                            'Braintree ApplePay Unable to create ApplePaySession',
                            err
                        );
                        console.log(
                            "We're unable to take payments through Apple Pay at the moment. Please try an alternative payment method."
                        );
                        return false;
                    }

                    // Handle invalid merchant
                    session.onvalidatemerchant = function(event) {
                        applePayInstance.performValidation(
                            {
                                validationURL: event.validationURL,
                                displayName: 'Apple Pay'
                            },
                            function(validationErr, merchantSession) {
                                if (validationErr) {
                                    session.abort();
                                    console.error(
                                        'Braintree ApplePay Error validating merchant:',
                                        validationErr
                                    );
                                    console.log(
                                        "We're unable to take payments through Apple Pay at the moment. Please try an alternative payment method."
                                    );
                                    return;
                                }
                                session.completeMerchantValidation(
                                    merchantSession
                                );
                            }
                        );
                    };

                    // Attach payment auth event
                    session.onpaymentauthorized = function(event) {
                        applePayInstance.tokenize(
                            {
                                token: event.payment.token
                            },
                            function(tokenizeErr, payload) {
                                setShowLoader(true);
                                if (tokenizeErr) {
                                    console.error(
                                        'Error tokenizing Apple Pay:',
                                        tokenizeErr
                                    );
                                    session.completePayment(
                                        ApplePaySession.STATUS_FAILURE
                                    );
                                    return;
                                }
                                let shippingContact =
                                        event.payment.shippingContact,
                                    billingContact =
                                        event.payment.billingContact;
                                const isValid = validateAddresses(
                                    shippingContact,
                                    billingContact,
                                    session
                                );
                                if (!isValid) {
                                    setShowLoader(false);
                                    return;
                                }

                                setFullShippingAddress({
                                    variables: {
                                        firstname: shippingContact.givenName,
                                        lastname: shippingContact.familyName,
                                        company: '',
                                        street: shippingContact.addressLines,
                                        city: shippingContact.locality,
                                        region:
                                            shippingContact.administrativeArea,
                                        postcode: shippingContact.postalCode,
                                        countryCode: shippingContact.countryCode.toUpperCase(),
                                        telephone: shippingContact.phoneNumber
                                            ? shippingContact.phoneNumber
                                            : '000000',
                                        region_id: getRegionId(
                                            shippingContact.countryCode.toUpperCase(),
                                            shippingContact.administrativeArea,
                                            countryData
                                        )
                                    }
                                }).then(shipResponse => {
                                    if (!shipResponse) {
                                        return;
                                    }
                                    setBillingAddress({
                                        variables: {
                                            firstname: billingContact.givenName,
                                            lastname: billingContact.familyName,
                                            company: '',
                                            street: billingContact.addressLines,
                                            city: billingContact.locality,
                                            region:
                                                billingContact.administrativeArea,
                                            postcode: billingContact.postalCode,
                                            countryCode: billingContact.countryCode.toUpperCase(),
                                            telephone: billingContact.phoneNumber
                                                ? billingContact.phoneNumber
                                                : '000000',
                                            region_id: getRegionId(
                                                billingContact.countryCode.toUpperCase(),
                                                billingContact.administrativeArea,
                                                countryData
                                            )
                                        }
                                    }).then(bilResponse => {
                                        if (!bilResponse) {
                                            return;
                                        }
                                        if (!isSignedIn()) {
                                            setGuestEmail({
                                                variables: {
                                                    cartId: cartState.cartId,
                                                    email:
                                                        shippingContact.emailAddress
                                                }
                                            }).then(emailResponse => {
                                                if (!emailResponse) {
                                                    return;
                                                }
                                                setupBrainTreePayments(
                                                    payload.nonce,
                                                    session
                                                );
                                            });
                                        } else {
                                            setupBrainTreePayments(
                                                payload.nonce,
                                                session
                                            );
                                        }
                                    });
                                });
                            }
                        );
                    };

                    // Attach onShippingContactSelect method
                    session.onshippingcontactselected = function(event) {
                        let address = event.shippingContact;
                        let bilAddress = event.billingContact;
                        setShippingAddress({
                            variables: {
                                firstname: dummy,
                                lastname: dummy,
                                company: '',
                                street: [dummy],
                                city: address.locality,
                                region: address.administrativeArea,
                                postcode: address.postalCode,
                                countryCode: address.countryCode,
                                telephone: '000000',
                                regionId: getRegionId(
                                    address.countryCode,
                                    address.administrativeArea,
                                    countryData
                                )
                            }
                        }).then(shipResult => {
                            if (!shipResult) {
                                const dummyShippingMethods = [
                                    {
                                        label: 'Free Shipping',
                                        detail: '',
                                        amount: '0.00',
                                        identifier: 'FreeShipping'
                                    }
                                ];
                                const total = {
                                    label: 'Total',
                                    type: 'final',
                                    amount:
                                        cartState.cart.prices.grand_total.value
                                };

                                //Params : Status, ShippingMethod, Total, Line items
                                session.completeShippingContactSelection(
                                    ApplePaySession.STATUS_INVALID_SHIPPING_POSTAL_ADDRESS,
                                    dummyShippingMethods,
                                    total,
                                    []
                                );
                            }
                        });
                    };

                    // Attach onShippingMethodSelect method
                    session.onshippingmethodselected = function(event) {
                        let method = event.shippingMethod;
                        setSelectedShippingMethod(method);
                        setChoosedShippingMethod({
                            variables: {
                                cartId: cartState.cartId,
                                carrierCode:
                                    shippingMethodsObj[method.identifier]
                                        .carrier_code,
                                methodCode: method.identifier
                            }
                        });
                        console.log(event);
                    };

                    session.oncancel = function(event) {
                        //This is to reset the quote addresses
                        resetAddress();
                        console.log(`session on cancel`);
                    };

                    session.begin();
                } catch (err) {
                    console.error(err);
                    console.log('Error in Apple Pay');
                }
            });

            const element = document.getElementById('apple-pay-container');
            element && element.appendChild(el);
        }
    }, [braintreeApplePaySetupComplete]);

    if (!braintreeApplePaySetupComplete) {
        return (
            <span className="apple-loader">
                <Skeleton height={'50px'} />
            </span>
        );
    }

    return (
        <>
            {showLoader && (
                <div className={'express-loader'}>
                    <LoadingIndicator />
                </div>
            )}
            <div id="apple-pay-container" />
        </>
    );
};

const ApplePayButtonWrapper = props => {
    // Load Apple Pay script
    const { braintreeScript, braintreeApplePayScript } = props;
    const [braintreeReady] = useScript(braintreeScript);
    const [braintreeApplePayReady] = useScript(braintreeApplePayScript);
    const canMakePayment =
        window.ApplePaySession && ApplePaySession.canMakePayments();
    const deviceSupportedCheck = () => {
        if (location.protocol !== 'https:') {
            console.warn(
                'Braintree Apple Pay requires your checkout be served over HTTPS'
            );
            return false;
        }

        if (!canMakePayment) {
            console.warn(
                'Braintree Apple Pay is not supported on this device/browser'
            );
            return false;
        }

        return true;
    };

    // Note: react does not guarantee that the value will never be forgotten for useMemo
    // - But it should be enough to lower warning count in the general case
    const deviceSupported = useMemo(deviceSupportedCheck, [
        location.protocol,
        canMakePayment
    ]);
    if (!deviceSupported) {
        return null;
    }

    return (
        <ApplePayButton
            brainTreeApplePayScriptsReady={
                braintreeReady && braintreeApplePayReady
            }
        />
    );
};

ApplePayButtonWrapper.propTypes = {
    braintreeScript: PropTypes.string,
    braintreeApplePayScript: PropTypes.string
};

ApplePayButtonWrapper.defaultProps = {
    braintreeScript:
        'https://js.braintreegateway.com/web/3.94.0/js/client.min.js',
    braintreeApplePayScript:
        'https://js.braintreegateway.com/web/3.94.0/js/apple-pay.min.js'
};

export { ApplePayButtonWrapper as ApplePayButton };
