import React from 'react';
import { shape, string } from 'prop-types';

import classNames from 'classnames/bind';
import style from './FieldIcons.less';
let examineClasses = classNames.bind(style);

export const FieldIcons = props => {
    const { after, before, children } = props;

    const style = {
        '--iconsBefore': before ? 1 : 0,
        '--iconsAfter': after ? 1 : 0
    };

    return (
        <div style={style} className={'field-icons'}>
            <span className={'input-block'}>{children}</span>
            <span className={examineClasses('before')}>{before}</span>
            <span className={examineClasses('after')}>{after}</span>
        </div>
    );
};

FieldIcons.propTypes = {
    classes: shape({
        after: string,
        before: string,
        root: string
    })
};
