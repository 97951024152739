import React from 'react';
import PropTypes from 'prop-types';

const UK = props => {
    const { color, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 17.15 17.16"
            height={size}
            width={size}
            {...otherProps}
        >
            <defs>
                <mask
                    id="a"
                    width="18.98"
                    height="18.95"
                    x="-0.94"
                    y="-0.94"
                    maskUnits="userSpaceOnUse"
                >
                    <circle cx="8.59" cy="8.59" r="8.57" fill="#fff"></circle>
                </mask>
            </defs>
            <g fillRule="evenodd" mask="url(#a)">
                <path
                    fill="#fffffe"
                    d="M16.1 11l1.32.79c.09-.26.17-.52.25-.79H16.1"
                ></path>
                <path
                    fill="#292f5f"
                    d="M-.63 11a8.45 8.45 0 00.28.85L1.07 11h-1.7M15.62 2.24A9.45 9.45 0 0010.9-.65v5.73l4.72-2.84M17.67 6.08a5.48 5.48 0 00-.22-.72l-1.2.72h1.42M1.53 14.92a9.48 9.48 0 004.74 2.8v-5.65l-4.74 2.85M-.39 5.29c-.09.26-.17.52-.24.79H.91l-1.3-.79M10.9 17.69a9.46 9.46 0 003.22-1.53 9.68 9.68 0 001.43-1.28l-4.65-2.8v5.61M6.27-.68A9.26 9.26 0 002.92.88 9.62 9.62 0 001.46 2.2l4.81 2.89V-.68"
                ></path>
                <path
                    fill="#fffffe"
                    d="M6.27 10.45h-7A2.12 2.12 0 00-.6 11h1.7l-1.42.85a8.6 8.6 0 00.68 1.44L4 11h2.27L1 14.25c.17.23.36.46.55.67l4.74-2.85v5.65a7.62 7.62 0 00.93.18v-7.45h-1M17.67 6.08h-1.42l1.2-.72a8.33 8.33 0 00-.69-1.52l-3.65 2.24H11l5.19-3.18c-.17-.23-.35-.45-.53-.66L10.9 5.08V-.65c-.31-.08-.62-.14-.93-.19v7.5h7.84a2 2 0 00-.14-.58M6.27-.68v5.77L1.46 2.2A9.11 9.11 0 00.38 3.67l4.08 2.41H2.52l-2.6-1.54c-.11.25-.22.5-.31.75l1.3.79H-.63a3.25 3.25 0 01-.14.58h8V-.86a7.62 7.62 0 00-.93.18M17.8 10.45H10v7.43c.31-.05.62-.11.93-.19v-5.61l4.65 2.8a17.29 17.29 0 001.14-1.56L12.92 11h1.87l2.36 1.42c.1-.22.19-.43.27-.65L16.1 11h1.57a2.65 2.65 0 01.13-.54"
                ></path>
                <path
                    fill="#d63330"
                    d="M4 11L.33 13.28a7.57 7.57 0 00.55.84.64.64 0 00.1.13L6.27 11H4M12.92 11l3.77 2.31c.17-.29.32-.59.46-.89L14.79 11h-1.87M13.11 6.08l3.65-2.24a8.07 8.07 0 00-.6-.92L11 6.08h2.13M4.46 6.08L.38 3.67c-.17.29-.32.58-.46.87l2.6 1.54h1.94"
                ></path>
                <path
                    fill="#d63330"
                    d="M17.81 6.66H10v-7.5a9.19 9.19 0 00-2.77 0v7.5h-8a9.67 9.67 0 000 3.79h8v7.45a9.19 9.19 0 002.77 0v-7.45h7.83a9.67 9.67 0 000-3.79"
                ></path>
            </g>
        </svg>
    );
};

UK.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

UK.defaultProps = {
    color: 'currentColor',
    size: '18'
};

export default UK;
