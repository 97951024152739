import { deepValue } from "@corratech/tag-manager";

const isAllDataDefined = (variableNames, data) => {
    let isDataDefined = true;
    variableNames.map(name => {
        if (typeof data[name] === 'undefined') {
            isDataDefined = false;
        }
    })
    return isDataDefined;
}

const PAGEVIEW_EVENT = 'pageview';
const variables = ['userLogState', 'pageDetails', 'cartContents'];

export const PageViewEvents = (eventType, eventHistory, getStoredData) => {
    if (eventHistory().includes(PAGEVIEW_EVENT)) {
        return;
    }
    const pageViewData = getStoredData(variables);
    const isDataDefined = isAllDataDefined(variables, pageViewData);
    const isPageNameDefined = !!deepValue(pageViewData, ['pageDetails', 'pageName'], false);
    if (!isDataDefined || !isPageNameDefined) {
        return;
    }

    return {
        event: PAGEVIEW_EVENT,
        ...pageViewData,
        _clear: true
    };
}