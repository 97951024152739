import React, { Fragment } from 'react';
import { node, shape, string, func } from 'prop-types';
import classNames from 'classnames/bind';
import { asField } from 'informed';
import { FieldIcons } from '../Field';
import { Message } from '../Message';
import style from '../Message/Message.less';
let examineClasses = classNames.bind(style);

export const TextArea = asField(({ fieldState, fieldApi, ...props }) => {
    const { value } = fieldState;
    const { setValue, setTouched } = fieldApi;
    const {
        onChange,
        onBlur,
        before,
        after,
        enableError,
        setErrorClass,
        initialValue,
        forwardedRef,
        message,
        ...rest
    } = props;

    const { asyncError, error } = fieldState;
    const errorMessage = error || asyncError;

    const className = errorMessage
        ? examineClasses('field-required-error')
        : examineClasses('');

    if (enableError) {
        errorMessage ? setErrorClass(true) : setErrorClass(false);
    }

    return (
        <Fragment>
            <FieldIcons after={after} before={before}>
                <textarea
                    {...rest}
                    data-cs-mask
                    className={className}
                    ref={forwardedRef}
                    onChange={e => {
                        setValue(e.target.value);
                        if (onChange) {
                            onChange(e);
                        }
                    }}
                    onBlur={e => {
                        setTouched(true);
                        if (onBlur) {
                            onBlur(e);
                        }
                    }}
                >
                    {!value && value !== 0 ? '' : value}
                </textarea>
            </FieldIcons>
            <Message fieldState={fieldState}>{message}</Message>
        </Fragment>
    );
});

TextArea.propTypes = {
    after: node,
    before: node,
    classes: shape({
        input: string
    }),
    fieldState: shape({
        value: string
    }),
    message: node,
    onChange: func,
    onBlur: func
};
