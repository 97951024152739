import React, { Fragment } from 'react';
import { node, shape, string } from 'prop-types';
import classNames from 'classnames/bind';
import { asField, BasicText } from 'informed';
import { FieldIcons } from '../Field';
import { Message } from '../Message';
import style from '../Message/Message.less';
let examineClasses = classNames.bind(style);

const TextInput = props => {
    const {
        after,
        before,
        classes,
        fieldState,
        message,
        focusEventEnabled,
        setFieldFocus,
        setEnableErrorClass,
        maxlength,
        ...rest
    } = props;
    const { asyncError, error } = fieldState;
    const errorMessage = error || asyncError;
    const className = errorMessage
        ? examineClasses('field-required-errror')
        : examineClasses('');

    //Only on Focus/Blur
    if (focusEventEnabled) {
        errorMessage ? setEnableErrorClass(true) : setEnableErrorClass(false);
        if (rest.fieldApi.getValue()) setFieldFocus(true);
    }
    const onFieldFocus = () => {
        if (focusEventEnabled) setFieldFocus(true);
    };
    const onFieldBlur = field => {
        if (focusEventEnabled) {
            if (!field.currentTarget.value) setFieldFocus(false);
        }
    };

    return (
        <Fragment>
            <FieldIcons after={after} before={before}>
                <BasicText
                    {...rest}
                    className={className}
                    fieldState={fieldState}
                    onBlur={onFieldBlur}
                    onFocus={onFieldFocus}
                    maxLength={maxlength}
                    data-cs-mask
                />
            </FieldIcons>
            <Message fieldState={fieldState}>{message}</Message>
        </Fragment>
    );
};

TextInput.propTypes = {
    after: node,
    before: node,
    classes: shape({
        input: string
    }),
    fieldState: shape({
        value: string
    }),
    message: node
};

export default asField(TextInput);
