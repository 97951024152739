export const getDeliveryDateRange = (deliveryObj) => {
    const options = { month: 'short', day: 'numeric' };
    if (!deliveryObj.min) {
        return '';
    }

    const minDate = new Date(deliveryObj.min);
    const maxDate = deliveryObj.max ? new Date(deliveryObj.max) : null;

    const formatWithDot = (date) => {
        const formatted = date.toLocaleDateString('en-US', options);
        return formatted.replace(/(\w+)\b/, '$1.');
    };

    const addDaySuffix = (date) => {
        const day = date.getDate();
        if (day >= 11 && day <= 13) return `${day}th`;
        switch (day % 10) {
            case 1: return `${day}st`;
            case 2: return `${day}nd`;
            case 3: return `${day}rd`;
            default: return `${day}th`;
        }
    };

    const minDayWithSuffix = addDaySuffix(minDate);
    const formattedMinDate = formatWithDot(minDate).replace(/\d+/, minDayWithSuffix);

    if (!maxDate) {
        return `Estimated delivery by ${formattedMinDate}.`;
    }

    const maxDayWithSuffix = addDaySuffix(maxDate);
    const formattedMaxDate = formatWithDot(maxDate).replace(/\d+/, maxDayWithSuffix);

    if (formattedMinDate === formattedMaxDate) {
        return `Estimated delivery by ${formattedMinDate}.`;
    } else {
        return `Estimated delivery between ${formattedMinDate} and ${formattedMaxDate}.`;
    }
};
