import React from 'react';
import { useTranslation } from 'react-i18next';

require('./progressBar.less');

export const ProgressBar = ({
    activeStepNumber,
    setShowPaymentForm,
    isVirtual
}) => {
    const [t] = useTranslation();

    const renderIndicator = (step, activeState) => {
        return (
            <span className="checkout-progress-title">
                <span
                    className={`step-indicator ${activeState &&
                        'active-step-count'}`}
                >
                    <span className="step-indicator-label">{step}</span>
                </span>
            </span>
        );
    };

    return (
        <div className="progress-container">
            {isVirtual ? (
                <button
                    aria-current="Review & Payment"
                    className={`step-container ${activeStepNumber === 1 &&
                        'active-step'}`}
                    aria-label="Current Step: Review & Payment"
                    tabIndex={activeStepNumber === 1 ? '0' : '-1'}
                >
                    {renderIndicator(1, activeStepNumber === 1)}{' '}
                    {t('Review & Payment')}
                </button>
            ) : (
                <>
                    <button
                        className={`step-container ${activeStepNumber === 1 &&
                            'active-step'}`}
                        aria-current="Shipping"
                        aria-label="Current Step: Shipping Method"
                        onClick={() => setShowPaymentForm(false)}
                        tabIndex={activeStepNumber === 1 ? '0' : '0'}
                    >
                        {renderIndicator(1, activeStepNumber === 1)}{' '}
                        {t('Shipping')}
                    </button>{' '}
                    <span className="connection-line" />{' '}
                    <button
                        aria-current="Review & Payment"
                        className={`step-container ${activeStepNumber === 2 &&
                            'active-step'}`}
                        aria-label="Current Step: Review & Payment"
                        tabIndex={activeStepNumber === 2 ? '0' : '-1'}
                    >
                        {renderIndicator(2, activeStepNumber === 2)}{' '}
                        {t('Review & Payment')}
                    </button>
                </>
            )}
        </div>
    );
};

ProgressBar.defaultProps = {
    isVirtual: false
};
