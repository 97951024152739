import { getCategoryPath as defaultItemCategoryPath } from '@corratech/google-tag-manager';
import { deepValue } from '@corratech/tag-manager';
import { MAX_CATEGORY_PATH_DEPTH } from '@corratech/google-tag-manager';

const getPrimaryCategory = categories => {
    let categoryNames = categories.filter(category => {
        return (
            category.breadcrumbs &&
            deepValue(category, ['breadcrumbs', '0', 'category_name'])
        );
    });

    return categoryNames && categoryNames.length
        ? deepValue(categoryNames[0], ['breadcrumbs', '0', 'category_name'])
        : null;
};

export const formatCategoryPath = (path = [], appendItem = false) => {
    if (path.length === 0 || path[0] !== 'Home') {
        path.splice(0, 0, '');
    }
    let lengthLimit = !!appendItem
        ? MAX_CATEGORY_PATH_DEPTH - 1
        : MAX_CATEGORY_PATH_DEPTH;
    path = path.slice(0, lengthLimit);
    if (!!appendItem) {
        path.push(appendItem);
    }
    return path.join('');
};

export const getItemCategoryPath = item => {
    let category = deepValue(item, ['category', 'name']);
    if (category) {
        return formatCategoryPath([], category);
    }

    if (item.category) {
        return formatCategoryPath([], item.category);
    }

    let categories = item.product
        ? getPrimaryCategory(deepValue(item, ['product', 'categories'], []))
        : null;

    if (categories) {
        return formatCategoryPath([], categories);
    }

    return defaultItemCategoryPath(item);
};
