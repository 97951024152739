import React, { useReducer } from 'react';

const initialState = { class: 'overlay-hide' };
const callBacksInitialState = [];
const OverlayStore = React.createContext(initialState);

let reducer = (state, action) => {
    switch (action.type) {
        case 'SHOW':
            return { ...state, ...{ class: 'overlay-open' } };
            break;
        case 'HIDE':
            return { ...state, ...{ class: 'overlay-hide' } };
        default:
            return { ...state };
    }
};

let callbackReducer = (state, action) => {
    switch (action.type) {
        case 'PUSH':
            return [...state, action.data];

        default:
            return { ...state };
    }
};

function OverlayProvider(props) {
    const [overlayState, overlayDispatch] = useReducer(reducer, initialState);
    const [callbackState, callbackDispatch] = useReducer(
        callbackReducer,
        callBacksInitialState
    );

    return (
        <OverlayStore.Provider
            value={{
                overlayState,
                overlayDispatch,
                callbackState,
                callbackDispatch
            }}
        >
            {props.children}
        </OverlayStore.Provider>
    );
}
export { OverlayStore, OverlayProvider };
