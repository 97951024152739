import React from 'react';
import PropTypes from 'prop-types';

const SortIcon = props => {
    const { color, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 14 18"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g
                    fill="#000"
                    fillRule="nonzero"
                    transform="translate(-209 -196)"
                >
                    <g transform="translate(20 185)">
                        <g transform="translate(184 8)">
                            <g>
                                <path d="M16 17.01V10h-2v7.01h-3L15 21l4-3.99h-3zM9 3L5 6.99h3V14h2V6.99h3L9 3z"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

SortIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

SortIcon.defaultProps = {
    color: 'currentColor',
    size: '24'
};

export default SortIcon;
