import React, { useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AddressBlock } from '../AddressBlock';
import { getCurrencySymbol } from '../util/getCurrencySymbol';
import { setPriceZeroes } from '../util/setPriceZeroes';
import './SummaryBlock.less';
import { CartItemList } from '@corratech/cart';
import { GiftSummaryBlock } from '@corratech/giftcard';
import PropTypes from 'prop-types';
import { CollapsibleContainer } from '@corratech/collapsible-container';
import { deepValue } from '@corratech/tag-manager';
let CartStore, useGlobalOptions, LoaderStore;
if (process.env.STORYBOOK_IN_STORYBOOK) {
    const allContext = require('./MockCartContext');
    CartStore = allContext.CartStore;
    useGlobalOptions = allContext.useGlobalOptions;
    LoaderStore = allContext.LoaderStore;
} else {
    const allContext = require('@corratech/context-provider');
    CartStore = allContext.CartStore;
    useGlobalOptions = allContext.useGlobalOptions;
    LoaderStore = allContext.LoaderStore;
}
const HeaderWithEditOption = ({ title, onClickProp, showPaymentForm }) => {
    const [t] = useTranslation();
    return (
        <div className="container-header-with-edit-option">
            <h2 className={'address-label'}>{t(title)}</h2>
            {showPaymentForm && (
                <Button
                    variant="link"
                    onClick={onClickProp}
                    className={'edit-link-button'}
                >
                    {t('Edit')}
                </Button>
            )}
        </div>
    );
};
export const SummaryBlock = props => {
    const [t] = useTranslation();
    const { cartState } = useContext(CartStore);
    const LoadingIndicator = useContext(LoaderStore);
    const globalOptions = useGlobalOptions();
    const storeConfig = globalOptions.storeConfig || {};
    const includeTaxSubtotal =
        storeConfig.display_subtotal_shopping_cart === '2' ? true : false;

    const getCouponCodeForDiscountLabel = () => {
        if (
            cartState.cart.applied_coupons &&
            cartState.cart.applied_coupons[0]
        ) {
            return `${t('Promo Code')} "${
                cartState.cart.applied_coupons[0].code
            }"`;
        } else {
            let discounts = deepValue(
                cartState,
                ['cart', 'prices', 'discounts'],
                null
            );

            if (discounts && discounts[0]) {
                return `${t('Promo')} "${discounts[0].label}"`;
            }
        }
        return t('Promo Code');
    };
    const getStoreCredit = () => {
        if (
            cartState.cart.applied_store_credit &&
            cartState.cart.applied_store_credit.enabled &&
            cartState.cart.applied_store_credit.applied_balance.value > 0
        ) {
            return (
                <div className={'price-line cart-sub-total'}>
                    {t('Store Credit')}{' '}
                    <span>
                        {'-'}
                        {getCurrencySymbol(
                            cartState.cart.applied_store_credit.applied_balance
                                .currency
                        ) +
                            setPriceZeroes(
                                cartState.cart.applied_store_credit
                                    .applied_balance.value
                            )}
                    </span>
                </div>
            );
        } else {
            return null;
        }
    };

    const getTotalTax = () => {
        const totalTax = cartState.cart.prices.applied_taxes.reduce(
            (accumulator, tax) => accumulator + tax.amount.value,
            0
        );
        return totalTax.toFixed(2);
    };

    const getGrandTotal = () => {
        if (
            cartState.cart.applied_store_credit &&
            cartState.cart.applied_store_credit.applied_balance.value > 0
        ) {
            const total =
                cartState.cart.prices.grand_total.value -
                cartState.cart.applied_store_credit.applied_balance.value;

            return total > 0 ? total.toFixed(2) : (0).toFixed(2);
        } else {
            return cartState.cart.prices.grand_total.value.toFixed(2);
        }
    };

    if (!cartState.cart.items) return <LoadingIndicator />;

    const getDiscountPrice = () => {
        return (
            parseFloat(cartState.cart.prices.subtotal_excluding_tax.value) -
            parseFloat(
                cartState.cart.prices.subtotal_with_discount_excluding_tax.value
            )
        );
    };
    const getSubtotal = () => {
        let subTotal = 0;
        if (includeTaxSubtotal) {
            if (cartState.cart.prices.subtotal_including_tax) {
                subTotal = cartState.cart.prices.subtotal_including_tax.value;
            }
        } else {
            if (cartState.cart.prices.subtotal_excluding_tax) {
                subTotal = cartState.cart.prices.subtotal_excluding_tax.value;
            }
        }
        return setPriceZeroes(subTotal);
    };

    const displayPrice = (price, label, key) => {
        const currency = getCurrencySymbol(
            cartState?.cart?.prices?.subtotal_with_discount_excluding_tax
                ?.currency
        );
        return (
            <div className={'price-line cart-sub-total'} key={key}>
                <span data-cs-mask>{label}</span>
                <span>
                    {'-'}
                    {currency + price.toFixed(2)}
                </span>
            </div>
        );
    };

    const DisplayDiscountPrice = () => {
        const separateDiscounts = deepValue(
            cartState,
            ['cart', 'prices', 'separate_discounts'],
            null
        );

        return separateDiscounts?.map((separateDiscount, index) => {
            const price = separateDiscount?.amount?.value;
            const label = separateDiscount?.label;

            if (price && label) {
                return displayPrice(price, label, `discount-${index}`);
            }
        });
    };

    return (
        <div className={'summary-wrapper'}>
            <div className={'checkout-summary-block'}>
                <CollapsibleContainer
                    isOpen={props.startSummaryOpen}
                    title={
                        <>
                            <h2>{t('Order Summary')}</h2>
                            <br />
                            <span className={'num-items'}>
                                <div>
                                    <b>
                                        {cartState.cart.items.length > 0
                                            ? cartState.cart.total_quantity &&
                                              cartState.cart.total_quantity > 0
                                                ? cartState.cart.total_quantity
                                                : cartState.cart.items.length
                                            : cartState.cart.items.length}{' '}
                                        {cartState.cart.items.length > 1
                                            ? t('Items in Cart')
                                            : t('Item in Cart')}
                                    </b>
                                </div>
                            </span>
                        </>
                    }
                >
                    <CartItemList disableControls />
                </CollapsibleContainer>
                <div className="divider" />
                <div>
                    <div className={'price-line cart-sub-total'}>
                        {t('Subtotal')}{' '}
                        {
                            <span>
                                {getCurrencySymbol(
                                    cartState.cart.prices.subtotal_excluding_tax
                                        .currency
                                ) + getSubtotal()}
                            </span>
                        }
                    </div>
                    {!cartState.cart.is_virtual && (
                        <div className={'price-line cart-sub-total'}>
                            {t('Shipping')}{' '}
                            <span>
                                {!(
                                    !!cartState.cart.shipping_addresses &&
                                    !!cartState.cart.shipping_addresses[0] &&
                                    !!cartState.cart.shipping_addresses[0]
                                        .selected_shipping_method
                                ) ? (
                                    <i>
                                        {t(
                                            'pending selection of shipping method'
                                        )}
                                    </i>
                                ) : (
                                    <span>
                                        {`${cartState.cart.shipping_addresses[0].selected_shipping_method.carrier_title}
                                        (${cartState.cart.shipping_addresses[0].selected_shipping_method.method_title}) `}
                                        {getCurrencySymbol(
                                            cartState.cart.shipping_addresses[0]
                                                .selected_shipping_method.amount
                                                .currency
                                        ) +
                                            setPriceZeroes(
                                                cartState.cart
                                                    .shipping_addresses[0]
                                                    .selected_shipping_method
                                                    .amount.value
                                            )}
                                    </span>
                                )}
                            </span>
                        </div>
                    )}
                    {cartState.cart.prices.applied_taxes.length > 0 && (
                        <div className={'price-line cart-sub-total'}>
                            {t('VAT 20%')}{' '}
                            <span>
                                {getCurrencySymbol(
                                    cartState.cart.prices.applied_taxes[0]
                                        .amount.currency
                                ) + getTotalTax()}
                            </span>
                        </div>
                    )}
                    {getStoreCredit()}
                    {cartState?.cart?.prices?.separate_discounts ? (
                        <DisplayDiscountPrice />
                    ) : null}
                    <GiftSummaryBlock />
                    <div className={'price-line cart-grand-total row'}>
                        <div className={'col-left'}>{t('Order Total')}</div>
                        <div className={'col-right'}>
                            {getCurrencySymbol(
                                cartState.cart.prices.grand_total.currency
                            ) + getGrandTotal()}
                        </div>
                    </div>
                </div>
                {!cartState.cart.is_virtual &&
                    !!cartState.cart.shipping_addresses &&
                    !!cartState.cart.shipping_addresses[0] && (
                        <div>
                            <HeaderWithEditOption
                                title="Shipping Address"
                                onClickProp={() => {
                                    props.setShowPaymentForm(false);
                                }}
                                showPaymentForm={props.showPaymentForm}
                            />
                            <AddressBlock
                                address={cartState.cart.shipping_addresses[0]}
                            />
                        </div>
                    )}
                {!!cartState.cart.billing_address && (
                    <div>
                        <h2 className={'address-label'}>
                            {t('Billing Address')}
                        </h2>
                        <AddressBlock
                            address={cartState.cart.billing_address}
                        />
                    </div>
                )}
                {!cartState.cart.is_virtual &&
                    !!cartState.cart.shipping_addresses &&
                    !!cartState.cart.shipping_addresses[0] &&
                    !!cartState.cart.shipping_addresses[0]
                        .selected_shipping_method && (
                        <div>
                            <HeaderWithEditOption
                                title="Shipping Method"
                                onClickProp={() => {
                                    props.setShowPaymentForm(false);
                                }}
                                showPaymentForm={props.showPaymentForm}
                            />
                            <div className={'price-line cart-grand-total row'}>
                                <div className={'col-left'}>
                                    {
                                        cartState.cart.shipping_addresses[0]
                                            .selected_shipping_method
                                            .carrier_title
                                    }{' '}
                                    (
                                    {
                                        cartState.cart.shipping_addresses[0]
                                            .selected_shipping_method
                                            .method_title
                                    }
                                    )
                                </div>
                                <div className={'col-right'}>
                                    {getCurrencySymbol(
                                        cartState.cart.shipping_addresses[0]
                                            .selected_shipping_method.amount
                                            .currency
                                    ) +
                                        setPriceZeroes(
                                            cartState.cart.shipping_addresses[0]
                                                .selected_shipping_method.amount
                                                .value
                                        )}
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        </div>
    );
};

SummaryBlock.propTypes = {
    startSummaryOpen: PropTypes.bool,
    showPaymentForm: PropTypes.bool,
    setShowPaymentForm: PropTypes.func
};
