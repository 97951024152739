import React, { useContext, useEffect, useState } from 'react';
import { object, string, func, bool } from 'prop-types';
import { Util } from '@magento/peregrine';
import { useMutation, useLazyQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { Alert, Button } from 'react-bootstrap';
import {
    CartStore,
    LoaderStore,
    useSetNotification,
    useGlobalOptions
} from '@corratech/context-provider';
import { QuantitySelector } from '@corratech/quantity-selector';
import {
    getCurrencySymbol,
    getMatchingVariant,
    isProductConfigurable,
    setPriceZeroes
} from '@corratech/cart/src/util';
import { VariantSelector } from '@corratech/variant-selector';
import ProductDetails from '@corratech/cart/src/queries/getProductDetailBySku.graphql';
import addConfigurableProductToCart from '../queries/addConfigurableProductToCart.graphql';
import updateCartItems from '../queries/updateCartItems.graphql';
import removeItemFromCart from '../queries/removeItemFromCart.graphql';
import { useTagManager } from '@corratech/context-provider';
import '@corratech/cart/src/CartEditItem/CartEditItem.less';

export const CartEditItem = props => {
    const [t] = useTranslation();
    const globalOptions = useGlobalOptions();
    const storeConfig = globalOptions.storeConfig || {};
    const includeTaxItemPrice = storeConfig.display_price_shopping_cart === '2';

    const {
        editItem,
        className,
        css,
        setIsEditingItem,
        setShowMessage,
        currencyCode,
        colorAttrCode,
        ErrorIcon,
        isMinicart,
        addConfigurableProductToCartGraphql,
        removeItemFromCartGraphql,
        updateCartItemsGraphql
    } = props;
    const { product, quantity: qty, id: currentId, prices } = editItem;

    const { setNotification, clearNotification } = useSetNotification();

    const [mutationError, setMutationError] = useState(null);

    const { BrowserPersistence } = Util;

    const storage = new BrowserPersistence();

    const [runProductDetailQuery, productDetailResult] = useLazyQuery(
        ProductDetails,
        {
            fetchPolicy: 'cache-and-network',
            onCompleted: data => {
                setProductDetailData(data);
            }
        }
    );

    const {
        loading: productDetailLoading,
        error: productDetailError
    } = productDetailResult;

    const editItemHasOptions =
        editItem.configurable_options &&
        editItem.configurable_options.length > 0;

    const { name: itemName, sku: parentSku } = product;

    const LoadingIndicator = useContext(LoaderStore);

    const { cartState, dispatch } = useContext(CartStore);

    const [quantity, setQuantity] = useState(qty);

    const [itemId, setItemId] = useState(currentId);

    const [sku, setSku] = useState('');

    const [previousSku, setPreviousSku] = useState('');

    const [isProductValid, setIsProductValid] = useState(false);

    const [productConfigurator, setProductConfigurator] = useState([{}]);

    const [defaultOptions, setDefaultOptions] = useState(null);

    const [productDetailData, setProductDetailData] = useState(null);

    const handleCancel = event => {
        event.nativeEvent.stopImmediatePropagation();
        setIsEditingItem(false);
        setShowMessage(false);
    };

    const handleUpdateCart = event => {
        event.nativeEvent.stopImmediatePropagation();
        clearNotification();
        setMutationError(null);
        updateItemCart();
    };

    const handleSelectionChange = (val, key, simpleProduct) => {
        if (simpleProduct.sku !== previousSku) {
            setIsProductValid(true);
            setSku(simpleProduct.sku);
            setMutationError(null);
        } else {
            setIsProductValid(false);
        }
    };

    const retrieveFromStorage = () => {
        return storage.getItem('backupCartItem') || null;
    };

    const writeToStorage = async cartItemVariables => {
        storage.setItem('backupCartItem', cartItemVariables);
    };

    const clearStorage = () => {
        storage.removeItem('backupCartItem');
    };

    const [
        addConfigurableItemToCart,
        { loading: addItemToCartMutationLoad }
    ] = useMutation(addConfigurableProductToCartGraphql);

    const updateItemCart = async () => {
        try {
            if (isProductConfigurable(configItem)) {
                await writeToStorage({
                    sku: previousSku,
                    quantity: quantity,
                    parentSku: parentSku
                });
                await removeItem();
                await addConfigurableToCart({ sku, quantity, parentSku });
                await runProductDetailQuery({
                    variables: {
                        sku: product.sku,
                        onServer: false
                    }
                });
            } else {
                await updateItemQuantity();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleChangeQuantity = quantity => {
        setQuantity(quantity);
        setIsProductValid(true);
    };

    const currencySymbol = currencyCode
        ? getCurrencySymbol(currencyCode)
        : null;

    useEffect(() => {
        if (editItemHasOptions) {
            runProductDetailQuery({
                variables: {
                    sku: product.sku,
                    onServer: false
                }
            });
        }
    }, [editItemHasOptions, runProductDetailQuery]);

    useEffect(() => {
        if (productDetailData) {
            const productData = productDetailData.products.items[0];

            if (productData) {
                setProductConfigurator([
                    {
                        id: productData.id,
                        sku: productData.sku,
                        product: productData,
                        qty: quantity
                    }
                ]);

                const { variants, configurable_options: options } = productData;

                const optionSelections = new Map();

                const optionSelected = new Map();

                const selectedByDefault = new Map();

                editItem.configurable_options.forEach(option => {
                    optionSelected.set(option.id, Number(option.value_id));
                    selectedByDefault.set(option.id, {
                        value_index: Number(option.value_id),
                        label: option.value_label
                    });
                });

                if (options) {
                    options.forEach(option => {
                        const value_index = optionSelected.get(
                            Number(option.attribute_id)
                        );

                        optionSelections.set(
                            option.attribute_code,
                            value_index
                        );
                    });

                    let defaultOptionsArr = [];
                    options.map(val => {
                        defaultOptionsArr.push({
                            attribute: val.attribute_code,
                            value_index: selectedByDefault.get(
                                Number(val.attribute_id)
                            ).value_index,
                            label: selectedByDefault.get(
                                Number(val.attribute_id)
                            ).label
                        });
                    });

                    setDefaultOptions(defaultOptionsArr);
                }

                const { sku, product } = getMatchingVariant(
                    variants,
                    optionSelections
                );

                setSku(sku);
                setPreviousSku(sku);
            }
        }
    }, [productDetailData]);

    const configItem =
        (productDetailData &&
            productDetailData.products &&
            productDetailData.products.items[0]) ||
        {};

    const [
        removeItem,
        { loading: removeItemMutationLoad, error: removeItemError }
    ] = useMutation(removeItemFromCartGraphql, {
        variables: {
            cartId: cartState.cartId,
            itemId: parseInt(itemId)
        },
        onCompleted: res => {
            const updatedCart = cartState.cart;
            updatedCart.items = res.removeItemFromCart.cart.items;
            updatedCart.prices = res.removeItemFromCart.cart.prices;
            if(res.removeItemFromCart.cart.free_items)
                updatedCart.free_items = res.removeItemFromCart.cart.free_items;
            if(res.removeItemFromCart.cart.total_quantity)
                updatedCart.total_quantity = res.removeItemFromCart.cart.total_quantity;
            updatedCart.applied_coupons = res.removeItemFromCart.cart.applied_coupons;
            dispatch({
                type: 'SET_CART',
                cart: updatedCart
            });

            setProductDetailData(null);
        },
        onError: error => {
            setMutationError(t(props.removeErrorText));
            throw new Error(error.message.split('GraphQL error: ')[1]);
        }
    });

    const addConfigurableToCart = async ({
        sku,
        quantity,
        parentSku,
        restored = null
    }) => {
        await addConfigurableItemToCart({
            fetchPolicy: 'no-cache',
            variables: {
                cartId: cartState.cartId,
                sku: sku,
                quantity: quantity,
                parentSku: parentSku
            }
        })
            .then(({ data }) => {
                const updatedCart = cartState.cart;
                updatedCart.items = data.addConfigurableProductsToCart.cart.items.map(
                    item => {
                        return {
                            ...item,
                            parent_sku: parentSku
                        };
                    }
                );
                updatedCart.prices =
                    data.addConfigurableProductsToCart.cart.prices;
                if(data.addConfigurableProductsToCart.cart.free_items)
                    updatedCart.free_items = data.addConfigurableProductsToCart.cart.free_items;
                if(data.addConfigurableProductsToCart.cart.total_quantity)   
                    updatedCart.total_quantity = data.addConfigurableProductsToCart.cart.total_quantity;
                dispatch({
                    type: 'SET_CART',
                    cart: updatedCart
                });

                setPreviousSku(sku);
                setItemId(data.addConfigurableProductsToCart.cart.items[0].id);

                if (!restored) {
                    setIsEditingItem(false);
                    setShowMessage(true);
                }
            })
            .catch(error => {
                setMutationError(error.message.split('GraphQL error: ')[1]);

                const backupCartItem = retrieveFromStorage();

                if (backupCartItem) {
                    addConfigurableToCart({
                        ...backupCartItem,
                        restored: true
                    });
                    clearStorage();
                }
            });
    };

    const [
        updateItemQuantity,
        { loading: updateItemQuantityMutationLoad, error: updateQuantityError }
    ] = useMutation(updateCartItemsGraphql, {
        fetchPolicy: 'no-cache',
        variables: {
            cartId: cartState.cartId,
            itemId: parseInt(itemId),
            quantity
        },
        onCompleted: res => {
            const updatedCart = cartState.cart;
            updatedCart.items = res.updateCartItems.cart.items.map(item => {
                return {
                    ...item,
                    parent_sku: parentSku
                };
            });
            if(res.updateCartItems.cart.free_items)
                updatedCart.free_items = res.updateCartItems.cart.free_items;
            if(res.updateCartItems.cart.total_quantity)
                updatedCart.total_quantity = res.updateCartItems.cart.total_quantity;
            updatedCart.prices = res.updateCartItems.cart.prices;
            updatedCart.applied_coupons = res.updateCartItems.cart.applied_coupons;
            dispatch({
                type: 'SET_CART',
                cart: updatedCart
            });

            setIsEditingItem(false);
            setShowMessage(true);
        },
        onError: error => {
            setMutationError(error.message.split('GraphQL error: ')[1]);
        }
    });

    useEffect(() => {
        if (mutationError && !isMinicart) {
            setNotification('danger', mutationError, 3600 * 1000 * 24);
        }
    }, [mutationError, isMinicart]);

    useEffect(() => {
        return () => {
            clearNotification();
        };
    }, []);

    if (
        productDetailLoading ||
        removeItemMutationLoad ||
        addItemToCartMutationLoad ||
        updateItemQuantityMutationLoad
    ) {
        return LoadingIndicator ? <LoadingIndicator /> : null;
    }

    const options = isProductConfigurable(configItem) ? (
        <div className={'cart-edit-item-options'}>
            {productConfigurator &&
                productConfigurator[0].sku &&
                productConfigurator.map((val, key) => {
                    return (
                        <VariantSelector
                            key={key}
                            product={configItem}
                            simpleProductCallback={simpleProduct => {
                                if (simpleProduct) {
                                    handleSelectionChange(
                                        val,
                                        key,
                                        simpleProduct
                                    );
                                } else {
                                    setIsProductValid(false);
                                }
                            }}
                            defaultOptions={defaultOptions}
                        />
                    );
                })}
        </div>
    ) : null;

    /**
     * If else statement as fallback
     */
    let totalItemCost;
    if (prices.row_total_including_tax && prices.row_total) {
        totalItemCost = includeTaxItemPrice
            ? parseFloat(prices.row_total_including_tax.value).toFixed(2)
            : parseFloat(prices.row_total.value).toFixed(2);
    } else {
        totalItemCost = (
            parseFloat(prices.price.value) * parseFloat(quantity)
        ).toFixed(2);
    }

    return (
        <div className={'cart-edit-item ' + (className || '')} css={css}>
            {isMinicart && mutationError && (
                <Alert variant={'danger'}>
                    {ErrorIcon}
                    {mutationError}
                </Alert>
            )}
            <div className={'cart-edit-item-top'}>
                <div className={'cart-edit-item-name-wrapper'}>
                    <div className={'cart-edit-item-name'}>{itemName}</div>
                    <div className={'cart-edit-item-price'}>
                        {currencySymbol + setPriceZeroes(totalItemCost)}
                    </div>
                </div>
            </div>
            <div className={'cart-edit-item-wrapper'}>
                <div className={'cart-edit-item-options-list'}>
                    {options}
                    <div
                        className={
                            'cart-edit-item-options-item cart-edit-item-quantity'
                        }
                    >
                        <h3 className={'cart-edit-item-quantity-title'}>
                            {t(`Qty`)}:
                        </h3>
                        <QuantitySelector
                            setQuantity={handleChangeQuantity}
                            quantity={quantity}
                            placeholder={'1'}
                        />
                    </div>
                </div>
            </div>

            <div className={'cart-edit-item-actions'}>
                <Button size="lg" variant="primary" onClick={handleCancel}>
                    {t(props.cancelText)}
                </Button>
                <Button
                    size="lg"
                    variant="primary"
                    disabled={!isProductValid}
                    onClick={handleUpdateCart}
                >
                    {t(props.updateCartText)}
                </Button>
            </div>
        </div>
    );
};

CartEditItem.propTypes = {
    className: string,
    css: object,
    currencyCode: string,
    editItem: object,
    setIsEditingItem: func,
    setShowMessage: func,
    colorAttrCode: string,
    isMinicart: bool
};

CartEditItem.defaultProps = {
    cancelText: 'Cancel',
    updateCartText: 'Update Cart',
    isMinicart: false,
    addConfigurableProductToCartGraphql:addConfigurableProductToCart,
    removeItemFromCartGraphql:removeItemFromCart,
    updateCartItemsGraphql:updateCartItems,
    removeErrorText: 'Could not remove item from cart. Please try again.'
};
