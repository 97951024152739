import React, { useContext, useEffect, useState, Fragment } from 'react';
import { bool, object, string, func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CartStore, LoaderStore } from '@corratech/context-provider';
import { CartItemList } from '../CartItem/CartItemList';
import { CouponForm } from '@corratech/coupon-form';
import { SummaryBlock } from '../SummaryBlock/SummaryBlock';
import { EmptyCartBlock } from '../EmptyCartBlock/EmptyCartBlock';
import { CartEditItem } from '../CartEditItem/CartEditItem';
import { Alert } from 'react-bootstrap';
import {
    Check as CheckIcon,
    X as DefaultErrorIcon
} from 'react-feather';
import { GiftCard } from '@corratech/giftcard';
import { useLazyQuery } from 'react-apollo';
import getCartQuery from '../queries/getCart.graphql';
import { Crosssell } from '../Crosssell';
require('./CartPage.less');

export const CartPage = props => {
    const {
        className,
        css,
        hasConfirmation,
        successfullyUpdatedText,
        canEdit,
        colorAttrCode,
        qtyLoaderProps,
        isAccordion,
        enableWishlist,
        enableCrosssell,
        SuccessIcon,
        ErrorIcon,
        showTotalCount,
        CartItem,
        CartEditItem,
        CartQueryGraphql,
        displayAsShoppingCart,
        addConfigurableProductToCartGraphql,
        removeItemFromCartGraphql,
        updateCartItemsGraphql,
        applyCouponToCartGraphql,
        removeCouponFromCartGraphql,
        successMsg1,
        promoTitle
    } = props;

    const [t] = useTranslation();
    const { cartState, dispatch } = useContext(CartStore);
    const LoadingIndicator = useContext(LoaderStore);
    const [getCardData, { loading }] = useLazyQuery(CartQueryGraphql, {
        fetchPolicy: 'no-cache',
        onCompleted: res => {
            if (res.cart) {
                dispatch({
                    type: 'SET_CART',
                    cart: res.cart
                });
            }
        }
    });

    /**
     * Execute once the cartState is intiated
     */
    useEffect(() => {
        if (cartState.cartId !== '') {
            getCardData({
                variables: {
                    cartId: cartState.cartId,
                    isSignedIn: !!cartState.cart.authenticated
                }
            });
        }
    }, [cartState.cartId]);

    const [isEditingItem, setIsEditingItem] = useState(false);
    const [editItem, setEditItem] = useState(null);
    const [showMessage, setShowMessage] = useState(null);
    const [wishlistMessage, setWishlistMessage] = useState(null);

    const handleIsEditingItem = state => {
        setIsEditingItem(state);
    };

    const isCartEmpty = () => {
        const { cart } = cartState;

        return !cart || !cart.items || cart.items.length === 0;
    };

    const totalQty = cartState.cart.items
        ? cartState.cart.items.reduce((qty, item) => qty + item.quantity, 0)
        : 0;

    useEffect(() => {
        if (!isEditingItem) {
            setEditItem(null);
        }
    }, [isEditingItem]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowMessage(null);
        }, 10000);
        return () => clearTimeout(timer);
    }, [showMessage]);

    useEffect(() => {
        const timer = setTimeout(() => {
            setWishlistMessage(null);
        }, 10000);
        return () => clearTimeout(timer);
    }, [wishlistMessage]);

    if (loading) return <LoadingIndicator />;

    return (
        <div
            className={'cart-page container-width ' + (className || '')}
            css={css}
        >
            {!displayAsShoppingCart && (
                <h1 className={'cart-page-header'}>
                    {isEditingItem ?
                        t('Edit Cart Item') :
                        t('Shopping Cart') + (showTotalCount && totalQty > 0 ? ' (' + totalQty + ')' : '')
                    }
                </h1>
            )}
            <div className={'cart-container'}>
                {(!isCartEmpty() || (isCartEmpty() && isEditingItem)) && (
                    <>
                        <div className={'lhs'}>
                            {isEditingItem && (
                                <CartEditItem
                                    setIsEditingItem={handleIsEditingItem}
                                    setShowMessage={setShowMessage}
                                    ErrorIcon={ErrorIcon}
                                    editItem={editItem}
                                    currencyCode={
                                        isCartEmpty()
                                            ? 'USD'
                                            : cartState.cart.prices.grand_total
                                                .currency
                                    }
                                    colorAttrCode={colorAttrCode}
                                    addConfigurableProductToCartGraphql={addConfigurableProductToCartGraphql}
                                    removeItemFromCartGraphql={removeItemFromCartGraphql}
                                    updateCartItemsGraphql={updateCartItemsGraphql}
                                />
                            )}

                            {!isEditingItem && (
                                <>
                                    {showMessage && (
                                        <Alert variant={'success'}>
                                            {SuccessIcon}
                                            {t(props.successfullyUpdatedText)}
                                        </Alert>
                                    )}

                                    {wishlistMessage && (
                                        <Alert variant={'success'}>
                                            {SuccessIcon}
                                            {t(wishlistMessage)}
                                        </Alert>
                                    )}

                                    <div className={'cart-items-container'}>
                                        {!displayAsShoppingCart && (
                                            <div className={'column-labels'}>
                                                <label className={'item-text'}>
                                                    {cartState.cart.items.length ===
                                                    1
                                                        ? t('Item')
                                                        : t('Items')}
                                                </label>
                                                <label className="item-price">
                                                    {t('Price')}
                                                </label>
                                                <label className="item-quantity">
                                                    {t('QTY')}
                                                </label>
                                                <label className="item-subtotal">
                                                    {t('Subtotal')}
                                                </label>
                                            </div>
                                        )}
                                        <CartItemList
                                            canEdit={canEdit}
                                            hasConfirmation={hasConfirmation}
                                            setIsEditingItem={
                                                handleIsEditingItem
                                            }
                                            setEditItem={setEditItem}
                                            qtyLoaderProps={qtyLoaderProps}
                                            shoppingCart={true}
                                            enableWishlist={enableWishlist}
                                            setWishlistMessage={
                                                setWishlistMessage
                                            }
                                            CartItem={CartItem}
                                            displayAsShoppingCart={displayAsShoppingCart}
                                            removeItemFromCartGraphql={removeItemFromCartGraphql}
                                            updateCartItemsGraphql={updateCartItemsGraphql}
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                        <div className={`${displayAsShoppingCart ? 'rhs-cart' : ''} rhs`}>
                            {!displayAsShoppingCart ? (
                                <Fragment>
                                    <SummaryBlock cart={cartState.cart} />
                                    <CouponForm 
                                        isAccordion={isAccordion} 
                                        applyCouponToCartGraphql={applyCouponToCartGraphql}
                                        removeCouponFromCartGraphql={removeCouponFromCartGraphql}
                                        removeItemFromCartGraphql={removeItemFromCartGraphql}
                                        successMsg1={successMsg1}
                                        ErrorIcon={ErrorIcon}
                                        promoTitle={promoTitle}
                                    />
                                    <GiftCard className={'cart-page-giftcard-form'} />
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <CouponForm 
                                        isAccordion={isAccordion}
                                        applyCouponToCartGraphql={applyCouponToCartGraphql}
                                        removeCouponFromCartGraphql={removeCouponFromCartGraphql}
                                        removeItemFromCartGraphql={removeItemFromCartGraphql}
                                        successMsg1={successMsg1}
                                        ErrorIcon={ErrorIcon}
                                        promoTitle={promoTitle}
                                    />
                                    <GiftCard className={'cart-page-giftcard-form'} />
                                    <SummaryBlock cart={cartState.cart} />
                                </Fragment>
                            )}

                        </div>
                        {enableCrosssell && <Crosssell />}
                    </>
                )}

                {isCartEmpty() && !isEditingItem && (
                    <>
                        {wishlistMessage && (
                            <Alert variant={'success'}>
                                {SuccessIcon}
                                {t(wishlistMessage)}
                            </Alert>
                        )}
                        <EmptyCartBlock cartPage />
                    </>
                )}
            </div>
        </div>
    );
};

CartPage.propTypes = {
    className: string,
    css: object,
    hasConfirmation: bool,
    successfullyUpdatedText: string,
    canEdit: bool,
    colorAttrCode: string,
    qtyLoaderProps: object,
    enableWishlist: bool,
    enableCrosssell: bool,
    SuccessIcon: object,
    ErrorIcon: object,
    showTotalCount: bool,
    CartItem: func,
    CartEditItem: func,
    displayAsShoppingCart: bool
};

CartPage.defaultProps = {
    successfullyUpdatedText: 'Successfully updated item.',
    canEdit: true,
    hasConfirmation: true,
    colorAttrCode: 'fashion_color',
    qtyLoaderProps: { height: 20, width: 120 },
    enableWishlist: true,
    enableCrosssell: true,
    SuccessIcon: <CheckIcon size={14} strokeWidth={'4'} color={'#000'}/>,
    ErrorIcon: <DefaultErrorIcon size={14} strokeWidth={'4'} color={'#000'}/>,
    showTotalCount: false,
    CartQueryGraphql: getCartQuery,
    CartEditItem: CartEditItem,
    displayAsShoppingCart: false
};
