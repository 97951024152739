import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

export const ContactBlock = props => {
    const { contactTitle, contactText, phoneNumber } = props;

    return (
        <div
            className={`footer-contact-wrapper ${props.className || ''}`}
            css={props.css}
        >
            {contactTitle ? (
                <strong className="footer-contact-heading">
                    {contactTitle}
                </strong>
            ) : (
                ''
            )}

            <div className="content">
                {contactText ? contactText : ''}
                {phoneNumber ? (
                    <Link to={`tel: ${phoneNumber}`} className="phone-link">
                        {phoneNumber}
                    </Link>
                ) : (
                    ''
                )}
            </div>
        </div>
    );
};

ContactBlock.propTypes = {
    contactTitle: PropTypes.string,
    contactText: PropTypes.string,
    phoneNumber: PropTypes.string
};
