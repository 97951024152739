import React from 'react';
import PropTypes from 'prop-types';

const FilterIcon = props => {
    const { color, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 16 16"
        >
            <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
                <g
                    fill="#002739"
                    fillRule="nonzero"
                    transform="translate(-38 -197)"
                >
                    <g transform="translate(20 185)">
                        <g transform="translate(16 10)">
                            <g>
                                <path d="M2.5 14.167v1.666h5v-1.666h-5zm0-10v1.666h8.333V4.167H2.5zM10.833 17.5v-1.667H17.5v-1.666h-6.667V12.5H9.167v5h1.666zm-5-10v1.667H2.5v1.666h3.333V12.5H7.5v-5H5.833zM17.5 10.833V9.167H9.167v1.666H17.5zm-5-3.333h1.667V5.833H17.5V4.167h-3.333V2.5H12.5v5z"></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

FilterIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

FilterIcon.defaultProps = {
    color: 'currentColor',
    size: '24'
};

export default FilterIcon;
