import React from 'react';
import { SEO } from './SEO';
import { useHistory } from 'react-router-dom';

// This component should be inserted at a high level in the app structure (for example, in App)
// It will add canonical link to all the pages.
// The pages that use SEO component explicitly (PDP/PLP/CMS) will override that link.
export const SEOFallback = props => {
    // Connect this component state to the history state changes
    useHistory();
    
    return <SEO {...props} />;
}
