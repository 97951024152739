import { useGlobalOptions } from '@corratech/context-provider';
import { CustomRadioButton } from '@corratech/form-components';
import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ConfirmAddress } from './ConfirmAddress';
import './loqateAddress.less';

export const LoqateAddressValidatorAuth = props => {
    const { formData, onFormSubmit } = props;

    const projectConfig = useGlobalOptions();

    const [t] = useTranslation();

    const [show, setShow] = useState(false);

    const [selectSuggestedAddress, setSelectSuggestedAddress] = useState(false);
    const [selectEnteredAddress, setSelectEnteredAddress] = useState(false);

    const [suggestedAddress, setSuggestedAddress] = useState(false);
    const [currentAddress, setCurrentAddress] = useState(false);

    const [showModal, setShowModal] = useState(false);

    const handleClose = () => {
        setShow(false);
        setShowModal(false);
        focusAddressElement();
    };
    const handleShow = () => {
        setShow(true);
    };

    const saveAndContinue = () => {
        let address = formData;

        if (selectSuggestedAddress) {
            address = {
                ...address,
                street: suggestedAddress.Address1,
                city: suggestedAddress.Address2,
                region: suggestedAddress.AdministrativeArea,
                postcode: suggestedAddress.PostalCode,
                countryCode: suggestedAddress.Country
            };
        }
        let formAddress = {
            variables: address
        };
        onFormSubmit(formAddress);
        handleClose();
    };

    const focusAddressElement = () => {
        const addresElm = document.querySelector('#street_address_1');
        setTimeout(() => {
            addresElm.focus();
        }, 1000);
    };

    useEffect(() => {
        if (formData) {
            let address = formData;

            setCurrentAddress(address);

            const apiKey = projectConfig.storeConfig.loqate_tag_api_key;

            const url =
                projectConfig.storeConfig.loqate_tag_addressvalidate_endpoint;

            let userAddress = {
                street1: address && address.street && address.street[0],
                street2: (address && address.street && address.street[1]) || '',
                city: address && address.city,
                region: address && address.region,
                postcode: address && address.postcode,
                country: address && address.countryCode
            };
            const autosuggestAddress = window.localStorage.getItem(
                'userAddress'
            );

            if (autosuggestAddress === JSON.stringify(userAddress)) {
                saveAndContinue();
                return;
            }

            let params = {
                Key: apiKey,
                Geocode: true,
                Addresses: [
                    {
                        Address1: address && address.street[0],
                        Address2: address && address.street[1],
                        Address3: address.city,
                        Address4: address && address.region,
                        Country: address && address.countryCode,
                        PostalCode: address && address.postcode
                    }
                ]
            };
            let body = JSON.stringify(params);
            let data = {
                method: 'post',
                body: body,
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                }
            };

            fetch(url, data)
                .then(function(response) {
                    return response.json();
                })
                .then(function(respose) {
                    try {
                        let data = respose[0];
                        let matches = data.Matches;
                        let address = matches && matches[0];

                        let aqi = address && address.AQI;

                        if (aqi == 'A' || aqi == 'B') {
                            saveAndContinue();
                        } else if (aqi == 'C' || aqi == 'D') {
                            setSelectSuggestedAddress(true);
                            setSelectEnteredAddress(false);
                            setSuggestedAddress(address);
                            setShowModal(true);
                        } else {
                            handleShow();
                        }
                    } catch (error) {
                        handleShow();
                    }
                });
        }
    }, [formData]);

    return (
        <>
            <ConfirmAddress
                currentAddress={currentAddress}
                show={show}
                handleClose={handleClose}
                saveAndContinue={saveAndContinue}
            />

            <Modal
                show={showModal}
                onHide={handleClose}
                className="loqate-address-suggestion-popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title>CONFIRM YOUR ADDRESS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="confirm-address-info">
                        We couldn't verify the exact address you entered, but
                        did find a close match. Please make sure your mailing
                        address is correct to ensure accurate delivery.
                    </div>
                    <div className="address-wrap">
                        <span className="address-head">Suggested Address</span>
                        <div className="address-block-wrap">
                            <CustomRadioButton
                                onClick={() => {
                                    setSelectSuggestedAddress(true);
                                    setSelectEnteredAddress(false);
                                }}
                                active={selectSuggestedAddress}
                            >
                                {suggestedAddress ? (
                                    <label>
                                        <div className="suggested-address">
                                            <span>
                                                {suggestedAddress.Address1}{' '}
                                            </span>
                                            <span>
                                                {suggestedAddress.Address2}{' '}
                                            </span>
                                            <span>
                                                {
                                                    suggestedAddress.AdministrativeArea
                                                }{' '}
                                            </span>
                                            <span>
                                                {suggestedAddress.PostalCode}{' '}
                                            </span>
                                            <span>
                                                {suggestedAddress.Country}
                                            </span>
                                        </div>
                                    </label>
                                ) : null}
                            </CustomRadioButton>
                        </div>
                    </div>
                    <div className="address-wrap">
                        <span className="address-head">Address Entered</span>
                        <div className="address-block-wrap">
                            <CustomRadioButton
                                onClick={() => {
                                    setSelectSuggestedAddress(false);
                                    setSelectEnteredAddress(true);
                                }}
                                active={selectEnteredAddress}
                            >
                                <label>
                                    <div>
                                        <span>
                                            {currentAddress &&
                                                currentAddress.street[0]}{' '}
                                        </span>
                                        <span>
                                            {currentAddress &&
                                                currentAddress.street[1]}{' '}
                                        </span>
                                        <span>
                                            {currentAddress &&
                                                currentAddress.city}{' '}
                                        </span>
                                        <span>
                                            {currentAddress &&
                                                currentAddress.region}{' '}
                                        </span>
                                        <span>
                                            {currentAddress &&
                                                currentAddress.postcode}{' '}
                                        </span>
                                        <span>
                                            {currentAddress &&
                                                currentAddress.countryCode}
                                        </span>
                                    </div>
                                </label>
                            </CustomRadioButton>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={saveAndContinue}>
                        {t('Save & Continue')}
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        {t('Edit Address')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
