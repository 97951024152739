import React from 'react';
import { CmsBlockGroup } from '@corratech/cms-display';
import PropTypes from 'prop-types';
require('./Copyright.less');

export const Copyright = props => {
    const { blockId } = props;
    const { copyrightText } = props.useCopyright();

    return (
        <div
            className={`copyright-wrapper ${props.className || ''}`}
            css={props.css}
        >
            <div className={`copyright-block container-width`}>
                {copyrightText ? <div>{copyrightText}</div> : ''}

                {blockId ? <CmsBlockGroup identifiers={blockId} /> : ''}
            </div>
        </div>
    );
};

Copyright.propTypes = {
    useCopyright: PropTypes.func.isRequired,
    blockId: PropTypes.string
};
