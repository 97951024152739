import React from 'react';
import PropTypes from 'prop-types';

const EU = props => {
    const { color, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 17.15 17.15"
            height={size}
            width={size}
            {...otherProps}
        >
            <defs>
                <mask
                    id="a"
                    width="17.15"
                    height="17.15"
                    x="0"
                    y="0"
                    maskUnits="userSpaceOnUse"
                >
                    <circle cx="8.57" cy="8.57" r="8.57" fill="#fff"></circle>
                </mask>
            </defs>
            <g mask="url(#a)">
                <circle cx="8.57" cy="8.57" r="8.57" fill="#24439b"></circle>
                <path
                    fill="#f7c135"
                    fillRule="evenodd"
                    d="M4.64 10.23L6.67 8.79 4.17 8.79 3.4 6.47 2.62 8.79 0.12 8.79 2.14 10.23 1.37 12.55 3.4 11.12 5.42 12.55 4.64 10.23"
                ></path>
                <path
                    fill="#f7c135"
                    fillRule="evenodd"
                    d="M14.71 7.49L16.73 6.05 14.23 6.05 13.46 3.73 12.69 6.05 10.19 6.05 12.21 7.49 11.44 9.81 13.46 8.37 15.48 9.81 14.71 7.49"
                ></path>
            </g>
        </svg>
    );
};

EU.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

EU.defaultProps = {
    color: 'currentColor',
    size: '18'
};

export default EU;
