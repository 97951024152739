// For each action name, all the fields , that are defined in the configMapping,
// should be replaced in the defaultMapping (if possible).
export const combineDataMapping = (defaultMapping, configMapping) => {
    let combinedMapping = { ...defaultMapping };
    Object.entries(configMapping).map(([actionName, actionFields]) => {
        // The action name should exist in the defaultMapping of a component.
        // This fact signifies that that specific handler component can process an action with that name.
        if (typeof defaultMapping[actionName] === 'undefined') {
            return;
        }
        
        // Only overrides for specific fields are applied
        Object.entries(configMapping[actionName]).map(([fieldName, fieldMapping]) => {
            combinedMapping[actionName][fieldName] = fieldMapping;
        });
    });
    
    return combinedMapping;
}
