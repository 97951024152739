import React from 'react';
import PropTypes from 'prop-types';

const FacebookIcon = props => {
    const { color, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill={color}
            stroke={color}
            viewBox="0 0 20 20"
            {...otherProps}
        >
            <path
                fill={color}
                fillRule="evenodd"
                stroke="none"
                strokeWidth="1"
                d="M20.122 10.06A10.06 10.06 0 0010.06 0 10.06 10.06 0 000 10.06c0 5.022 3.68 9.185 8.489 9.94v-7.03H5.933v-2.91H8.49V7.845c0-2.521 1.5-3.914 3.8-3.914 1.1 0 2.252.197 2.252.197V6.6h-1.269c-1.25 0-1.64.776-1.64 1.571v1.889h2.791l-.446 2.908h-2.344V20c4.81-.755 8.489-4.918 8.489-9.94z"
                transform="translate(-1094 -80) translate(968 26) translate(0 54) translate(126)"
            ></path>
        </svg>
    );
};

FacebookIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

FacebookIcon.defaultProps = {
    color: 'currentColor',
    size: '24'
};

export default FacebookIcon;
