import { cartItemMapping } from '@corratech/google-tag-manager/src/mapping';
import { deepValue } from '@corratech/tag-manager';

const productDiscount = data => {
    const items = deepValue(data, ['cart', 'items'], []);
    let discount = 0;
    items.map(item => {
        let discounts = deepValue(item, ['prices', 'discount'], []);
        discounts.map(discountItem => {
            let discountValue = discountItem.amount.value;
            if (!!discountValue) {
                discount += discountValue;
            }
        });
    });
    return discount;
};

export const PLACE_ORDER = (itemMapping = cartItemMapping) => {
    return {
        items: ['cart', 'items'],
        actionField: {
            id: ['placeOrder', 'order', 'increment_id'],
            affiliation: ['placeOrder', 'order', 'status'],
            revenue: ['placeOrder', 'order', 'grand_total', 'value'],
            //Elemis
            subtotal: ['placeOrder', 'order', 'subtotal', 'value'],
            ordercount: ['placeOrder', 'order', 'total_order_count'],
            tax: ['placeOrder', 'order', 'tax_amount', 'value'],
            shipping: ['placeOrder', 'order', 'shipping_amount', 'value'],
            discount: ['placeOrder', 'order', 'discount_amount', 'value'],
            coupon: ['cart', 'applied_coupons', '0', 'code'],
            productDiscount1: productDiscount
        },
        productsDetails: {
            data_source: ['cart', 'items'],
            mapping: itemMapping,
            strict: true
        }
    };
};
