import React from 'react';
import PropTypes from 'prop-types';

const MinicartIcon = props => {
    const { color, width, height, size, ...otherProps } = props;

    return (
        <svg width={width} height={height} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 0.5C11.1713 0.5 13.0656 2.03324 13.5 4.05168V4.49928C12.1711 4.498 9.51536 4.49872 7.16498 4.49936C6.18386 4.49963 5.25596 4.49988 4.5 4.49997V4.05168C4.93442 2.03324 6.82867 0.5 9 0.5Z" stroke={color}/>
            <path d="M16.5 5V17C16.5 17.2761 16.2761 17.5 16 17.5H2C1.72386 17.5 1.5 17.2761 1.5 17V5C1.5 4.72386 1.72386 4.5 2 4.5H16C16.2761 4.5 16.5 4.72386 16.5 5Z" stroke={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M4 6V4.5H5V6H4Z" fill={color}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M13 6V4.5H14V6H13Z" fill={color}/>
        </svg>
    );
};

MinicartIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

MinicartIcon.defaultProps = {
    color: 'currentColor',
    size: '24',
    width: '18',
    height: '18'
};

export default MinicartIcon;
