import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CartStore, useLoadingIndicator } from '@corratech/context-provider';
import { useCookies } from 'react-cookie';
import { useMutation } from 'react-apollo';
import applyCouponToCart from 'ModulesPath/App/Queries/applyCouponToCart.graphql';
import { Button, Modal } from 'react-bootstrap';

export const PROMO_CODE_PARAM = 'apply_promo';

const ApplyCoupon = props => {
    let history = useHistory();
    const { cartState, dispatch } = useContext(CartStore);
    const LoadingIndicator = useLoadingIndicator();
    const [cookies, setCookie, removeCookie] = useCookies(['coupon_code']);
    const [showModal, setShowModal] = useState(false);
    const [message, setMessage] = useState('');
    const [title, setTitle] = useState(
        `Cheers, we’ve auto-applied your promo code!`
    );
    const [isCouponApplied, setIsCouponApplied] = useState(false);

    const couponCode = props.couponCode.toUpperCase();

    const [applyCoupon] = useMutation(applyCouponToCart, {
        variables: {
            cartId: cartState.cartId,
            isSignedIn: !!cartState.cart.authenticated
        },
        onCompleted: res => {
            dispatch({
                type: 'SET_CART',
                cart: res.applyCouponToCart.cart
            });
            removeCookie('coupon_code');
            setMessage(
                `Your code ${couponCode} has been saved and will be applied when you add an eligible item to your cart.`
            );
            setShowModal(true);
        },
        onError: res => {
            let isAnotherCouponApplied =
                res?.graphQLErrors?.[0]?.extensions.category ===
                'graphql-input';
            setMessage(res?.graphQLErrors?.[0]?.message);

            setShowModal(true);
            isAnotherCouponApplied
                ? setTitle('Promo code applied')
                : setTitle('Apply Promo');
        }
    });

    const handleClose = () => {
        setShowModal(false);
        let currentUrl = new URL(window.location.href);
        currentUrl.searchParams.delete(PROMO_CODE_PARAM);
        const urlWithoutCouponCode = currentUrl.pathname + currentUrl.search;
        history.push(urlWithoutCouponCode);
    };

    let currentUrl = new URL(window.location.href);
    currentUrl.searchParams.delete(PROMO_CODE_PARAM);
    const urlWithoutCouponCode = currentUrl.pathname + currentUrl.search;
    if (!couponCode) history.push(urlWithoutCouponCode);

    useEffect(() => {
        const maxAge = 30 * 24 * 3600;

        let options = {
            maxAge: maxAge
        };

        if (couponCode) {
            setCookie('coupon_code', couponCode, options);
        }
    }, []);

    useEffect(() => {
        // if there is no item in the cart then save coupon_code in cookie
        // so it can be apply later when user add item to cart
        if (!cartState.cartId || cartState?.cart?.items?.length === 0) {
            setMessage(
                `Your code ${couponCode} has been saved and will be applied when you add eligible items to your cart.`
            );
            setShowModal(true);
        }
        // apply coupon if there are items in cart and coupon is not applied already.
        if (cartState?.cart?.items?.length > 0 && !isCouponApplied) {
            applyCoupon({
                variables: {
                    couponCode
                }
            });
            setIsCouponApplied(true);
        }
    }, [cartState.cart.items]);

    if (showModal) {
        return (
            <Modal
                show={showModal}
                onHide={handleClose}
                className="loqate-address-suggestion-popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>{message}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        {'Shop Now'}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
    return <></>;
};

export default ApplyCoupon;
