import { getAdvanced } from '../../utils';

export default node => {
    const slides = node.querySelectorAll('.home-banner'); // Checking for all banners with home-banner class inorder to get the slider name for promotion view impressions
    const linkElement = node.querySelectorAll('.home-banner a[data-element="link"]'); // Fetching all banner links with home-banner class for promotion view data
    return {
        minHeight: node.style.minHeight,
        autoplay: node.getAttribute('data-autoplay') === 'true',
        autoplaySpeed: parseInt(node.getAttribute('data-autoplay-speed')),
        fade: node.getAttribute('data-fade') === 'true',
        infinite: node.getAttribute('data-infinite-loop') === 'true',
        showArrows: node.getAttribute('data-show-arrows') === 'true',
        showDots: node.getAttribute('data-show-dots') === 'true',
        additionalConfig: node.getAttribute('data-additional-config'),
        slideName: [...slides].map(slide => slide.getAttribute('data-slide-name')),
        link : [...linkElement].map(linkEl => linkEl.getAttribute('href')),
        ...getAdvanced(node)
    };
};
