import React, { Component, Fragment } from 'react';

import PropTypes from 'prop-types';
import {
    isRequired,
    validatePassword,
    Message,
    TextInput,
    combine,
    Field
} from '@corratech/form-components';
import './ReactPasswordStrength.less';

const isTooShort = (password, minLength) => password.length < minLength;

export default class ReactPasswordStrength extends Component {
    static propTypes = {
        changeCallback: PropTypes.func,
        className: PropTypes.string,
        defaultValue: PropTypes.string,
        inputProps: PropTypes.object,
        minLength: PropTypes.number,
        minScore: PropTypes.number,
        namespaceClassName: PropTypes.string,
        scoreWords: PropTypes.array,
        style: PropTypes.object,
        tooShortWord: PropTypes.string,
        userInputs: PropTypes.array,
        validate: PropTypes.object,
        validatePasswordText: PropTypes.string
    };

    static defaultProps = {
        changeCallback: null,
        className: '',
        defaultValue: '',
        minLength: 5,
        minScore: 2,
        namespaceClassName: 'ReactPasswordStrength',
        scoreWords: ['weak', 'okay', 'good', 'strong', 'stronger'],
        tooShortWord: 'too short',
        userInputs: [],
        validate: { isRequired: false, validatePassword: false },
        validatePasswordText:
            'A password must contain at least 3 of the following: lowercase, uppercase, digits, special characters.',
        requiredText: 'This field is required.'
    };

    state = {
        score: 0,
        isValid: false,
        password: '',
        errorMessage: null
    };

    componentDidMount() {
        const { defaultValue } = this.props;

        if (defaultValue.length > 0) {
            this.setState({ password: defaultValue }, this.handleChange);
        }
    }

    clear = () => {
        const { changeCallback } = this.props;

        this.setState(
            {
                score: 0,
                isValid: false,
                password: '',
                errorMessage: null
            },
            () => {
                this.reactPasswordStrengthInput.value = '';

                if (changeCallback !== null) {
                    changeCallback(this.state);
                }
            }
        );
    };

    enteredPassword = password => {
        const { validate } = this.props;

        const requiredState = isRequired(password);

        return validate.isRequired && null != requiredState;
    };

    validatePassword = password => {
        const { validate, validatePasswordText } = this.props;

        const validatePasswordState = validatePassword(
            password,
            null,
            validatePasswordText
        );

        return validate.validatePassword && null != validatePasswordState;
    };
    checkPasswordStrength = password => {
        let strongRegex = new RegExp(
            '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
        );
        let mediumRegex = new RegExp(
            '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})'
        );

        let score = 1;

        if (strongRegex.test(password)) {
            score = 4;
        } else if (mediumRegex.test(password)) {
            score = 3;
        }

        return score;
    };
    handleChange = () => {
        const {
            changeCallback,
            minScore,
            userInputs,
            minLength,
            validate,
            validatePasswordText
        } = this.props;
        const password = this.reactPasswordStrengthInput.value;

        let score = 0;
        let result = null;
        let errorMessage = null;

        // always sets a zero score when min length requirement is not met

        if (isTooShort(password, minLength) === false) {
            score = this.checkPasswordStrength(password);
        }

        if ('' === password && this.enteredPassword(password)) {
            errorMessage = isRequired(password);
            score = 0;
        } else if ('' !== password && this.validatePassword(password)) {
            errorMessage = validatePassword(
                password,
                null,
                validatePasswordText
            );
            score = 0;
        }

        this.setState(
            {
                isValid: score >= minScore,
                password,
                score,
                errorMessage
            },
            () => {
                if (changeCallback !== null) {
                    changeCallback(this.state, result);
                }
            }
        );
    };

    render() {
        const { score, password, isValid, errorMessage } = this.state;
        const {
            className,
            inputProps,
            minLength,
            namespaceClassName,
            scoreWords,
            style,
            tooShortWord,
            requiredText
        } = this.props;

        const inputClasses = [`${namespaceClassName}-input`];
        const wrapperClasses = [
            namespaceClassName,
            className ? className : '',
            password.length > 0 ? `is-strength-${score}` : ''
        ];
        const strengthDesc = isTooShort(password, minLength)
            ? tooShortWord
            : scoreWords[score];

        if (isValid === true) {
            inputClasses.push('is-password-valid');
        } else if (password.length > 0) {
            inputClasses.push('is-password-invalid');
        }

        if (inputProps && inputProps.className) {
            inputClasses.push(inputProps.className);
        }

        return (
            <Fragment>
                <div className={wrapperClasses.join(' ')} style={style}>
                    <Field label={`Password`} required={true}>
                        <TextInput
                            className={inputClasses.join(' ')}
                            field="password"
                            type="password"
                            placeholder={`Password`}
                            validate={combine([
                                {
                                    fn: isRequired,
                                    text: requiredText
                                }
                            ])}
                            validateOnChange
                            validateOnBlur
                            onChange={this.handleChange}
                            ref={ref => (this.reactPasswordStrengthInput = ref)}
                            {...inputProps}
                            value={password}
                        />
                    </Field>

                    <div className={`${namespaceClassName}-strength-bar`} />
                    <span className={`${namespaceClassName}-strength-desc`}>
                        {strengthDesc}
                    </span>

                    {null !== errorMessage && (
                        <Message fieldState={{ error: errorMessage }} />
                    )}
                </div>
            </Fragment>
        );
    }
}
