import { formatPrice } from '@corratech/tag-manager';

function ProductClickEvents(tagData) {
    const {
        category,
        id,
        name,
        sku,
        price,
        currencyCode,
        product_type
    } = tagData;
    return {
        event: 'productClick',
        ecommerce: {
            click: {
                actionField: {
                    list: category
                },
                products: [
                    {
                        id: id,
                        name: name,
                        sku: sku,
                        price: formatPrice(price),
                        currency_code: currencyCode,
                        product_type: product_type,
                        category: category
                    }
                ]
            }
        },
        _clear: true
    };
}
export { ProductClickEvents };
