import React, { useState, useEffect } from 'react';
import { VisualSwatch } from './VisualSwatch';
import { Link } from 'react-router-dom';
export const CollapsibleOptions = props => {
    const {
        configurableOptions,
        isSelected,
        isAvailable,
        optionSelect,
        allowSelection,
        selectedValueEvent,
        optionLimit,
        productUrl,
        moreIcon
    } = props;
    const getSliced = value => {
        return optionLimit === false ? value : value.slice(0, optionLimit);
    };
    const [openAttribute, setOpenAttribute] = useState('');
    const [openClass, setOpenClass] = useState(false);
    return (
        <div
            className={`variants  ${openClass ? openAttribute + `-open` : ``}`}
        >
            {configurableOptions.map((parent, parentKey) => {
                const [open, setOpen] = useState(false);
                return (
                    <div
                        data-attribute_code={parent.attribute_code}
                        data-attribute_id={parent.attribute_id}
                        data-id={parent.id}
                        key={parent.attribute_id + parentKey}
                        className={`variant-${parent.attribute_code}`}
                    >
                        <div
                            className={`variant-heading`}
                            onClick={() => {
                                if (
                                    openAttribute == '' ||
                                    parent.attribute_code == openAttribute
                                ) {
                                    if (openClass && open) {
                                        setOpenClass(false);
                                        setOpen(false);
                                    } else {
                                        setOpenClass(true);
                                        setOpen(true);
                                    }
                                } else {
                                    setOpenClass(true);
                                    setOpen(true);
                                }
                                setOpenAttribute(parent.attribute_code);
                            }}
                        >

                            {
                                !selectedValueEvent(parent.attribute_code, parent.values) ?
                                    <span className={'selected-key'}>
                                        {parent.attribute_code}
                                    </span>
                                :
                                    ''
                            }
                            <span className={'selected-value'}>
                                {selectedValueEvent(
                                    parent.attribute_code,
                                    parent.values
                                )}
                            </span>
                        </div>

                        <ul className={`variant-options`}>
                            {getSliced(parent.values).map((child, childKey) => {
                                const isThisSelected = isSelected(
                                    parent.attribute_code,
                                    child.value_index
                                );
                                const isThisAvailable = isAvailable(
                                    parent.attribute_code,
                                    child
                                );
                                const hasVisual = child.swatch_data != null;
                                const isThisOOS =
                                    isThisAvailable.product !== false &&
                                    isThisAvailable.allOOS == true
                                        ? 'oos'
                                        : '';

                                return (
                                    <li
                                        title={child.label}
                                        className={`variant-option
                                            ${
                                            parent.attribute_codegit 
                                            }-${child.label.toLowerCase()}
                                            ${
                                            hasVisual === true
                                                ? 'visual-option'
                                                : ''
                                            }
                                            ${isThisSelected} 
                                            ${isThisOOS}
                                            ${
                                            isThisAvailable.product ===
                                            false
                                                ? 'notavailable'
                                                : ''
                                            } `}
                                        onClick={() => {
                                            if (
                                                isThisAvailable.product !==
                                                false &&
                                                allowSelection(isThisAvailable)
                                            ) {
                                                optionSelect(
                                                    parent.attribute_code,
                                                    child.value_index,
                                                    child.label
                                                );
                                            }
                                        }}
                                        key={childKey}
                                        data-value_index={child.value_index}
                                    >
                                        <div className={'cross-line'}></div>
                                        <div className={'swatch-data'}>
                                            {hasVisual ? (
                                                <>
                                                    <VisualSwatch
                                                        option={child}
                                                    />
                                                    {isThisSelected && (
                                                        <span
                                                            className={`name`}
                                                        >
                                                            {child.label}
                                                        </span>
                                                    )}
                                                </>
                                            ) : (
                                                child.label
                                            )}
                                        </div>
                                    </li>
                                );
                            })}
                            <li className="more-options">
                                {optionLimit !== false &&
                                parent.values.length > optionLimit && (
                                    <Link to={productUrl}>
                                        {`${moreIcon}${parent.values
                                            .length - optionLimit}`}
                                    </Link>
                                )}
                            </li>
                        </ul>
                    </div>
                );
            })}
        </div>
    );
};
