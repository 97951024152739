import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useQuery } from 'react-apollo';
import { Modal, Form, Button } from 'react-bootstrap';
import { ExternalLink } from 'react-feather';
import coAgreementsQuery from './getCheckoutAgreements.graphql';
import { useTranslation } from 'react-i18next';
import { Checkbox } from '@corratech/form-components';

export const CheckoutAgreements = props => {
    const { setParentChecked, parentChecked } = props;
    const [t] = useTranslation();
    const [isChecked, setIsChecked] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const { data, loading, error } = useQuery(coAgreementsQuery, {
        fetchPolicy: 'cache-and-network'
    });

    useEffect(() => {
        // When we get data, if there are no agreements then set parent to checked.
        // This will enable checkout when there are no agreements.
        if (
            !data ||
            !data.checkoutAgreements ||
            data.checkoutAgreements.length === 0
        ) {
            if (setParentChecked) setParentChecked(true);
        } else if ((data || !isChecked) && setParentChecked)
            setParentChecked(false);
    }, [data]);

    if (
        (!data && loading) ||
        (!!data &&
            data.checkoutAgreements &&
            data.checkoutAgreements.length === 0)
    )
        return null;
    else if (props.prompt) {
        return (
            <>
                <div className={'checkout-agreements'}>
                    <Form.Check type="checkbox" id="checkout-agree">
                        <div className={'checkout-agree-label'}>
                            <Checkbox
                                field={'checkout-agreements'}
                                onChange={() => {
                                    setIsChecked(!isChecked);
                                    if (setParentChecked)
                                        setParentChecked(!parentChecked);
                                }}
                                label={data.checkoutAgreements[0].checkbox_text}
                            />
                            <ExternalLink onClick={() => setIsOpen(true)} />
                        </div>
                    </Form.Check>
                </div>
                <Modal
                    show={isOpen}
                    onHide={() => setIsOpen(false)}
                    className={'checkout-agreements-modal'}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Terms and Conditions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {data.checkoutAgreements[0].is_html ? (
                            <div
                                dangerouslySetInnerHTML={
                                    data.checkoutAgreements[0].content
                                }
                            />
                        ) : (
                            data.checkoutAgreements[0].content
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            onClick={() => {
                                setIsChecked(true);
                                setIsOpen(false);
                                if (setParentChecked) setParentChecked(true);
                            }}
                        >
                            {t('Agree')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    } else if (!props.prompt) {
        return (
            <>
                <div
                    className={
                        'checkout-agreements checkout-agreements-no-check'
                    }
                >
                    <div className={'checkout-agree-label'}>
                        {data.checkoutAgreements[0].checkbox_text}
                        <ExternalLink onClick={() => setIsOpen(true)} />
                    </div>
                </div>
                <Modal
                    show={isOpen}
                    onHide={() => setIsOpen(false)}
                    className={'checkout-agreements-modal'}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>{t('Terms and Conditions')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {data.checkoutAgreements[0].is_html ? (
                            <div
                                dangerouslySetInnerHTML={
                                    data.checkoutAgreements[0].content
                                }
                            />
                        ) : (
                            data.checkoutAgreements[0].content
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="primary"
                            onClick={() => {
                                setIsChecked(true);
                                setIsOpen(false);
                                if (setParentChecked) setParentChecked(true);
                            }}
                        >
                            {t('Agree')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
};

CheckoutAgreements.defaultProps = {
    prompt: false
};

CheckoutAgreements.propTypes = {
    parentChecked: PropTypes.bool,
    setParentChecked: PropTypes.func,
    prompt: PropTypes.bool
};
