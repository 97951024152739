import React, { useContext, useEffect, useReducer } from 'react';
import { useLazyQuery, useMutation } from 'react-apollo';
import { queryCustomerCart } from './Queries/queryCustomerCart';
import { mutationMergeCarts } from './Queries/mutationMergeCarts';
import { AuthStore } from '@corratech/context-provider/src/AuthContext';
import { CartStore } from '@corratech/context-provider';

let getCustomerCart = null;

export const MergeCartOnStopMakeCart = props => {
    const { authState, dispatch: authStateDispatch } = useContext(AuthStore);
    const { cartState, dispatch } = useContext(CartStore);
    const setLoading = isLoading => {
        dispatch({ type: 'SET_LOADING', isLoading });
    };

    const [mergeCarts] = useMutation(mutationMergeCarts);
    const [customerCartOnMakeStopCart] = useLazyQuery(queryCustomerCart, {
        fetchPolicy: 'network-only',
        onCompleted: res => {
            if (!!cartState.cart.items && cartState.cart.items.length > 0) {
                mergeCarts({
                    variables: {
                        sourceCartId: cartState.cartId,
                        destinationCartId: res.customerCart.id
                    }
                })
                    .then(resMerge => {
                        dispatch({
                            type: 'SET_ALL',
                            cartId: res.customerCart.id,
                            cart: {
                                ...resMerge.data.mergeCarts,
                                shipping_addresses: null,
                                billing_address: null,
                                authenticated: true
                            }
                        });
                        setLoading(false);
                    })
                    .catch(() => {
                        setLoading(false);
                    });
            } else {
                dispatch({
                    type: 'SET_ALL',
                    cartId: res.customerCart.id,
                    cart: {
                        ...res.customerCart,
                        shipping_addresses: null,
                        billing_address: null,
                        authenticated: true
                    }
                });
                setLoading(false);
            }
        },
        onError: () => {
            setLoading(false);
        }
    });

    useEffect(() => {
        getCustomerCart = customerCartOnMakeStopCart;
    }, [customerCartOnMakeStopCart]);

    useEffect(() => {
        // login happened
        if (authState.token) {
            if (authState.justSignedIn) {
                setLoading(true);
                customerCartOnMakeStopCart();
                authStateDispatch({
                    type: 'SET_AUTH_TOKEN',
                    ...authState,
                    justSignedIn: false
                });
            }
        }
    }, [authState]);

    return <div></div>;
};
