import React from 'react';

export const VisualSwatch = props => {
    const swatchData = props.option.swatch_data;
    let bgType;
    if (swatchData.type == 'IMAGE') {
        bgType = {
            backgroundImage: `url(${swatchData ? swatchData.thumbnail : ''})`
        };
    } else {
        bgType = {
            backgroundColor: swatchData.value
        };
    }
    return (
        <div
            style={bgType}
            title={props.option.label}
            className={props.option.label === 'White' ? 'visual-swatch white-swatch' : 'visual-swatch'}
        />
    );
};
