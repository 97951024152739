import React, { useContext, useEffect, useState, useRef } from 'react';
import { useQuery, useMutation, useLazyQuery } from 'react-apollo';
import cartQuery from '@corratech/paypal-express/src/graphql/cartQuery.graphql';
import placeOrderMutation from '@corratech/paypal-express/src/graphql/placeOrder.graphql';
import paypalMethodMutation from '@corratech/paypal-express/src/graphql/setPaypalPaymentMethod.graphql';
import {
    CartStore,
    LoaderStore,
    useGlobalOptions
} from '@corratech/context-provider';
import { Route, Switch, useHistory } from 'react-router-dom';
import { SummaryBlock } from '@corratech/checkout/SummaryBlock';
import { useTranslation } from 'react-i18next';
import { useWindowSize, Util } from '@magento/peregrine';
import { Alert, Button } from 'react-bootstrap';

import '@corratech/paypal-express/src/PaypalConfirmationPage.less';
import { ProgressBar } from '@corratech/checkout/ProgressBar';
import { ShippingMethodForm } from '@corratech/checkout/ShippingMethodForm/ShippingMethodForm';
import { PayPalPlaceOrderButton } from 'ModulesPath/PaypalExpressReturnFromMiniCart/PayPalPlaceOrderButton/PayPalPlaceOrderButton';
import { PaypalExpress } from '@corratech/paypal-express';
import setPaymentMethodOnCart from '@corratech/checkout/PaymentMethods/PaypalExpress/setPaypalPaymentMethod.graphql';
import getCountry from '@corratech/account-dashboard/src/Data/Queries/getCountry.graphql';
import { AlertTriangle as Warning } from 'react-feather';
const storage = new Util.BrowserPersistence();

export const PaypalExpressReturnFromMiniCart = ({
    startSummaryOpen = true,
    dataLayerAction
}) => {
    const { cartState, dispatch } = useContext(CartStore);
    const [invalidShippingAddress, setInvalidShippingAddress] = useState(false);
    const [invalidShippingMsg, setInvalidShippingMsg] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const LoadingIndicator = useContext(LoaderStore);
    const history = useHistory();
    const [t] = useTranslation();
    const projectConfig = useGlobalOptions();
    const windowSize = useWindowSize();
    const [token, setToken] = useState('');
    const [payerId, setPayerId] = useState('');
    const isMobile = windowSize.innerWidth <= projectConfig.viewport.mobile;
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [isGiftCardProduct, setIsGiftCardProduct] = useState(false);
    const [isPaymentmethodCompleted, setIsPaymentmethodCompleted] = useState(
        false
    );
    const [renderShippingMethod, setRenderShippingMethod] = useState(false);
    const isValidState = useRef(false);

    const {
        data: dataCountry,
        loading: loadingCountry,
        error: errorCountry
    } = useQuery(getCountry);

    const [getCartData, { data, loading, error }] = useLazyQuery(cartQuery, {
        variables: {
            cartId: storage.getItem('cartId'),
            isSignedIn: !!storage.getItem('signin_token')
        },
        onCompleted: res => {
            dispatch({
                type: 'SET_CART',
                cart: res.cart
            });
        }
    });

    useEffect(() => {
        if (
            cartState.cart &&
            cartState.cart.shipping_addresses !== undefined &&
            cartState.cart.shipping_addresses[0] &&
            !cartState.cart.shipping_addresses[0].selected_shipping_method
        ) {
            if (
                cartState.cart.shipping_addresses[0].available_shipping_methods
                    .length === 0
            ) {
                setInvalidShippingMsg('has-trigger-paypal');
            } else {
                setInvalidShippingMsg(
                    'Your shipping method has been reset. Please choose a shipping method to proceed.'
                );
            }
        }
        // check if quote has only GiftCard item
        if (cartState?.cart?.is_virtual) {
            setIsGiftCardProduct(true);
        }
    }, [cartState.cart]);

    const dispatchData = async newData => {
        dispatch({
            type: 'SET_CART',
            cart: newData.setPaymentMethodOnCart.cart
        });
        setShowLoader(false);
        setIsPaymentmethodCompleted(true);
        getCartData();
    };

    const [
        setPayPalPaymentMethod,
        {
            loading: setPayPalPaymentMethodLoading,
            error: payPalPaymentMethodError
        }
    ] = useMutation(setPaymentMethodOnCart, {
        variables: {
            cartId: cartState.cartId,
            paypalToken: token,
            paypalPayerId: payerId
        },
        onCompleted: res => {
            dispatchData(res);
        },
        onError: error => {
            setShowLoader(false);
        }
    });

    const checkIsValidState = addedState => {
        isValidState.current = false;
        dataCountry.countries.map((i, v) => {
            if (i.two_letter_abbreviation === 'US') {
                if (i.available_regions) {
                    i.available_regions.map(function(reg) {
                        if (
                            reg.name.toLowerCase() === addedState.toLowerCase()
                        ) {
                            isValidState.current = true;
                        }
                    });
                }
            }
        });
    };

    const triggerPaypal = () => {
        var btn = document.getElementById('paypal-payment-button');
        btn.click();
    };

    useEffect(() => {
        if (payerId && token) {
            const paypalToken = window.location.search.replace('?token=', '');
            if (paypalToken) {
                setPayPalPaymentMethod();
            }
        }
    }, [payerId, token]);

    // Check if the status if success and then place the order
    useEffect(() => {
        if (location.search) {
            const dataFromURL = window.location.search.split('&');
            setToken(dataFromURL[0].replace('?token=', ''));
            setPayerId(dataFromURL[1].replace('PayerID=', ''));
        }
    }, [location.search]);

    /**
     * Redirect if no items present or
     * no token and payerid detected
     */
    useEffect(() => {
        if (!cartState && !cartState.cart.items.length) {
            history.push('/checkout');
        }
    }, [cartState]);

    useEffect(() => {
        if (
            isPaymentmethodCompleted &&
            cartState.cart.shipping_addresses &&
            cartState.cart.shipping_addresses[0]
        ) {
            const selectedCountry =
                cartState.cart.shipping_addresses[0].country.code;
            if (selectedCountry === 'US') {
                checkIsValidState(
                    cartState.cart.shipping_addresses[0].region.label
                );
            } else {
                isValidState.current = true;
            }
            setRenderShippingMethod(true);
        }
    }, [isPaymentmethodCompleted === true, cartState.cart.shipping_addresses]);

    if (loading && !cartState) <LoadingIndicator />;

    if (loading || setPayPalPaymentMethodLoading || showLoader) {
        return <LoadingIndicator />;
    } else if ((!loading && !data) || error) {
        if (error) {
            history.push({
                pathname: '/checkout',
                paypalError: {
                    hasError: true,
                    state: 'danger',
                    message: 'Something wrong happned. Payment canceled',
                    error: [error]
                }
            });
        }
        return null;
    }

    return (
        <div className={'paypal-confirmation-page'}>
            <div className={'checkout-container container-width'}>
                <div className="left-column">
                    {!isMobile ? (
                        <ProgressBar
                            activeStepNumber={2}
                            setShowPaymentForm={setShowPaymentForm}
                        />
                    ) : (
                        ''
                    )}
                    {cartState.cart.prices &&
                        cartState.cart.prices.grand_total &&
                        isGiftCardProduct && (
                            <PayPalPlaceOrderButton
                                cartId={cartState.cartId}
                                price={cartState.cart.prices.grand_total.value}
                                isGiftCardProduct
                            />
                        )}
                    {!isGiftCardProduct &&
                        renderShippingMethod &&
                        cartState.cart &&
                        cartState.cart.shipping_addresses &&
                        cartState.cart.shipping_addresses[0] && (
                            <>
                                <div className={'ship-to-address'}>
                                    <div className={'ship-to-wrap'} data-cs-mask>
                                        <span className={'ship-to-label'}>
                                            Ship To
                                        </span>
                                        <span>
                                            {
                                                cartState.cart
                                                    .shipping_addresses[0]
                                                    .firstname
                                            }
                                        </span>
                                        <span>
                                            {
                                                cartState.cart
                                                    .shipping_addresses[0]
                                                    .lastname
                                            }
                                            ,
                                        </span>
                                        <span>
                                            {
                                                cartState.cart
                                                    .shipping_addresses[0]
                                                    .street[0]
                                            }
                                        </span>
                                        <span>
                                            {
                                                cartState.cart
                                                    .shipping_addresses[0]
                                                    .street[1]
                                            }
                                            ,
                                        </span>
                                        <span>
                                            {
                                                cartState.cart
                                                    .shipping_addresses[0].city
                                            }
                                            ,
                                        </span>
                                        <span>
                                            {
                                                cartState.cart
                                                    .shipping_addresses[0]
                                                    .region.label
                                            }
                                            ,
                                        </span>
                                        <span>
                                            {
                                                cartState.cart
                                                    .shipping_addresses[0]
                                                    .postcode
                                            }
                                        </span>
                                        <span className={'ship-to-zip'}>
                                            {
                                                cartState.cart
                                                    .shipping_addresses[0]
                                                    .country.label
                                            }
                                        </span>
                                        <PaypalExpress
                                            isIncontext={
                                                projectConfig.storeConfig
                                                    .paypal_incontext_mode
                                            }
                                            merchantId={
                                                projectConfig.storeConfig
                                                    .paypal_account_id
                                            }
                                            isSandbox={
                                                projectConfig.storeConfig
                                                    .paypal_account_is_sandbox_mode
                                            }
                                            isGoBackToPaypal={true}
                                            returnUrl={
                                                'checkout/paypal/returnfromcart'
                                            }
                                        />
                                    </div>
                                </div>

                                {renderShippingMethod &&
                                    isValidState.current === false && (
                                        <div className={'invalid-state-error'}>
                                            <Alert variant={'warning'}>
                                                <Warning
                                                    size={14}
                                                    strokeWidth={'4'}
                                                    color={'#000'}
                                                />
                                                <>
                                                    <span>
                                                        You have entered an
                                                        invalid state/region.
                                                        Please change your
                                                        address
                                                    </span>
                                                    <span
                                                        onClick={() =>
                                                            triggerPaypal()
                                                        }
                                                        className={
                                                            'paypal-trigger'
                                                        }
                                                    >
                                                        here
                                                    </span>
                                                </>
                                            </Alert>
                                        </div>
                                    )}

                                {cartState.cart &&
                                    cartState.cart.shipping_addresses &&
                                    cartState.cart.shipping_addresses[0] &&
                                    isValidState.current === true && (
                                        <>
                                            <ShippingMethodForm
                                                invalidShippingMsg={
                                                    invalidShippingMsg
                                                }
                                                setInvalidShippingMsg={
                                                    setInvalidShippingMsg
                                                }
                                                setInvalidShippingAddress={
                                                    setInvalidShippingAddress
                                                }
                                                isFromPaypal={true}
                                            />
                                            {cartState.cart.prices &&
                                                cartState.cart.prices
                                                    .grand_total && (
                                                    <PayPalPlaceOrderButton
                                                        cartId={
                                                            cartState.cartId
                                                        }
                                                        price={
                                                            cartState.cart
                                                                .prices
                                                                .grand_total
                                                                .value
                                                        }
                                                    />
                                                )}
                                        </>
                                    )}
                            </>
                        )}
                </div>
                <div className={'cart-info'}>
                    {!loading &&
                    cartState.cart &&
                    cartState.cart.items &&
                    (renderShippingMethod || isGiftCardProduct) ? (
                        <SummaryBlock
                            startSummaryOpen={startSummaryOpen}
                            hasLoader={loading && !cartState}
                        />
                    ) : (
                        <LoadingIndicator />
                    )}
                </div>
            </div>
        </div>
    );
};
