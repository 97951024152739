import React from 'react';
import { arrayOf, func, number, object, string } from 'prop-types';
import { TrackCustomerData } from './TrackCustomerData';
import { TrackUrlChange } from './TrackUrlChange';
import { TrackCartState } from './TrackCartState';

const initialState = {};
const TagContext = React.createContext(initialState);

const TagContextInit = props => {
    const {
        dataLayerHandlers = [],
        isVisitorNewCookieExpiresHours,
        uniqueIdentifierExpiresDays,
        checkoutPagePath,
        accountPagePath,
        cartPagePath,
        cartFieldsToObserve,
        checkoutStepIdByName,
        customPages
    } = props;

    const dataLayerAction = (actionData) => {
        dataLayerHandlers.map((handler) => {
            try{
                handler(actionData);
            }catch (e) {
                console.log(e);
            }
        })
    }

    // Backward compatibility with the old name
    const getTagData = (actionData) => {
        dataLayerAction(actionData);
    }

    return (
        <TagContext.Provider value={{ getTagData, dataLayerAction }}>
            <TrackCustomerData
                dataLayerAction={dataLayerAction}
                isVisitorNewCookieExpiresHours={isVisitorNewCookieExpiresHours}
                uniqueIdentifierExpiresDays={uniqueIdentifierExpiresDays}
            />
            <TrackUrlChange
                dataLayerAction={dataLayerAction}
                checkoutPagePath={checkoutPagePath}
                accountPagePath={accountPagePath}
                cartPagePath={cartPagePath}
                checkoutStepIdByName={checkoutStepIdByName}
                customPages={customPages}
            />
            <TrackCartState
                dataLayerAction={dataLayerAction}
                cartFieldsToObserve={cartFieldsToObserve}
            />
            {props.children}
        </TagContext.Provider>
    );
}

TagContextInit.propTypes = {
    dataLayerHandlers: arrayOf(func),
    isVisitorNewCookieExpiresHours: number,
    uniqueIdentifierExpiresDays: number,
    checkoutPagePath: string,
    cartPagePath: string,
    cartFieldsToObserve: arrayOf(string),
    checkoutStepIdByName: object,
    customPages: object
}

TagContextInit.defaultProps = {
    isVisitorNewCookieExpiresHours: 1,
    uniqueIdentifierExpiresDays: 30,
    checkoutPagePath: 'checkout',
    cartPagePath: 'my-cart',
    accountPagePath: 'my-account',
    cartFieldsToObserve: ['items', 'prices'],
    checkoutStepIdByName: {
        cart: 1,
        shipping: 2,
        payment: 3
    },
    customPages: {}
}

export { TagContext, TagContextInit };
