import React from 'react';
import PropTypes from 'prop-types';

const TwitterIcon = props => {
    const { color, width, height, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width ? width : size}
            height={height ? height : size}
            fill={color}
            stroke={color}
            viewBox={`0 0 ${width ? width : size} ${height ? height : size}`}
            {...otherProps}
        >
            <path
                fill={color}
                fillRule="evenodd"
                stroke="none"
                strokeWidth="1"
                d="M17.944 6.048c.013.178.013.356.013.533 0 5.419-4.124 11.663-11.663 11.663-2.322 0-4.48-.673-6.294-1.84.33.038.647.05.99.05a8.21 8.21 0 005.089-1.75 4.106 4.106 0 01-3.833-2.844c.254.038.508.064.774.064.368 0 .736-.05 1.079-.14A4.1 4.1 0 01.812 7.761v-.05c.546.304 1.18.495 1.853.52A4.096 4.096 0 01.838 4.817c0-.761.203-1.46.558-2.068a11.651 11.651 0 008.452 4.29 4.627 4.627 0 01-.102-.94A4.097 4.097 0 0113.846 2a4.09 4.09 0 012.994 1.294 8.07 8.07 0 002.602-.99 4.088 4.088 0 01-1.802 2.26A8.217 8.217 0 0020 3.928a8.811 8.811 0 01-2.056 2.12z"
                transform="translate(-1157 -82) translate(968 26) translate(0 54) translate(189 .004)"
            ></path>
        </svg>
    );
};

TwitterIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

TwitterIcon.defaultProps = {
    color: 'currentColor',
    size: '24'
};

export default TwitterIcon;
