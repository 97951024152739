import { cartItemMapping } from '@corratech/google-tag-manager';
import { deepValue } from '@corratech/tag-manager';

export const CART_PAGE = (itemMapping = cartItemMapping) => {
    return {
        items: ['cart', 'items'],
        discountAmount: data => {
            let totalInclDiscountExclTax = deepValue(
                data,
                [
                    'cart',
                    'prices',
                    'subtotal_with_discount_excluding_tax',
                    'value'
                ],
                0
            );
            let totalExclTax = deepValue(
                data,
                ['cart', 'prices', 'subtotal_excluding_tax', 'value'],
                0
            );
            return totalInclDiscountExclTax - totalExclTax;
        },
        productDiscount: data => {
            let productDiscount = 0;
            let discounts = deepValue(
                data,
                ['cart', 'prices', 'discounts'],
                []
            );
            discounts &&
                discounts.map(discount => {
                    productDiscount += deepValue(
                        discount,
                        ['amount', 'value'],
                        0
                    );
                });
            return productDiscount;
        },
        productDiscount_cart: data => {
            let productDiscount = 0;
            let discounts = deepValue(
                data,
                ['cart', 'prices', 'discounts'],
                []
            );
            discounts &&
                discounts.map(discount => {
                    productDiscount += deepValue(
                        discount,
                        ['amount', 'value'],
                        0
                    );
                });
            return productDiscount;
        },
        cartDiscount: [
            'cart',
            'prices',
            'subtotal_with_discount_excluding_tax',
            'value'
        ],
        priceIclTax: ['cart', 'prices', 'subtotal_including_tax', 'value'],
        taxAmount: ['cart', 'prices', 'applied_taxes', '0', 'amount', 'value'],
        couponCode: ['cart', 'applied_coupons', '0', 'code'],
        total: ['cart', 'prices', 'grand_total', 'value'],
        currencyCode: ['cart', 'prices', 'grand_total', 'currency'],
        productsDetails: {
            data_source: ['cart', 'items'],
            mapping: itemMapping,
            strict: true
        }
    };
};
