/**
 * @fileoverview This file houses functions that can be used for
 * validation of form fields.
 *
 * Note that these functions should return a string error message
 * when they fail, and `undefined` when they pass.
 */

const SUCCESS = undefined;

export const hasLengthAtLeast = (value, values, minimumLength, text) => {
    if (!value || value.length < minimumLength) {
        return text;
    }

    return SUCCESS;
};

export const hasLengthAtMost = (value, values, maximumLength, text) => {
    if (value && value.length > maximumLength) {
        return text;
    }

    return SUCCESS;
};

export const hasLengthExactly = (value, values, length, text) => {
    if (value && value.length !== length) {
        return text;
    }

    return SUCCESS;
};

export const isRequired = (value, values, text) => {
    return (value || '').trim() ? SUCCESS : text || text;
};

export const validateEmail = (value, values, text) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(value) ? SUCCESS : text;
};

export const validateRegionCode = (value, values, countries, text) => {
    const country = countries.find(({ id }) => id === 'US');

    if (!country) {
        return text;
    }
    const { available_regions: regions } = country;

    if (!(Array.isArray(regions) && regions.length)) {
        return text;
    }

    const region = regions.find(({ code }) => code === value);
    if (!region) {
        return text;
    }

    return SUCCESS;
};

export const validatePassword = (value, values, text) => {
    const count = {
        lower: 0,
        upper: 0,
        digit: 0,
        special: 0
    };

    for (const char of value) {
        if (/[a-z]/.test(char)) count.lower++;
        else if (/[A-Z]/.test(char)) count.upper++;
        else if (/\d/.test(char)) count.digit++;
        else if (/\S/.test(char)) count.special++;
    }

    if (Object.values(count).filter(Boolean).length < 3) {
        return `Must contain ${text}`;
    }

    return SUCCESS;
};

export const validateConfirmPassword = (
    value,
    values,
    text,
    passwordKey = 'password'
) => {
    return value === values[passwordKey] ? SUCCESS : text;
};

export const emailsSeparatedByComas = (value, values, text) => {
    const emails = value ? value.split(',') : [],
        regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const filteredEmails = emails.filter(el => {
        return !regex.test(el.trim());
    });

    return !filteredEmails.length ? SUCCESS : text;
};

export const isNumber = (value, values, text) => {
    return isNaN(Number(value)) || Number(value) <= 0 ? text : SUCCESS;
};
