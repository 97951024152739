import React from 'react';
import { string, object, func, node } from 'prop-types';
import { useTranslation } from 'react-i18next';
require('./MiniCartHeader.less');

export const MiniCartHeader = props => {
    const [t] = useTranslation();

    return (
        <div
            className={'minicart-header ' + (props.className || '')}
            css={props.css}
        >
            <div
                className="minicart-title"
                id="minicart-title-heading"
                role="heading"
                aria-level="2"
            >
                <span className="text">{props.title}</span>
            </div>

            <button className={'close-minicart'} onClick={props.closeCart}>
                <span>{t('Close')}</span>
            </button>
        </div>
    );
};

MiniCartHeader.propTypes = {
    className: string,
    css: object,
    closeCart: func.isRequired,
    title: node.isRequired
};
