import React, { useState } from 'react';
import { SEO } from './SEO';

// This component should wrap components that can update product data and the SEO component that consumes the data
// For example, Yotpo Reviews component loads reviews data, that can be used in aggregateRating JSON
// When the tracking data is updated in the child component, it should execute setTriggerReload
export const SEOWrapper = props => {
    const { getChildComponents } = props;
    const [triggerReload, setTriggerReload] = useState();
    
    return (
        <>
            <SEO {...props} />
            {getChildComponents({...props, setTriggerReload})}
        </>
    );
}
