import React, { useReducer, useCallback, useContext } from 'react';
import ConstructorIOClient from '@constructor-io/constructorio-client-javascript';
import { useHistory } from 'react-router-dom';
import { AuthStore, isSignedIn } from '@corratech/context-provider';
import { useDataLayerAction } from '@corratech/tag-manager';
import { useGlobalOptions } from '../../@corratech/context-provider';
import jsSHA from 'jssha/sha256';

const initialState = {
    items: [],
    filters: {},
    count: null,
    page: 1,
    sort: {},
    loading: false,
    filterCount: [],
    availableFilters: [],
    sortFields: [],
    searchQuery: '',
    category: {},
    promoContent: []
};

export const ConstructorPLPManagerStore = React.createContext(initialState);

const LOAD_PRODUCTS = 'LOAD_PRODUCTS';
const SET_PRODUCTS = 'SET_PRODUCTS';
const SET_PAGE = 'SET_PAGE';
const LOADING_PAGE = 'LOADING_PAGE';
const SET_FILTERS = 'SET_FILTERS';
const SORT = 'SORT';

const reducer = (state, action) => {
    const {
        items,
        filters,
        count,
        page,
        loading,
        filterCount,
        sort,
        availableFilters,
        sortFields,
        searchQuery,
        category,
        promoContent
    } = action.payload || {};

    switch (action.type) {
        case LOAD_PRODUCTS:
            return {
                ...state,
                items: [...state.items, ...items],
                filters: { ...state.filters, ...filters },
                availableFilters: availableFilters,
                count: count,
                sortFields: sortFields || state.sortFields,
                sort: sort || state.sort,
                searchQuery: searchQuery || state.searchQuery,
                page: page,
                category: category || {},
                promoContent
            };
        case SET_PRODUCTS:
            return {
                ...state,
                items: items,
                count: count,
                availableFilters: availableFilters,
                filters: filters,
                sortFields: sortFields || state.sortFields,
                sort: sort || state.sort,
                searchQuery: searchQuery || state.searchQuery,
                page: page,
                category: category || {},
                promoContent
            };
        case SET_PAGE:
            return {
                ...state,
                page: page
            };
        case LOADING_PAGE:
            return {
                ...state,
                loading: loading
            };
        case SET_FILTERS:
            return {
                ...state,
                filters: filters
            };
        case SORT:
            return {
                ...state,
                sort: sort
            };
        default:
            return state;
    }
};

export const ConstructorPLPManager = ({ children, prompts }) => {
    const apiKey = prompts.constructorApiKey;
    const enableVisibilityPreFilter = prompts.enableVisibilityPreFilter;
    const [plpState, dispatch] = useReducer(reducer, initialState);
    const { authState } = useContext(AuthStore);
    const history = useHistory();
    const constructorClientParams = {
        apiKey: apiKey
    };

    if (isSignedIn()) {
        let userId = authState.user.id;
        if (userId) {
            let shaObj = new jsSHA('SHA-256', 'TEXT');
            shaObj.update(userId + 'ELEMIS');
            userId = shaObj.getHash('HEX');
            constructorClientParams.userId = userId;
        }
    }
    const constructorIOClient = new ConstructorIOClient(
        constructorClientParams
    );
    const options = useGlobalOptions();
    const { storeConfig } = options;
    const dataLayerAction = useDataLayerAction();

    const searchTimeout =
        parseInt(prompts.constructorSearchNetworkTimeout) || 1000;
    const productListTimeout =
        parseInt(prompts.constructorProductListNetworkTimeout) || 1000;
    const searchResultPerPage =
        parseInt(prompts.constructorSearchProductResultPerPage) || 30;
    const productListResultPerPage =
        parseInt(prompts.constructorProductListProductResultPerPage) || 30;

    const visibilityInCatalog = '2';
    const visibilityInSearch = '3';
    const visibilityBoth = '4';

    const getVisibilityFilter = visibility => {
        const visibilityConditions = {
            [visibilityInCatalog]: [visibilityInCatalog, visibilityBoth],
            [visibilityInSearch]: [visibilityInSearch, visibilityBoth],
            [visibilityBoth]: [
                visibilityInCatalog,
                visibilityBoth,
                visibilityInSearch
            ]
        };

        if (enableVisibilityPreFilter && visibilityConditions[visibility]) {
            return {
                or: visibilityConditions[visibility].map(val => ({
                    name: 'visibility',
                    value: val
                }))
            };
        }
        return null;
    };

    const redirectToPage = path => {
        if (path) {
            history.push(path);
        }
    };

    const trackSearch = (
        gtmType,
        results,
        page,
        resultPerPage,
        query = '',
        category = {}
    ) => {
        const items = results.map(item => ({
            sku: item.data.id,
            name: item.value,
            brand_name: item.data.brand || '',
            categories: { 1: { name: item.data.category || '' } },
            price_range: {
                minimum_price: {
                    final_price: {
                        value: item.data.price || '0'
                    }
                }
            }
        }));

        const data = {
            items,
            pageSize: resultPerPage,
            currentPage: page,
            currencyCode: storeConfig.base_currency_code,
            category:
                gtmType === 'PLP_PAGE'
                    ? { name: category?.display_name ?? '' }
                    : '',
            searchTerm: query || '',
            isSearchResult: gtmType === 'SEARCH'
        };

        if (gtmType === 'PLP_PAGE') {
            dataLayerAction({ type: 'PLP_PAGE', data });
        } else if (gtmType === 'SEARCH') {
            dataLayerAction({ type: 'RESET_ECOMMERCE' });
            dataLayerAction({ type: 'SEARCH', data });
        }
    };

    const loadProducts = useCallback(
        async (query, filters, page, sort, gtmType = 'SEARCH') => {
            const appliedFilters = Object.fromEntries(
                Object.entries(filters).filter(
                    ([_, filter]) => filter && filter.length > 0
                )
            );

            let timeout =
                gtmType === 'PLP_PAGE' ? productListTimeout : searchTimeout;
            let resultPerPage =
                gtmType === 'PLP_PAGE'
                    ? productListResultPerPage
                    : searchResultPerPage;
            try {
                let result;
                const params = {
                    filters: appliedFilters,
                    page,
                    sortBy: sort?.value || 'relevance',
                    sortOrder: sort?.order || 'descending',
                    resultsPerPage: resultPerPage,
                    networkParameters: { timeout }
                };
                let preFilterExpression = getVisibilityFilter(
                    prompts.isCategory && prompts.catId
                        ? visibilityInCatalog
                        : visibilityInSearch
                );

                if (preFilterExpression) {
                    params.preFilterExpression = preFilterExpression;
                }

                if (prompts.isCategory && prompts.catId) {
                    result = await constructorIOClient.browse.getBrowseResults(
                        'group_id',
                        String(prompts.catId),
                        params
                    );
                } else if (query) {
                    result = await constructorIOClient.search.getSearchResults(
                        query,
                        params
                    );
                }

                if (result?.response?.redirect?.data?.url) {
                    redirectToPage(result.response.redirect.data.url);
                }

                const {
                    results,
                    facets,
                    total_num_results,
                    sort_options,
                    refined_content = [],
                    groups = []
                } = result.response;
                const category =
                    gtmType === 'PLP_PAGE' && groups.length > 0
                        ? groups[0]
                        : {};
                const promoContent = refined_content;

                dispatch({
                    type: page === 1 ? SET_PRODUCTS : LOAD_PRODUCTS,
                    payload: {
                        items: results,
                        filters: appliedFilters,
                        count: total_num_results ?? 0,
                        page,
                        sort,
                        availableFilters: facets,
                        sortFields: sort_options,
                        searchQuery: query,
                        category,
                        promoContent
                    }
                });

                trackSearch(
                    gtmType,
                    results,
                    page,
                    resultPerPage,
                    query,
                    category
                );
            } catch (error) {
                console.error('Error loading products:', error);
            }
        },
        [dispatch, prompts]
    );

    const applySort = useCallback(
        async sort => {
            dispatch({
                type: SORT,
                payload: { sort }
            });
            const { searchQuery, filters } = plpState;
            await loadProducts(searchQuery, filters, 1, sort);
        },
        [loadProducts, plpState.filters, plpState.searchQuery]
    );

    const addFilter = useCallback(
        async (filterKey, filterValue) => {
            const updatedFilters = {
                ...plpState.filters,
                [filterKey]: [
                    ...(plpState.filters[filterKey] || []),
                    filterValue
                ]
            };

            dispatch({
                type: SET_FILTERS,
                payload: { filters: updatedFilters }
            });
            await loadProducts(
                plpState.searchQuery,
                updatedFilters,
                1,
                plpState.sort
            );
        },
        [plpState.filters, plpState.searchQuery, plpState.sort, loadProducts]
    );

    const removeFilter = useCallback(
        async (filterKey, filterValue) => {
            const updatedFilters = {
                ...plpState.filters,
                [filterKey]: plpState.filters[filterKey].filter(
                    val => val !== filterValue
                )
            };
            if (updatedFilters[filterKey].length === 0) {
                delete updatedFilters[filterKey];
            }
            dispatch({
                type: SET_FILTERS,
                payload: { filters: updatedFilters }
            });
            await loadProducts(
                plpState.searchQuery,
                updatedFilters,
                1,
                plpState.sort
            );
        },
        [plpState.filters, plpState.searchQuery, plpState.sort, loadProducts]
    );

    const removeAllFilters = useCallback(async () => {
        const clearedFilters = {};
        dispatch({ type: 'SET_FILTERS', payload: { filters: clearedFilters } });
        await loadProducts(
            plpState.searchQuery,
            clearedFilters,
            1,
            plpState.sort
        );
    }, [plpState.searchQuery, plpState.sort, loadProducts]);

    const setPage = useCallback(
        payload => {
            dispatch({ payload, type: SET_PAGE });
        },
        [dispatch]
    );

    const setLoading = useCallback(
        payload => {
            dispatch({ payload, type: LOADING_PAGE });
        },
        [dispatch]
    );

    return (
        <ConstructorPLPManagerStore.Provider
            value={{
                plpState,
                loadProducts,
                addFilter,
                removeFilter,
                setPage,
                setLoading,
                applySort,
                removeAllFilters
            }}
        >
            {children}
        </ConstructorPLPManagerStore.Provider>
    );
};

export const useConstructorPLPManager = () =>
    React.useContext(ConstructorPLPManagerStore);
