import React from 'react';
import PropTypes from 'prop-types';

const LockIcon = props => {
    const { color, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill={color}
            stroke={color}
            viewBox="0 0 24 24"
            {...otherProps}
        >
            <path d="M10 16a2 2 0 014 0c0 .738-.404 1.376-1 1.723V20h-2v-2.277c-.596-.347-1-.985-1-1.723zm11-6v14H3V10h3V6a6 6 0 0112 0v4h3zM8 10h8V6c0-2.206-1.795-4-4-4S8 3.794 8 6v4zm11 2H5v10h14V12z"></path>
        </svg>
    );
};

LockIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

LockIcon.defaultProps = {
    color: 'currentColor',
    size: '24'
};

export default LockIcon;
