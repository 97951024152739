import React from 'react';

export const ApplePayIcon = () => {
    return (
        <svg role="img" aria-label="Apple Pay Icon" xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 20" fill="none">
            <rect x="0.5" y="0.500244" width="29" height="19" rx="1.5" fill="black" stroke="#F2F4F5"/>
            <path d="M10.2798 8.29832C9.80167 8.62803 9.56261 9.02569 9.56261 9.4913C9.56261 10.0505 9.85745 10.4793 10.4471 10.7779C10.2878 11.2256 10.0578 11.6166 9.75727 11.9508C9.45902 12.2827 9.18808 12.4487 8.94447 12.4487C8.8238 12.4487 8.66102 12.4108 8.45611 12.3351L8.36048 12.2983L8.19997 12.2382C8.09752 12.2025 7.97458 12.1847 7.83114 12.1847C7.68998 12.1847 7.53402 12.2148 7.36327 12.2749L7.24032 12.3184L7.08664 12.3819C6.96825 12.4264 6.84758 12.4487 6.72464 12.4487C6.33531 12.4487 5.95054 12.129 5.57033 11.4896C5.19011 10.8503 5 10.2031 5 9.54811C5 8.99116 5.15823 8.54226 5.4747 8.20141C5.79117 7.85833 6.20782 7.68679 6.72464 7.68679C6.91816 7.68679 7.1003 7.72132 7.27106 7.79038L7.38717 7.83716L7.51012 7.88729C7.61713 7.92962 7.70364 7.95078 7.76967 7.95078C7.85618 7.95078 7.95181 7.93184 8.05654 7.89397L8.33316 7.79038C8.52669 7.72132 8.73729 7.68679 8.96496 7.68679C9.51138 7.68679 9.94966 7.89063 10.2798 8.29832ZM8.99228 6.2699C8.99684 6.34119 8.99911 6.39578 8.99911 6.43365C8.99911 6.78787 8.86706 7.09865 8.60296 7.36598C8.34113 7.63109 8.03491 7.76364 7.68429 7.76364C7.67518 7.68567 7.67063 7.62775 7.67063 7.58988C7.67063 7.29135 7.79244 7.01065 8.03605 6.74777C8.28194 6.48489 8.56539 6.33006 8.88641 6.28327C8.90918 6.28104 8.94447 6.27659 8.99228 6.2699Z" fill="white"/>
            <path d="M12.4219 12.315V5.83356H14.693C16.1691 5.83356 17.1438 6.62985 17.1438 7.95393C17.1438 9.278 16.1691 10.0743 14.693 10.0743H13.3303V12.315H12.4219ZM13.3303 9.25948H14.7119C15.696 9.25948 16.2354 8.85208 16.2354 7.95393C16.2354 7.05578 15.696 6.64837 14.7119 6.64837H13.3303V9.25948Z" fill="white"/>
            <path d="M18.6566 12.4076C17.7671 12.4076 17.1521 11.8891 17.1521 11.065C17.1521 10.1576 17.8996 9.69467 18.7513 9.61134L20.2274 9.46319V9.14837C20.2274 8.59282 19.7827 8.33356 19.1771 8.33356C18.6756 8.33356 18.2781 8.55578 18.1456 9.08356H17.3602C17.5116 8.04652 18.3822 7.59282 19.2339 7.59282C20.341 7.59282 21.0602 8.17615 21.0602 9.05578V11.2224C21.0602 11.5187 21.1926 11.7039 21.4765 11.7039C21.5522 11.7039 21.6279 11.6947 21.7225 11.6669V12.278C21.4954 12.3521 21.344 12.3706 21.1926 12.3706C20.7763 12.3706 20.3883 12.2039 20.2558 11.6484C19.9341 12.0002 19.319 12.4076 18.6566 12.4076ZM18.7513 11.6669C19.3285 11.6669 19.8111 11.3521 20.2274 10.9447V10.1113L18.808 10.2687C18.2497 10.3336 17.9848 10.6576 17.9848 11.028C17.9848 11.4632 18.316 11.6669 18.7513 11.6669Z" fill="white"/>
            <path d="M22.3794 14.1669L23.2216 12.1113L21.348 7.68541H22.2186L23.4866 10.6391L23.6096 11.2039L24.9912 7.68541H25.8333L23.2027 14.1669H22.3794Z" fill="white"/>
        </svg>
)};