import React, { Suspense, useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Page } from '@corratech/peregrine-page';
import resolveUnknownRoute from '@corratech/peregrine-page/Router/resolveUnknownRoute';
import ErrorView from '../ErrorView/';
import { Search } from '../../RootComponents/Search';
import Logo from 'ModulesPath/Shell/Header/Logo';
import { ACCOUNT_LINKS } from './NavItems';
import { Error, Warning, Tick, MinicartIcon } from '../Icons';
import LoadingIndicator from '../LoadingIndicator';
const renderRoutingError = props => <ErrorView {...props} />;

import { useDataLayerAction } from '@corratech/tag-manager';
import addConfigurableProductToCartReorder from 'ModulesPath/App/Queries/addConfigurableProductToCartReorder.graphql';
import addSimpleProductsToCartReorder from 'ModulesPath/App/Queries/addSimpleProductsToCartReorder.graphql';
import reorderCreateCart from 'ModulesPath/App/Queries/reorderCreateCart.graphql';
import { useGlobalOptions } from '@corratech/context-provider';
import zendeskCreateTicket from 'ModulesPath/ContactForm/zendeskCreateTicket.graphql';
import removeItemFromCart from 'ModulesPath/App/Queries/removeItemFromCart.graphql';
import { ProductSlider } from '../ProductSlider';
import { Util } from '@magento/peregrine';
import { HeaderBlocker, FooterBlock } from '@corratech/checkout';
import redirectToLowercaseURL from '../../util/redirectToLowercaseURL';
import { PROMO_CODE_PARAM } from 'ModulesPath/ApplyCoupon/ApplyCoupon';
import { DebugBearMonitoring } from 'ModulesPath/DebugBear/DebugBearMonitoring';

const { BrowserPersistence } = Util;
const storage = new BrowserPersistence();

const RenderRoutes = () => {
    const { homepageId } = useGlobalOptions();
    const options = useGlobalOptions();

    const AddressValidation = React.lazy(() =>
        import('@corratech/address-validation/Avalara/Avalara')
    );
    const CheckoutPage = React.lazy(() => import('@corratech/checkout'));
    const OrderConfirmation = React.lazy(() =>
        import('@corratech/checkout/OrderConfirmation/OrderConfirmation')
    );
    const MyAccount = React.lazy(() => import('@corratech/account-dashboard'));
    const StoreLocator = React.lazy(() => import('@corratech/store-locator'));
    const Blog = React.lazy(() => import('ModulesPath/Blog/Blog'));
    const GiftFinder = React.lazy(() => import('ModulesPath/GiftFinder'));
    const ContactFormFields = React.lazy(() =>
        import('ModulesPath/ContactForm/FormFields')
    );
    const CancelPayPalFromHome = React.lazy(() =>
        import(
            'ModulesPath/PaypalExpressReturnFromMiniCart/CancelPayPalFromHome'
        )
    );
    const Contact = React.lazy(() => import('@corratech/contact/src/index'));
    const GuestOrderTracking = React.lazy(() =>
        import('@corratech/guest-order-tracking/GuestOrderTracking')
    );
    const Cms = React.lazy(() => import('../../RootComponents/CMS/CMS'));
    const EmailPreferences = React.lazy(() =>
        import('ModulesPath/EmailPreferences')
    );
    const ApplyCoupon = React.lazy(() =>
        import('ModulesPath/ApplyCoupon/ApplyCoupon')
    );
    const PerfectCorpLandingPage = React.lazy(() =>
        import('ModulesPath/PerfectCorp/LandingPage')
    );
    const SitemapHtml = React.lazy(() =>
        import('ModulesPath/SitemapHtml/SitemapHtml')
    );

    const [enteredPassword, setEnteredPassword] = useState('');
    const [joinRewards, setJoinRewards] = useState(false);

    const params = new URLSearchParams(window.location.search);
    const couponCode = params.get(PROMO_CODE_PARAM);

    return (
        <Suspense fallback={<LoadingIndicator />}>
            <Switch>
                <Route
                    path="/"
                    exact
                    component={() => {
                        resolveUnknownRoute({
                            route: '/',
                            apiBase: '/graphql'
                        });
                        return <Cms id={homepageId} />;
                    }}
                />
                <Route path="/checkout">
                    <CheckoutPage
                        DefaultLogo={() => <Logo color={'#002739'} />}
                        IconCart={
                            <MinicartIcon
                                color={'#002739'}
                            />
                        }
                        startSummaryOpen={true}
                        AddressValidationMethod={AddressValidation}
                        getTagData={useDataLayerAction()}
                        removeItemFromCartGraphql={removeItemFromCart}
                        ErrorIcon={<Error size={9} color={'#B30015'} />}
                    />
                </Route>
                <Route path="/search/" component={Search} />
                <Route path="/contact-us/">
                    <Contact
                        cmsBlockTopContent={'contact-page-content-top'}
                        cmsBlockBottomContent={'contact-page-content-bottom'}
                        storeCountries={['US', 'UK']}
                        FormFields={ContactFormFields}
                        sendContactDetailsMutaion={zendeskCreateTicket}
                    />
                </Route>
                <Route path="/my-account/wishlist" component={MyAccount} />
                <Route
                    path={'/cancel-paypal-from-minicart'}
                    component={() => <CancelPayPalFromHome />}
                />
                <Route path="/my-account">
                    {couponCode ? <ApplyCoupon couponCode={couponCode} /> : ''}
                    <MyAccount
                        accountLinks={ACCOUNT_LINKS}
                        redirectOnSignout={true}
                        SuccessIco={
                            <Tick
                                size="14"
                                strokeWidth={'2'}
                                color={'#054F70'}
                            />
                        }
                        WarningIco={<Warning size={14} color={'#B30015'} />}
                        ErrorIco={<Error size={9} color={'#B30015'} />}
                        configProductAddToCartGraphql={
                            addConfigurableProductToCartReorder
                        }
                        simpleProductAddToCartGraphql={
                            addSimpleProductsToCartReorder
                        }
                        reorderCreateCart={reorderCreateCart}
                    />
                </Route>
                <Route
                    path="/store-locator/:storeUrlKey?"
                    component={props => {
                        useEffect(() => {
                            DebugBearMonitoring('StoreLocator');
                        }, []);
                        redirectToLowercaseURL(props);
                        return <StoreLocator {...props} />;
                    }}
                />
                <Route path="/blog/:urlKeyPrimary?/:urlKeySecondary?">
                    {props => {
                        DebugBearMonitoring('Blog');
                        redirectToLowercaseURL(props);
                        return (
                            <Blog
                                {...props}
                                blogRouteName={'blog'}
                                singleAuthorMode={true}
                                useTags={false}
                            />
                        );
                    }}
                </Route>
                <Route path="/order-status">
                    <GuestOrderTracking
                        ErrorIcon={<Error size={9} color={'#B30015'} />}
                        reorderCreateCart={reorderCreateCart}
                    />
                </Route>
                <Route
                    exact
                    path="/gift-finder"
                    component={() => {
                        useEffect(() => {
                            DebugBearMonitoring('GiftFinder');
                        }, []);
                        return <GiftFinder />;
                    }}
                />
                <Route exact path="/product-slider">
                    <ProductSlider type={'popular'} productId={''} />
                </Route>
                <Route
                    exact
                    path="/email-preferences"
                    component={EmailPreferences}
                />
                <Route
                    exact
                    path="/express-checkout/success"
                    component={() => (
                        <>
                            <HeaderBlocker
                                DefaultLogo={() => <Logo color={'#002739'} />}
                            />
                            <OrderConfirmation
                                setEnteredPassword={setEnteredPassword}
                                enteredPassword={enteredPassword}
                                joinRewards={joinRewards}
                                setJoinRewards={setJoinRewards}
                            />
                            <FooterBlock config={options} />
                        </>
                    )}
                />
                <Route
                    exact
                    path="/perfect-corp"
                    component={PerfectCorpLandingPage}
                />
                <Route exact path="/sitemap" component={SitemapHtml} />
                {/*This route MUST be last*/}
                <Route render={() => <Page>{renderRoutingError}</Page>} />
            </Switch>
        </Suspense>
    );
};

export default RenderRoutes;
