import React from 'react';
import { Link } from 'react-router-dom';
import { Error as ErrorIcon } from 'ModulesPath/Icons';

export const ProductRemoveMessage = props => {
    const { removedItems } = props;
    const getProductUrl = productName => {
        let url = '';
        if (props.cartItems) {
            url = props.cartItems.find(
                item => item.product.name == productName
            );
        }
        return url;
    };
    let productUrl = '';
    const removeProducts = props.removedItems.map((item, index) => {
        productUrl = getProductUrl(item);
        return (
            <span>
                <Link
                    className={'oos-link'}
                    to={
                        productUrl && productUrl != ''
                            ? productUrl.product.url_key + '.html'
                            : '#'
                    }
                >
                    {item}
                </Link>
                {removedItems.length != 1 ? ', ' : ''}
            </span>
        );
    });

    return (
        <div className={'oos-product-remove-msg'}>
            <span>
                {props.removedItems.length > 1
                    ? `The following items are out of stock and have been removed from your shopping bag: `
                    : `The following item is out of stock and has been removed from your shopping bag: `}
            </span>
            <ErrorIcon size={9} color={'#B70020'} />
            {removeProducts}
            {removedItems.length != 1 ? ' etc' : ''}
        </div>
    );
};
