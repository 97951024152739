import React from 'react';
import PropTypes from 'prop-types';

const StoreIcon = props => {
    const { color, size, width, ...otherProps } = props;

    return (
        <svg width={width} height={width} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9 1.5C12.0376 1.5 14.5 3.96243 14.5 7C14.5 9.32101 13.0948 11.7061 11.6096 13.5627C10.8758 14.4799 10.1408 15.2456 9.58896 15.7821C9.3541 16.0105 9.15298 16.1967 9 16.3349C8.84703 16.1967 8.6459 16.0105 8.41104 15.7821C7.85921 15.2456 7.12421 14.4799 6.39043 13.5627C4.90522 11.7061 3.5 9.32101 3.5 7C3.5 3.96243 5.96243 1.5 9 1.5Z"
                stroke={color}/>
            <path
                d="M9 4.5C10.3807 4.5 11.5 5.61929 11.5 7C11.5 8.38071 10.3807 9.5 9 9.5C7.61929 9.5 6.5 8.38071 6.5 7C6.5 5.61929 7.61929 4.5 9 4.5Z"
                stroke={color}/>
        </svg>
    );
};

StoreIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

StoreIcon.defaultProps = {
    color: 'currentColor',
    size: '24',
    width: '18'
};

export default StoreIcon;
