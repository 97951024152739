import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router-dom';

export const useUserVisitCookie = () => {
    const cookieName = 'returningVisitor';
    const maxAge = 30 * 24 * 3600;

    const [cookies, setCookie] = useCookies([cookieName]);

    const location = useLocation();

    const isCO = () => {
        return location.pathname.indexOf('/checkout') == 0;
    };

    useEffect(() => {
        if (!isCO()) {
            const visitorCount = cookies[cookieName];

            const count = visitorCount ? parseInt(visitorCount) + 1 : 1;

            let options = {
                path: '/',
                maxAge: maxAge
            };

            setCookie(cookieName, count, options);
        }
    }, [location?.pathname]);
};
