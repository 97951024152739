import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import GET_STORE_CONFIG from './getConstructorStoreConfig.graphql';

export const useConstructorConfig = props => {
    const [
        constructorAutocompleteEnable,
        setConstructorAutocompleteEnable
    ] = useState(false);
    const [constructorApiKey, setConstructorApiKey] = useState(null);
    const [
        constructorProductSectionLimit,
        setConstructorProductSectionLimit
    ] = useState(null);
    const [constructorNetworkTimeout, setConstructorNetworkTimeout] = useState(
        null
    );
    const [constructorSearchEnable, setConstructorSearchEnable] = useState(
        false
    );
    const [
        constructorSearchProductResultPerPage,
        setConstructorSearchProductResultPerPage
    ] = useState(null);
    const [
        constructorSearchNetworkTimeout,
        setConstructorSearchNetworkTimeout
    ] = useState(null);
    const [
        constructorProductListEnable,
        setConstructorProductListEnable
    ] = useState(false);
    const [
        constructorProductListProductResultPerPage,
        setConstructorProductListProductResultPerPage
    ] = useState(null);
    const [
        constructorProductListNetworkTimeout,
        setConstructorProductListNetworkTimeout
    ] = useState(null);
    const [enableVisibilityPreFilter, setEnableVisibilityPreFilter] = useState(
        false
    );
    const [constructorConfigLoaded, setConstructorConfigLoaded] = useState(
        false
    );

    const { loading, error } = useQuery(GET_STORE_CONFIG, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (!!data.storeConfig) {
                setConstructorAutocompleteEnable(
                    data.storeConfig.constructor_autocomplete_enable
                );
                setConstructorApiKey(data.storeConfig.constructor_api_key);
                setConstructorProductSectionLimit(
                    data.storeConfig.constructor_product_section_limit
                );
                setConstructorNetworkTimeout(
                    data.storeConfig.constructor_network_timeout
                );
                setConstructorSearchEnable(
                    data.storeConfig.constructor_search_enable
                );
                setConstructorSearchProductResultPerPage(
                    data.storeConfig.constructor_search_product_results_per_page
                );
                setConstructorSearchNetworkTimeout(
                    data.storeConfig.constructor_search_network_timeout
                );
                setConstructorProductListEnable(
                    data.storeConfig.constructor_product_list_enable
                );
                setConstructorProductListProductResultPerPage(
                    data.storeConfig
                        .constructor_product_list_product_results_per_page
                );
                setConstructorProductListNetworkTimeout(
                    data.storeConfig.constructor_product_list_network_timeout
                );
                setEnableVisibilityPreFilter(
                    data.storeConfig.constructor_enable_visibility_pre_filter
                );
                setConstructorConfigLoaded(
                    true
                );
            }
        }
    });

    return {
        constructorAutocompleteEnable: constructorAutocompleteEnable ?? false,
        constructorApiKey: constructorApiKey ?? '',
        constructorProductSectionLimit: constructorProductSectionLimit,
        constructorNetworkTimeout: constructorNetworkTimeout,
        constructorSearchEnable: constructorSearchEnable ?? false,
        constructorSearchProductResultPerPage: constructorSearchProductResultPerPage,
        constructorSearchNetworkTimeout: constructorSearchNetworkTimeout,
        constructorProductListEnable: constructorProductListEnable ?? false,
        constructorProductListProductResultPerPage: constructorProductListProductResultPerPage,
        constructorProductListNetworkTimeout: constructorProductListNetworkTimeout,
        enableVisibilityPreFilter: enableVisibilityPreFilter,
        constructorConfigLoaded: constructorConfigLoaded,
    };
};
