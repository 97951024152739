import React, { useState, useEffect } from 'react';

export const SetNotificationStore = React.createContext();
export const NotificationValueStore = React.createContext();

const initialState = {
    variant: null,
    content: null,
    duration: null
};

export const NotificationProviders = props => {
    // Notifications should have this shape where
    // `variant` is one of the following strings: 'primary','secondary','success','danger','warning','info','light','dark',
    //      More info here https://react-bootstrap.github.io/components/alerts/
    // `content` is React node (any component or text which React can display)
    // `duration` is an integer in milliseconds

    const [notification, setNotification] = useState(initialState);
    const [notificationTimeout, setNotificationTimeout] = useState();

    useEffect(() => {
        if (notification.duration) {
            setNotificationTimeout(
                setTimeout(() => {
                    setNotification(initialState);
                    clearTimeout(notificationTimeout);
                    setNotificationTimeout(null);
                }, notification.duration)
            );
        }
    }, [notification]);

    useEffect(() => {
        // if the component unmounts before the timeout occurs,
        // we need to clear the timeout manually
        return () => {
            if (notificationTimeout) {
                setNotification(initialState);
                clearTimeout(notificationTimeout);
                setNotificationTimeout(null);
            }
        };
    }, []);

    return (
        <SetNotificationStore.Provider value={setNotification}>
            <NotificationValueStore.Provider value={notification}>
                {props.children}
            </NotificationValueStore.Provider>
        </SetNotificationStore.Provider>
    );
};
