import { deepValue } from "@corratech/tag-manager";

export const getConfigurableVariant = (item) => {
    const options = deepValue(item, ['configurable_options'], []);
    let optionLabels = [];
    options.map(option => {
        let label = deepValue(option, ['value_label']);
        if (!!label) {
            optionLabels.push(label);
        }
    });
    if (optionLabels.length === 0) {
        optionLabels.push(deepValue(item, ['product', 'name']));
    }
    return optionLabels.join(' - ');
}