import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MagentoRouteHandler, RouteConsumer } from './Router';

export default class Page extends Component {
    static propTypes = {
        disableRewrite: PropTypes.bool
    };

    render() {
        const { props } = this;

        return (
            <RouteConsumer>
                {context => <MagentoRouteHandler {...props} {...context} />}
            </RouteConsumer>
        );
    }
}
