import classNames from 'classnames/bind';
import { getIn } from 'formik';
import { shape, string } from 'prop-types';
import React, { Fragment } from 'react';
//import { FiChevronDown as ChevronDownIcon } from 'react-icons/fi';
import { ChevronDown as ChevronDownIcon } from 'react-feather';
import { FieldIcons } from '../Field';
import Icon from '../Icon';
import style from '../Message/Message.less';

let examineClasses = classNames.bind(style);

const arrow = <Icon src={ChevronDownIcon} size={25} />;

const SelectInputFormik = ({
    field,
    items,
    form: { errors, touched },
    after,
    before,
    enableError,
    setErrorClass,
    handleOnChange,
    ...rest
}) => {
    const errorMessage = getIn(errors, field.name);
    const touch = getIn(touched, field.name);

    const className = errorMessage
        ? examineClasses('field-required-error')
        : examineClasses('');

    if (enableError) {
        errorMessage ? setErrorClass(true) : setErrorClass(false);
    }

    const options = items.map(({ value, label }, idx) => (
        <option key={idx} value={value}>
            {label}
        </option>
    ));

    return (
        <Fragment>
            <div className={'select-box-wrapper'}>
                <FieldIcons after={arrow}>
                    <select
                        type="select"
                        className={'select-select'}
                        {...field}
                        onChange={value => {
                            if (!!handleOnChange) {
                                handleOnChange();
                            }
                            field.onChange(value);
                        }}
                        {...rest}
                    >
                        {options}
                    </select>
                </FieldIcons>
            </div>
            {touch && errorMessage && (
                <span className="root_error">
                    <p className={className}>{errorMessage}</p>
                </span>
            )}
        </Fragment>
    );
};

export default SelectInputFormik;
