import React from 'react';
import PropTypes from 'prop-types';

const SA = props => {
    const { color, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            height={size}
            width={size}
            {...otherProps}
        >
            <path
                fill="#6DA544"
                d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256 256-114.6 256-256S397.4 0 256 0zm64 144.7h33.4v77.9H320v-77.9zm-139.1 0h33.4v44.5c0 3.1 2.5 5.6 5.6 5.6 3.1 0 5.6-2.5 5.6-5.6v-44.5h33.4v44.5c0 3.1 2.5 5.6 5.6 5.6s5.6-2.5 5.6-5.6v-44.5h33.4v77.9c0 30.7-25 55.7-55.7 55.7v-33.4c10.5 0 19.2-7.3 21.6-17-1.6.2-3.2.3-4.9.3-8.3 0-15.9-2.6-22.3-7-6.3 4.4-14 7-22.3 7-21.5 0-39-17.5-39-39v-44.5zm0 100.2H231v33.4h-50.1v-33.4zm-72.4 0c12.3 0 22.3-10 22.3-22.3v-77.9h33.4v77.9c0 30.7-25 55.7-55.7 55.7v-33.4zm258.8 94.6c0 15.4-12.5 27.8-27.8 27.8h-33.4c-15.4 0-27.8-12.5-27.8-27.8H178.1c-18.4 0-33.4-14.9-33.4-33.4h222.6v33.4zm36.2-116.9c0 30.7-25 55.7-55.7 55.7v-33.4c12.3 0 22.3-10 22.3-22.3v-77.9h33.4v77.9z"
            ></path>
        </svg>
    );
};

SA.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

SA.defaultProps = {
    color: 'currentColor',
    size: '18'
};

export default SA;
