// A copy of the function from react-recaptcha-v3@2.0.1, with a fix that prevents creating script tag duplicates
// Callback by default is an empty function to execute nothing when no callback is passed
const loadReCaptcha = (
    siteKey,
    callback = () => {},
    scriptId = 'recaptcha-v3-src'
) => {
    if (!document.getElementById(scriptId)) {
        const script = document.createElement('script');
        script.id = scriptId;
        if (!window.onLoadCaptchaV3Callback && callback) {
            window.onLoadCaptchaV3Callback = callback;
        }
        script.src = `https://www.recaptcha.net/recaptcha/api.js?onload=onLoadCaptchaV3Callback&render=${siteKey}`;
        document.body.appendChild(script);
        return true;
    }
    return false;
};

export default loadReCaptcha;
