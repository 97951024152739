// Retrieve object value by path
export const getDeepValue = (obj, path, defaultValue) => {
    let result = obj;
    for (let i = 0; i < path.length; i++) {
        if (typeof result[path[i]] === 'undefined') {
            return defaultValue;
        }
        result = result[path[i]];
    }
    return result;
};

// 256 minus possible '...' ending
export const DESCRIPTION_LENGTH_LIMIT = 253;
// Get text with a limited length from a text/HTML value
// If possible, should consist of completed sentences.
export const getTextValue = (input, limit = DESCRIPTION_LENGTH_LIMIT) => {
    let tmpElement = document.createElement('p');
    tmpElement.innerHTML = input;
    let text = tmpElement.innerText;
    if (!limit || text.length < limit) {
        return text;
    }
    text = text.slice(0, limit);
    if (text.endsWith('.')) {
        return text;
    }
    if (!text.includes('.')) {
        return `${text}...`;
    }
    if (text.includes('.')) {
        text.slice(0, text.lastIndexOf('.'));
    }
    return `${text}...`;
};

export const getProductUrl = (product, urlBase, urlSuffix) => {
    if (!!product.canonical_url) {
        return `${urlBase}${product.canonical_url}`;
    }
    let urlKey = product.url_key;
    if (urlKey && urlSuffix && !urlKey.includes(urlSuffix)) {
        urlKey += urlSuffix;
    }
    return `${urlBase}${urlKey}`;
};

export const getProductAttributeValue = (
    product,
    config,
    configKey,
    defaultValue = null
) => {
    if (typeof config[configKey] === 'undefined') {
        return defaultValue;
    }

    let result = null;
    // Handle the case when there are several paths where the value can be found
    if (
        Array.isArray(config[configKey]) &&
        Array.isArray(config[configKey][0])
    ) {
        config[configKey].map(configEntry => {
            if (result === null) {
                result = getDeepValue(product, configEntry, null);
            }
        });
    } else {
        result = getDeepValue(product, config[configKey], null);
    }

    return result !== null ? result : defaultValue;
};

const ITEM_CONDITION_CONFIG_KEY = 'itemCondition';
const DEFAULT_ITEM_CONDITION = 'https://schema.org/NewCondition';
export const getItemCondition = (productItem, config) => {
    const itemCondition = getProductAttributeValue(
        productItem,
        config.attributes,
        ITEM_CONDITION_CONFIG_KEY
    );
    // If product doesn't have the attribute, or the value is not mapped - item should be new by default
    if (
        itemCondition === null ||
        typeof config.itemConditionMap[itemCondition] === 'undefined'
    ) {
        return DEFAULT_ITEM_CONDITION;
    }

    return config.itemConditionMap[itemCondition];
};
