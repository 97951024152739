import React from 'react';
import PropTypes from 'prop-types';

const Warning = props => {
    const { color, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill={color}
            stroke={color}
            viewBox="0 0 14 13"
            {...otherProps}
        >
            <path
                fill={color}
                fillRule="evenodd"
                stroke="none"
                strokeWidth="1"
                d="M122.539 485.574a.819.819 0 000-1.122.754.754 0 00-.539-.232c-.2 0-.397.085-.539.232a.816.816 0 000 1.122.751.751 0 00.539.232c.2 0 .397-.084.539-.232zm.07-2.633v-2.95a.622.622 0 00-.609-.633.622.622 0 00-.609.634v2.95c0 .35.273.633.609.633a.622.622 0 00.609-.634zm-5.955 3.79h10.692L122 476.92l-5.346 9.813zM128.391 488H115.61a.605.605 0 01-.526-.315.657.657 0 01-.004-.632l6.392-11.732A.605.605 0 01122 475c.219 0 .421.123.53.321l6.39 11.732a.656.656 0 01-.003.632.604.604 0 01-.526.315z"
                transform="translate(-115 -475)"
            ></path>
        </svg>
    );
};

Warning.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Warning.defaultProps = {
    color: 'currentColor',
    size: '24'
};

export default Warning;
