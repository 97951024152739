import { getCategoryPath } from '..';
import { getCategoryName } from '..';

export const PDP_PAGE = (
    itemCategoryPath = getCategoryPath,
    categoryName = getCategoryName
) => {
    return {
        id: ['id'],
        name: ['name'],
        sku: ['sku'],
        discount: ['price_range', 'minimum_price', 'discount', 'amount_off'],
        price: [
            ['price_range', 'minimum_price', 'final_price', 'value'],
            ['price_range', 'minimum_price', 'regular_price', 'value']
        ],
        currencyCode: [
            ['price_range', 'minimum_price', 'final_price', 'currency'],
            ['price_range', 'minimum_price', 'regular_price', 'currency']
        ],
        productType: ['__typename'],
        brand: ['brand_name'],
        category: itemCategoryPath,
        categoryPath: categoryName
    };
}