// These custom pages need to be defined in order to trigger `pageview` GTM event.
// `pageview` event requires pageType and pageName (title/meta_title).
// Object key should be the same as location.pathname of the page
// pageName is necessary. pageType may be omitted (will default to 'other')
export const CustomPages = {
    '/offers': {
        pageType: 'cms',
        pageName: 'Offers'
    },
    '/explore': {
        pageType: 'cms',
        pageName: 'Explore'
    }
};
