import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { OptionsStore } from '@corratech/context-provider';
import classNames from 'classnames/bind';
import defaultClasses from './Badge.less';

const style = classNames.bind(defaultClasses);

export const Badge = props => {
    const { img } = props;
    const [t] = useTranslation();
    const projectConfig = useContext(OptionsStore) || {};
    const storeConfig = projectConfig.storeConfig || {};
    return (
        <div
            className={`${style('sales-badge')} ${
                props.className ? props.className : ''
            }`}
            style={{
                backgroundImage: `url(${storeConfig.secure_base_media_url}wysiwyg/badges/${img})`
            }}
        ></div>
    );
};

Badge.propTypes = {
    label: PropTypes.string
};

Badge.defaultProps = {
    label: 'Sales'
};
