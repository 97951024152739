import React from 'react';
import { Helmet } from "react-helmet-async";

export const GeneralMeta = props => {
    const {
        urlBase,
        config: {
            twitter_site,
            twitter_enabled,
            store_name,
            description,
            logo,
            logo_alt,
            locale,
            og_enabled
        }
    } = props
    
    return (
        <>
            <Helmet>
                {!!twitter_site && !!twitter_enabled &&
                    <>
                        <meta name="twitter:card" content="summary" />
                        <meta name="twitter:site" content={twitter_site} />
                        <meta name="twitter:title" content={store_name} />
                        <meta name="twitter:description" content={description} />
                        <meta name="twitter:image" content={logo} />
                        <meta name="twitter:image:alt" content={logo_alt} />
                    </>
                }
            </Helmet>
            <Helmet>
                {!!og_enabled &&
                    <>
                        <meta property="og:type" content="website" />
                        <meta property="og:title" content={store_name} />
                        <meta property="og:url" content={urlBase} />
                        <meta property="og:image" content={logo} />
                        <meta property="og:image:alt" content={logo_alt} />
                        <meta property="og:site_name" content={store_name} />
                        <meta property="og:description" content={description} />
                        <meta property="og:locale" content={locale} />
                    </>
                }
            </Helmet>
        </>
    );
}