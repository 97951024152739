import React, { useEffect } from 'react';
import { createScript } from './utils/createScript';

export default function useProductActivity(addedProduct, sku) {
    const config = {
        enable: false
    };

    if (!config.enable)
        return null;

    const scriptBody = `(function(){if(typeof _ltk == 'object'){ltkCode();}else{(function (d) {
        if (document.addEventListener) document.addEventListener('ltkAsyncListener', d); else {
        e = document.documentElement; e.ltkAsyncProperty = 0; e.attachEvent('onpropertychange', function (e) {
        if (e.propertyName == 'ltkAsyncProperty') { d(); } }); } })(function(){ltkCode();});}
        function ltkCode(){_ltk_util.ready(function(){
            ${addedProduct ? `_ltk.Activity.AddProductBrowse('${sku}');` : ''}
        })}})();`;

    useEffect(() => {
        const listrakGlobalEl = document.getElementById('listrak-global');

        if (addedProduct) {
            document.body.insertBefore(createScript(scriptBody, 'listrak-product-activity'), listrakGlobalEl);
        } else {
            if (document.getElementById('listrak-product-activity') !== null) {
                document.getElementById('listrak-product-activity').remove();
            }
        }
    }, [addedProduct]);

    useEffect(() => {
        return () => {
            if (document.getElementById('listrak-product-activity') !== null) {
                document.getElementById('listrak-product-activity').remove();
            }
        }
    }, []);
}