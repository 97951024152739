/** @jsx jsx */
import React, {useState, useEffect, useRef, Fragment} from 'react';
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';
import { SubMenuCMS } from '../SubMenuCMS';
import { forceCheck } from 'react-lazyload';
import { useGlobalOptions } from '@corratech/context-provider';
import { RichContent } from '@corratech/rich-content';

let examineClasses = classNames.bind();

const adjustUrl = url => {
    if (url.endsWith('/')) {
        return url.slice(0, -1);
    }
    return url;
}

export const TopCategory = props => {
    const {
        item,
        menuLevel,
        setMenuLevel,
        isTab,
        Link,
        showSubmenu,
        showCMSBlock,
        showOnyCMSBlock,
        activeTopmenu,
        setActiveTopmenu,
        activeSubmenu,
        setActiveSubmenu,
        isShownSubMenu,
        setIsShownSubMenu,
        setTopToggle,
        setActiveSubMenuItem,
        categoryUrlSuffix
    } = props;
    const [toggle, setToggle] = useState(false);
    const locationPath = window.location.pathname;
    const categoryURL = `/${item.url_key}${categoryUrlSuffix}`;
    const currentItemRef = useRef(null);
    const [shownSubMenuId, setShownSubMenuId] = useState(null);

    const projectConfig = useGlobalOptions();
    const baseUrl = adjustUrl(projectConfig.storeConfig.base_url);

    const handleOnMouseLeave = () => {
        setShownSubMenuId(null);
    };

    useEffect(() => {
        const currentItem = currentItemRef.current;
        const handleBlur = (event) => {
            if (currentItem && !currentItem.contains(event.relatedTarget)) {
                setShownSubMenuId(null);
            }
        };

        currentItem.addEventListener('blur', handleBlur, true);

        return () => {
            currentItem.removeEventListener('blur', handleBlur, true);
        };
    }, []);

    useEffect(() => {
        if (locationPath == categoryURL) {
            setActiveTopmenu(item.id);
        }
    }, [locationPath, categoryURL]);

    const lazyForceImage = () => {
        if (forceCheck) forceCheck();
    };

    const handleSubMenuOpen = () => {
        const id = !isShownSubMenu ? item.id : (shownSubMenuId === item.id) ? null : item.id;

        setShownSubMenuId(id);
        setIsShownSubMenu(id);
    }

    const handleOnMouseEnter = () => {
        lazyForceImage();
        handleSubMenuOpen();
    };

    return (
        <li
            className={`
              menu-item
              level-${menuLevel}
              ${toggle ? 'open' : activeTopmenu === item.id ? 'active' : ''}
              ${
                shownSubMenuId && isShownSubMenu === item.id ? 'submenu-open' : ''}
              `}
            ref={currentItemRef}
            onMouseEnter={handleOnMouseEnter}
            onMouseLeave={handleOnMouseLeave}
            css={props.css}
        >
            {!isTab && (
                <Fragment>
                    {item.image_icon && (
                        <img
                            src={`${baseUrl}${item.image_icon}`}
                            alt=""
                            className={'levelTop-icon'}
                            aria-hidden="true"
                            width={'16'}
                            height={'16'}
                        />
                    )}
                    <Link
                        className={examineClasses('levelTop')}
                        to={`/${item.url_key}${categoryUrlSuffix}`}
                        onClick={() => {
                            setActiveTopmenu(item.id);
                            setTopToggle(false);
                        }}
                    >
                        <span className={'name'}>{item.name}</span>
                    </Link>
                    <button
                        className={'levelTop-subMenuOpenBtn'}
                        title={`Open subcategories for ${item.name}`}
                        onClick={handleSubMenuOpen}
                    >
                    </button>
                    {item.effect && (
                        <div className={`levelTop-effect ${item.effect}`} aria-hidden="true"></div>
                    )}
                    <div className={'subCat'}>
                        <div className={'subCatParent'}>
                            <div>
                                {showSubmenu ? (
                                    <div
                                        className={examineClasses('subCatWrapper')}
                                    ></div>
                                ) : (
                                    ''
                                )}

                                <div
                                    className={examineClasses(
                                        `${'imageWrapper'} ${'imageWrapper-'}${menuLevel}`
                                    )}
                                >
                                    <SubMenuCMS item={item} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
            {isTab && (
                <Fragment>
                    <button
                        className={examineClasses('levelTop')}
                        onClick={() => {
                            // setToggle(!toggle);
                            setMenuLevel(2);
                            setActiveSubMenuItem(item);
                            lazyForceImage();
                        }}
                    >
                        <span className={'name'}>{item.name}</span>
                    </button>
                    {item.description_mobile && (
                        <RichContent html={item.description_mobile} />
                    )}
                </Fragment>
            )}
        </li>
    );
};
