import React from 'react';
import { node, shape, string } from 'prop-types';

import classNames from 'classnames/bind';
import style from './Message.less';
let examineClasses = classNames.bind(style);

export const Message = props => {
    const { children, fieldState } = props;
    const { asyncError, error } = fieldState;
    const errorMessage = error || asyncError;
    const className = errorMessage
        ? examineClasses('root_error')
        : examineClasses('root');

    return (
        <span className={'message-root'}>
            <p className={className}>{errorMessage || children}</p>
        </span>
    );
};

Message.propTypes = {
    children: node,
    classes: shape({
        root: string,
        root_error: string
    }),
    fieldState: shape({
        asyncError: string,
        error: string
    })
};
