import React, { useEffect, useState } from 'react';
import './indicator.less';

const LoadingIndicator = ({ width = 157, dotOffset = 20, dotWidth = 6}) => {
    const [dotStyles, setDotStyles] = useState([]);
    const dotCount = 4;

    useEffect(() => {
        const totalDotsWidth = dotOffset * (dotCount - 1);
        const startPosition = (width - totalDotsWidth) / 2;
        const colors = ['#002739', '#667D88', '#99A9B0', '#CCD4D7'];
        const delayStep = 0.1675;
        const calculatedDotStyles = [];

        for (let i = 0; i < dotCount; i++) {
            calculatedDotStyles.push({
                left: `${startPosition + i * dotOffset - dotWidth / 2}px`,
                background: colors[i],
                animationDelay: `${-(delayStep * (dotCount - i))}s`
            });
        }

        setDotStyles(calculatedDotStyles);
    }, [width]);

    return (
        <div className="loading-container">
            <div className="loading-spinner">
                <div className="loader-dots">
                    {dotStyles.map((style, index) => (
                        <div key={index} style={style}></div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LoadingIndicator;
