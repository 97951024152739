import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import GET_STORE_CONFIG from './../Queries/getStoreConfig.graphql';

export const useStoreConfig = props => {
    const [isOrderGiftMessageAllow, setOrderGiftMessageAllow] = useState(null);
    const { loading, error } = useQuery(GET_STORE_CONFIG, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (!!data.storeConfig) {
                setOrderGiftMessageAllow(
                    data.storeConfig.sales_gift_options_allow_order
                );
            }
        }
    });

    return {
        isOrderGiftMessageAllow: isOrderGiftMessageAllow
    };
};
