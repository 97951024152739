import React, { useReducer, useCallback } from 'react';
const initialState = {
    items: [],
    filters: {},
    count: null,
    page: 1,
    sort: {},
    loading: false,
    filterCount: [],
    availableFilters: {}
};
export const PLPManagerStore = React.createContext(initialState);
//Action Types
const LOAD_PRODUCTS = 'LOAD_PRODUCTS';
const SET_PRODUCTS = 'SET_PRODUCTS';
const ADD_FILTER = 'ADD_FILTER';
const REMOVE_FILTER = 'REMOVE_FILTER';
const SET_PAGE = 'SET_PAGE';
const LOADING_PAGE = 'LOADING_PAGE';
const SORT = 'SORT';

const reducer = (state, action) => {
    const {
        items,
        filters,
        count,
        page,
        loading,
        filterCount,
        sort,
        availableFilters
    } = action.payload;

    switch (action.type) {
        case LOAD_PRODUCTS:
            return {
                ...state,
                items: [...state.items, ...items],
                filters: { ...state.filters, ...filters },
                availableFilters: availableFilters,
                count: count || count === 0 ? count : state.count
            };
        case SET_PRODUCTS:
            return {
                ...state,
                items: items,
                count: count || count === 0 ? count : state.count,
                availableFilters: availableFilters,
                filterCount: []
            };
        case ADD_FILTER:
            return {
                ...state,
                items: items,
                filters: { ...state.filters, ...filters },
                count: count || count === 0 ? count : state.count,
                availableFilters: availableFilters,
                filterCount: filterCount ? filterCount : []
            };
        case REMOVE_FILTER:
            return {
                ...state,
                items: items,
                filters: { ...state.filters, ...filters },
                count: count || count === 0 ? count : state.count,
                availableFilters: availableFilters,
                filterCount: filterCount ? filterCount : []
            };
        case SET_PAGE:
            return {
                ...state,
                ...{ page: page }
            };
        case LOADING_PAGE:
            return {
                ...state,
                loading: loading
            };
        case SORT:
            return {
                ...state,
                items: items,
                sort: sort
            };
        default:
            return state;
    }
};

const intialTitleState = {
    primary: '',
    secondary: '',
    tertiary: ''
};

export const PLPManager = ({ children }) => {
    const [plpState, dispatch] = useReducer(reducer, initialState);

    const addProducts = useCallback(
        payload => {
            dispatch({ payload, type: LOAD_PRODUCTS });
        },
        [dispatch]
    );
    const setProducts = useCallback(
        payload => {
            dispatch({ payload, type: SET_PRODUCTS });
        },
        [dispatch]
    );
    const addFilter = useCallback(
        payload => {
            dispatch({ payload, type: ADD_FILTER });
        },
        [dispatch]
    );
    const removeFilter = useCallback(
        payload => {
            dispatch({ payload, type: REMOVE_FILTER });
        },
        [dispatch]
    );
    const setPage = useCallback(
        payload => {
            dispatch({ payload, type: SET_PAGE });
        },
        [dispatch]
    );
    const setLoading = useCallback(
        payload => {
            dispatch({ payload, type: LOADING_PAGE });
        },
        [dispatch]
    );
    const applySort = useCallback(
        payload => {
            dispatch({ payload, type: SORT });
        },
        [dispatch]
    );

    return (
        <PLPManagerStore.Provider
            value={{
                plpState,
                addProducts,
                addFilter,
                removeFilter,
                setPage,
                setLoading,
                applySort,
                setProducts
            }}
        >
            {children}
        </PLPManagerStore.Provider>
    );
};

export const usePLPManager = () => React.useContext(PLPManagerStore);
