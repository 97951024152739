import React from 'react';
import PropTypes from 'prop-types';

const ShoppingBag = props => {
    const {color, size, isAdded, ...otherProps } = props;

    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19 6.5H15.25C15.25 5.30653 14.7759 4.16193 13.932 3.31802C13.0881 2.47411 11.9435 2 10.75 2C9.55653 2 8.41193 2.47411 7.56802 3.31802C6.72411 4.16193 6.25 5.30653 6.25 6.5H2.5C2.10218 6.5 1.72064 6.65804 1.43934 6.93934C1.15804 7.22064 1 7.60218 1 8V19.25C1 19.6478 1.15804 20.0294 1.43934 20.3107C1.72064 20.592 2.10218 20.75 2.5 20.75H19C19.3978 20.75 19.7794 20.592 20.0607 20.3107C20.342 20.0294 20.5 19.6478 20.5 19.25V8C20.5 7.60218 20.342 7.22064 20.0607 6.93934C19.7794 6.65804 19.3978 6.5 19 6.5ZM10.75 3.5C11.5456 3.5 12.3087 3.81607 12.8713 4.37868C13.4339 4.94129 13.75 5.70435 13.75 6.5H7.75C7.75 5.70435 8.06607 4.94129 8.62868 4.37868C9.19129 3.81607 9.95435 3.5 10.75 3.5ZM19 19.25H2.5V8H6.25V9.5C6.25 9.69891 6.32902 9.88968 6.46967 10.0303C6.61032 10.171 6.80109 10.25 7 10.25C7.19891 10.25 7.38968 10.171 7.53033 10.0303C7.67098 9.88968 7.75 9.69891 7.75 9.5V8H13.75V9.5C13.75 9.69891 13.829 9.88968 13.9697 10.0303C14.1103 10.171 14.3011 10.25 14.5 10.25C14.6989 10.25 14.8897 10.171 15.0303 10.0303C15.171 9.88968 15.25 9.69891 15.25 9.5V8H19V19.25Z"
                fill="#fff"/>
            {isAdded && (
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M13.3522 12.1468C13.3988 12.1933 13.4357 12.2484 13.4609 12.3091C13.4861 12.3698 13.4991 12.4349 13.4991 12.5007C13.4991 12.5664 13.4861 12.6315 13.4609 12.6922C13.4357 12.7529 13.3988 12.8081 13.3522 12.8545L10.3536 15.8532C10.3072 15.8997 10.252 15.9366 10.1913 15.9618C10.1306 15.987 10.0655 16 9.99974 16C9.934 16 9.86891 15.987 9.80819 15.9618C9.74747 15.9366 9.69232 15.8997 9.6459 15.8532L8.14657 14.3538C8.1001 14.3074 8.06324 14.2522 8.03809 14.1915C8.01294 14.1308 8 14.0657 8 14C8 13.9343 8.01294 13.8692 8.03809 13.8085C8.06324 13.7478 8.1001 13.6926 8.14657 13.6462C8.24041 13.5523 8.36769 13.4996 8.50041 13.4996C8.56612 13.4996 8.63119 13.5125 8.69191 13.5377C8.75262 13.5628 8.80778 13.5997 8.85425 13.6462L9.99974 14.7926L12.6446 12.1468C12.691 12.1003 12.7461 12.0634 12.8069 12.0382C12.8676 12.013 12.9327 12 12.9984 12C13.0641 12 13.1292 12.013 13.1899 12.0382C13.2507 12.0634 13.3058 12.1003 13.3522 12.1468Z"
                      fill="#fff"/>
            )}
        </svg>
    );
};

ShoppingBag.propTypes = {
    color: PropTypes.string,
    isAdded: PropTypes.bool,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

ShoppingBag.defaultProps = {
    color: 'currentColor',
    size: '24',
    isAdded: false
};

export default ShoppingBag;
