import React from 'react';
import PropTypes from 'prop-types';
export const OptionsStore = React.createContext();

// This is a read-only data store for global config options.
// Pass in a project config object to provide it to all child components.
// Since it can NEVER update, it has no impact on performance.

export const OptionsProvider = props => {
    return (
        <OptionsStore.Provider value={props.config}>
            {props.children}
        </OptionsStore.Provider>
    );
};

OptionsProvider.propTypes = {
    config: PropTypes.object.isRequired
};
