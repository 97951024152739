import { useContext, useCallback } from 'react';
import { AuthStore } from '../AuthContext';

export const useAuth = () => {
    const { authState, dispatch } = useContext(AuthStore);

    const setIsAccountDrawerOpen = useCallback(
        showAccountDrawer => {
            dispatch({ showAccountDrawer, type: 'SHOW_ACCOUNT_DRAWER' });
        },
        [dispatch]
    );

    const toggleIsAccountDrawerOpen = useCallback(
        () => setIsAccountDrawerOpen(!authState.showAccountDrawer),
        [setIsAccountDrawerOpen, authState.showAccountDrawer]
    );

    const setIsLogin = useCallback(
        showLogin => dispatch({ type: 'TOGGLE_LOGIN_FORM', showLogin }),
        [dispatch]
    );

    const signOut = useCallback(
        () => dispatch({ type: 'SIGN_OUT', justLogout: true }),
        [dispatch]
    );

    const setAuthError = useCallback(
        error => dispatch({ type: 'SET_AUTH_ERROR', error }),
        [dispatch]
    );

    return {
        authState,
        setIsAccountDrawerOpen,
        toggleIsAccountDrawerOpen,
        setIsLogin,
        signOut,
        setAuthError
    };
};
