import React, {useContext, useEffect, useState, Fragment, useRef} from 'react';
import {string, object, array, func, bool} from 'prop-types';
import {SignedInDropDown, SlideSteps} from '@corratech/account-drawer';
import {
    isSignedIn,
    AuthStore,
    OverlayStore
} from '@corratech/context-provider';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import '@corratech/account-drawer/AccountForm/AccountForm.less';
import { useHistory } from 'react-router-dom';
import { Button, Dropdown } from 'react-bootstrap';
import { CollapsibleContainer } from '@corratech/collapsible-container';
import { Util } from '@magento/peregrine';
const { BrowserPersistence } = Util;
const storage = new BrowserPersistence();

export const AccountForm = props => {
    const [t] = useTranslation();
    const history = useHistory();

    const {
        css,
        className,
        myAccountLinksConfig,
        termsAndConditionsHandle,
        displayLabelSignedUser,
        displayLabelGuestUser,
        hideLinkAfterLoggedIn,
        isPushmenAccount,
        setPushMenuToggle,
        isTab,
        removeHeaderAccountLink,
        removeLoyaltyWelcomeButtons
    } = props;

    const {overlayDispatch} = useContext(OverlayStore);

    const {authState} = useContext(AuthStore);

    const accountFormClasses = classNames('account-form', className);

    const [isOpenDropdown, setIsOpenDropdown] = useState(false);

    const [isOpenSidebar, setIsOpenSidebar] = useState(false);

    const [hideForm, setHideForm] = useState(false);
    const [isLoyality, setIsLoyality] = useState(false);
    const [show, setShow] = useState(false);

    const toggleSidebarClick = event => {
        if (event) event.preventDefault();
        if (isPushmenAccount && typeof setPushMenuToggle == 'function') {
            setPushMenuToggle(false);
        }
        setIsOpenSidebar(!isOpenSidebar);
    };

    const navigateToAccount = () => {
        history.push(myAccountLinksConfig[0].link);
    };

    const toggleDropdownClick = event => {
        if (isPushmenAccount) {
            navigateToAccount();
            setIsOpenDropdown(false);
        }
        if (event) event.preventDefault();
    };

    /**
     * Prevent redirection to my account if
     * user is not logged in
     */
    useEffect(() => {
        history.block(({ pathname }) => {
            if (
                (pathname === '/my-account' ||
                    pathname === '/my-account/' ||
                    pathname === '/my-account/rewards') &&
                !isSignedIn()
            ) {
                setIsOpenSidebar(true);
                //scroll to top
                window.scrollTo(0, 0);
                return false;
            }
        });
    }, [history]);

    useEffect(() => {
        if (isSignedIn()) {
            setIsOpenDropdown(false);
            overlayDispatch({ type: 'HIDE' });

            if (hideLinkAfterLoggedIn) {
                setHideForm(true);
            }
            setIsOpenSidebar(false);
        }
    }, [isSignedIn(), authState.token]);

    useEffect(() => {
        isOpenSidebar && isTab
            ? document.body.classList.add('account-open')
            : document.body.classList.remove('account-open');
    }, [isOpenSidebar]);

    const AccountFormLink = () => {
        return (
            <>
                <span
                    className={`${'account-form-link'} ${
                        isOpenDropdown ? 'open' : ''
                    }`}
                >
                    {isTab ? (
                        <div
                            onClick={
                                isSignedIn()
                                    ? toggleDropdownClick
                                    : toggleSidebarClick
                            }
                        >
                            {isSignedIn()
                                ? t(displayLabelSignedUser)
                                : t(displayLabelGuestUser)}
                        </div>
                    ) : (
                        <Button
                            onClick={
                                isSignedIn()
                                    ? toggleDropdownClick
                                    : toggleSidebarClick
                            }
                            variant="link"
                            className="signin-btn"
                            aria-label="Open sign in form"
                        >
                            {isSignedIn()
                                ? t(displayLabelSignedUser)
                                : t(displayLabelGuestUser)}
                        </Button>
                    )}
                </span>
            </>
        );
    };

    if (hideForm) {
        return null;
    }

    return (
        <AuthStore.Consumer>
            {value =>
                isSignedIn() ? (
                    <Fragment>
                        <div
                            className={`${accountFormClasses} ${
                                isLoyality ? 'loyalty-welcome-gift' : ''
                            } `}
                            css={css}
                        >
                            <SlideSteps
                                setIsOpenSidebar={setIsOpenSidebar}
                                isOpenSidebar={isOpenSidebar}
                                termsAndConditionsHandle={
                                    termsAndConditionsHandle
                                }
                                isLoyality={isLoyality}
                                removeLoyaltyWelcomeButtons={
                                    removeLoyaltyWelcomeButtons
                                }
                            />
                        </div>
                        {!removeHeaderAccountLink &&
                            (!isTab ? (
                                <div
                                    className={
                                        accountFormClasses + ' signin qq'
                                    }
                                    css={css}
                                    onMouseEnter={() => setShow(true)}
                                    onMouseLeave={() => setShow(false)}
                                >
                                    <Dropdown className="account-form-link">
                                        <Dropdown.Toggle
                                            className={
                                                accountFormClasses + ' signin-btn btn btn-link'
                                            }
                                            variant="success"
                                            aria-label="Open my account dropdown"
                                        >
                                            {isSignedIn()
                                            ? t(displayLabelSignedUser)
                                            : t(displayLabelGuestUser)}
                                        </Dropdown.Toggle>
                                        <SignedInDropDown
                                            show={show}
                                            isTab={false}
                                            myAccountLinks={
                                                myAccountLinksConfig
                                            }
                                        />
                                    </Dropdown>
                                </div>
                            ) : (
                                <div className={'pm-account-signin'}>
                                    <CollapsibleContainer
                                        title={<AccountFormLink/>}
                                        children={
                                            <SignedInDropDown
                                                isTab={true}
                                                openDropdown={isOpenDropdown}
                                                myAccountLinks={
                                                    myAccountLinksConfig
                                                }
                                            />
                                        }
                                    />
                                </div>
                            ))}
                    </Fragment>
                ) : (
                    <div className={accountFormClasses} css={css}>
                        {!removeHeaderAccountLink && <AccountFormLink/>}
                        <SlideSteps
                            setIsOpenSidebar={setIsOpenSidebar}
                            isOpenSidebar={isOpenSidebar}
                            termsAndConditionsHandle={termsAndConditionsHandle}
                        />
                    </div>
                )
            }
        </AuthStore.Consumer>
    );
};

export default AccountForm;

AccountForm.defaultProps = {
    displayLabelSignedUser: 'Account',
    displayLabelGuestUser: 'Account',
    hideLinkAfterLoggedIn: false,
    isPushmenAccount: false,
    isTab: false,
    setPushMenuToggle: null,
    removeHeaderAccountLink: false
};

AccountForm.propTypes = {
    className: string,
    css: object,
    myAccountLinksConfig: array,
    termsAndConditionsHandle: func,
    displayLabelSignedUser: string,
    displayLabelGuestUser: string,
    hideLinkAfterLoggedIn: bool,
    removeHeaderAccountLink: bool
};