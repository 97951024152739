import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { default as braintreeDropin } from 'braintree-web-drop-in';

import { useMutation, useLazyQuery } from 'react-apollo';
import { useTranslation } from 'react-i18next';
import { Alert, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { AuthStore, CartStore, LoaderStore } from '@corratech/context-provider';
import createTokenQuery from './createBraintreeClientToken.graphql';
import setBraintreePaymentMethod from './setBraintreePaymentMethod.graphql';
import setBraintreeApplepayMethod from 'src/_STORES_/us/node_modules/@corratech/checkout/PaymentMethods/BraintreePayment/setBraintreeApplepayMethod.graphql';
import { Checkbox } from '@corratech/form-components';
import { AuthBillingAddressForm } from './AuthBillingAddressForm';
import { UnAuthBillingAddressForm } from './UnAuthBillingAddressForm';
import { CheckoutAgreements } from '../../CheckoutAgreements/CheckoutAgreements';
import placeOrderMutation from '../../graphql/placeOrder.graphql';
import setBillingAddressOnCart from '../../graphql/setBillingAddressOnCart.graphql';
import NewsletterCheckbox from '../../NewsletterCheckbox/NewsletterCheckbox';
import { Info } from 'react-feather';
import { isAddressValid } from '../../util/isAddressValid';
import { RenderAppliedCredits } from '../../RenderAppliedCredits';
import {
    useSetNotification,
    useGlobalOptions
} from '@corratech/context-provider';
import { PrivacyPolicy, PaymentFailure } from 'ModulesPath/Checkout';
import OGSubscriptionCheckBox from 'ModulesPath/OGSubscriptionCheckBox';
import { deepValue } from '@corratech/tag-manager';
import { isOrderGroovePresent, orderGrooveOptins } from 'UtilPath/orderGroove';
import { useStoreConfig } from 'ModulesPath/Checkout/useStoreConfig';
import { useReCaptchaStoreConfig } from 'ModulesPath/Checkout/useReCaptchaStoreConfig';
import { ReCaptcha } from 'react-recaptcha-v3';
import loadReCaptcha from '@corratech/google-recaptcha-v3/src/loadReCaptcha';
import { Error as ErrorIcon } from 'ModulesPath/Icons';
import PaymentLoaderWrapper from '@corratech/checkout/PaymentLoaderWrapper/PaymentLoaderWrapper';
import getCartQuery from '@corratech/checkout/graphql/cartQuery.graphql';

const BraintreePayment = props => {
    const {
        dataLayerAction,
        isPaymentFailureError,
        setPaymentFailureError,
        invalidShippingAddress,
        setShowPaymentForm,
        setIsSubscriptionPaymentError,
        setInvalidShippingAddress
    } = props;
    // Check for braintree errors
    const [isShowingEmptyError, setShowingEmptyError] = useState(false);
    const [isEmailError, setEmailError] = useState(false);

    // Store reference to the dropinInstance - don't want this to disappear on every state change
    const [dropinInstance, setDropinInstance] = useState(null);

    const [t] = useTranslation();
    const options = useGlobalOptions();
    let displayName = 'Apple Pay Merchant';
    let applepayMethod = '';
    let setBraintreeApplepayPayment = '';

    const [threeDSecureErrorMsg, setThreeDSecureErrorMsg] = useState(null);
    const isThreeDSecureEnabled =
        options.storeConfig && options.storeConfig.verify_bt_3dsecure_enabled
            ? options.storeConfig.verify_bt_3dsecure_enabled
            : true;

    const { isVerify3DSecureEnable, isAlwaysRequest3DS } = useStoreConfig();

    const isChallengeRequested =
        isVerify3DSecureEnable && isAlwaysRequest3DS
            ? isAlwaysRequest3DS
            : false;

    const { setNotification } = useSetNotification();

    const [buttonState, setButtonState] = useState({
        text: t('Place My Order'),
        disabled: false
    });

    const isPaymentMethodAvailable = methodCode => {
        return (
            cartState.cart &&
            cartState.cart.available_payment_methods &&
            cartState.cart.available_payment_methods.findIndex(
                x => x.code === methodCode
            ) > -1
        );
    };

    const { cartState, dispatch } = useContext(CartStore);
    const { authState } = useContext(AuthStore);

    // OgSubscription
    const [ogSubscribeChecked, setOGSubscribeChecked] = useState(false);
    const isOGProductPresent = isOrderGroovePresent(cartState);
    const OGProducts = orderGrooveOptins();
    const OGProductIds = OGProducts?.map(ogProduct => ogProduct.product);
    const isShouldSavedCardDisabled = OGProductIds.length > 0;

    //ReCaptcha
    const { actionV3PlaceOrder } = useReCaptchaStoreConfig();
    const [captchaInvalidMsg, setCaptchaInvalidMsg] = useState(null);
    const [reCaptchaToken, setReCaptchaToken] = useState(null);

    let recaptchaV3Publickey = '';
    let isV3PlaceOrderCaptchaEnable = false;

    if (options.storeConfig && options.storeConfig.recaptcha_v3_public_key) {
        recaptchaV3Publickey = options.storeConfig.recaptcha_v3_public_key;
    }

    if (actionV3PlaceOrder && actionV3PlaceOrder === 'recaptcha_v3') {
        isV3PlaceOrderCaptchaEnable = true;
    }

    const verifyReCaptchaTokenCallback = token => {
        setReCaptchaToken(token);
        setInterval(function() {
            resetReCaptchaToken();
        }, 100 * 1000);
    };

    // Determines whether or not we should save card for Auth Users
    const [shouldSaveCard, setShouldSaveCard] = useState(
        !!authState.token && authState.token !== ''
    );

    // Mutation to set braintree payment info on the cart
    const [setBraintreePayment] = useMutation(setBraintreePaymentMethod, {
        variables: {
            cartId: cartState.cartId
        }
    });

    if (isPaymentMethodAvailable('braintree_applepay') && !isOGProductPresent) {
        applepayMethod = 'braintree_applepay';
        [setBraintreeApplepayPayment] = useMutation(
            setBraintreeApplepayMethod,
            {
                variables: {
                    cartId: cartState.cartId
                }
            }
        );
    }

    const [cartQuery] = useLazyQuery(getCartQuery, {
        fetchPolicy: 'no-cache',
        variables: {
            cartId: cartState.cartId,
            isSignedIn: !!cartState.cart.authenticated
        },
        onCompleted: res => {
            dispatch({
                type: 'SET_CART',
                cart: res.cart
            });
        }
    });

    const setShouldShowRegisterForm = shouldShowRegisterForm => {
        setShowPaymentForm(false);
        setIsSubscriptionPaymentError(true);
        dispatch({ type: 'SHOW_REGISTER_FORM', shouldShowRegisterForm });
    };

    // Mutation to placeorder
    const [placeOrder] = useMutation(placeOrderMutation, {
        variables: {
            cartId: cartState.cartId,
            OGProductIds: OGProductIds.toString(),
            ogOptions: OGProducts.map(ogProduct => ({
                productId: ogProduct.product,
                ...ogProduct.tracking_override
            })),
            reCaptchaToken: reCaptchaToken
        },
        onCompleted: res => {
            if (!!dataLayerAction) {
                dataLayerAction({
                    type: 'PLACE_ORDER',
                    data: {
                        ...cartState,
                        actionField: res
                    }
                });
            }
            dispatch({
                type: 'PLACED_ORDER',
                placedOrder: res
            });
        },
        onError: error => {
            console.error(error);
            setPaymentFailureError(true);
            cartQuery();
            let reCaptchaFailure = false;
            let captchaInvalidMsg = null;

            if (error?.graphQLErrors) {
                for (var idx = 0; idx < error.graphQLErrors.length; idx++) {
                    if (
                        error.graphQLErrors[idx]?.extensions?.category ===
                        'graphql-recaptcha'
                    ) {
                        captchaInvalidMsg = error.graphQLErrors[idx]?.message;
                        reCaptchaFailure = true;
                    }

                    let message = error.graphQLErrors[idx]?.message;
                    if (message.includes('three_d_secure')) {
                        console.error(message);
                        setThreeDSecureErrorMsg(message);
                        setPaymentFailureError(false);
                        resetPaymentProcess();
                    }
                }
            }

            if (!reCaptchaFailure) {
                resetReCaptchaToken();
            }

            if (captchaInvalidMsg) {
                if (captchaInvalidMsg === 'You are required to login/register to subscribe to any products.') {
                    setShouldShowRegisterForm(true);
                }
                setCaptchaInvalidMsg(captchaInvalidMsg);
            }
            setButtonState({
                text: t('Place My Order'),
                disabled: false
            });
        }
    });

    const resetReCaptchaToken = () => {
        if (window.grecaptcha !== undefined) {
            window.grecaptcha
                .execute(recaptchaV3Publickey, { action: 'place_order' })
                .then(function(token) {
                    setReCaptchaToken(token);
                });
        }
    };

    // Will use this to set Billing address if it is same as shipping address
    const [setBillingAddress, { loading: billingAddressLoading }] = useMutation(
        setBillingAddressOnCart,
        {
            variables: {
                cartId: cartState.cartId
            },
            onCompleted: res => {
                dispatch({
                    type: 'SET_CART',
                    cart: res.setBillingAddressOnCart.cart
                });
            }
        }
    );

    if (options.storeConfig && options.storeConfig.store_information_name) {
        displayName = options.storeConfig.store_information_name;
    }

    //Only ASCII-printable characters is accepted
    const removeNonASCII = str => {
        if (str === null) str = '';
        else str = str.toString();
        return str.replace(/[^\x20-\x7E]/g, '');
    };

    const applepay = {
        displayName: displayName,
        paymentRequest: {
            total: {
                label: displayName,
                type: 'final',
                amount: cartState.cart.prices.grand_total.value
            }
        }
    };

    // Create the Dropin instance
    const [getBraintreeToken, { data: clientTokenData }] = useMutation(
        createTokenQuery
    );
    const billingAddress = deepValue(
        cartState,
        ['cart', 'billing_address'],
        {}
    );
    const threeDSecureParameters = {
        amount: cartState.cart.prices.grand_total.value,
        email: cartState.cart.email,
        challengeRequested: isChallengeRequested,
        billingAddress: {
            givenName: removeNonASCII(
                deepValue(billingAddress, ['firstname'], '')
            ),
            surname: removeNonASCII(
                deepValue(billingAddress, ['lastname'], '')
            ),
            phoneNumber: deepValue(billingAddress, ['telephone'], ''),
            streetAddress:
                deepValue(billingAddress, ['street', '0'], '') +
                ' ' +
                deepValue(billingAddress, ['street', '1'], ''),
            locality: deepValue(billingAddress, ['city'], ''),
            //region: deepValue(billingAddress, ['region', 'code'], ''),
            postalCode: deepValue(billingAddress, ['postcode'], ''),
            countryCodeAlpha2: deepValue(
                billingAddress,
                ['country', 'code'],
                'UK'
            )
        }
    };

    useEffect(() => {
        (async () => {
            if (!clientTokenData) {
                await getBraintreeToken();
            } else {
                createNewDropinInstance(
                    clientTokenData.createBraintreeClientToken
                );
            }
        })();
    }, [clientTokenData, cartState.cart.prices.grand_total.value]);

    const createNewDropinInstance = async clientToken => {
        try {
            let createBraintreeParams = {
                authorization: clientToken,
                container: '#braintree-dropin-container',
                threeDSecure: isThreeDSecureEnabled,
                card: {
                    overrides: {
                        fields: {
                            number: {
                                maskInput: {
                                    // Only show the last four digits of the credit card number after focus exits this field.
                                    showLastFour: true
                                }
                            }
                        }
                    }
                }
            };

            if (isPaymentMethodAvailable(applepayMethod) && !isOGProductPresent) {
                createBraintreeParams.applePay = applepay;
            }

            let dropinContainer = document.getElementById(
                'braintree-dropin-container'
            );
            dropinContainer.innerHTML = '';

            const newInstance = await braintreeDropin.create(
                createBraintreeParams
            );
            setDropinInstance(newInstance);
        } catch (error) {
            console.error('Error creating new drop-in instance: ', error);
        }
    };

    const resetPaymentProcess = async () => {
        if (dropinInstance) {
            try {
                await dropinInstance.teardown();
                setDropinInstance(null);
            } catch (error) {
                console.error(
                    'Error tearing down the drop-in instance: ',
                    error
                );
            }
        }

        try {
            if (!clientTokenData) {
                await getBraintreeToken(); // Эта функция должна обновить `clientTokenData`.
            } else {
                await createNewDropinInstance(
                    clientTokenData.createBraintreeClientToken
                );
            }
        } catch (error) {
            console.error('Error creating new drop-in instance: ', error);
        }
    };

    const checkThreeDSecureError = error => {
        if (
            error &&
            error.code &&
            error.code == 'THREEDS_LOOKUP_VALIDATION_ERROR'
        ) {
            if (
                error.details &&
                error.details.originalError &&
                error.details.originalError.details &&
                error.details.originalError.details.originalError &&
                error.details.originalError.details.originalError.error &&
                error.details.originalError.details.originalError.error.message
            ) {
                let errorMessage =
                    error.details.originalError.details.originalError.error
                        .message;
                if (
                    errorMessage === 'Billing line1 format is invalid.' &&
                    threeDSecureParameters.billingAddress.streetAddress.length >
                        50
                ) {
                    errorMessage = t(
                        'Billing line1 must be string and less than 50 characters. Please update the address and try again.'
                    );
                }
                setThreeDSecureErrorMsg(errorMessage);
                resetPaymentProcess();
            }
        }
    };

    const datalayerCheckoutOption = () => {
        dataLayerAction({
            type: 'CHECKOUT_OPTION',
            data: {
                step: 2,
                option: 'Credit card'
            }
        });
    };

    const handlePlaceOrder = () => {
        // if the user wants to place order with the billing address the same as the shipping address
        // otherwise he/she submitted a billing address
        if (cartState.cart.isBillingSameAsShipping) {
            setBillingAddress({
                variables: {
                    cartId: cartState.cartId,
                    ...cartState.cart.shipping_addresses[0],
                    region: cartState.cart.shipping_addresses[0].region.code,
                    countryCode:
                        cartState.cart.shipping_addresses[0].country.code
                }
            })
                .then(res => {
                    if (res) {
                        datalayerCheckoutOption();
                        placeOrder();
                    }
                })
                .catch(e => {
                    console.log('error in setBillingAddress', e);
                    setButtonState({
                        text: t('Place My Order'),
                        disabled: false
                    });
                });
        } else {
            datalayerCheckoutOption();
            placeOrder();
        }
    };

    useEffect(() => {
        if (cartState.cart.email) setEmailError(false);
    }, [cartState.cart]);

    return (
        <div>
            <div>
                {threeDSecureErrorMsg && (
                    <Alert variant="danger">
                        <ErrorIcon size={9} color={'#B70020'} />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: threeDSecureErrorMsg
                            }}
                        />
                    </Alert>
                )}
            </div>
            <div id="braintree-dropin-container" data-cs-mask/>
            {isV3PlaceOrderCaptchaEnable && loadReCaptcha(recaptchaV3Publickey)}
            {!!authState.token && authState.token !== '' ? (
                <div className={'should-save-credit-card'}>
                    <Checkbox
                        disabled={isShouldSavedCardDisabled}
                        field={'should-save-card'}
                        fieldState={{ value: shouldSaveCard }}
                        onChange={() => {
                            if (!isShouldSavedCardDisabled) {
                                setShouldSaveCard(!shouldSaveCard);
                            }
                        }}
                        label={t('Save this card for future purchases.')}
                    />
                    <OverlayTrigger
                        placement="top-start"
                        overlay={props => (
                            <Tooltip
                                {...props}
                                id={`tooltip-save-cc`}
                                show={props.show.toString()}
                            >
                                {t(
                                    'We store your payment information securely via SSL.'
                                )}
                            </Tooltip>
                        )}
                    >
                        <Button variant="success" className="tooltip-btn"><Info size={16} /></Button>
                    </OverlayTrigger>
                </div>
            ) : null}
            {authState.token && authState.token !== '' ? (
                <AuthBillingAddressForm
                    invalidShippingAddress={invalidShippingAddress}
                    setInvalidShippingAddress={setInvalidShippingAddress}
                />
            ) : (
                <UnAuthBillingAddressForm
                    invalidShippingAddress={invalidShippingAddress}
                    setInvalidShippingAddress={setInvalidShippingAddress}
                />
            )}

            <RenderAppliedCredits />
            {isEmailError && (
                <div className={'payment-error message-error'}>
                    {t(
                        'Guest email for cart is missing. Please enter a valid email address.'
                    )}
                </div>
            )}
            {isPaymentFailureError && <PaymentFailure />}
            <div>
                {isV3PlaceOrderCaptchaEnable && captchaInvalidMsg && (
                    <Alert variant="danger">
                        <ErrorIcon size={9} color={'#B70020'} />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: captchaInvalidMsg
                            }}
                        />
                    </Alert>
                )}
            </div>
            {isShowingEmptyError && (
                <div style={{ color: '#d10000', marginBottom: '10px' }}>
                    {t('Please enter a valid credit card in the form above.')}
                </div>
            )}
            <NewsletterCheckbox />
            {isOGProductPresent && (
                <OGSubscriptionCheckBox
                    ogSubscribeChecked={ogSubscribeChecked}
                    setOGSubscribeChecked={setOGSubscribeChecked}
                    setShouldSaveCard={setShouldSaveCard}
                    shouldSaveCard={shouldSaveCard}
                />
            )}
            <PrivacyPolicy privacyPolicyLink={'/privacy-policy'} />
            <div>
                {isV3PlaceOrderCaptchaEnable && (
                    <ReCaptcha
                        action="place_order"
                        sitekey={recaptchaV3Publickey}
                        verifyCallback={verifyReCaptchaTokenCallback}
                    />
                )}
            </div>
            <Button
                variant="secondary"
                className={'submit-braintree-method'}
                disabled={
                    buttonState.disabled ||
                    (!cartState.cart.isBillingSameAsShipping &&
                        !isAddressValid(cartState.cart.billing_address)) ||
                    (isOGProductPresent && !ogSubscribeChecked)
                }
                onClick={async () => {
                    setPaymentFailureError(false);
                    if (cartState.cart.is_virtual && !cartState.cart.email) {
                        setEmailError(true);
                        return false;
                    }
                    setButtonState({
                        text: t('Placing order...'),
                        disabled: true
                    });
                    try {
                        if (
                            dropinInstance &&
                            dropinInstance.isPaymentMethodRequestable()
                        ) {
                            setShowingEmptyError(false);
                            const paymentNonce = await (isThreeDSecureEnabled &&
                            threeDSecureParameters
                                ? dropinInstance.requestPaymentMethod({
                                      threeDSecure: threeDSecureParameters
                                  })
                                : dropinInstance.requestPaymentMethod());
                            if (paymentNonce.type === 'ApplePayCard') {
                                setBraintreeApplepayPayment({
                                    variables: {
                                        braintreeNonce: paymentNonce.nonce
                                    }
                                }).then(res => {
                                    if (
                                        res.data.setPaymentMethodOnCart.cart
                                            .selected_payment_method.code ===
                                        applepayMethod
                                    ) {
                                        handlePlaceOrder();
                                    }
                                });
                            } else {
                                setBraintreePayment({
                                    variables: {
                                        braintreeNonce: paymentNonce.nonce,
                                        storeInVault: shouldSaveCard
                                    }
                                }).then(res => {
                                    if (
                                        res.data.setPaymentMethodOnCart.cart
                                            .selected_payment_method.code ===
                                        'braintree'
                                    ) {
                                        handlePlaceOrder();
                                    }
                                });
                            }
                        } else {
                            resetReCaptchaToken();
                            setShowingEmptyError(true);
                            setButtonState({
                                text: t('Place My Order'),
                                disabled: false
                            });
                        }
                    } catch (e) {
                        resetReCaptchaToken();
                        checkThreeDSecureError(e);

                        // An error occurred and we have no stored data.
                        // BrainTree will update the UI with error messaging,
                        // but signal that there was an error.
                        console.error(`Invalid Payment Details. \n${e}`);
                        setButtonState({
                            text: t('Place My Order'),
                            disabled: false
                        });
                    }
                }}
            >
                {buttonState.text}
            </Button>
            <CheckoutAgreements prompt={false} />
            {buttonState.disabled && (
                <PaymentLoaderWrapper/>
            )}
        </div>
    );
};

BraintreePayment.propTypes = {
    storeInVault: PropTypes.bool
};

export default BraintreePayment;
