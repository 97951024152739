import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import './SkipToContent.less';

export const SkipToContent = props => {
    const [t] = useTranslation();
    const { id, targetDomId, text } = props;

    return (
        <a id={id} className={'skip-to-content'} href={`#${targetDomId}`}>
            {t(text)}
        </a>
    );
};

SkipToContent.propTypes = {
    id: PropTypes.string,
    text: PropTypes.string,
    targetDomId: PropTypes.string
};

SkipToContent.defaultProps = {
    id: 'skip-to-content',
    text: 'Skip To Content',
    targetDomId: 'contentarea'
};
