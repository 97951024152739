import React from 'react';
import { staticColors } from './staticColors';
import { func, string } from 'prop-types';
import './ColorSwatch.less';

export const ColorSwatch = ({ color, round, title, handleClick, classes }) => (
    <button
        onClick={handleClick}
        title={title}
        style={{ backgroundColor: staticColors(color.toLowerCase()) }}
        className={
            (round ? 'round color-swatch' : 'color-swatch') + ' ' + classes
        }
    />
);

ColorSwatch.propTypes = {
    color: string.isRequired,
    round: string,
    title: string,
    handleClick: func.isRequired,
    classes: string
};
