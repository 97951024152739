import React, { useContext, useEffect, useState } from 'react';
import { string, object, array, func, bool } from 'prop-types';
import { SignedInDropDown, SlideSteps } from '../';
import {
    isSignedIn,
    AuthStore,
    OverlayStore
} from '@corratech/context-provider';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import './AccountForm.less';
import { Button } from 'react-bootstrap';

export const AccountForm = props => {
    const [t] = useTranslation();

    const {
        css,
        className,
        myAccountLinksConfig,
        termsAndConditionsHandle,
        displayLabelSignedUser,
        displayLabelGuestUser,
        hideLinkAfterLoggedIn,
        hideSigninLink
    } = props;

    const { overlayDispatch } = useContext(OverlayStore);

    const { authState } = useContext(AuthStore);

    const accountFormClasses = classNames('account-form', className);

    const [isOpenDropdown, setIsOpenDropdown] = useState(false);

    const [isOpenSidebar, setIsOpenSidebar] = useState(false);

    const [hideForm, setHideForm] = useState(false);

    const toggleSidebarClick = event => {
        if (event) event.preventDefault();
        setIsOpenSidebar(!isOpenSidebar);
    };

    const toggleDropdownClick = event => {
        if (event) event.preventDefault();
    };

    useEffect(() => {
        if (isSignedIn()) {
            overlayDispatch({ type: 'HIDE' });

            if (hideLinkAfterLoggedIn) {
                setHideForm(true);
            }
        }
    }, [isSignedIn(), authState.token]);

    const AccountFormLink = () => {
        React.useEffect(() => {
            authState.token
                ? setIsOpenSidebar(false)
                : setIsOpenDropdown(false);
        }, []);

        return (
            <>
                {!hideSigninLink && (
                    <span className={'account-form-link'}>
                        <Button
                            onClick={
                                isSignedIn()
                                    ? toggleDropdownClick
                                    : toggleSidebarClick
                            }
                            variant="link"
                        >
                            {isSignedIn()
                                ? t(displayLabelSignedUser)
                                : t(displayLabelGuestUser)}
                        </Button>
                    </span>
                )}
            </>
        );
    };

    if (hideForm) {
        return null;
    }

    return (
        <AuthStore.Consumer>
            {value =>
                isSignedIn() ? (
                    <div
                        className={accountFormClasses + ' signin'}
                        css={css}
                        onMouseOver={() => setIsOpenDropdown(true)}
                        onFocus={() => setIsOpenDropdown(true)}
                        onMouseLeave={() => setIsOpenDropdown(false)}
                        onBlur={() => setIsOpenDropdown(false)}
                    >
                        <AccountFormLink />
                        <SignedInDropDown
                            openDropdown={isOpenDropdown}
                            myAccountLinks={myAccountLinksConfig}
                        />
                    </div>
                ) : (
                    <div className={accountFormClasses} css={css}>
                        <AccountFormLink />
                        <SlideSteps
                            setIsOpenSidebar={setIsOpenSidebar}
                            isOpenSidebar={isOpenSidebar}
                            termsAndConditionsHandle={termsAndConditionsHandle}
                        />
                    </div>
                )
            }
        </AuthStore.Consumer>
    );
};

export default AccountForm;

AccountForm.defaultProps = {
    displayLabelSignedUser: 'Account',
    displayLabelGuestUser: 'Account',
    hideLinkAfterLoggedIn: false,
    hideSigninLink: false
};

AccountForm.propTypes = {
    className: string,
    css: object,
    myAccountLinksConfig: array,
    termsAndConditionsHandle: func,
    displayLabelSignedUser: string,
    displayLabelGuestUser: string,
    hideLinkAfterLoggedIn: bool
};
