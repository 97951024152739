import React, { useContext } from 'react';
import { CartStore } from '@corratech/context-provider';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';

const mutation = gql`
    #To add product to cart
    mutation addSimpleProductsToCart {
        addSimpleProductsToCart(
            input: {
                cart_id: "10nOrPZSNCKVA2KHLiXidL4EIzjSZGQk"
                cart_items: [{ data: { quantity: 1, sku: "35223" } }]
            }
        ) {
            cart {
                items {
                    id
                    discount_tax_compensation_amount
                    product {
                        name
                        sku
                        stock_status
                        thumbnail {
                            label
                            url
                        }

                        price {
                            regularPrice {
                                amount {
                                    currency
                                    value
                                }
                            }
                        }
                        special_price
                        url_key
                        ... on ConfigurableProduct {
                            variants {
                                product {
                                    id
                                    sku
                                }
                            }
                        }
                    }
                    quantity
                }

                prices {
                    grand_total {
                        value
                        currency
                    }
                    applied_taxes {
                        label
                        amount {
                            value
                            currency
                        }
                    }
                    subtotal_including_tax {
                        value
                        currency
                    }
                    subtotal_excluding_tax {
                        value
                        currency
                    }
                    subtotal_with_discount_excluding_tax {
                        value
                        currency
                    }
                }
            }
        }
    }
`;

export const TestAddToCart = () => {
    const { cartState, dispatch } = useContext(CartStore);
    const [testAddToCart] = useMutation(mutation, {
        fetchPolicy: 'no-cache',
        //on successful completion:
        onCompleted: res => {
            //copy locally stored cart from context provider
            const updatedCart = cartState.cart;
            //update fields on the copied cart based on results of mutation's query
            updatedCart.items = res.addSimpleProductsToCart.cart.items;
            updatedCart.prices = res.addSimpleProductsToCart.cart.prices;
            //use the dispatch from cartstore to open the minicart and set the stored cart to the updated cart
            dispatch({
                type: 'SHOULD_OPEN_DRAWER',
                shouldOpenDrawer: true
            });
            dispatch({
                type: 'SET_CART',
                cart: updatedCart
            });
        }
    });

    return <button onClick={testAddToCart}>Test add to cart</button>;
};
