import React from 'react';
import { object } from 'prop-types';
import './RenderApolloError.less';

export const RenderApolloError = ({ error }) =>
    error.graphQLErrors
        ? error.graphQLErrors.map(({ message }, i) => (
              <span key={i} className="apollo-error">
                  {message}
              </span>
          ))
        : null;

RenderApolloError.propTypes = {
    error: object.isRequired
};
