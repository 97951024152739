import React from 'react';
import PropTypes from 'prop-types';

const SG = props => {
    const { color, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            height={size}
            width={size}
            {...otherProps}
        >
            <path
                fill="#F0F0F0"
                d="M256 0C114.6 0 0 114.6 0 256 0 114.6 114.6 0 256 0zM256 512c141.4 0 256-114.6 256-256H0c0 141.4 114.6 256 256 256zM512 256C512 114.6 397.4 0 256 0c141.4 0 256 114.6 256 256z"
            ></path>
            <path
                fill="#D80027"
                d="M512 256C512 114.6 397.4 0 256 0S0 114.6 0 256h512zM227.1 139.1l-14.5-10.5-14.5 10.5 5.5-17-14.5-10.5H207l5.5-17 5.5 17h18l-14.5 10.5 5.6 17zm-26.8 72.4c-43 0-77.9-34.9-77.9-77.9s34.9-77.9 77.9-77.9c5.7 0 11.3.6 16.7 1.8-35 7.6-61.2 38.8-61.2 76.1s26.2 68.5 61.2 76.1c-5.3 1.1-10.9 1.8-16.7 1.8zm43.5-22.3l-14.5-10.5-14.5 10.5 5.5-17-14.5-10.5h17.9l5.5-17 5.5 17h17.9l-14.5 10.5 5.7 17zm-2.3-83.5l5.5-17-14.5-10.5h17.9l5.5-17 5.5 17h17.9l-14.5 10.5 5.5 17L256 95.2l-14.5 10.5zm55.6 83.5l-14.5-10.5-14.5 10.5 5.5-17-14.5-10.5H277l5.5-17 5.5 17h17.9l-14.5 10.5 5.7 17zm16.7-50.1l-14.5-10.5-14.5 10.5 5.5-17-14.3-10.5h17.9l5.5-17 5.5 17h17.9l-14.5 10.5 5.5 17z"
            ></path>
            <path
                fill="#F0F0F0"
                d="M155.8 133.6c0-37.3 26.2-68.5 61.2-76.1-5.4-1.2-11-1.8-16.7-1.8-43 0-77.9 34.9-77.9 77.9s34.9 77.9 77.9 77.9c5.7 0 11.3-.6 16.7-1.8-35-7.7-61.2-38.8-61.2-76.1z"
            ></path>
            <path
                fill="#F0F0F0"
                d="M264.9 88.7L279.4 78.2 261.5 78.2 256 61.2 250.5 78.2 232.6 78.2 247.1 88.7 241.5 105.7 256 95.2 270.5 105.7z"
            ></path>
            <path
                fill="#F0F0F0"
                d="M218.2 111.6L212.6 94.6 207.1 111.6 189.2 111.6 203.7 122.1 198.2 139.1 212.6 128.6 227.1 139.1 221.6 122.1 236 111.6z"
            ></path>
            <path
                fill="#F0F0F0"
                d="M304.9 111.6L299.4 94.6 293.9 111.6 276 111.6 290.4 122.1 284.9 139.1 299.4 128.6 313.8 139.1 308.3 122.1 322.8 111.6z"
            ></path>
            <path
                fill="#F0F0F0"
                d="M288.2 161.7L282.7 144.7 277.2 161.7 259.3 161.7 273.7 172.2 268.2 189.2 282.7 178.7 297.1 189.2 291.6 172.2 306.1 161.7z"
            ></path>
            <path
                fill="#F0F0F0"
                d="M234.8 161.7L229.3 144.7 223.8 161.7 205.9 161.7 220.4 172.2 214.9 189.2 229.3 178.7 243.8 189.2 238.3 172.2 252.7 161.7z"
            ></path>
        </svg>
    );
};

SG.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

SG.defaultProps = {
    color: 'currentColor',
    size: '18'
};

export default SG;
