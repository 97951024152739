import React from 'react';

export const LoaderStore = React.createContext();

const DefaultLoader = () => 'Loading';

export const LoaderProvider = props => {
    return (
        <LoaderStore.Provider value={props.loadingIndicator || DefaultLoader}>
            {props.children}
        </LoaderStore.Provider>
    );
};
