import React from 'react';
import { string } from 'prop-types';

export const SweatIcon = props => {
    const { color, size, strokeWidth, ...otherProps } = props;

    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 47 47"
            {...otherProps}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g
                id="sweat-wrapper"
                stroke="none"
                strokeWidth={strokeWidth}
                fill="none"
                fillRule="evenodd"
            >
                <g
                    id="sweat-empty"
                    transform="translate(-697.000000, -189.000000)"
                    fill={color}
                    fillRule="nonzero"
                >
                    <g id="sweat" transform="translate(697.000000, 189.000000)">
                        <path
                            d="M15.5,23 C16.1859375,23 16.806875,22.7232812 17.2585938,22.2759375 C17.71625,
                            21.8229687 18,21.1946875 18,20.5 C18,19.119375 16.880625,18 15.5,18 C14.119375,
                            18 13,19.119375 13,20.5 C13,20.8248437 13.0639063,21.1342187 13.1767188,
                            21.4190625 C13.5432813,22.3445312 14.4442187,23 15.5,23 Z"
                            id="Shape"
                        ></path>
                        <path
                            d="M32.8232812,21.4190625 C32.9360937,21.134375 33,20.8248437 33,20.5 C33,
                            19.119375 31.880625,18 30.5,18 C29.119375,18 28,19.119375 28,20.5 C28,
                            21.1946875 28.28375,21.8229688 28.7414063,22.2760937 C29.193125,22.7232812 29.8140625,
                            23 30.5,23 C31.5557812,23 32.4567187,22.3445312 32.8232812,21.4190625 Z"
                            id="Shape"
                        ></path>
                        <rect
                            id="Rectangle-path"
                            x="18"
                            y="32"
                            width="10"
                            height="2"
                        ></rect>
                        <path
                            d="M47,12.0898019 C47,10.5770483 46.0563702,8.68559035 44.1151707,6.30745348 C42.7801597,
                            4.6719584 41.4622826,3.41754956 41.4070736,3.36499142 L40.5338829,2.53660522 L39.6608508,
                            3.36483263 C39.6205545,3.40294126 38.9155291,4.07476452 38.0225076,5.06384208 C34.020489,
                            1.89463371 28.966961,0 23.4793759,0 C10.5328635,-2.6619457e-15 0,10.5419566 0,23.5 C0,
                            36.4578846 10.5328635,47 23.4793759,47 C36.4258884,47 46.9587519,36.4578846 46.9587519,
                            23.5 C46.9587519,20.8619306 46.5219979,18.3237375 45.7173424,15.9544926 C46.5226325,
                            14.8753835 47,13.5372944 47,12.0898019 Z M44.4204067,23.5 C44.4204067,35.0570751 35.0263083,
                            44.4594251 23.4793759,44.4594251 C17.7274857,44.4594251 12.5100757,42.1259071 8.72175416,
                            38.355694 C7.20890041,36.8500858 5.92465638,35.1148732 4.92470701,33.2078542 C3.40169988,
                            30.3038183 2.53834521,27.0012297 2.53834521,23.5 C2.53834521,11.9429249 11.9324436,
                            2.54057487 23.4793759,2.54057487 C28.3488739,2.54057487 32.8355578,4.2127495 36.3962215,
                            7.01341572 C36.324196,7.10805214 36.2534396,7.20189462 36.1847456,7.2946256 C34.763431,
                            9.21625292 34.0674484,10.7926796 34.0674484,12.0896431 C34.0674484,15.6583569 36.9683011,
                            18.5617576 40.5338829,18.5617576 C41.3821661,18.5617576 42.1918982,18.3956675 42.9348401,
                            18.0973087 C43.1721754,18.0017196 43.4031648,17.8942215 43.6255873,17.7722739 C44.1432511,
                            19.5935486 44.4204067,21.5150171 44.4204067,23.5 Z M40.5338829,16.0211827 C38.3680398,
                            16.0211827 36.6057936,14.2575474 36.6057936,12.0896431 C36.6057936,10.7124927 38.6842224,
                            8.01297315 40.5343588,6.08007203 C42.3840192,8.01106772 44.4616548,10.7091582 44.4616548,
                            12.0896431 C44.4616548,14.2575474 42.6995673,16.0211827 40.5338829,16.0211827 Z"
                            id="Shape"
                        ></path>
                    </g>
                </g>
            </g>
        </svg>
    );
};

SweatIcon.propTypes = {
    color: string
};

SweatIcon.defaultProps = {
    color: '#000000',
    size: '47',
    strokeWidth: '1'
};
