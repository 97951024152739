import React from 'react';
import { string, number, oneOfType } from 'prop-types';

export const RemoveIcon = props => {
    const { color, size, ...otherProps } = props;
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...otherProps}
        >
            <line x1="18" y1="6" x2="6" y2="18" />
            <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
    );
};

RemoveIcon.propTypes = {
    color: string,
    size: oneOfType([string, number])
};

RemoveIcon.defaultProps = {
    color: 'currentColor',
    size: '24'
};
