/** @jsx jsx */
import React, { Fragment, useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import { Price } from '../Price';
import { useTranslation } from 'react-i18next';
import { FadeIn } from 'react-lazyload-fadein';
import classNames from 'classnames/bind';
import defaultClasses from './ProductItem.less';
import defaultClasses2 from '../ProductList/ProductList.less';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import projectConfig from 'SrcPath/project.config';
import addSimpleProductsToCart from 'ModulesPath/App/Queries/addSimpleProductsToCart.graphql';
import addConfigurableProductToCart from 'ModulesPath/App/Queries/addConfigurableProductToCart.graphql';
import addBundleProductsToCart from 'ModulesPath/App/Queries/addBundleProductsToCart.graphql';
import { Badge } from '../Badge';
import { useWindowSize } from '@magento/peregrine';
import BrowserPersistence from '@magento/peregrine/lib/util/simplePersistence';
import { ProductRating } from '../ProductRating';
import { PromoLabel } from '../Constructor/Promo/PromoLabel';

const persistence = new BrowserPersistence();
const routeCacheKey = 'urlResolver';
// TTL of one day
const ttl = 86400;

const style = classNames.bind(defaultClasses);
const categoryUrlSuffix = '.html';
const VariantSelector = React.lazy(() =>
    import('@corratech/variant-selector/src/index')
);
const AddToCart = React.lazy(() =>
    import('@corratech/add-to-cart/src/AddToCart')
);

const ProductTile = props => {
    const {
        product,
        imageParam,
        categoryName,
        fadeId,
        appearance,
        cssClasses
    } = props;
    const { url_key, id } = product;
    const [t] = useTranslation();
    const urlParam = `?auto=webp&optimize=high&format=jpg&width=${imageParam.width}`;
    const [sku, setSku] = useState(product.sku);
    const [price, setPrice] = useState(
        product.price_range.minimum_price.final_price.value
    );
    const [optionProduct, setOptionProduct] = useState(product);
    const [optionProductID, setOptionProductID] = useState(0);
    const [optionSelected, seOptionSelected] = useState('');
    const [originalProduct, setOriginalProduct] = useState(product);
    const [promoLabel, setPromoLabel] = useState(product.promo_label);
    const [stockStatus, setStockStatus] = useState(product.stock_status);
    const history = useHistory();
    const windowSize = useWindowSize();
    const isMobile = windowSize.innerWidth < projectConfig.viewport.mobile;
    const isGrid =
        appearance === 'grid' && cssClasses.includes('bestsellers-grid');

    const defaultOptionSelection = true;

    const isConfig = product => {
        return product.__typename == 'ConfigurableProduct';
    };

    const isGiftCard = product => {
        return product && product.__typename === 'GiftCardProduct';
    };

    const isBundleProduct = product => {
        return product && product.__typename === 'BundleProduct';
    };

    const isCustomisableBundleProduct = product.exploding_kit
        ? !Number(product.exploding_kit)
        : 1;

    const [image, setImage] = useState(product.small_image);
    const [altImage, setAltImage] = useState(product.hover_image);

    const ImageUrl = mainImage => {
        return mainImage.url ? `${mainImage.url}${urlParam}` : '';
    };

    let defaultOptions = [];
    let firstConfigOption = '';

    /**
     * create instock options
     */
    const getInstockOptions = () => {
        let { variants } = product;
        let availableOptions = [];
        variants.map(variant => {
            if (variant.product.stock_status == 'IN_STOCK') {
                if (variant.attributes && variant.attributes[0]) {
                    availableOptions.push({
                        code: variant.attributes[0].code,
                        value_index: variant.attributes[0].value_index,
                        label: variant.attributes[0].label
                    });
                }
            }
        });
        return availableOptions;
    };

    if (defaultOptionSelection && isConfig(product)) {
        const instockOptions = getInstockOptions();
        if (instockOptions) {
            let defaultOption = null;
            firstConfigOption = product.configurable_options[0];
            if (firstConfigOption.attribute_code) {
                //Default url added from admin
                if (
                    !defaultOption &&
                    product[firstConfigOption.attribute_code]
                ) {
                    defaultOption = instockOptions.find(
                        ({ value_index }) =>
                            value_index ===
                            product[firstConfigOption.attribute_code]
                    );
                }

                //First availale instock option is set as default
                if (!defaultOption) {
                    firstConfigOption.values.map((child, childKey) => {
                        if (!defaultOption) {
                            defaultOption = instockOptions.find(
                                ({ value_index }) =>
                                    value_index === child.value_index
                            );
                        }
                    });
                }
                if (defaultOption) {
                    defaultOptions.push({
                        attribute: defaultOption.code,
                        value_index: defaultOption.value_index,
                        label: defaultOption.label,
                        promo_label: defaultOption.promo_label
                    });
                }
            }
        }
    }
    let componentOOS = false;
    const bundleOptions = isBundleProduct(product)
        ? (() => {
              let defaultBundleOptions = [];
              let isDefaultSet;
              product &&
                  product.items &&
                  product.items.map(item => {
                      isDefaultSet = false;
                      if (item.required) {
                          item.options.map(
                              ({
                                  is_default,
                                  quantity,
                                  id,
                                  product: optionProduct
                              }) => {
                                  if (is_default) {
                                      if (
                                          optionProduct?.stock_status ==
                                          'OUT_OF_STOCK'
                                      ) {
                                          componentOOS = true;
                                      }
                                      defaultBundleOptions.push({
                                          id: item.option_id,
                                          quantity: quantity,
                                          value: [id.toString()]
                                      });
                                      isDefaultSet = true;
                                  }
                              }
                          );

                          if (!isDefaultSet && item.options[0]) {
                              if (
                                  item.options[0].product?.stock_status ==
                                  'OUT_OF_STOCK'
                              ) {
                                  componentOOS = true;
                              }
                              defaultBundleOptions.push({
                                  id: item.option_id,
                                  quantity: item.options[0].quantity,
                                  value: [item.options[0].id.toString()]
                              });
                          }
                      } else {
                          item.options.map(
                              ({
                                  is_default,
                                  quantity,
                                  id,
                                  product: opitonProduct
                              }) => {
                                  if (is_default) {
                                      if (
                                          optionProduct.stock_status ==
                                          'OUT_OF_STOCK'
                                      ) {
                                          componentOOS = true;
                                      }
                                      defaultBundleOptions.push({
                                          id: item.option_id,
                                          quantity: quantity,
                                          value: [id.toString()]
                                      });
                                  }
                              }
                          );
                      }
                  });
              return defaultBundleOptions;
          })()
        : {};

    useEffect(() => {
        if (product) {
            setOptionProduct(product);
            setImage(product.small_image);
            setAltImage(product.hover_image);
            setPromoLabel(product.promo_label);
        }
    }, [product]);

    const handleLinkClick = (url_key, categoryUrlSuffix) => {
        window.scrollTo(0, 0);
        const routes = persistence.getItem(routeCacheKey) || {};
        routes[`/${url_key}${categoryUrlSuffix}`] = {
            data: {
                urlResolver: {
                    type: 'PRODUCT',
                    id,
                    relative_url: `${url_key}${categoryUrlSuffix}`,
                    redirectCode: 0,
                    __typename: 'EntityUrl'
                }
            }
        };
        persistence.setItem(routeCacheKey, routes, ttl);
    };

    return (
        <Fragment>
            {product && (
                <div
                    className={style('productItemBlock')}
                    data-cnstrc-item-name={product.name}
                    data-cnstrc-item-id={product.sku}
                    data-cnstrc-item-variation-id={
                        product.sku !== optionProduct?.sku
                            ? optionProduct?.sku
                            : null
                    }
                    data-cnstrc-item-price={
                        optionProduct?.stock_status !== 'OUT_OF_STOCK'
                            ? price
                            : null
                    }
                >
                    <div className={style('productImageBlock')}>
                        {optionProduct.product_badge && (
                            <Badge img={optionProduct.product_badge} />
                        )}
                        <Link
                            onClick={() => {
                                if (!isMobile) {
                                    window.scrollTo(0, 0);
                                }
                                handleLinkClick(url_key, categoryUrlSuffix);
                            }}
                            to={`/${product.url_key}${categoryUrlSuffix}${
                                optionSelected !== ''
                                    ? '?' + optionSelected
                                    : ''
                            }`}
                            className={style('productImg-link')}
                            aria-label={product.name}
                        >
                            <span className={style('productImageRatio')}>
                                {fadeId && (
                                    <FadeIn height={250} duration={50}>
                                        {onload => (
                                            <img
                                                src={ImageUrl(image)}
                                                alt={product.name}
                                                title={product.name}
                                                onLoad={onload}
                                                onMouseOver={e =>
                                                    !isMobile &&
                                                    altImage &&
                                                    altImage.length
                                                        ? (e.currentTarget.src = altImage)
                                                        : ''
                                                }
                                                onMouseOut={e =>
                                                    altImage && altImage.length
                                                        ? (e.currentTarget.src = ImageUrl(
                                                              image
                                                          ))
                                                        : ''
                                                }
                                                width="291"
                                                height="291"
                                                className={'productImg'}
                                                loading={'lazy'}
                                            />
                                        )}
                                    </FadeIn>
                                )}
                                {!fadeId && (
                                    <img
                                        src={ImageUrl(image)}
                                        alt={product.name}
                                        title={product.name}
                                        onLoad={onload}
                                        onMouseOver={e =>
                                            !isMobile &&
                                            altImage &&
                                            altImage.length
                                                ? (e.currentTarget.src = altImage)
                                                : ''
                                        }
                                        onMouseOut={e =>
                                            altImage && altImage.length
                                                ? (e.currentTarget.src = ImageUrl(
                                                      image
                                                  ))
                                                : ''
                                        }
                                        width="291"
                                        height="291"
                                        className={'productImg'}
                                        loading={'lazy'}
                                    />
                                )}
                            </span>
                        </Link>
                    </div>
                    <div className={style('product-details')}>
                        <h2 className={style('productName')}>
                            <Link
                                onClick={() =>
                                    handleLinkClick(url_key, categoryUrlSuffix)
                                }
                                to={`/${product.url_key}${categoryUrlSuffix}${
                                    optionSelected != ''
                                        ? '?' + optionSelected
                                        : ''
                                }`}
                                className={style('productName-link')}
                                title={product.name}
                            >
                                {product.name}
                                {product.subtitle && isGrid && (
                                    <span className={'subtitle'}>
                                        {product.subtitle}
                                    </span>
                                )}
                            </Link>
                        </h2>

                        {product.subtitle && !isGrid && (
                            <div className={'subtitle'}>{product.subtitle}</div>
                        )}

                        {!isGiftCard(product) && (
                            <Fragment>
                                <ProductRating
                                    rating={product?.bv_rating}
                                    review_count={product?.bv_review_count}
                                />

                                <div className={'productPrice'}>
                                    <Price product={optionProduct} inline />
                                </div>
                                {!isConfig(product) &&
                                    product.bottle_size_text != 'false' && (
                                        <div className={'product-size'}>
                                            {product.bottle_size_text}
                                        </div>
                                    )}
                            </Fragment>
                        )}
                        {promoLabel &&
                            promoLabel.map(labelObj => (
                                <PromoLabel
                                    label={labelObj.label}
                                    code={labelObj.code}
                                />
                            ))}
                        {isConfig(product) && !isGrid && (
                            <div className={'product-list-variants'}>
                                <Fragment>
                                    <VariantSelector
                                        product={product}
                                        optionSelectCallback={selectedOptions => {
                                            if (
                                                selectedOptions?.items?.[0] &&
                                                defaultOptions?.[0]
                                                    ?.value_index !==
                                                    selectedOptions.items[0]
                                                        .value_index
                                            ) {
                                                seOptionSelected(
                                                    `${selectedOptions.items[0].attribute}=${selectedOptions.items[0].value_index}`
                                                );
                                            } else {
                                                seOptionSelected('');
                                            }
                                        }}
                                        simpleProductCallback={simpleProduct => {
                                            if (simpleProduct) {
                                                setSku(simpleProduct.sku);
                                                setPrice(
                                                    simpleProduct?.price_range
                                                        ?.minimum_price
                                                        .final_price.value
                                                );
                                                setOptionProduct(simpleProduct);
                                                setStockStatus(
                                                    simpleProduct.stock_status
                                                );
                                                setOptionProductID(
                                                    simpleProduct.id
                                                );
                                                setImage(
                                                    simpleProduct.small_image
                                                );
                                                setAltImage(
                                                    simpleProduct.hover_image
                                                );
                                                setPromoLabel(
                                                    simpleProduct.promo_label
                                                );
                                            } else {
                                                setSku(originalProduct.sku);
                                                setOptionProduct(
                                                    originalProduct
                                                );
                                                setStockStatus(
                                                    originalProduct.stock_status
                                                );
                                                setImage(
                                                    originalProduct.small_image
                                                );
                                                setAltImage(
                                                    originalProduct.hover_image
                                                );
                                                setPromoLabel(
                                                    simpleProduct.promo_label
                                                );
                                            }
                                        }}
                                        defaultOptions={
                                            defaultOptions.length > 0
                                                ? defaultOptions
                                                : []
                                        }
                                        disableUnselect={true}
                                        optionLimit={
                                            product.configurable_options[0]
                                                .values.length > 4
                                                ? 4
                                                : false
                                        }
                                        productUrl={`/${product.url_key}${categoryUrlSuffix}`}
                                    />
                                </Fragment>
                            </div>
                        )}
                        {!isGrid ? (
                            <div
                                className={`add-to-cart-btn ${
                                    !isConfig(product)
                                        ? 'is-simple-product'
                                        : ''
                                } `}
                            >
                                {stockStatus === 'IN_STOCK' &&
                                (!isBundleProduct(product) || !componentOOS) ? (
                                    <Fragment>
                                        {isGiftCard(product) ||
                                        (isCustomisableBundleProduct &&
                                            isBundleProduct(product)) ? (
                                            <Button
                                                data-cnstrc-btn="add_to_cart"
                                                onClick={() => {
                                                    history.push(
                                                        `/${product.url_key}${categoryUrlSuffix}`
                                                    );
                                                }}
                                                variant={'primary'}
                                            >
                                                {t('ADD TO BAG')}
                                            </Button>
                                        ) : (
                                            <AddToCart
                                                addToCartText={t('ADD TO BAG')}
                                                addToCartLoadingText={t(
                                                    'ADDING...'
                                                )}
                                                addedMessage={t('ADDED')}
                                                quantity={1}
                                                sku={
                                                    !!isConfig(product)
                                                        ? sku
                                                        : product.sku
                                                }
                                                isConfigProduct={
                                                    !!isConfig(product)
                                                }
                                                isBundleProduct={
                                                    !!isBundleProduct(product)
                                                }
                                                parent_sku={
                                                    product && product.sku
                                                }
                                                isBundled={false}
                                                showTick={false}
                                                projectConfig={projectConfig}
                                                categoryName={categoryName}
                                                configProductAddToCartGraphql={
                                                    addConfigurableProductToCart
                                                }
                                                simpleProductAddToCartGraphql={
                                                    addSimpleProductsToCart
                                                }
                                                addBundleProductsToCart={
                                                    addBundleProductsToCart
                                                }
                                                bundleOptions={bundleOptions}
                                            />
                                        )}
                                    </Fragment>
                                ) : (
                                    <Button variant="primary" disabled>
                                        {t('OUT OF STOCK')}
                                    </Button>
                                )}
                            </div>
                        ) : (
                            <Link
                                onClick={() =>
                                    handleLinkClick(url_key, categoryUrlSuffix)
                                }
                                to={`/${product.url_key}${categoryUrlSuffix}${
                                    optionSelected !== ''
                                        ? '?' + optionSelected
                                        : ''
                                }`}
                                className={'product-grid-link'}
                            >
                                {t('Shop Now')} >
                            </Link>
                        )}
                    </div>
                </div>
            )}
        </Fragment>
    );
};

export const ProductItem = React.memo(
    props => <ProductTile {...props} />,
    (prevProps, newProps) =>
        JSON.stringify(prevProps) === JSON.stringify(newProps)
);
