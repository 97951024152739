import { useMutation } from 'react-apollo';
import trackInitiateCheckoutGql from './Query/trackInitiateCheckout.graphql';
import { useGlobalOptions } from '@corratech/context-provider';

export const TrackInitiateCheckout = () => {
    const [setInitiateCheckout] = useMutation(trackInitiateCheckoutGql);
    const options = useGlobalOptions();
    const isFbTrackingEnable = options.storeConfig.is_fb_tracking_enable;

    return function() {
        if (isFbTrackingEnable) {
            return setInitiateCheckout({
                fetchPolicy: 'no-cache',
                variables: {
                    userAgent: navigator.userAgent
                }
            });
        }
    };
};
