import React, { useEffect, useState } from 'react';
import { string, arrayOf } from 'prop-types';
import { TrackCheckoutStepChange } from './TrackCheckouStepChange';
import './windowHistoryStateEvents';
import { useHistory } from 'react-router-dom';

// Create a RegExp that matches any checkout steps.
// Success step is not listed because we do not track it as a real step, but it will be in the URL.
const getCheckoutStepExpression = (checkoutPagePath, checkoutStepIdByName) => {
    let stepNames = Object.keys(checkoutStepIdByName);
    if (!stepNames.includes('success')) {
        stepNames.push('success');
    }
    return new RegExp(`${checkoutPagePath}.*(${stepNames.join('|')})`);
}

export const TrackUrlChange = props => {
    const {
        dataLayerAction,
        checkoutPagePath,
        accountPagePath,
        cartPagePath,
        checkoutStepIdByName,
        customPages
    } = props;
    const [trackedPagePath, setTrackedPagePath] = useState(null);
    // Connect component state to the react router history changes
    const routeHistory = useHistory();
    const checkoutSuccessExpr = new RegExp(`${checkoutPagePath}.*success`);
    const checkoutPageExpr = new RegExp(`${checkoutPagePath}`);
    const checkoutStepExpr = getCheckoutStepExpression(checkoutPagePath, checkoutStepIdByName);
    const accountPageExpr = new RegExp(`${accountPagePath}`);
    // We do not use this variable directly, but we trigger an update in the case of pushState
    const [historyStateUrl, setHistoryStateUrl] = useState('');
    const {
        location: {
            origin,
            pathname,
            hash
        }
    } = window;
    // Track hash changes only on checkout page
    const pagePath = (checkoutPageExpr.test(pathname)) ? `${pathname}${hash}` : pathname;

    let pageName = '';
    let pageType = '';
    if (pagePath === '/') {
        pageType = 'home';
    } else if (checkoutSuccessExpr.test(pagePath)) {
        pageType = 'order confirmation';
        pageName = 'Checkout';
    } else if (checkoutPageExpr.test(pagePath)) {
        pageType = 'checkout';
        pageName = 'Checkout';
    } else if (pagePath === `/${cartPagePath}`) {
        pageType = 'cart';
        pageName = 'Cart';
    } else if (/search/.test(pagePath)) {
        pageType = 'searchresults';
        pageName = 'Search';
    } else if (accountPageExpr.test(pagePath)) {
        pageType = 'other';
        pageName = 'My Account';
    }

    if (typeof customPages[pathname] !== 'undefined') {
        pageType = customPages[pathname]['pageType'] || 'other';
        pageName = customPages[pathname]['pageName'];
    }

    const isInitialCheckoutPageLoad = () => {
        return (checkoutPageExpr.test(pagePath) && !checkoutStepExpr.test(pagePath));
    }

    if (trackedPagePath !== pagePath && !isInitialCheckoutPageLoad()) {
        setTrackedPagePath(pagePath);
        let trackingData = { virtualPagePath: pagePath };
        // If page title or type is not defined, then it will be loaded with SEO component
        if (!!pageName && !!pageType) {
            trackingData['pageName'] = pageName;
            trackingData['pageType'] = pageType;
        }

        dataLayerAction({
            type: 'PAGE_VIEW',
            data: trackingData
        });
    }

    useEffect(() => {
        let historyStateListener = (e) => {
            if (!e.detail || !e.detail.url) {
                return;
            }
            setHistoryStateUrl(`${origin}${e.detail.url}`);
        }
        window.addEventListener('historypushstate', historyStateListener);
        window.addEventListener('historyreplacestate', historyStateListener);

        return () => {
            window.removeEventListener('historypushstate', historyStateListener);
            window.removeEventListener('historyreplacestate', historyStateListener);
        }
    }, []);

    const isCartStep = pagePath === `/${cartPagePath}`;

    return (
        <>
            {((checkoutPageExpr.test(pagePath) && !!hash) || isCartStep) && (
                <TrackCheckoutStepChange
                    hash={hash}
                    pagePath={pagePath}
                    dataLayerAction={dataLayerAction}
                    isCartStep={isCartStep}
                    checkoutStepIdByName={checkoutStepIdByName}
                />
            )}
        </>
    );
}

TrackUrlChange.propTypes = {
    checkoutPagePath: string,
    cartPagePath: string,
    cartFieldsToObserve: arrayOf(string)
}
