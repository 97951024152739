import { getCategoryPath } from '..';
import { getConfigurableVariant } from '..';
import { getCartItemPrice } from '..';

export const cartItemMapping = (
    itemCategoryPath = getCategoryPath,
    cartItemPrice = getCartItemPrice,
    configurableVariant = getConfigurableVariant
) => {
    return {
        id: [
            ['itemsku'],
            ['product', 'sku']
        ],
        name: ['product', 'name'],
        price: cartItemPrice,
        quantity: ['quantity'],
        category: itemCategoryPath,
        currencyCode: [
            ['prices', 'row_total_including_tax', 'currency'],
            ['prices', 'row_total', 'currency'],
            ['prices', 'price', 'currency'],
            ['product', 'price', 'regularPrice', 'amount', 'currency']
        ],
        urlKey: ['product', 'url_key'],
        thumbnailUrl: ['product', 'thumbnail', 'url'],
        variant: configurableVariant,
        brand: ['product', 'brand_name']
    };
};