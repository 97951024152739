import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-apollo';
import { CartStore, LoaderStore } from '@corratech/context-provider';
import { getCurrencySymbol } from '../util/getCurrencySymbol';
import { setPriceZeroes } from '../util/setPriceZeroes';
import { useTranslation } from 'react-i18next';
import applyStoreCreditToCart from '../graphql/applyStoreCreditToCart.graphql';
import removeStoreCreditFromCart from '../graphql/removeStoreCreditFromCart.graphql';
import './StoreCredit.less';

export const StoreCredit = () => {
    const [t] = useTranslation();

    const { cartState, dispatch } = useContext(CartStore);

    const LoadingIndicator = useContext(LoaderStore);

    const [applyStoreCredit, { loading: applyLoading }] = useMutation(
        applyStoreCreditToCart,
        {
            variables: {
                cartId: cartState.cartId,
                isSignedIn: !!cartState.cart.authenticated
            },
            onCompleted: res => {
                const prices = cartState.cart.prices;

                dispatch({
                    type: 'SET_CART',
                    cart: {
                        applied_store_credit: {
                            ...res.applyStoreCreditToCart.cart
                                .applied_store_credit
                        },
                        prices: {
                            ...prices,
                            ...res.applyStoreCreditToCart.cart.prices
                        },
                        available_payment_methods:
                            res.applyStoreCreditToCart.cart
                                .available_payment_methods
                    }
                });
            }
        }
    );

    const [removeStoreCredit, { loading: removeLoading }] = useMutation(
        removeStoreCreditFromCart,
        {
            variables: {
                cartId: cartState.cartId,
                isSignedIn: !!cartState.cart.authenticated
            },
            onCompleted: res => {
                const prices = cartState.cart.prices;

                dispatch({
                    type: 'SET_CART',
                    cart: {
                        applied_store_credit: {
                            ...res.removeStoreCreditFromCart.cart
                                .applied_store_credit
                        },
                        prices: {
                            ...prices,
                            ...res.removeStoreCreditFromCart.cart.prices
                        },
                        available_payment_methods:
                            res.removeStoreCreditFromCart.cart
                                .available_payment_methods
                    }
                });
            }
        }
    );

    if (
        !(
            cartState.cart.applied_store_credit &&
            cartState.cart.applied_store_credit.enabled
        ) ||
        cartState.cart.applied_store_credit.current_balance.value === 0 ||
        (cartState.cart.prices.subtotal_with_discount_excluding_tax.value ===
            0 &&
            cartState.cart.prices.applied_taxes.length === 0)
    ) {
        return null;
    }

    if (applyLoading || removeLoading) return <LoadingIndicator />;

    return (
        <div className={'store-credit-form'}>
            <h3>{t('STORE CREDIT')}</h3>

            {cartState.cart.applied_store_credit.applied_balance.value > 0 ? (
                <div
                    className={
                        'store-credit-wrapper store-credit-remove-wrapper'
                    }
                >
                    <span className={'applied-store-credit'}>
                        {t('Applied Store Credit')}:{' '}
                        {getCurrencySymbol(
                            cartState.cart.applied_store_credit.applied_balance
                                .currency
                        )}{' '}
                        {
                            cartState.cart.applied_store_credit.applied_balance
                                .value
                        }
                    </span>
                    <Link
                        to={'#'}
                        className={'remove-store-credit btn btn-link'}
                        onClick={removeStoreCredit}
                    >
                        {t('Remove Store Credit')}
                    </Link>
                </div>
            ) : (
                <div
                    className={
                        'store-credit-wrapper store-credit-apply-wrapper'
                    }
                >
                    <span className={'available-store-credit'}>
                        {getCurrencySymbol(
                            cartState.cart.applied_store_credit.current_balance
                                .currency
                        ) +
                            setPriceZeroes(
                                cartState.cart.applied_store_credit
                                    .current_balance.value
                            )}{' '}
                        {t('Store Credit available.')}
                    </span>
                    <button
                        className={'btn btn-primary'}
                        onClick={applyStoreCredit}
                    >
                        {t('Apply Store Credit')}
                    </button>
                </div>
            )}
        </div>
    );
};
