import { getMapProductDetails } from './functions/getMapProductDetails';
import { getCategoryName } from '@corratech/google-tag-manager';

export const PLP_PAGE = () => {
    return {
        items: ['products', 'items'],
        //category: ['categoryList', '0', 'name'],
        categoryName: ['category', 'name'],
        //category: getCategoryName,
        pageSize: ['pageSize'],
        currencyCode: [
            [
                'products',
                'items',
                '0',
                'price_range',
                'minimum_price',
                'regular_price',
                'currency'
            ],
            [
                'category',
                'products',
                'items',
                '0',
                'price_range',
                'minimum_price',
                'regular_price',
                'currency'
            ]
        ],
        currentPage: ['currentPage'],
        productsDetails: getMapProductDetails()
    };
};
