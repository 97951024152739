import React, { useContext, useState } from 'react';
import { CartStore, LoaderStore } from '@corratech/context-provider';
import { useMutation } from 'react-apollo';
import setPaymentMethod from './setPaymentMethod.graphql';
import placeOrderMutation from '../../graphql/placeOrder.graphql';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { CheckoutAgreements } from '../../CheckoutAgreements/CheckoutAgreements';
import setBillingAddressMutation from '../../graphql/setBillingAddressOnCart.graphql';
import { AuthBillingAddressForm } from '../BraintreePayment/AuthBillingAddressForm';
import { UnAuthBillingAddressForm } from '../BraintreePayment/UnAuthBillingAddressForm';
import { getCurrencySymbol } from '../../util/getCurrencySymbol';
import { isAddressValid } from '../../util/isAddressValid';
import { RenderAppliedCredits } from '../../RenderAppliedCredits';

export const OtherPaymentOptions = props => {
    const { dataLayerAction } = props;
    const { cartState, dispatch } = useContext(CartStore);
    const LoadingIndicator = useContext(LoaderStore);
    const [t] = useTranslation();
    const [selectedCode, setSelectedCode] = useState();

    const [setPaymentMethodFunc] = useMutation(setPaymentMethod);

    // Mutation to placeorder
    const [placeOrder, { loading: placeOrderLoading }] = useMutation(
        placeOrderMutation,
        {
            variables: {
                cartId: cartState.cartId
            },
            onCompleted: res => {
                if (!!dataLayerAction) {
                    dataLayerAction({ type: 'PLACE_ORDER', data: {
                            ...cartState,
                            actionField: res
                        }
                    });
                }
                dispatch({
                    type: 'PLACED_ORDER',
                    placedOrder: res
                });
            }
        }
    );

    // Magento requires a billing address be set on all orders, so we set it to the billing address if same as shipping
    const [setBillingAddress, { loading: addressLoading }] = useMutation(
        setBillingAddressMutation,
        {
            variables: {
                cartId: cartState.cartId,
                firstname: cartState.cart.shipping_addresses[0].firstname,
                lastname: cartState.cart.shipping_addresses[0].lastname,
                company: cartState.cart.shipping_addresses[0].company || '',
                street: cartState.cart.shipping_addresses[0].street,
                city: cartState.cart.shipping_addresses[0].city,
                region: cartState.cart.shipping_addresses[0].region.code,
                postcode: cartState.cart.shipping_addresses[0].postcode,
                countryCode: cartState.cart.shipping_addresses[0].country.code,
                telephone: cartState.cart.shipping_addresses[0].telephone
            }
        }
    );

    const getMethodByCode = methodCode => {
        if (
            cartState.cart &&
            cartState.cart.available_payment_methods &&
            cartState.cart.available_payment_methods.findIndex(
                x => x.code === methodCode
            ) > -1
        ) {
            return cartState.cart.available_payment_methods[
                cartState.cart.available_payment_methods.findIndex(
                    x => x.code === methodCode
                )
            ];
        } else {
            return null;
        }
    };

    if (!(placeOrderLoading || addressLoading))
        return (
            <div className={'other-payment-method'}>
                <h2 className={'step-title other-method-header'}>
                    {t('Payment Method')}
                </h2>

                {/* We hard-code these values purposely because this form should
                    only show options which do not have their own specific form
                    */}

                <div className={'other-payment-method-options'}>
                    {!!getMethodByCode('checkmo') && (
                        <>
                            <label className={'other-payment-method-label'}>
                                <input
                                    type="radio"
                                    name="other-payment-method"
                                    onChange={event =>
                                        setSelectedCode(event.target.value)
                                    }
                                    value={getMethodByCode('checkmo').code}
                                    checked={
                                        selectedCode ===
                                        getMethodByCode('checkmo').code
                                    }
                                    className="other-payment-method-radio"
                                />
                                {getMethodByCode('checkmo').title}
                            </label>
                            <br />
                        </>
                    )}
                    {!!getMethodByCode('cashondelivery') && (
                        <>
                            <label className={'other-payment-method-label'}>
                                <input
                                    type="radio"
                                    name="other-payment-method"
                                    onChange={event =>
                                        setSelectedCode(event.target.value)
                                    }
                                    value={
                                        getMethodByCode('cashondelivery').code
                                    }
                                    checked={
                                        selectedCode ===
                                        getMethodByCode('cashondelivery').code
                                    }
                                    className="other-payment-method-radio"
                                />
                                {getMethodByCode('cashondelivery').title}
                            </label>
                            <br />
                        </>
                    )}
                </div>

                {cartState.cart.authenticated ? (
                    <AuthBillingAddressForm />
                ) : (
                    <UnAuthBillingAddressForm />
                )}

                <RenderAppliedCredits
                    className={'checkout-applied-credits other-method'}
                />

                {cartState.cart.isBillingSameAsShipping ? (
                    <Button
                        onClick={() => {
                            setPaymentMethodFunc({
                                variables: {
                                    cartId: cartState.cartId,
                                    method: selectedCode
                                }
                            }).then(() =>
                                setBillingAddress().then(() => placeOrder())
                            );
                        }}
                    >
                        {t('Place My Order')}
                    </Button>
                ) : (
                    <Button
                        disabled={
                            !isAddressValid(cartState.cart.billing_address)
                        }
                        onClick={() => {
                            setPaymentMethodFunc({
                                variables: {
                                    cartId: cartState.cartId,
                                    method: selectedCode
                                }
                            }).then(() => placeOrder());
                        }}
                    >
                        {t('Place My Order')}
                    </Button>
                )}
                <CheckoutAgreements />
            </div>
        );
    else return <LoadingIndicator />;
};
