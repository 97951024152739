/**
 * The function purpose is to group validation callbacks into a chain within single callback function.
 * @typedef {Object} Callback
 * @property {Function} fn
 * @property {string} text
 * @property {*} [param]
 *
 * @param {Callback[]} callbacks
 * @return {function(value, values): *}
 */
export default callbacks => {
    if (callbacks == null || !Array.isArray(callbacks)) {
        throw new Error('Expected `callbacks` to be array.');
    }

    return (value, values) => {
        let result = null;

        for (let i = 0; i < callbacks.length; i++) {
            const callback = callbacks[i];

            if (callback.fn == null || typeof callback.fn !== 'function') {
                throw new Error(
                    'Expected `callbacks[' + i + ']` to be array or function.'
                );
            } else if (callback.param) {
                if (typeof callback.fn !== 'function') {
                    throw new Error(
                        'Expected `callbacks[' + i + '][0]` to be function.'
                    );
                }

                result = callback.fn(
                    value,
                    values,
                    callback.param,
                    callback.text
                );
            } else {
                result = callback.fn(value, values, callback.text);
            }

            if (result) {
                break;
            }
        }

        return result;
    };
};
