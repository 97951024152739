import React from 'react';
import { useMutation } from '@apollo/react-hooks';
import { addSimpleProductToWishlist as addSimpleProductToWishlistQuery } from './Queries';

export function useAddToWishlist() {
    const [addSimpleProductToWishlist, { error, loading }] = useMutation(
        addSimpleProductToWishlistQuery
    );

    return function(productId, productQty) {
        return addSimpleProductToWishlist({
            fetchPolicy: 'no-cache',
            variables: {
                items: [
                    {
                        product: productId,
                        qty: productQty
                    }
                ]
            }
        });
    };
}
