import { useMutation } from 'react-apollo';
import trackSearchContentGql from './Query/trackSearchContent.graphql';
import { useGlobalOptions } from '@corratech/context-provider';

export const TrackSearchContent = () => {
    const [setSearchContent] = useMutation(trackSearchContentGql);
    const options = useGlobalOptions();
    const isFbTrackingEnable = options.storeConfig.is_fb_tracking_enable;

    return function(searchQuery) {
        if (isFbTrackingEnable) {
            return setSearchContent({
                fetchPolicy: 'no-cache',
                variables: {
                    query: searchQuery,
                    userAgent: navigator.userAgent
                }
            });
        }
    };
};
