import jsSHA from 'jssha/dist/sha256';

function hashValue(value) {
    if (!value) {
        return '';
    }
    const normalizedValue = value.trim().toLowerCase();
    const shaObj = new jsSHA("SHA-256", "TEXT");
    shaObj.update(normalizedValue);
    return shaObj.getHash("HEX");
}

function ShoppingCartUserStateEvents(tagData, addStoredData, getStoredData) {
    const {
        normalizedGuestHashedEmail,
        normalizedGuestHashedPhone
    } = tagData;

    addStoredData({
        userLogState: {
            ...getStoredData(['userLogState']).userLogState,
            normalizedGuestHashedEmail: hashValue(normalizedGuestHashedEmail),
            normalizedGuestHashedPhone: hashValue(normalizedGuestHashedPhone),
        }
    });
}

export { ShoppingCartUserStateEvents };
