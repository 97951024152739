import { deepValue } from "@corratech/tag-manager";

// These page types have separate events
const skipPageTypes = ['category', 'product', 'checkout', 'order confirmation', 'searchresults'];

export const PageTypeEvents = (preparedData, dataLayerName) => {
    if (!preparedData || !preparedData.event || preparedData.event !== 'pageview') {
        return;
    }
    
    const pageType = deepValue(preparedData, ['pageDetails', 'pageType'], false);
    if (!pageType || skipPageTypes.includes(pageType)) {
        return;
    }

    return {
        event: pageType
    };
}