/*
This hook allows delaying certain events, so that they can be triggered
right before or right after other events.
Before rules structure example: {
  <current event type>: <event type, after which the current event should be triggered>
}
Queue structure example: {
  <current event type>: [<list of enqueued events to be triggered before this event>]
}
 */
import { useRefState } from './useRefState';

// These events (object keys) are fired, they should be delayed
// until another event (object values) is fired
const defaultEventQueueAfterRules = {
    category: 'pageview',
    product: 'pageview',
    purchase: 'pageview',
    search: 'pageview',
    checkout: 'pageview'
};
const defaultEventQueueBeforeRules = {};
const defaultDelayToNextPageEvents = ['purchase'];

export const useEventQueue = (
    customBeforeRules = {},
    customAfterRules = {},
    customDelayToNextPageEvents = [],
    dataLayerName
) => {
    const beforeRules = {...defaultEventQueueBeforeRules, ...customBeforeRules};
    const afterRules = {...defaultEventQueueAfterRules, ...customAfterRules};
    const delayToNextPageEvents = [...defaultDelayToNextPageEvents, ...customDelayToNextPageEvents];
    // For some reason, using a useState() here and resetting these states in resetQueue
    // makes it impossible to pass from shipping to payment checkout step
    const [eventHistory, setEventHistory] = useRefState([]);
    const [nextPageEvents, setNextPageEvents] = useRefState([]);
    const [beforeQueue, setBeforeQueue] = useRefState({});
    const [afterQueue, setAfterQueue] = useRefState({});
    
    // Add event to the history, add to the queue if needed
    const enqueueEvent = (event, data, queue, setQueue, rules, ignoreHistory = false) => {
        if (typeof rules[event] === 'undefined') {
            return false;
        }
        
        const dependentEventType = rules[event];
        if (!ignoreHistory && eventHistory().includes(dependentEventType)) {
            return false;
        }
        let updatedQueue = {...queue};
        let dependentEventQueue = (!!updatedQueue[dependentEventType]) ? updatedQueue[dependentEventType] : [];
        dependentEventQueue.push(data);
        updatedQueue[dependentEventType] = dependentEventQueue;
        setQueue(updatedQueue);
        return true;
    }
    
    // Push queued events and remove from the queue
    const dequeueEvents = (event, data, queue, setQueue,) => {
        if (typeof queue[event] === 'undefined') {
            return;
        }
        
        queue[event].map(queuedAction =>
            window[dataLayerName].push(queuedAction)
        );
        
        let updatedQueue = {...queue};
        delete updatedQueue[event];
        setQueue(updatedQueue);
    }
    
    const enqueueOnNextPage = (event, data) => {
        if (!delayToNextPageEvents.includes(event)) {
            return false;
        }
        let updatedNextPageEvents = nextPageEvents();
        updatedNextPageEvents.push(data);
        setNextPageEvents(updatedNextPageEvents);
        return true;
    }
    
    // Return a flag that notifies whether this action is queued, or should be tracked now
    const checkQueueBeforeEvent = (data = {}) => {
        const { event } = data;
        if (!event) {
            return false;
        }
        
        const queuedBefore = enqueueEvent(event, data, beforeQueue(), setBeforeQueue, beforeRules);
        const queuedAfter = enqueueEvent(event, data, afterQueue(), setAfterQueue, afterRules);
        const queuedOnNextPage = enqueueOnNextPage(event, data);
        dequeueEvents(event, data, beforeQueue(), setBeforeQueue);
        return queuedBefore || queuedAfter || queuedOnNextPage;
    }
    const checkQueueAfterEvent = (data = {}) => {
        const { event } = data;
        if (!event) {
            return false;
        }
        dequeueEvents(event, data, afterQueue(), setAfterQueue);
        let updatedHistory = eventHistory();
        updatedHistory.push(event);
        setEventHistory(updatedHistory);
    }
    
    const resetQueue = () => {
        setEventHistory([]);
        setBeforeQueue({});
        setAfterQueue({});
        nextPageEvents().map(data => {
            let { event } = data;
            // Try queueing delayed events by existing rules
            const queuedBefore = enqueueEvent(event, data, beforeQueue(), setBeforeQueue, beforeRules, true);
            const queuedAfter = enqueueEvent(event, data, afterQueue(), setAfterQueue, afterRules, true);
            // If event doesn't match any rule, push it after pageview by default
            if (!queuedBefore && !queuedAfter) {
                let defaultRule = {[event]: 'pageview'};
                enqueueEvent(event, data, afterQueue(), setAfterQueue, defaultRule, true);
            }
        });
        setNextPageEvents([]);
    }
    
    // In some cases, we may have a need to dequeue a next page event immediately
    const dequeueNextPageEvent = (eventName = '') => {
        if (!eventName) {
            return;
        }
        let eventsToKeep = [];
        nextPageEvents().map(data => {
            let { event } = data;
            if (eventName !== event) {
                eventsToKeep.push(data);
                return;
            }
            window[dataLayerName].push(data);
        });
        setNextPageEvents(eventsToKeep);
    }
    
    return {
        eventHistory,
        resetQueue,
        checkQueueBeforeEvent,
        checkQueueAfterEvent,
        dequeueNextPageEvent
    }
}