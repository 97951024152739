import React, { useEffect } from 'react';
import useScript from '@corratech/bll/script/useScript';

export default function useCaptureEmail(id = '') {

    useEffect(() => {

        if (id) {

            // Track Email subscriptions. Since email field is in footer, this code should be in all pages
            (function() {
                if (typeof _ltk == 'object') {
                    ltkCode();
                } else {
                    (function(d) {
                        if (document.addEventListener)
                            document.addEventListener('ltkAsyncListener', d);
                        else {
                            e = document.documentElement;
                            e.ltkAsyncProperty = 0;
                            e.attachEvent('onpropertychange', function(e) {
                                if (e.propertyName == 'ltkAsyncProperty') {
                                    d();
                                }
                            });
                        }
                    })(function() {
                        ltkCode();
                    });
                }
                function ltkCode() {
                    _ltk_util.ready(function() {
                        /********** Begin Custom Code **********/
                        _ltk.SCA.CaptureEmail(id);
                        /********** End Custom Code ************/
                    });
                }
            })();
            
        }
        
        
    }, []);
}
