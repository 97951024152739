import React from 'react';
import PropTypes from 'prop-types';

const BacktoTopIcon = props => {
    const { color, width, height, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            x="0"
            y="0"
            enableBackground="new 0 0 9 9"
            version="1.1"
            viewBox="0 0 9 9"
            xmlSpace="preserve"
        >
            <path d="M0 7L4.48 1.03 8.96 7z" className="st0"></path>
        </svg>
    );
};

BacktoTopIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

BacktoTopIcon.defaultProps = {
    color: 'currentColor',
    size: '24'
};

export default BacktoTopIcon;
