/**
 * Created the useScript hook to inject the External
 * CDN URL (Accept.js) to atuthorize.net payment gateway
 * CORRA
 */
import { useEffect, useState } from 'react';

const ScriptStatus = {
    IDLE: 'idle',
    LOADING: 'loading',
    READY: 'ready',
    ERROR: 'error'
};

export default function useScript(url) {
    const [status, setStatus] = useState(
        url ? ScriptStatus.LOADING : ScriptStatus.IDLE
    );

    useEffect(() => {
        if (!url) {
            setStatus(ScriptStatus.IDLE);
            return;
        }
        //Set the script status loading
        setStatus(ScriptStatus.LOADING);

        //Check if script is already loaded
        let script = document.querySelector(`script[src="${url}"]`);

        if (!script) {
            script = document.createElement('script');
            script.src = url;
            script.async = true;
            document.head.appendChild(script);

            script.onerror = () => {
                if (script)
                    script.setAttribute('data-status', ScriptStatus.ERROR);
            };

            script.onload = () => {
                if (script)
                    script.setAttribute('data-status', ScriptStatus.READY);
            };
        } else if (script.hasAttribute('data-status')) {
            setStatus(script.getAttribute('data-status'));
        }

        const eventHandler = e => {
            setStatus(
                e.type === 'load' ? ScriptStatus.READY : ScriptStatus.ERROR
            );
        };

        // Add load event listener
        script.addEventListener('load', eventHandler);
        script.addEventListener('error', eventHandler);

        return () => {
            if (script) {
                script.removeEventListener('load', eventHandler);
                script.removeEventListener('error', eventHandler);
            }
        };
    }, [url]);

    return [status === ScriptStatus.READY, status];
}
