import React from 'react';
import {
    GTMAddToCartEvents,
    GTMCheckoutEvents,
    GTMCheckoutOptionEvents,
    GTMCustomerEvents,
    GTMOrderConfirmationEvents,
    GTMPageDetailsEvents,
    GTMPageTypeEvents,
    GTMPageViewEvents,
    GTMPDPEvents,
    GTMPLPEvents,
    GTMProductClickEvents,
    GTMPromotionEvents,
    GTMRemoveCartEvents,
    GTMSearchEvents,
    GTMShoppingCartEvents,
    GTMShoppingCartUserStateEvents,
    GTMSmsSignUpEvents
} from './GTMEvents';
import { applyDataMapping, combineDataMapping } from '@corratech/tag-manager';
import { defaultMapping } from './defaultMapping';
import { resetGTM } from './utils/resetGTM';
import { addScriptFromRaw } from './utils/addScriptFrowRaw';
import { addNoScriptFromRaw } from './utils/addNoScriptFrowRaw';
import { gtmInit } from './templates/gtmInit';
import { gtmInitNoScript } from './templates/gtmInitNoScript';
import { formatEmptyValues } from './utils/formatEmptyValues';
import { useEventQueue } from './hooks/useEventQueue';
import { useDataLayerData } from './hooks/useDataLayerData';
import './utils/dataLayerHelper';

// Queue or push an event. Also, push queued events.
const getPushEvent = (
    dataLayerName,
    checkQueueBeforeEvent,
    checkQueueAfterEvent,
    addStoredData
) => {
    return data => {
        if (!data) {
            return;
        }
        const isQueued = checkQueueBeforeEvent(data);
        if (!isQueued) {
            window[dataLayerName].push(data);
            addStoredData(data);
        }
        checkQueueAfterEvent(data);
    };
};

const getDataLayerAction = (
    dataLayerName,
    GTMReset = () => {},
    eventQueue = {},
    getStoredData = () => {},
    addStoredData = () => {}
) => {
    return ({ type, data }) => {
        const {
            eventHistory,
            checkQueueBeforeEvent,
            checkQueueAfterEvent,
            dequeueNextPageEvent
        } = eventQueue;
        const pushEvent = getPushEvent(
            dataLayerName,
            checkQueueBeforeEvent,
            checkQueueAfterEvent,
            addStoredData
        );
        let preparedData;
        switch (type) {
            case 'PDP_PAGE':
                preparedData = GTMPDPEvents(data);
                break;
            case 'ADD_TO_CART':
                preparedData = GTMAddToCartEvents(data);
                break;
            case 'REMOVE_TO_CART':
                preparedData = GTMRemoveCartEvents(data);
                break;
            case 'PLP_PAGE':
                preparedData = GTMPLPEvents(data);
                break;
            case 'PRODUCT_CLICK':
                preparedData = GTMProductClickEvents(data);
                break;
            case 'PROMOTION_VIEW':
                preparedData = GTMPromotionEvents(data);
                break;
            case 'SEARCH':
                preparedData = GTMSearchEvents(data);
                break;
            case 'CHECKOUT_PAGE':
                preparedData = GTMCheckoutEvents(data);
                break;
            case 'CHECKOUT_OPTION':
                preparedData = GTMCheckoutOptionEvents(data);
                break;
            case 'PLACE_ORDER':
                preparedData = GTMOrderConfirmationEvents(data);
                break;
            case 'TRIGGER_IMMEDIATELY':
                let { eventName } = data;
                dequeueNextPageEvent(eventName);
                break;
            // Four events below are not being tracked immediately.
            // They provide data, needed for pageview event.
            case 'CUSTOMER_EVENTS':
                GTMCustomerEvents(data, addStoredData);
                break;
            case 'CART_PAGE':
                GTMShoppingCartEvents(data, addStoredData);
                break;
            case 'CART_GUEST_USER_STATE':
                GTMShoppingCartUserStateEvents(data, addStoredData, getStoredData);
                break;
            case 'PAGE_VIEW':
                // This event happens right after visiting a new page
                // We reset all the variables, except dataLayerFieldsToPreserve
                GTMReset();
                GTMPageDetailsEvents(data, addStoredData);
                break;
            case 'PAGE_VIEW_DATA':
                // Additional pageView data. No need to reset dataLayer
                GTMPageDetailsEvents(data, addStoredData);
                break;
            case 'SMS_SIGNUP':
                preparedData = GTMSmsSignUpEvents(data, getStoredData);
                break;
            default:
                // Push the data directly if it doesn't match any handlers
                preparedData = data;
                break;
        }

        // Main data push
        preparedData = formatEmptyValues(preparedData);
        pushEvent(preparedData);

        // Track pageview event if possible
        const pageViewData = GTMPageViewEvents(
            type,
            eventHistory,
            getStoredData
        );
        pushEvent(pageViewData);

        // Page type events follow right after pageview events
        const pageTypeData = GTMPageTypeEvents(pageViewData, dataLayerName);
        pushEvent(pageTypeData);
    };
};

export const useGTMTracking = (props = {}) => {
    const {
        dataLayerName = 'dataLayer',
        mapping = {},
        gtmScriptSrc,
        gtmDataLayerCode,
        gtmNoscriptSrc,
        enablePageViewDataLayerReset = false,
        dataLayerActionOverride = {},
        eventQueueBeforeRules = {},
        eventQueueAfterRules = {},
        delayToNextPageEvents = []
    } = props;
    const eventQueue = useEventQueue(
        eventQueueBeforeRules,
        eventQueueAfterRules,
        delayToNextPageEvents,
        dataLayerName
    );

    // Init datalayer if needed
    if (typeof window[dataLayerName] === 'undefined') {
        window[dataLayerName] = [];
    }

    if (gtmScriptSrc && gtmDataLayerCode) {
        addScriptFromRaw(gtmInit(gtmScriptSrc, gtmDataLayerCode), 'gtm-init');
    }

    if (gtmNoscriptSrc) {
        addNoScriptFromRaw(
            gtmInitNoScript(gtmNoscriptSrc),
            'gtm-init-noscript'
        );
    }

    const {
        getStoredData,
        addStoredData,
        resetStoredData
    } = useDataLayerData();

    const GTMReset = resetGTM(
        enablePageViewDataLayerReset,
        dataLayerName,
        eventQueue,
        resetStoredData
    );

    const dataLayerAction = getDataLayerAction(
        dataLayerName,
        GTMReset,
        eventQueue,
        getStoredData,
        addStoredData
    );

    const combinedMapping = combineDataMapping(defaultMapping, mapping);
    const handleDataLayerAction = action => {
        if (!gtmScriptSrc) {
            return;
        }
        let mappedData = applyDataMapping(action, combinedMapping);
        if (typeof dataLayerActionOverride[action.type] !== 'undefined') {
            // Data, processed by an action override does not need 'type'
            mappedData = {
                type: '',
                data: dataLayerActionOverride[action.type](
                    mappedData.data,
                    addStoredData
                )
            };
        }
        dataLayerAction(mappedData);
    };

    return handleDataLayerAction;
};
