import resolveUnknownRoute from '@corratech/peregrine-page/Router/resolveUnknownRoute';

const redirectToLowercaseURL = props => {
    const url = props.match.url;
    const currentPathname = url[0] === '/' ? url.substring(0) : url;
    const urlResolver = resolveUnknownRoute({
        route: url,
        apiBase: '/graphql'
    });
    urlResolver.then(response => {
        if (response.errors) {
            throw new Error(
                `response query failed: ${JSON.stringify(res.errors, null, 2)}`
            );
        }

        if (
            currentPathname !== '/' &&
            currentPathname !== response?.relative_url &&
            typeof response?.relative_url !== 'undefined'
        ) {
            history.replaceState(
                {},
                null,
                '/' + response?.relative_url + window.location.search
            );
        }
    });
};

export default redirectToLowercaseURL;
