import React, { useContext, useEffect, useState } from 'react';
import { CartStore } from "@corratech/context-provider";
import { func, string, object } from "prop-types";

// This logic needs to be handled in a component because it's use is conditional in the parent component
export const TrackCheckoutStepChange = props => {
    const {
        hash,
        isCartStep,
        pagePath,
        dataLayerAction,
        checkoutStepIdByName
    } = props;
    const { cartState } = useContext(CartStore);
    const [trackedPagePath, setTrackedPagePath] = useState('');
    
    const stepName = isCartStep ? 'cart' : hash.substr(1);
    
    const canTrackCheckoutStep = () => {
        if (typeof checkoutStepIdByName[stepName] === "undefined" || trackedPagePath === pagePath) {
            return false;
        }
        
        // We track cart step in the cart component. We can't track checkout steps with empty cart.
        if (isCartStep || (!cartState.cart.items || cartState.cart.items.length === 0)) {
            return false;
        }
        
        return true;
    }
    
    useEffect(() => {
        if (canTrackCheckoutStep()) {
            dataLayerAction({
                type: 'CHECKOUT_PAGE',
                data: {
                    ...cartState,
                    actionField: {
                        step: checkoutStepIdByName[stepName],
                        description: stepName,
                        action: 'checkout'
                    }
                }
            });
            setTrackedPagePath(pagePath);
        }
    }, [hash, isCartStep, cartState]);
    
    return '';
}

TrackCheckoutStepChange.propTypes = {
    hash: string,
    dataLayerAction: func,
    checkoutStepIdByName: object
}
