import React, { useEffect, useState, useContext } from 'react';
import { useQuery } from 'react-apollo';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ChevronLeft, ChevronRight } from 'react-feather';
import LoadingIndicator from 'ModulesPath/LoadingIndicator';
import { ProductItem } from 'ModulesPath/Catalog/ProductItem';
import { AuthStore, useGlobalOptions } from '@corratech/context-provider';
import { CmsBlockGroup } from '@corratech/cms-display/src/cmsBlock.js';
import { ConstructorRecommendation } from 'ModulesPath/ConstructorRecommendation';
import './ProductSlider.less';

export const ProductSlider = props => {
    const { type, callbackOnError, podId, resultNumber, productId } = props;
    const options = useGlobalOptions();

    const getFallbackBlockName = () => {
        const siteCode = options.storeConfig.code;
        switch (type) {
            case 'complementary':
                return `${siteCode}-complementary-products`;
            case 'popular':
            case 'most-popular':
                return `${siteCode}-home-most-popular`;
            case 'trending':
                return `${siteCode}-home-best-seller`;
            default:
                return null;
        }
    };
    const renderContent = () => {
        if (type === 'constructor' && podId) {
            return (
                <ConstructorRecommendation
                    pod_id={podId}
                    numResults={resultNumber}
                    itemsId={productId}
                />
            );
        } else {
            const blockName = getFallbackBlockName();
            return (
                <CmsBlockGroup
                    identifiers={blockName}
                    callbackOnError={callbackOnError}
                />
            );
        }
    };
    return (
        <>
            <div className={`product-slider`}>{renderContent()}</div>
        </>
    );
};

ProductSlider.defaultProps = {
    productId: '',
    podId: '',
    resultNumber: 5
};
