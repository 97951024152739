import React from 'react';
import PropTypes from 'prop-types';

const HK = props => {
    const { color, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            height={size}
            width={size}
            {...otherProps}
        >
            <path
                fill="#D80027"
                d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256 256-114.6 256-256S397.4 0 256 0zM142.1 186.2c37 22.7 43.8 13.8 62.8 25.4 21.2 13 13.6 21.4 32.1 32.8-16.1 26.2-50.3 34.4-76.5 18.3-26.2-16.1-34.4-50.3-18.4-76.5zm90.8 162.7c-23.4 20-58.5 17.2-78.5-6.2 33-28.2 26.6-37.4 43.6-51.8 18.9-16.2 24.6-6.3 41.1-20.4 19.9 23.3 17.1 58.5-6.2 78.4zm-12.8-181.7c7.2-29.9 37.2-48.3 67.1-41.1-10.1 42.2.4 45.9-4.8 67.5-5.8 24.2-16.2 19.6-21.2 40.7-29.9-7.1-48.3-37.2-41.1-67.1zm87 212.2c-16.6-40.1-27.3-36.9-35.8-57.5-9.5-23 1.6-25.3-6.7-45.4 28.4-11.8 61 1.7 72.7 30.1 11.7 28.5-1.8 61.1-30.2 72.8zm16.4-117.5c-24.8 1.9-23.6-9.3-45.3-7.6-2.4-30.6 20.5-57.4 51.1-59.8 30.6-2.4 57.4 20.5 59.8 51.1-43.2 3.4-43.5 14.5-65.6 16.3z"
            ></path>
            <path
                fill="#F0F0F0"
                d="M287.2 126.1c-29.9-7.2-59.9 11.2-67.1 41.1-7.2 29.9 11.2 59.9 41.1 67.1 5.1-21.1 15.4-16.5 21.2-40.7 5.2-21.6-5.3-25.3 4.8-67.5zM237 244.4c-18.5-11.3-10.9-19.8-32.1-32.8-19-11.6-25.7-2.7-62.8-25.4-16.1 26.2-7.9 60.5 18.4 76.5 26.2 16.1 60.5 7.9 76.5-18.3zm-39 46.5c-16.9 14.5-10.6 23.6-43.6 51.8 20 23.4 55.1 26.2 78.5 6.2s26.2-55.1 6.2-78.5c-16.5 14.1-22.2 4.3-41.1 20.5zm66.5-14.3c8.3 20.1-2.8 22.4 6.7 45.4 8.5 20.6 19.2 17.3 35.8 57.5 28.4-11.8 41.9-44.3 30.1-72.7-11.6-28.5-44.2-42-72.6-30.2zm64.8-82.2c-30.6 2.4-53.5 29.2-51.1 59.8 21.6-1.7 20.4 9.6 45.3 7.6 22.2-1.7 22.4-12.9 65.7-16.3-2.4-30.6-29.2-53.5-59.9-51.1z"
            ></path>
        </svg>
    );
};

HK.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

HK.defaultProps = {
    color: 'currentColor',
    size: '18'
};

export default HK;
