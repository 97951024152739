import React, { useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { X as ErrorIcon, Check as CheckIcon } from 'react-feather';
import { useHistory } from 'react-router-dom';

export const PaypalErrorRender = props => {
    const { paypal } = props;
    const history = useHistory();
    const [alert, setAlert] = useState(paypal);
    const [t] = useTranslation();
    const duration = 5000;
    let timer;

    useEffect(() => {
        clearTimeout(timer);
        timer = setTimeout(() => {
            setAlert(null);
            if(history.location.paypalError) {
                history.location.paypalError = null;
            };
        }, duration);
    }, []);

    return alert && alert.message ? (
        <Alert variant={alert.state}>
            {alert.hasError ? (
                <ErrorIcon size="14" strokeWidth={'4'} color={'#B30015'} />
            ) : (
                <CheckIcon size="14" strokeWidth={'4'} />
            )}
            {t(alert.message)}
        </Alert>
    ) : null;
};
