import { formatPrice } from '@corratech/tag-manager';

function RemoveCartEvents(tagData) {
    const {
        id,
        name,
        price,
        quantityChange,
        category,
        variant,
        brand,
        urlKey,
        thumbnailUrl,
        productsDetails = [],
        currencyCode
    } =  tagData;
    return {
        event: 'removeFromCart',
        ecommerce: {
            currencyCode,
            remove: {
                products: [
                    {
                        id,
                        name,
                        price: formatPrice(price),
                        quantity: quantityChange,
                        category,
                        variant,
                        brand,
                        urlKey,
                        thumbnailUrl,
                    }
                ]
            }
        },
        cartContents: {
            currencyCode,
            products: productsDetails.reverse()
        },
        _clear: true
    };
}
export { RemoveCartEvents };
