import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
//import { AccountForm } from './AccountForm';
import ProjectConfig from '../../../project.config';
import { MegaMenu } from './MegaMenu/MegaMenu';
//import { MiniCart } from '@corratech/cart';
import { MinicartIcon, Error, StoreIcon, SearchIcon } from '../../Icons';
import { GlobalAlert } from '@corratech/global-alert';
//import { SearchBar } from '@corratech/searchbar';
import { StoreSwitcher } from './StoreSwitcher';
import { useWindowSize } from '@magento/peregrine';
import addConfigurableProductToCart from 'ModulesPath/App/Queries/addConfigurableProductToCart.graphql';
import addSimpleProductsToCart from 'ModulesPath/App/Queries/addSimpleProductsToCart.graphql';
import applyCouponToCart from 'ModulesPath/App/Queries/applyCouponToCart.graphql';
import removeCouponFromCart from 'ModulesPath/App/Queries/removeCouponFromCart.graphql';
import removeItemFromCart from 'ModulesPath/App/Queries/removeItemFromCart.graphql';
import updateCartItems from 'ModulesPath/App/Queries/updateCartItems.graphql';
import getCartQuery from 'ModulesPath/App/Queries/getCart.graphql';
import { useHistory } from 'react-router-dom';
import Logo from './Logo';
import { isSignedIn } from '@corratech/context-provider';

require('./Header.less');
const MiniCart = React.lazy(() =>
    import('@corratech/cart/src/MiniCart/MiniCart')
);
const SearchBar = React.lazy(() => import('@corratech/searchbar/SearchBar'));
const AccountForm = React.lazy(() => import('./AccountForm'));

export const Header = props => {
    const [t] = useTranslation();
    const windowSize = useWindowSize();
    const history = useHistory();
    const isTab = windowSize.innerWidth < ProjectConfig.viewport.tab;
    const [headerClicked, setHeaderClicked] = useState(false);
    const [toggle, setToggle] = useState(false);

    const toggleClick = () => {
        setHeaderClicked(!headerClicked);
    };
    const storeConfig = ProjectConfig.storeConfig
        ? ProjectConfig.storeConfig
        : {};
    const rootCatId = storeConfig.root_category_id
        ? storeConfig.root_category_id
        : 2;

    const sampleAccountLinksConfig = [
        {
            label: t('My Account'),
            link: '/my-account'
        },
        {
            label: t('My Orders'),
            link: '/my-account/orders/'
        }
    ];

    return (
        <header className={'page-header'}>
            <div className={'page-header-inner'}>
                <div className={'container-width header-container'}>
                    <div className={'logo-block'}>
                        <span className="logo">
                            <Link
                                to={'/'}
                                onClick={toggleClick}
                                aria-label={'Logo'}
                            >
                                <Logo color={'#002739'} />
                            </Link>
                        </span>
                    </div>
                    <div className={'header-utility-items'}>
                        {isTab && (
                            <div
                                className={'header-search-wrapper'}
                                onClick={toggleClick}
                            >
                                <SearchBar
                                    isTab={isTab}
                                    history={history}
                                    showResultPageLink={true}
                                    SearchIcon={<SearchIcon aria-hidden="true" color={'#002739'} />}
                                />
                            </div>
                        )}
                        {!isTab && <StoreSwitcher />}
                        {!isTab && (
                            <div className={'hdr-store-locator'}>
                                <Link
                                    to={'/store-locator'}
                                    onClick={toggleClick}
                                    aria-label={'store-locator'}
                                >
                                    <StoreIcon width={18} color={'#002739'} />
                                </Link>
                            </div>
                        )}
                        {!(isSignedIn() && isTab && toggle) && (
                            <AccountForm
                                myAccountLinksConfig={sampleAccountLinksConfig}
                            />
                        )}
                        <React.Suspense fallback="">
                            <MiniCart
                                showPromoBlock={true}
                                showFreeGifts={true}
                                addConfigurableProductToCartGraphql={
                                    addConfigurableProductToCart
                                }
                                addSimpleProductsToCartGraphql={
                                    addSimpleProductsToCart
                                }
                                applyCouponToCartGraphql={applyCouponToCart}
                                removeCouponFromCartGraphql={
                                    removeCouponFromCart
                                }
                                removeItemFromCartGraphql={removeItemFromCart}
                                updateCartItemsGraphql={updateCartItems}
                                getCartQuery={getCartQuery}
                                noDefaultVal={true}
                                ErrorIcon={<Error size={9} color={'#B30015'} />}
                                promoTitle={'ADD A PROMOTIONAL CODE'}
                                successMsg1={'Code'}
                                rearrangeElem={true}
                                costBlockVariant={'custom'}
                                showFreeSamples={true}
                                cartTitle={'Shopping Bag'}
                            >
                                <div className={`shopping-cart`}>
                                    <label className={'cart-lbl'}>
                                        <MinicartIcon
                                            color={'#002739'}
                                        />
                                    </label>
                                </div>
                            </MiniCart>
                        </React.Suspense>
                    </div>
                    {isTab ? (
                        <div className="mobile-lhs-links">
                            <div className={'nav-menu-wrapper'}>
                                <MegaMenu
                                    projectConfig={ProjectConfig}
                                    showOnyCMSBlock={true}
                                    headerClicked={headerClicked}
                                    categoryUrlSuffix={''}
                                    rootCategoryID={rootCatId}
                                    sampleAccountLinksConfig={
                                        sampleAccountLinksConfig
                                    }
                                    toggle={toggle}
                                    setToggle={setToggle}
                                />
                                {props.children}
                            </div>
                            <div className={'pm-store-locator'}>
                                <Link
                                    to={'/store-locator/'}
                                    onClick={toggleClick}
                                    aria-label={'store-locator'}
                                >
                                    <StoreIcon width={18} color={'#002739'} />
                                </Link>
                            </div>
                        </div>
                    ) : (
                        <div className={'nav-menu-wrapper'}>
                            <MegaMenu
                                projectConfig={ProjectConfig}
                                showOnyCMSBlock={true}
                                categoryUrlSuffix={''}
                                rootCategoryID={rootCatId}
                                sampleAccountLinksConfig={
                                    sampleAccountLinksConfig
                                }
                                toggle={toggle}
                                setToggle={setToggle}
                            />
                            {props.children}
                            {!isTab && (
                                <div
                                    className={'header-search-wrapper'}
                                    onClick={toggleClick}
                                >
                                    <SearchBar
                                        isTab={isTab}
                                        history={history}
                                        showResultPageLink={true}
                                        SearchIcon={<SearchIcon />}
                                    />
                                </div>
                            )}
                        </div>
                    )}
                </div>
                <GlobalAlert />
            </div>
            <div className={'hp-hdr-seperator'}></div>
        </header>
    );
};
