import React from 'react';

export const DiscoverIcon = () => {
    return (
        <svg role="img" aria-label="Payment Method Discover Icon" xmlns="http://www.w3.org/2000/svg" width="24" height="4" viewBox="0 0 24 4" fill="none">
            <path d="M12.9353 3.73458C13.909 3.73458 14.6983 2.95458 14.6983 1.99241C14.6983 1.03024 13.909 0.250244 12.9353 0.250244C11.9616 0.250244 11.1722 1.03024 11.1722 1.99241C11.1722 2.95458 11.9616 3.73458 12.9353 3.73458Z" fill="#F18A1B"/>
            <path d="M3.9054 0.335632H4.50329V3.64108H3.9054V0.335632Z" fill="black"/>
            <path d="M3.21549 1.25332C3.12352 1.05975 2.99302 0.887623 2.83222 0.747779C2.67257 0.609016 2.48767 0.503326 2.28798 0.436678C2.08183 0.372867 1.86728 0.341394 1.65175 0.343347H0.333313V3.6488H1.5751C1.78583 3.64621 1.99504 3.61214 2.19599 3.54769C2.39719 3.47917 2.58427 3.37371 2.7479 3.23659C2.92342 3.09849 3.06727 2.92334 3.16949 2.72327C3.28105 2.49652 3.33631 2.24555 3.33047 1.99218C3.34297 1.74047 3.30382 1.48887 3.21549 1.25332ZM2.62525 2.52883C2.55361 2.66287 2.45424 2.77961 2.33397 2.87104C2.2038 2.96697 2.05477 3.03329 1.89705 3.06548C1.72436 3.1106 1.54641 3.13154 1.36814 3.1277H0.931211V0.864442H1.46779C1.63821 0.865504 1.80796 0.88638 1.9737 0.926662C2.11378 0.96891 2.24591 1.03463 2.36463 1.1211C2.48026 1.21254 2.57015 1.33325 2.62525 1.47109C2.6944 1.63295 2.72839 1.80803 2.7249 1.98441C2.7368 2.17146 2.70255 2.35858 2.62525 2.52883Z" fill="black"/>
            <path d="M6.92556 1.91461C6.80585 1.84057 6.67697 1.78303 6.54229 1.74351L6.15902 1.61907C6.0552 1.58137 5.95714 1.529 5.86774 1.46351C5.79017 1.40233 5.74719 1.30638 5.75276 1.20686C5.75038 1.13901 5.76628 1.07178 5.79875 1.01242C5.82901 0.959317 5.87099 0.914059 5.9214 0.8802C5.97523 0.84401 6.0349 0.817685 6.0977 0.802425C6.16391 0.794644 6.23079 0.794644 6.297 0.802425C6.4123 0.801162 6.52679 0.822281 6.63427 0.864645C6.73677 0.904616 6.82495 0.975108 6.88723 1.06686L7.32416 0.615764C7.19666 0.49588 7.04494 0.405639 6.87957 0.351328C6.71333 0.298778 6.5401 0.272544 6.36599 0.273525C6.2132 0.274056 6.06114 0.294976 5.91373 0.335773C5.77094 0.373323 5.63594 0.436544 5.51513 0.522434C5.40198 0.604576 5.30781 0.710736 5.23918 0.833535C5.1652 0.969001 5.12816 1.12203 5.13187 1.27685C5.12315 1.44059 5.16321 1.6032 5.24685 1.74351C5.32245 1.85745 5.42191 1.95305 5.53813 2.0235C5.65854 2.09421 5.78733 2.14909 5.9214 2.18682L6.30466 2.31904C6.4097 2.35743 6.50803 2.41256 6.59595 2.48237C6.67517 2.55243 6.71769 2.65595 6.71093 2.76236C6.71242 2.83358 6.6938 2.90375 6.65727 2.96458C6.62227 3.0212 6.5751 3.06906 6.51929 3.10457C6.46038 3.14339 6.39567 3.17228 6.32766 3.19013C6.25895 3.19908 6.1894 3.19908 6.1207 3.19013C5.98995 3.18984 5.86117 3.15784 5.74509 3.0968C5.63082 3.03675 5.53328 2.94849 5.46148 2.84014L5.04755 3.30679C5.18099 3.46343 5.35288 3.58149 5.54579 3.649C5.73803 3.71673 5.94019 3.75091 6.14369 3.75011C6.30167 3.7519 6.4591 3.73095 6.61128 3.68789C6.75247 3.64857 6.8851 3.58261 7.00221 3.49345C7.11484 3.40656 7.20658 3.29487 7.2705 3.16679C7.33996 3.02155 7.37413 2.86153 7.37015 2.70014C7.37947 2.53389 7.33941 2.36862 7.25517 2.22571C7.1719 2.09633 7.05875 1.98953 6.92556 1.91461Z" fill="black"/>
            <path d="M18.8836 2.21002H20.4244V1.68114H18.8836V0.864504H20.5087V0.335632H18.2934V3.64108H20.593V3.11221H18.8836V2.21002Z" fill="black"/>
            <path d="M22.8157 2.17878C23.0408 2.17076 23.2511 2.06267 23.3906 1.88323C23.6164 1.57445 23.6548 1.16407 23.4903 0.817708C23.4201 0.699843 23.3228 0.601074 23.2066 0.52994C23.0838 0.454837 22.9488 0.402188 22.808 0.374389C22.6525 0.359741 22.496 0.359741 22.3404 0.374389H21.1447V3.67984H21.7426V2.27988H22.1795L22.946 3.67984H23.6666L22.8157 2.17878ZM22.4938 1.75101H21.7732V0.841041H22.2945H22.5244C22.5982 0.848562 22.6705 0.866901 22.739 0.895483C22.805 0.921336 22.8614 0.96759 22.9 1.0277C22.946 1.09908 22.9675 1.18379 22.9613 1.2688C22.9651 1.35604 22.9438 1.4425 22.9 1.51769C22.855 1.5799 22.794 1.62833 22.7237 1.65768C22.6512 1.69837 22.5739 1.72973 22.4938 1.75101Z" fill="black"/>
            <path d="M9.94561 3.09685C9.8218 3.15924 9.68526 3.19121 9.54701 3.19018C9.38648 3.19209 9.22729 3.16031 9.07943 3.09685C8.94336 3.03709 8.82076 2.94975 8.71916 2.84019C8.61493 2.72814 8.5341 2.59586 8.48153 2.45131C8.42466 2.29445 8.3961 2.12847 8.39721 1.96133C8.3969 1.80438 8.4255 1.64875 8.48153 1.50246C8.53591 1.36344 8.61664 1.23661 8.71916 1.12913C8.8212 1.02204 8.94382 0.937336 9.07943 0.880253C9.22748 0.817422 9.38654 0.785674 9.54701 0.786923C9.81849 0.7829 10.0774 0.902823 10.2522 1.11358L10.7121 0.779146C10.564 0.603399 10.3777 0.464952 10.1679 0.374714C9.96774 0.295165 9.75431 0.255553 9.53935 0.258051C9.29626 0.255412 9.05472 0.297576 8.82647 0.382492C8.61821 0.45886 8.42771 0.578006 8.2669 0.73248C8.1078 0.888638 7.9825 1.0767 7.89896 1.28469C7.80869 1.51464 7.76439 1.76051 7.76865 2.008C7.76458 2.25042 7.8089 2.49117 7.89896 2.71575C7.98323 2.92106 8.10851 3.10644 8.2669 3.26018C8.42811 3.41412 8.61849 3.5332 8.82647 3.61017C9.05472 3.69508 9.29626 3.73725 9.53935 3.73461C9.77882 3.73649 10.0162 3.68886 10.2369 3.59461C10.4576 3.4975 10.6483 3.34163 10.7888 3.14351L10.2982 2.8013C10.203 2.92438 10.0827 3.02519 9.94561 3.09685Z" fill="black"/>
            <path d="M16.3464 2.83222L15.4342 0.335632H14.7443L16.0628 3.64108H16.5763L17.9331 0.335632H17.2892L16.3464 2.83222Z" fill="black"/>
        </svg>
)};