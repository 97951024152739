export const addNoScriptFromRaw = (scriptContents, id) => {
    let script = document.querySelector(`noscript[id="${id}"]`);

    if (!script) {
        script = document.createElement('noscript');
        if (!!id) {
            script.id = id;
        }
        script.innerHTML = scriptContents;
        document.body.appendChild(script);
    }

    return !!script;
};
