import React, { Fragment, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const ScrollToTop = props => {
    const { top, left, children, location } = props;
    useEffect(() => {
        window.scrollTo(top, left);
    }, [location.pathname]);
    return <Fragment>{children}</Fragment>;
};
ScrollToTop.defaultProps = {
    top: 0,
    left: 0
};
ScrollToTop.propTypes = {
    top: PropTypes.number,
    left: PropTypes.number,
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
};
export default withRouter(ScrollToTop);
