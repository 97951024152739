import React, { useContext, useEffect, useState } from 'react';
import { AuthStore, isSignedIn } from '@corratech/context-provider';
import jsSha256 from 'jssha/dist/sha256';
import uuid from 'react-uuid';

const getCookieValue = cookieName => {
    let res = null;
    let cookieString = RegExp('' + cookieName + '[^;]+').exec(document.cookie);
    if (!!cookieString) {
        res = decodeURIComponent(
            cookieString.toString().replace(/^[^=]+./, '')
        );
    }
    return res;
};

const createOrRenewCookie = (
    cookieName,
    defaultValue = null,
    expirationTime = false
) => {
    const cookieValue = getCookieValue(cookieName) || defaultValue;
    const currentDate = new Date();
    currentDate.setTime(currentDate.getTime() + expirationTime);
    const expires = !!expirationTime ? currentDate.toUTCString() : '';
    document.cookie = `${cookieName}=${cookieValue}; expires=${expires}; path=/`;
};

const UNIQUE_IDENTIFIER_COOKIE = 'UUID';
const getUUIDCookie = uniqueIdentifierExpiresDays => {
    const expirationTime = uniqueIdentifierExpiresDays * 24 * 60 * 60 * 1000;
    createOrRenewCookie(UNIQUE_IDENTIFIER_COOKIE, uuid(), expirationTime);
    return getCookieValue(UNIQUE_IDENTIFIER_COOKIE);
};

const IS_VISITOR_NEW_COOKIE = 'isVisitorNew';
const checkIsVisitorNew = isVisitorNewCookieExpiresHours => {
    // A visitor is a new one if they do not have UUID or their isVisitorNew cookie has not expired
    const isVisitorNew =
        !getCookieValue(UNIQUE_IDENTIFIER_COOKIE) ||
        !!getCookieValue(IS_VISITOR_NEW_COOKIE);
    if (isVisitorNew) {
        const expirationTime = isVisitorNewCookieExpiresHours * 60 * 60 * 1000;
        createOrRenewCookie(IS_VISITOR_NEW_COOKIE, true, expirationTime);
    }
    return isVisitorNew;
};

export const TrackCustomerData = props => {
    const {
        dataLayerAction,
        isVisitorNewCookieExpiresHours,
        uniqueIdentifierExpiresDays
    } = props;
    const { authState } = useContext(AuthStore);
    const [trackedLoggedInState, setTrackedLoggedInState] = useState(null);

    const encrypt = msg => {
        const shaObj = new jsSha256("SHA-256", "TEXT", { encoding: "UTF8" });
        shaObj.update(msg);
        return {content : shaObj.getHash('HEX')};
    };

    useEffect(() => {
        const isLoggedIn = isSignedIn();
        const canTrackCustomer =
            (typeof authState.user.email !== 'undefined' && isLoggedIn) ||
            !isLoggedIn;
        if (trackedLoggedInState !== isLoggedIn && canTrackCustomer) {
            // In order to identify a new user, check this earlier than UUID cookie is created
            const isNew = checkIsVisitorNew(isVisitorNewCookieExpiresHours);
            dataLayerAction({
                type: 'CUSTOMER_EVENTS',
                data: {
                    isLoggedIn: isLoggedIn ? 1 : 0,
                    UID: getUUIDCookie(uniqueIdentifierExpiresDays),
                    id: authState.user.id,
                    isNew: isNew ? 1 : 0,
                    email: isLoggedIn ? encrypt(authState.user.email).content : '',
                    normalizedHashedEmail: isLoggedIn ? authState.user?.extension_attributes?.normalized_hashed_email : '',
                    normalizedHashedPhone: isLoggedIn ? authState.user?.extension_attributes?.normalized_hashed_phone : '',
                    userData: authState.user || {}
                }
            });
            setTrackedLoggedInState(isLoggedIn);
        }
    }, [authState]);

    return '';
};
