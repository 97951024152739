import React, { useContext, useState } from 'react';
import { bool, node, shape, string } from 'prop-types';

import classNames from 'classnames/bind';
import style from './Field.less';
import { OptionsStore } from '@corratech/context-provider';
let examineClasses = classNames.bind(style);

export const Field = props => {
    const { children, label, labelText, isChecked } = props;

    const fcOptions = useContext(OptionsStore)['form-components'];

    const focusEventEnabled = !!fcOptions && fcOptions.focusEventEnabled;

    const requiredSymbol = () => {
        return props.required ? (
            <span
                className={examineClasses('requiredSymbol')}
                aria-hidden="true"
            >
                *
            </span>
        ) : null;
    };

    const [fieldFocus, setFieldFocus] = useState(false);
    const [enableErrorClass, setEnableErrorClass] = useState(false);

    const renderElement = (child) => {
        if (
            focusEventEnabled
            && !(children.props && children.props.items)
            && (typeof child.type === 'function' || child.type.displayName === 'asField(TextInput)')
        ) {
            return React.cloneElement(child, {
                focusEventEnabled: focusEventEnabled,
                setFieldFocus: setFieldFocus,
                setEnableErrorClass: setEnableErrorClass
            })
        }

        return React.cloneElement(child, {
            setEnableErrorClass: setEnableErrorClass,
        });
    };

    let elements = [];

    if (children.length){
        elements = children;
    } else {
        elements.push(children);
    }

    return (
        <div
            className={examineClasses(
                `field-wrapper ${focusEventEnabled ? ' can-focus' : ''} ${
                    enableErrorClass ? ' error-input' : ''
                } ${fieldFocus ? ' show-label is-focused' : ''}`
            )}
        >
            <label
                htmlFor={labelText}
                className={examineClasses('label', { checked: isChecked })}
            >
                {label} {requiredSymbol()}
            </label>
            {React.Children.toArray(
                elements.map((child) => renderElement(child))
            )}
        </div>
    );
};

Field.propTypes = {
    children: node,
    classes: shape({
        label: string,
        root: string
    }),
    label: node,
    labelText: string,
    required: bool
};
