import { ForgotPasswordForm, ReLoginForm } from '@corratech/account-drawer';
import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AuthStore } from './AuthContext';
import { signOut } from './accountActions';
import { useHistory } from 'react-router-dom';
import { func } from 'prop-types';
import './ReAuthentication.less';

const ReAuthenticationContext = React.createContext();

let reauthUser = null;

function ReAuthentication({
    children,
    tokenExpTimeInMilliseconds,
    showSubTitle,
    showKeepMeSignedIn,
    ReLoginForm
}) {
    const { ready, t } = useTranslation(null, { useSuspense: false });
    const [timeoutStored, setTimeoutStored] = useState(null);

    const { authState, dispatch } = useContext(AuthStore);

    const history = useHistory();
    const redirectToPage = path => {
        if (path) {
            history.push(path);
        }
    };
    const [showModal, setShowModal] = useState(false);
    // Expose setShowModal to outside
    useEffect(() => {
        reauthUser = () => {
            setShowModal(true);
            signOut({ dispatch });
            redirectToPage('/');
        };
    }, [setShowModal]);

    // Form Management
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [email, setEmail] = useState('');

    return (
        <>
            <ReAuthenticationContext.Provider
                // Ignore this line:
                value={{
                    expiry: 1000000,
                    setExpiry: () => {},
                    resetExpiry: () => {}
                }}
            >
                {children}

                <Modal
                    show={showModal}
                    onHide={() => {
                        setShowModal(false);
                    }}
                >
                    <Modal.Header
                        closeButton
                        className="checkout-agreements-modal"
                    >
                        <Modal.Title>
                            {t(
                                'Your current session has expired, please log in again.'
                            )}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {!showForgotPassword ? (
                            <ReLoginForm
                                setEmail={setEmail}
                                email={email}
                                setShowModal={setShowModal}
                                setShowForgotPassword={setShowForgotPassword}
                                showKeepMeSignedIn={showKeepMeSignedIn}
                                timeoutStored={timeoutStored}
                                // Ignore this line:
                                setTimeoutStored={() => {}}
                            />
                        ) : (
                            <ForgotPasswordForm
                                updateEmail={setEmail}
                                enteredEmail={email}
                                signInClick={() => setShowForgotPassword(false)}
                                showSubTitle={showSubTitle}
                            />
                        )}
                    </Modal.Body>
                </Modal>
            </ReAuthenticationContext.Provider>
        </>
    );
}

ReAuthentication.propTypes = {
    ReLoginForm: func
};

ReAuthentication.defaultProps = {
    ReLoginForm: ReLoginForm
};

export { ReAuthenticationContext, ReAuthentication, reauthUser };
