import React from 'react';
import classNames from 'classnames/bind';
import { getIn } from 'formik';
import { FieldIcons } from '../Field';
import style from '../Message/Message.less';
let examineClasses = classNames.bind(style);

const TextInputFormik = ({
         field,
         form: { errors, touched },
         after,
         before,
         enableError,
         setErrorClass,
         focusEventEnabled,
         setFieldFocus,
         setEnableErrorClass,
         maxlength,
         ...rest
     }) => {
    const errorMessage = getIn(errors, field.name);
    const touch = getIn(touched, field.name);

    const className = errorMessage
        ? examineClasses('field-required-error')
        : examineClasses('');

    if (enableError) {
        errorMessage ? setErrorClass(true) : setErrorClass(false);
    }

    //Only on Focus/Blur
    if (focusEventEnabled) {
        errorMessage ? setEnableErrorClass(true) : setEnableErrorClass(false);
        if (field.value) setFieldFocus(true);
    }

    const onFieldFocus = () => {
        if (focusEventEnabled) setFieldFocus(true);
    };

    const onFieldBlur = () => {
        if (focusEventEnabled) {
            if (!field.value) setFieldFocus(false);
        }
    };

    return (
        <>
            <FieldIcons after={after} before={before}>
                <input
                    {...field}
                    className={className}
                    onFocus={onFieldFocus}
                    onBlur={e => {
                        onFieldBlur(e);
                        field.onBlur(e);
                    }}
                    maxLength={maxlength}
                    {...rest}
                />
            </FieldIcons>
            {touch && errorMessage && (
                <span className="root_error">
                    <p className={className}>{errorMessage}</p>
                </span>
            )}
        </>
    );
};

export default TextInputFormik;
