import React from 'react';
//maintenance page html

const MaintenancePage = () => {
    const InstagramLink = '#';
    const mail = '#';
    return (
        <div>
            <style
                dangerouslySetInnerHTML={{
                    __html:
                        'body{margin:0;padding:0}.maintanance-wrap{height:100vh;background:#f4ecec;display:flex;align-items:center;justify-content:center;padding:30px;flex-direction:column;background-repeat:no-repeat;background-position:center;background-size:cover;background-image:url(https://mcstaging.elemis.com/media/wysiwyg/site-maintenance-bg.jpg)}.maintanance-logo svg{max-width:246px;width:100%}.maintanace-content{text-align:center}.maintanace-content h1{font-family:Arial,Helvetica,sans-serif;font-size:38px;line-height:46px;text-align:center;font-weight:400;margin:0;text-transform:capitalize}.maintanace-content p{font-family:Arial,Helvetica,sans-serif;margin:0;padding:0;font-size:16px;line-height:26px}.maintanace-content a,.maintanace-content a:active,.maintanace-content a:focus,.maintanace-content a:hover{color:#000;text-decoration:underline}@media screen and (max-width:767px){.maintanace-content h1{padding:78px 0 30px}}@media screen and (min-width:768px){.maintanace-content h1{padding:63px 0 35px}}'
                }}
            />
            <div className="maintanance-wrap">
                <div className="maintanace-content">
                    <div className="maintanance-logo">
                        <svg
                            width={500}
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 839.81 233.2"
                        >
                            <path
                                d="M832.71,25.6V11.9c0-2.1-.3-3.7-2.8-4.7a88.35,88.35,0,0,0-35-7.2c-32.4,0-54.9,11.4-55.3,39.2-.8,46.1,75.5,39,75,67.5-.3,18.7-18.3,23.1-35.8,22.5a80,80,0,0,1-35.8-9.6c-2.4-1.2-3.5-1.3-3.6,2.2l-.1,15.4c0,2.5.9,3.7,2.6,4.6,11.3,5.6,27.9,8.3,39.4,8.3,35.6,0,58.5-12.7,58.5-43.2,0-49.3-75.7-43.8-75.3-68.8.3-15.5,17.6-18.5,31.1-18.4a78.81,78.81,0,0,1,33.4,8C831.71,28.9,832.71,28.1,832.71,25.6ZM499.91,5c-1.2-1.7-2.8-2.9-4.4-2.9h-12.2c-2.8,0-3.8,1-3.8,3.7V144.1c0,2.7,1,3.7,3.8,3.7h16c2.8,0,3.8-1,3.8-3.7V47.9l47.2,72.1c1.6,2.4,2.6,3.2,4.9,3.2s3.4-.8,5-3.2l47.2-72.1V144c0,2.7,1,3.7,3.8,3.7h16c2.8,0,3.8-1,3.8-3.7V5.8c0-2.7-1-3.7-3.8-3.7H615c-1.6,0-3.2,1.2-4.4,2.9l-55.4,84.1ZM42.61,91c13.1-.3,25.4-4.4,31-6.3,34.3-11.6,46.9-14.6,61.2-10.2.8.3,1.9.7,2.2.3s-.3-1.7-.8-2.5c-18.4-23.6-56.5-13.7-70.5-9.3C27.81,74.7,18,77.5,2.21,74c-.9-.2-1.7-.6-2.1-.1s.4,1.9,1.1,2.7a40.65,40.65,0,0,0,17.6,11.9v55.7c0,2.8.6,3.7,2.9,3.7h112.4c2.3,0,2.9-.6,2.9-2.9V129c0-2.3-.6-2.9-2.9-2.9H42.81V91Zm.1-67.5h91.2c2.3,0,2.9-.6,2.9-2.9V4.9c0-2.3-.6-2.9-2.9-2.9H21.51c-2.3,0-2.9.6-2.9,3.5V64.7c10.4-.5,21.3-5.3,24.2-12.7ZM342.91,91c13.1-.3,25.4-4.4,31-6.3,34.3-11.6,46.9-14.6,61.2-10.2.8.3,1.9.7,2.2.3s-.3-1.7-.8-2.5c-18.6-23.5-56.7-13.6-70.7-9.2-37.9,11.7-47.7,14.5-63.6,11-.9-.2-1.7-.6-2.1-.1s.4,1.9,1.1,2.7a40.65,40.65,0,0,0,17.6,11.9v55.7c0,2.8.6,3.7,2.9,3.7h112.4c2.3,0,2.9-.6,2.9-2.9v-16c0-2.3-.6-2.9-2.9-2.9h-91.3V91Zm.1-67.5h91.2c2.3,0,2.9-.6,2.9-2.9V4.9c0-2.3-.6-2.9-2.9-2.9l-112.4.1c-2.3,0-2.9.6-2.9,3.5V64.8c10.4-.5,21.2-5.3,24.1-12.7ZM678.91,2c-2.8,0-3.7,1-3.7,3.7V144.2c0,2.7.9,3.7,3.7,3.7H696c2.8,0,3.7-1,3.7-3.7V5.8c0-2.7-1-3.7-3.7-3.7h-17.1Zm-392,127.3v15.4c0,2.8-.5,3.2-3.4,3.2H182.91c-2.8,0-3.7-.9-3.7-3.7V5.4c0-2.8.5-3.4,3.4-3.4h17.6c2.8,0,3.4.6,3.4,3.4V126.1h79.8c2.8,0,3.5.4,3.5,3.2Z"
                                transform="translate(0 0)"
                                style={{ fill: '#0e2039', fillRule: 'evenodd' }}
                            />
                            <path
                                d="M408,192.8V232h-7.4l-19.9-24.2h-.1V232H372V192.8h7.4l20,24.3h.1V192.8Z"
                                transform="translate(0 0)"
                                style={{ fill: '#0e2039' }}
                            />
                            <path
                                d="M333.91,191.6c12.4,0,22.4,9.3,22.4,20.7,0,11.6-10,20.9-22.4,20.9s-22.1-9.2-22.1-20.9C311.81,200.9,321.51,191.6,333.91,191.6Zm0,34.2a13.4,13.4,0,0,0,0-26.8h0a13.11,13.11,0,0,0-13.1,13.14v.16a13.3,13.3,0,0,0,13.1,13.5Z"
                                transform="translate(0 0)"
                                style={{ fill: '#0e2039', fillRule: 'evenodd' }}
                            />
                            <path
                                d="M427,232V192.8h18.4c7.2,0,11.9,2,15.4,5.6a19.92,19.92,0,0,1,5.3,14,20.34,20.34,0,0,1-5.6,14.5c-3.4,3.2-7.6,5.2-15,5.2H427Zm18.1-7.4c4.5,0,6.4-1,8.4-2.8a14.14,14.14,0,0,0,.3-18.5c-2-2-4.1-3.1-8.7-3.1h-9.5v24.4Z"
                                transform="translate(0 0)"
                                style={{ fill: '#0e2039', fillRule: 'evenodd' }}
                            />
                            <path
                                d="M574.81,192.8V232h-7.4l-19.9-24.2h-.1V232h-8.6V192.8h7.4l20,24.3h.1V192.8Z"
                                transform="translate(0 0)"
                                style={{ fill: '#0e2039' }}
                            />
                            <path
                                d="M500.81,191.6c12.4,0,22.4,9.3,22.4,20.7,0,11.6-10,20.9-22.4,20.9s-22.1-9.2-22.1-20.9C478.71,200.9,488.41,191.6,500.81,191.6Zm0,34.2a13.4,13.4,0,0,0,0-26.8h0a13.4,13.4,0,0,0,0,26.8Z"
                                transform="translate(0 0)"
                                style={{ fill: '#0e2039' }}
                            />
                            <path
                                d="M278.61,192.8v31.8h23V232H270V192.8Z"
                                transform="translate(0 0)"
                                style={{ fill: '#0e2039' }}
                            />
                        </svg>
                    </div>
                    <h1>Something Amazing Coming</h1>
                    <p>Sorry, we're down for maintenance.</p>
                    <p>
                        We will be backup shortly. Check out our
                        <a href={InstagramLink}>Instagram Shop</a> for the
                        meantime, <a href={mail}>@Elemis</a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default MaintenancePage;
