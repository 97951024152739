import React, { useEffect } from 'react';
import { useGlobalOptions } from '@corratech/context-provider';
import { createScript } from './utils/createScript';
import { useScript } from '@corratech/bll/script';

export default function useListrak() {
    const options = useGlobalOptions();

    const config = {
        enable: false,
        merchatId: options.storeConfig.listrak_merchant_id
    };

    if (!config.enable || config.merchatId === '') return null;

    const [ready, status] = useScript(
        'https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js'
    );

    const scriptBody = `var biJsHost = (("https:" == document.location.protocol) ? "https://" : "http://");
        (function (d, s, id, tid, vid) {
          var js, ljs = d.getElementsByTagName(s)[0];
          if (d.getElementById(id)) return; js = d.createElement(s); js.id = id;
          js.src = biJsHost + "cdn.listrakbi.com/scripts/script.js?m=" + tid + "&v=" + vid;
          ljs.parentNode.insertBefore(js, ljs);
        })(document, 'script', 'ltkSDK', '${config.merchatId}', '1');`;

    useEffect(() => {
        const listrakGlobalEl = document.getElementById('listrak-global');

        if (
            config.enable &&
            config.merchatId &&
            listrakGlobalEl == null &&
            ready
        ) {
            document.body.appendChild(
                createScript(scriptBody, 'listrak-global')
            );
        }

        return () => {
            if (listrakGlobalEl !== null) {
                document.getElementById('listrak-global').remove();
            }
        };
    }, [ready, document]);
}
