import React from 'react';
import PropTypes from 'prop-types';

const Logo = props => {
    const { color, size, ...otherProps } = props;

    return (
        <svg width="100%" height="100%" viewBox="0 0 200 62" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#christmasLogoClipPath)">
                <path id="christmasLogoCanvas" fill="#fff" d="M-1 -1h202v72H-1z" />
                <mask id="christmasLogoMask" className="christmas-logo-mask-type-alpha" maskUnits="userSpaceOnUse" x="1" y="5" width="195" height="57">
                    <g fill="#000">
                        <path d="M67.44 52.309H65.6v8.433h6.779v-1.589h-4.94V52.31zM79.297 52.051c-2.661 0-4.744 2-4.744 4.456 0 2.454 2.083 4.495 4.744 4.495 2.66 0 4.795-1.99 4.795-4.495 0-2.507-2.135-4.456-4.795-4.456zm0 7.36c-1.583 0-2.817-1.318-2.817-2.907s1.234-2.855 2.817-2.855a2.872 2.872 0 0 1 2.868 2.855c0 1.564-1.26 2.907-2.868 2.907zM93.312 57.539H93.3l-4.282-5.23h-1.583v8.433h1.853v-5.218h.012l4.268 5.218h1.596V52.31h-1.852v5.23zM106.479 53.509c-.746-.763-1.75-1.2-3.292-1.2H99.24v8.433h3.947c1.582 0 2.495-.425 3.215-1.111.785-.775 1.209-1.886 1.209-3.113 0-1.226-.398-2.246-1.132-3.01zm-1.557 5.036c-.438.388-.836.606-1.801.606h-2.043V53.91h2.043c.991 0 1.44.246 1.864.672.464.477.709 1.149.709 1.95 0 .85-.282 1.522-.772 2.014zM115.056 52.051c-2.661 0-4.744 2-4.744 4.456 0 2.454 2.083 4.495 4.744 4.495 2.661 0 4.795-1.99 4.795-4.495 0-2.507-2.134-4.456-4.795-4.456zm0 7.36c-1.582 0-2.817-1.318-2.817-2.907s1.235-2.855 2.817-2.855a2.871 2.871 0 0 1 2.868 2.855c0 1.564-1.26 2.907-2.868 2.907zM129.073 57.539h-.014l-4.282-5.23h-1.58v8.433h1.85v-5.218h.014l4.269 5.218h1.593V52.31h-1.85v5.23z" />
                        <path d="M174.989 14.96c0-2.624 2.258-4.702 7.577-4.702 5.76 0 8.032 2.771 9.208 4.023l2.806-7.608c-1.158.038-1.29.05-3.366-.374-2.202-.45-6.005-1.297-8.596-1.297-9.461 0-13.894 4.367-13.894 9.96 0 12.866 20.539 8.263 20.539 15.735 0 2.574-2.308 4.503-7.68 4.503-.156 0-5.775.276-11.059-5.102l-3.235 7.975c4.48-.428 7.523 2.472 14.294 2.472 9.46 0 13.943-4.306 13.943-9.848 0-12.716-20.534-8.361-20.534-15.735l-.003-.002z">
                        </path>
                        <path d="M67.914 32.525c-1.691 1.088-4.066 2.347-8.502 2.347H46.688V5.744h-5.634v34.122H66.72l1.193-7.343v.002zM10.86 17.443V10.74h14.587c4.436 0 6.813 1.259 8.502 2.347l-1.193-7.344-27.53.005v14.68c2.424-.115 4.975-1.25 5.635-2.986zM10.86 34.872v-8.326c3.056-.066 5.733-1.2 7.026-1.633 7.994-2.72 11.291-3.91 14.602-2.879-4.3-5.51-13.204-3.201-16.47-2.188C7.192 22.584 4.7 23.831 1 23.024c1.869 2.296 4.227 2.94 4.227 2.94v13.902h27.532l1.192-7.343c-1.691 1.088-4.065 2.347-8.502 2.347H10.863l-.002.002zM73.068 20.429c2.424-.115 4.975-1.25 5.634-2.986V10.74h14.586c4.437 0 6.814 1.259 8.503 2.347l-1.193-7.344-27.53.005v14.68zM101.791 32.525c-1.692 1.088-4.066 2.347-8.503 2.347H78.702v-8.326c3.055-.066 5.732-1.2 7.026-1.633 7.994-2.72 11.29-3.91 14.602-2.879-4.301-5.51-13.204-3.201-16.471-2.188-8.824 2.74-11.317 3.985-15.017 3.18 1.868 2.296 4.226 2.94 4.226 2.94v13.903H100.6l1.193-7.344h-.002zM144.58 11.578a15.077 15.077 0 0 1 1.559-5.834h-5.998l-13.444 20.318-13.442-20.318h-5.998c.9 1.821 1.447 3.803 1.559 5.834.047.843.07 1.688.07 2.53v19.203c0 2.338-.75 4.774-1.629 6.558h8.68c-.879-1.779-1.415-4.22-1.415-6.558V16.813l10.749 16.258h2.856l10.749-16.258V33.31c0 2.338-.536 4.779-1.414 6.557h8.679c-.881-1.78-1.629-4.22-1.629-6.557v-19.2c0-.846.024-1.688.07-2.53h-.002zM161.4 5.747h-8.679c.878 1.778 1.522 4.219 1.522 6.557V33.31c0 2.338-.644 4.779-1.522 6.558h8.679c-.878-1.78-1.522-4.22-1.522-6.558V12.304c0-2.338.644-4.779 1.522-6.557z" />
                    </g>
                </mask>
                <g id="christmasLogoBase" fill="#002739">
                    <path d="M67.44 52.309H65.6v8.433h6.779v-1.589h-4.94V52.31zM79.297 52.051c-2.661 0-4.744 2-4.744 4.456 0 2.454 2.083 4.495 4.744 4.495 2.66 0 4.795-1.99 4.795-4.495 0-2.507-2.135-4.456-4.795-4.456zm0 7.36c-1.583 0-2.817-1.318-2.817-2.907s1.234-2.855 2.817-2.855a2.872 2.872 0 0 1 2.868 2.855c0 1.564-1.26 2.907-2.868 2.907zM93.312 57.539H93.3l-4.282-5.23h-1.583v8.433h1.853v-5.218h.012l4.268 5.218h1.596V52.31h-1.852v5.23zM106.479 53.509c-.746-.763-1.75-1.2-3.292-1.2H99.24v8.433h3.947c1.582 0 2.495-.425 3.215-1.111.785-.775 1.209-1.886 1.209-3.113 0-1.226-.398-2.246-1.132-3.01zm-1.557 5.036c-.438.388-.836.606-1.801.606h-2.043V53.91h2.043c.991 0 1.44.246 1.864.672.464.477.709 1.149.709 1.95 0 .85-.282 1.522-.772 2.014zM115.056 52.051c-2.661 0-4.744 2-4.744 4.456 0 2.454 2.083 4.495 4.744 4.495 2.661 0 4.795-1.99 4.795-4.495 0-2.507-2.134-4.456-4.795-4.456zm0 7.36c-1.582 0-2.817-1.318-2.817-2.907s1.235-2.855 2.817-2.855a2.871 2.871 0 0 1 2.868 2.855c0 1.564-1.26 2.907-2.868 2.907zM129.073 57.539h-.014l-4.282-5.23h-1.58v8.433h1.85v-5.218h.014l4.269 5.218h1.593V52.31h-1.85v5.23z" />
                    <path d="M174.989 14.96c0-2.624 2.258-4.702 7.577-4.702 5.76 0 8.032 2.771 9.208 4.023l2.806-7.608c-1.158.038-1.29.05-3.366-.374-2.202-.45-6.005-1.297-8.596-1.297-9.461 0-13.894 4.367-13.894 9.96 0 12.866 20.539 8.263 20.539 15.735 0 2.574-2.308 4.503-7.68 4.503-.156 0-5.775.276-11.059-5.102l-3.235 7.975c4.48-.428 7.523 2.472 14.294 2.472 9.46 0 13.943-4.306 13.943-9.848 0-12.716-20.534-8.361-20.534-15.735l-.003-.002z">
                    </path>
                    <path d="M67.914 32.525c-1.691 1.088-4.066 2.347-8.502 2.347H46.688V5.744h-5.634v34.122H66.72l1.193-7.343v.002zM10.86 17.443V10.74h14.587c4.436 0 6.813 1.259 8.502 2.347l-1.193-7.344-27.53.005v14.68c2.424-.115 4.975-1.25 5.635-2.986zM10.86 34.872v-8.326c3.056-.066 5.733-1.2 7.026-1.633 7.994-2.72 11.291-3.91 14.602-2.879-4.3-5.51-13.204-3.201-16.47-2.188C7.192 22.584 4.7 23.831 1 23.024c1.869 2.296 4.227 2.94 4.227 2.94v13.902h27.532l1.192-7.343c-1.691 1.088-4.065 2.347-8.502 2.347H10.863l-.002.002zM73.068 20.429c2.424-.115 4.975-1.25 5.634-2.986V10.74h14.586c4.437 0 6.814 1.259 8.503 2.347l-1.193-7.344-27.53.005v14.68zM101.791 32.525c-1.692 1.088-4.066 2.347-8.503 2.347H78.702v-8.326c3.055-.066 5.732-1.2 7.026-1.633 7.994-2.72 11.29-3.91 14.602-2.879-4.301-5.51-13.204-3.201-16.471-2.188-8.824 2.74-11.317 3.985-15.017 3.18 1.868 2.296 4.226 2.94 4.226 2.94v13.903H100.6l1.193-7.344h-.002zM144.58 11.578a15.077 15.077 0 0 1 1.559-5.834h-5.998l-13.444 20.318-13.442-20.318h-5.998c.9 1.821 1.447 3.803 1.559 5.834.047.843.07 1.688.07 2.53v19.203c0 2.338-.75 4.774-1.629 6.558h8.68c-.879-1.779-1.415-4.22-1.415-6.558V16.813l10.749 16.258h2.856l10.749-16.258V33.31c0 2.338-.536 4.779-1.414 6.557h8.679c-.881-1.78-1.629-4.22-1.629-6.557v-19.2c0-.846.024-1.688.07-2.53h-.002zM161.4 5.747h-8.679c.878 1.778 1.522 4.219 1.522 6.557V33.31c0 2.338-.644 4.779-1.522 6.558h8.679c-.878-1.78-1.522-4.22-1.522-6.558V12.304c0-2.338.644-4.779 1.522-6.557z" />
                </g>
                <g id="christmasLogoTexture" mask="url(#christmasLogoMask)">
                    <circle cx="7" cy="37" fill="#A53C19" r="7" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="33" cy="39" fill="#A53C19" r="3" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="173" cy="17" fill="#A53C19" r="7" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="175" cy="22" fill="#463221" r="3" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="181" cy="19" fill="#463221" r="3" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="181" cy="25" fill="#8B91B2" r="5" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="154" cy="20" fill="#8B91B2" r="5" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="114" cy="16" fill="#8B91B2" r="5" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="88" cy="41" fill="#8B91B2" r="5" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="93" cy="38" fill="#8B91B2" r="5" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="35" cy="10" fill="#A53C19" r="7" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="187" cy="24" fill="#A53C19" r="7" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="162" cy="41" fill="#A53C19" r="7" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="111.5" cy="25.5" fill="#A53C19" r="5.5" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="110.5" cy="11.5" fill="#A53C19" r="3.5" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="118.5" cy="24.5" fill="#A53C19" r="5.5" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="21" cy="24" fill="#A53C19" r="7" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="75" cy="17" fill="#A53C19" r="7" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="70" cy="41" fill="#5B4228" r="7" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="100.5" cy="35.5" fill="#5B4228" r="5.5" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="148" cy="14" fill="#5B4228" r="7" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="122" cy="59" fill="#5B4228" r="7" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="147.5" cy="37.5" fill="#5B4228" r="5.5" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="158" cy="3" fill="#5B4228" r="7" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="93" cy="10" fill="#854936" r="11" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="26" cy="27" fill="#D3704F" r="7" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="177" cy="37" fill="#D3704F" r="7" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="79" cy="23" fill="#D3704F" r="7" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="114" cy="60" fill="#D3704F" r="7" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="98" cy="50" fill="#D3704F" r="7" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                    <circle cx="65" cy="50" fill="#D3704F" r="7" className="christmas-logo-mix-blend-mode-difference" opacity=".6" />
                </g>
                <g id="christmasLogoCirclesGroup1" mask="url(#christmasLogoMask)" className="christmas-logo-mix-blend-mode-plus-lighter">
                    <path d="M95.652 41.5c0 3.038-2.654 5.5-5.929 5.5-3.274 0-5.929-2.462-5.929-5.5s2.655-5.5 5.93-5.5c3.274 0 5.928 2.462 5.928 5.5z" fill="red" fillOpacity=".5" />
                    <path className="christmas-logo-circle1" d="M83.794 15c0 6.627-5.662 12-12.648 12-6.985 0-12.648-5.373-12.648-12S64.161 3 71.146 3c6.986 0 12.648 5.373 12.648 12z" fill="#5C6C94">
                    </path>
                    <path className="christmas-logo-circle2" d="M200 33c0 9.941-8.507 18-19 18s-19-8.059-19-18 8.507-18 19-18 19 8.059 19 18z" fill="red" />
                </g>
                <g id="christmasLogoCirclesGroup2" mask="url(#christmasLogoMask)" className="christmas-logo-mix-blend-mode-plus-lighter" opacity=".5">
                    <path className="christmas-logo-circle3" d="M60.87 49c0 11.598-9.91 21-22.135 21-12.224 0-22.134-9.402-22.134-21s9.91-21 22.134-21C50.96 28 60.87 37.402 60.87 49z" fill="red" />
                    <path className="christmas-logo-circle4" d="M158.893 28.5c0 12.979-10.971 23.5-24.506 23.5-13.534 0-24.506-10.521-24.506-23.5S120.853 5 134.387 5c13.535 0 24.506 10.521 24.506 23.5z" fill="#674C67" />
                    <path className="christmas-logo-circle5" d="M103.557 21.5c0 14.636-12.564 26.5-28.063 26.5-15.499 0-28.063-11.864-28.063-26.5S59.995-5 75.494-5c15.499 0 28.063 11.864 28.063 26.5z" fill="red" />
                    <path className="christmas-logo-circle6" d="M41.897 22c0 14.36-12.387 26-27.668 26-15.28 0-27.668-11.64-27.668-26S-1.05-4 14.23-4 41.897 7.64 41.897 22z" fill="#A77945" />
                </g>
            </g>
            <defs>
                <clipPath id="christmasLogoClipPath">
                    <path fill="#fff" d="M0 0h200v70H0z" />
                </clipPath>
            </defs>
        </svg>
    );
};

Logo.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Logo.defaultProps = {
    color: 'currentColor',
    size: '24'
};

export default Logo;
