import { useRef } from 'react';

export const useRefState = (initialState) => {
    const ref = useRef(initialState);
    const setState = (state) => {
        ref.current = state;
    }
    const getState = () => {
        return ref.current;
    }
    
    return [
        getState,
        setState
    ];
}