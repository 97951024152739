import React from 'react';
import PropTypes from 'prop-types';

const CloseIcon = props => {
    const { color, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            viewBox="0 0 8.11 8.11"
        >
            <g>
                <path
                    fill="none"
                    stroke={color}
                    strokeWidth="0.55"
                    d="M0.19 0.19L7.91 7.91"
                ></path>
                <path
                    fill="none"
                    stroke={color}
                    strokeWidth="0.55"
                    d="M7.91 0.19L0.19 7.91"
                    className="cls-1"
                    data-name="Line"
                ></path>
            </g>
        </svg>
    );
};

CloseIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

CloseIcon.defaultProps = {
    color: 'currentColor',
    size: '24'
};

export default CloseIcon;
