import { useContext } from 'react';
import { CartStore } from '../CartContext';

export const useCart = () => {
    const { cartState, dispatch } = useContext(CartStore);

    return {
        cartState,
        setIsMinicartOpen: isOpen =>
            dispatch({ type: 'SHOULD_OPEN_DRAWER', shouldOpenDrawer: isOpen }),
        openMinicart: () =>
            dispatch({ type: 'SHOULD_OPEN_DRAWER', shouldOpenDrawer: true }),
        setCart: cart => dispatch({ type: 'SET_CART', cart }),
        clearCart: () => dispatch({ type: 'CLEAR_CART' })
    };
};
