import React, { Suspense } from 'react';
import getContentTypeConfig from './config';
import { useGlobalOptions } from '@corratech/context-provider';
import { bool } from 'prop-types';

/**
 * Render a content type
 *
 * @param Component
 * @param data
 * @returns {*}
 */
const renderContentType = (
    Component,
    data,
    AddToWishlist,
    AddToWishlistLabel,
    globalOptions,
    isLazyLoad
) => {
    return (
        <Component
            {...data}
            AddToWishlist={AddToWishlist}
            AddToWishlistLabel={AddToWishlistLabel}
            globalOptions={globalOptions}
            isLazyLoad={isLazyLoad}
        >
            {data.children.map((childTreeItem, i) => (
                <ContentTypeFactory
                    key={i}
                    data={childTreeItem}
                    AddToWishlist={AddToWishlist}
                    AddToWishlistLabel={AddToWishlistLabel}
                    globalOptions={globalOptions}
                    isLazyLoad={isLazyLoad}
                />
            ))}
        </Component>
    );
};

/**
 * Create an instance of a content type component based on configuration
 *
 * @param data
 * @returns {*}
 * @constructor
 */
const ContentTypeFactory = ({
    data,
    AddToWishlist,
    AddToWishlistLabel,
    isLazyLoad
}) => {
    const { isHidden, ...props } = data;

    const globalOptions = useGlobalOptions();

    if (isHidden) {
        return null;
    }

    const contentTypeConfig = getContentTypeConfig(props.contentType);
    if (contentTypeConfig && contentTypeConfig.component) {
        return (
            <Suspense fallback={''}>
                {renderContentType(
                    contentTypeConfig.component,
                    props,
                    AddToWishlist,
                    AddToWishlistLabel,
                    globalOptions,
                    isLazyLoad
                )}
            </Suspense>
        );
    }

    return null;
};

ContentTypeFactory.propTypes = {
    isLazyLoad: bool
};

ContentTypeFactory.defaultProps = {
    isLazyLoad: true
};

export default ContentTypeFactory;
