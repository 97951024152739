import React, { useEffect, useContext } from 'react';
import { CartStore } from '@corratech/context-provider';
import { Util } from '@magento/peregrine';
const { BrowserPersistence } = Util;
const storage = new BrowserPersistence();

export default function useListrakCart() {
    const { cartState, dispatch } = useContext(CartStore);

    useEffect(() => {
        /**
         * Preventing unwanted conditions
         */
        if (storage.getItem('minicart_opened')) {
            storage.removeItem('minicart_opened');
        }
    }, []);

    useEffect(() => {
        try {
            /**
             * Opening mincart when cart id is passed through url
             */
            if (!storage.getItem('minicart_opened') && !cartState.isLoading) {
                let params = new URLSearchParams(location.search);
                let urlCartId = params.get('ltkMeta1');
                if (urlCartId && urlCartId != '') {
                    if (window.location.pathname !== '/checkout') {
                        if (
                            typeof cartState.cart.items != 'undefined' &&
                            cartState.cart.items.length
                        ) {
                            dispatch({
                                type: 'SHOULD_OPEN_DRAWER',
                                shouldOpenDrawer: true
                            });

                            storage.setItem('minicart_opened', true);
                        }
                    }
                }
            }
        } catch (e) {
            console.warn(e);
        }
    }, [cartState.isLoading]);
}
