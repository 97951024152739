import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyright } from '@corratech/bll/footer';
import './FooterBlock.less';
import LockIcon from './LockIcon';

export const FooterBlock = () => {
    const [t] = useTranslation();
    const { copyrightText } = useCopyright();

    return (
        <footer className={'checkout-footer'}>
            <div className={'container-width'}>
                <div className={'checkout-footer-left'}>
                    <div className={'checkout-icon-block'}>
                        <p className={'checkout-footer-lock'}>
                            <LockIcon />
                        </p>
                        <p className={'checkout-icon-label'}>
                            {t('Secure Checkout')}
                        </p>
                    </div>
                </div>
                <div className={'checkout-footer-right'}>
                    {copyrightText || ''}
                </div>
            </div>
        </footer>
    );
};
