import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFormApi } from 'informed';

export const SubmitButton = props => {
    const { loading, onSubmit, btnLabel } = props;
    const [t] = useTranslation();
    const formApi = useFormApi();

    return (
        <Button
            size="lg"
            variant="primary"
            onClick={() => onSubmit(formApi)}
            disabled={loading}
        >
            {t(btnLabel)}
        </Button>
    );
};

SubmitButton.propTypes = {
    loading: PropTypes.bool,
    onSubmit: PropTypes.func,
    btnLabel: PropTypes.string
};

SubmitButton.defaultProps = {
    loading: false,
    btnLabel: 'Subscribe'
};
