import React, { Suspense, useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ApolloLink } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { RetryLink } from 'apollo-link-retry';

import { Util } from '@magento/peregrine';
import { CorraContextProvider } from '@corratech/context-provider';
import store from './store';
import app from '@magento/peregrine/lib/store/actions/app';
import { App } from './Modules/App/App';
import { init_i18n } from './i18n';
import LoadingIndicator from './Modules/LoadingIndicator';

const { BrowserPersistence } = Util;
const apiBase = new URL('/graphql', location.origin).toString();
import { registerSW } from './registerSW';
import { useCookies } from 'react-cookie';
import { OptionsStore } from '@corratech/context-provider';

import cartQuery from './Modules/App/Queries/cartQuery.graphql';
import { queryCustomerCart } from './Modules/App/Queries/queryCustomerCart';
import { mutationMergeCarts } from './Modules/App/Queries/mutationMergeCarts';
import { useListrakAbandonedCart } from './Modules/AbandonedCart';
import MaintenancePage from 'ModulesPath/Maintenance/MaintenancePage';

const REGIONAL_COOKIE = 'regional-preference-url';

/**
 * The Venia adapter provides basic context objects: a router, a store, a
 * GraphQL client, and some common functions.
 */

// The Venia adapter is not opinionated about auth.
const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists.
    const storage = new BrowserPersistence();
    const token = storage.getItem('signin_token');
    const currencyMap = {
        uk: 'GBP',
        us: 'USD',
        eu: 'EUR'
    };
    const ContentCurrency = window.STORE_CODE
        ? currencyMap[window.STORE_CODE]
        : '';

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : '',
            Store: window.STORE_CODE ? window.STORE_CODE : '',
            'Content-Currency': ContentCurrency
        }
    };
});

// @see https://www.apollographql.com/docs/link/composition/.
const apolloLink = ApolloLink.from([
    // by default, RetryLink will retry an operation five (5) times.
    //Included CorraContextProvider.maintenanceEnabled condition, this will be false by default
    new RetryLink({
        attempts: {
            max: 5,
            retryIf: (error, _operation) =>
                !CorraContextProvider.maintenanceEnabled &&
                !!error &&
                _operation.operationName != 'specialCase'
        }
    }),
    authLink,
    // An apollo-link-http Link
    CorraContextProvider.apolloLink(apiBase)
]);

const InitalRender = props => {
    const [cookies, setCookies, removeCookie] = useCookies([REGIONAL_COOKIE]);
    const projectConfig = useContext(OptionsStore) || {};
    const storeConfig = projectConfig.storeConfig || {};
    const { cookie_domain, cookie_httponly, cookie_path } = storeConfig;

    //Setting cartId from listrak url
    useListrakAbandonedCart();

    useEffect(() => {
        removeCookie(REGIONAL_COOKIE);
        setCookies(
            REGIONAL_COOKIE,
            { value: window.location.origin },
            {
                path: cookie_path || '/',
                domain: cookie_domain || '.elemis.com',
                httpOnly: cookie_httponly || false
            }
        );
    }, []);

    return (
        <CorraContextProvider
            apiBase={apiBase}
            stopMakeCart={true}
            apollo={{ link: apolloLink }}
            loadingIndicator={LoadingIndicator}
            tokenExpTimeInMilliseconds={60 * 60 * 1000}
            globalOptions={require('./project.config')}
            customerCartQuery={queryCustomerCart}
            cartQuery={cartQuery}
            mergeCartMutation={mutationMergeCarts}
            MaintenancePage={MaintenancePage}
            showMaintenancePage={true}
        >
            <App />
        </CorraContextProvider>
    );
};

ReactDOM.render(<InitalRender />, document.getElementById('root'));

window.addEventListener('load', registerSW);

window.addEventListener('online', () => {
    store.dispatch(app.setOnline());
});
window.addEventListener('offline', () => {
    store.dispatch(app.setOffline());
});

if (module.hot) {
    // When any of the dependencies to this entry file change we should hot reload.
    module.hot.accept();
}
