import gql from 'graphql-tag';

const QUERY_IS_EMAIL_AVAILABLE = gql`
    query isEmailAvailable($email: String!) {
        isEmailAvailable(email: $email) {
            is_email_available
        }
    }
`;

export default QUERY_IS_EMAIL_AVAILABLE;
