import React, { useContext, useEffect } from 'react';
import { object, string } from 'prop-types';
import { OverlayStore } from '@corratech/context-provider';
import './Overlay.less';

const Overlay = props => {
    const { overlayState, overlayDispatch, callbackState } = useContext(
        OverlayStore
    );

    const overlayClassName = 'page-overlay';
    const htmlClass = 'nav-open';

    const html = document.getElementsByTagName('html')[0];

    const addBodyClass = className => {
        document.body.classList.add(overlayClassName);
        html.classList.add(htmlClass);
    }

    const removeBodyClass = className => {
        document.body.classList.remove(overlayClassName);
        html.classList.remove(htmlClass);
    }

    useEffect(() => {
        if ('overlay-open' === overlayState.class) {
            overlayClassName instanceof Array
                ? className.map(addBodyClass)
                : addBodyClass(overlayClassName);
        } else {
            overlayClassName instanceof Array
                ? className.map(removeBodyClass)
                : removeBodyClass(overlayClassName);
        }
    }, [overlayState.class]);

    const callbacks = () => {
        overlayDispatch({ type: 'HIDE' });

        callbackState.map((fn, index) => {
            fn();
        });
    };

    return (
        <div
            className={
                'global-overlay ' +
                overlayState.class +
                ' ' +
                (props.className || '')
            }
            css={props.css}
            onClick={() => callbacks()}
        />
    );
};

Overlay.propTypes = {
    className: string,
    css: object
};

export default Overlay;