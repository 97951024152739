import React from 'react';
import RatingStar from './RatingStar';
import PropTypes from "prop-types";

export const ProductRating = ({ rating = 0, review_count = 0 }) => {
    const totalStars = 5;
    const fullStars = Math.floor(rating);
    const partialFill = (rating % 1) * 100;

    return (
        <div
            className={'product-info-rating-wrapper'}
        >
            <div className="star-container">
                {[...Array(totalStars)].map((_, index) => {
                    const fillPercentage = index < fullStars ? 100 : index === fullStars ? partialFill : 0;
                    return <RatingStar key={index} fillPercentage={fillPercentage} />;
                })}
            </div>
            <span className={'product-info-rating-count'}>({review_count || 0})</span>
        </div>
    );
};

ProductRating.propTypes = {
    rating: PropTypes.number,
    review_count: PropTypes.number
};