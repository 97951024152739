import React, { useEffect } from 'react';
import { GeneralMeta } from './GeneralMeta';
import { ProductMeta } from './ProductMeta';
import { Searchbox } from './Searchbox';
import { Organization } from './Organization';
import { Breadcrumbs } from './Breadcrumbs';
import { Category } from 'ModulesPath/Seo/MetaData/Category';

export const Metadata = props => {
    const {
        seo_data_string,
        categoryItems,
        config,
        product,
        urlBase,
        breadcrumbModel,
        getStoreConfig,
        projectConfig,
        pageBreadcrumbs,
        removeMetaTagsBySelector = []
    } = props;
    const {
        organization,
        organization_pages,
        website,
        breadcrumbs,
        category,
        product: product_seo_data
    } = (!!seo_data_string && JSON.parse(seo_data_string)) || {};

    const categoryItemsData =
        categoryItems &&
        category &&
        category.filter(item =>
            categoryItems.some(categoryItem => categoryItem.sku === item.sku)
        );

    /**
     * Clean up meta tags, added in the page source, before adding tags from this component
     */
    useEffect(() => {
        removeMetaTagsBySelector.map(selector => {
            document
                .querySelectorAll(selector)
                .forEach(element => element.remove());
        });
    }, []);

    return (
        <>
            {!product && <GeneralMeta urlBase={urlBase} config={config} />}
            {!!product && (
                <ProductMeta
                    amasty_product_data={product_seo_data}
                    urlBase={urlBase}
                    product={product}
                    config={config}
                    getStoreConfig={getStoreConfig}
                    projectConfig={projectConfig}
                />
            )}
            {category && <Category amasty_category_data={categoryItemsData} />}
            <Searchbox
                amasty_website_data={website}
                urlBase={urlBase}
                config={config}
            />
            <Organization
                amasty_organization_pages={organization_pages}
                amasty_organization_data={organization}
                urlBase={urlBase}
                config={config}
            />
            <Breadcrumbs
                amasty_breadcrumbs_data={breadcrumbs}
                pageBreadcrumbs={pageBreadcrumbs}
                model={breadcrumbModel}
                getStoreConfig={getStoreConfig}
                urlBase={urlBase}
                config={config}
            />
        </>
    );
};
