import { useMutation } from '@apollo/react-hooks';
import addNewsLetterGQL from './addNewsletterSubscriber.graphql';

/**
 * Hook which fetches and processes copyright data on-load
 *
 * @function
 * @param {Object} [apolloOptions] - Apollo query options
 *
 * @returns {Array} first element is the mutation function, second element is the mutation results
 *  func addSubscriber(email, options),
 *  {
 *  message: string
 *  error: object
 *  loading: boolean
 *  }
 *
 */
export const useNewsletterSubscription = (apolloOptions, reCaptchaToken) => {
    const [addNewsLetterFunc, { data, loading, error }] = useMutation(
        addNewsLetterGQL,
        {
            fetchPolicy: 'no-cache',
            ...apolloOptions,
            context: {
                headers: {
                    'X-ReCaptcha': reCaptchaToken || ''
                }
            }
        }
    );

    const addSubscriber = (email, options) =>
        addNewsLetterFunc({
            variables: {
                subscriber_email: email
            },
            ...options
        });

    return [
        addSubscriber,
        {
            error,
            loading,
            message:
                data &&
                data.createNewsSubscriber &&
                data.createNewsSubscriber.message
        }
    ];
};
