// const address1 = require('./address1.json');
// const address1copy = require('./address1copy.json');
// const address2 = require('./address2.json');

export function isTwoAddressesEqualByArrayOfKeys(
    address1,
    address2,
    arrayOfKeys,
    isEqualShape
) {
    let result = false;

    for (let i = 0; i < arrayOfKeys.length; i++) {
        if (!isEqualShape) {
            if (arrayOfKeys[i] === 'region') {
                result =
                    address1[arrayOfKeys[i]].region_code ===
                    address2[arrayOfKeys[i]].code;
            } else if (arrayOfKeys[i] === 'country') {
                result =
                    address1['country_id'] === address2[arrayOfKeys[i]].code;
            } else {
                result =
                    JSON.stringify(address1[arrayOfKeys[i]]) ===
                    JSON.stringify(address2[arrayOfKeys[i]]);
            }

            if (result === false) {
                break;
            }
        } else {
            result =
                JSON.stringify(address1[arrayOfKeys[i]]) ===
                JSON.stringify(address2[arrayOfKeys[i]]);
            if (result === false) {
                break;
            }
        }
    }

    return result;
}

export const arrayOfKeysToCompareAddresses = [
    'firstname',
    'lastname',
    'street',
    'city',
    'region',
    'postcode',
    'telephone',
    'country'
];

// leave it for testing

// console.log(
//     isTwoObjectsEqualByArrayOfKeys(
//         address1,
//         address1copy,
//         arrayOfKeysToCompareAddresses
//     )
// );
