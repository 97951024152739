import React from 'react';
import { Helmet } from 'react-helmet-async';
import {
    getProductUrl,
    getTextValue,
    getProductAttributeValue,
    getItemCondition
} from '../functions';
import { formatPrice } from '@corratech/tag-manager';

const PRODUCT_TYPE_OFFER = ['SimpleProduct'];
const PRODUCT_TYPE_AGGREGATE_OFFER = ['ConfigurableProduct', 'GroupedProduct'];

// https://schema.org/ItemAvailability has more statuses, but we handle only two
const mapProductAvailability = stockStatus => {
    if (stockStatus === 'IN_STOCK') {
        return 'https://schema.org/InStock';
    }
    return 'https://schema.org/OutOfStock';
};

const getPriceValidUntil = days => {
    const expirationTime = days * 24 * 60 * 60 * 1000;
    const currentDate = new Date();
    currentDate.setTime(currentDate.getTime() + expirationTime);
    return currentDate.toISOString().substr(0, 10);
};

const getChildItems = product => {
    if (product.__typename === 'GroupedProduct') {
        return product.items || [];
    }
    if (product.__typename === 'ConfigurableProduct') {
        return product.variants || [];
    }
    return [];
};

const getItemUrl = (item, urlBase, urlSuffix, parentUrl) => {
    if (item.__typename === 'ConfigurableProduct' || !item.url_key) {
        return parentUrl;
    }
    return getProductUrl(item, urlBase, urlSuffix);
};

export const ProductJson = props => {
    const {
        product,
        productMetadataConfig,
        baseImage,
        getImageUrl,
        productUrl,
        priceAmount,
        priceCurrency,
        storeName,
        urlBase,
        urlSuffix,
        amasty_product_data,
        config
    } = props;

    if (amasty_product_data) {
        return (
            <Helmet>
                <script type={'application/ld+json'}>
                    {JSON.stringify(amasty_product_data, null, 2)}
                </script>
            </Helmet>
        );
    }

    if (!config) {
        return '';
    }

    const images = [baseImage];
    if (productMetadataConfig.gallery.include) {
        const gallery = getProductAttributeValue(
            product,
            productMetadataConfig.attributes,
            'media_gallery',
            []
        );
        gallery.map(galleryItem => {
            let url = galleryItem.url
                ? galleryItem.url
                : getImageUrl(galleryItem.file);
            if (
                !!url &&
                !images.includes(url) &&
                images.length < productMetadataConfig.gallery.limit + 1
            ) {
                images.push(url);
            }
        });
    }
    const productDescription = getTextValue(
        getProductAttributeValue(
            product,
            productMetadataConfig.attributes,
            'description',
            ''
        ),
        false
    );

    const jsonData = {
        '@context': 'http://schema.org/',
        '@type': 'Product',
        name: getProductAttributeValue(
            product,
            productMetadataConfig.attributes,
            'name',
            product.name
        ),
        image: images,
        description: productDescription,
        sku: product.sku,
        mpn: getProductAttributeValue(
            product,
            productMetadataConfig.attributes,
            'mpn',
            product.sku
        ),
        model: getProductAttributeValue(
            product,
            productMetadataConfig.attributes,
            'model',
            product.sku
        )
    };

    const alternateName = getProductAttributeValue(
        product,
        productMetadataConfig.attributes,
        'alternate_name'
    );
    if (typeof alternateName === 'string') {
        jsonData['alternateName'] = alternateName
            .split(',')
            .map(name => name.trim());
    }

    const gtin = getProductAttributeValue(
        product,
        productMetadataConfig.attributes,
        'gtin'
    );
    const allowedGtinLength = [8, 12, 13, 14];
    if (gtin && allowedGtinLength.includes(gtin.length)) {
        jsonData[`gtin${gtin.length}`] = gtin;
    }

    const brand = getProductAttributeValue(
        product,
        productMetadataConfig.attributes,
        'brand',
        product.brand || false
    );
    if (brand) {
        jsonData['brand'] = {
            '@type': 'Thing',
            name: brand
        };
    }

    const manufacturer = getProductAttributeValue(
        product,
        productMetadataConfig.attributes,
        'manufacturer',
        product.manufacturer || false
    );
    if (manufacturer) {
        jsonData['manufacturer'] = {
            '@type': 'Organization',
            name: manufacturer
        };
    }

    const reviewCount = getProductAttributeValue(
        product,
        productMetadataConfig.attributes,
        'review_count'
    );
    if (reviewCount) {
        const ratingValue = getProductAttributeValue(
            product,
            productMetadataConfig.attributes,
            'review_summary',
            0
        );
        const bestRating = getProductAttributeValue(
            product,
            productMetadataConfig.attributes,
            'best_rating',
            0
        );
        jsonData['aggregateRating'] = {
            '@type': 'AggregateRating',
            ratingValue: ratingValue,
            reviewCount: reviewCount,
            bestRating: bestRating
        };
    }

    const priceValidUntil = getPriceValidUntil(
        productMetadataConfig.priceValidUntilDays
    );
    if (PRODUCT_TYPE_OFFER.includes(product.__typename)) {
        jsonData['offers'] = {
            '@type': 'Offer',
            url: productUrl,
            priceCurrency: priceCurrency,
            price: formatPrice(priceAmount),
            priceValidUntil: priceValidUntil,
            itemCondition: getItemCondition(product, productMetadataConfig),
            availability: mapProductAvailability(product.stock_status),
            seller: {
                '@type': 'Organization',
                name: storeName
            }
        };
    } else if (PRODUCT_TYPE_AGGREGATE_OFFER.includes(product.__typename)) {
        const offers = [];
        let highPrice = '',
            lowPrice = '';
        getChildItems(product).map(item => {
            const { product: itemProduct } = item;
            const priceAmount = getProductAttributeValue(
                itemProduct,
                productMetadataConfig.attributes,
                'price_amount',
                0
            );
            if (!highPrice || parseFloat(highPrice) < parseFloat(priceAmount)) {
                highPrice = priceAmount;
            }
            if (!lowPrice || parseFloat(lowPrice) > parseFloat(priceAmount)) {
                lowPrice = priceAmount;
            }
            const itemProductDescription = getTextValue(
                getProductAttributeValue(
                    itemProduct,
                    productMetadataConfig.attributes,
                    'description',
                    productDescription
                ),
                false
            );
            offers.push({
                '@type': 'Offer',
                priceCurrency: priceCurrency,
                price: formatPrice(priceAmount),
                availability: mapProductAvailability(itemProduct.stock_status),
                itemCondition: getItemCondition(item, productMetadataConfig),
                url: getItemUrl(itemProduct, urlBase, urlSuffix, productUrl),
                sku: itemProduct.sku,
                name: itemProduct.name,
                description: itemProductDescription
            });
        });

        jsonData['offers'] = {
            '@type': 'AggregateOffer',
            url: productUrl,
            priceCurrency: priceCurrency,
            offerCount: offers.length,
            highPrice: formatPrice(highPrice),
            lowPrice: formatPrice(lowPrice),
            priceValidUntil: priceValidUntil,
            availability: mapProductAvailability(product.stock_status),
            seller: {
                '@type': 'Organization',
                name: storeName
            },
            offers: offers
        };
    }

    return (
        <Helmet>
            <script type={'application/ld+json'}>
                {JSON.stringify(jsonData, null, 2)}
            </script>
        </Helmet>
    );
};
