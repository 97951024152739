/**
 * Get region ID
 */
export const getRegionId = (currentCountryCode, regionCode, countryData) => {
    if (countryData && countryData.countries) {
        const currentCountry = countryData.countries.find(
            country => country.id === currentCountryCode
        );

        if (currentCountry !== undefined && currentCountry.available_regions) {
            const result = currentCountry.available_regions.find(
                value => value.code === regionCode
            );

            if (result) {
                return result.id;
            }
        }
    }
    return null;
};
