import React from 'react';
import './ProductRating.less';
import PropTypes from "prop-types";

const RatingStar = ({ fillPercentage }) => {
    const style = fillPercentage ? { '--fill': `${fillPercentage}%` } : undefined;

    return (
        <div
            className="star"
            style={style}
        ></div>
    );
};

export default RatingStar;

RatingStar.propTypes = {
    PropTypes: PropTypes.number
};