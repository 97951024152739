export const PageDetailsEvents = (tagData, addStoredData) => {
    const {
        pageType,
        pageName,
        virtualPagePath
    } = tagData;
    
    if (!pageName) {
        return false;
    }

    addStoredData({
        pageDetails: {
            pageType,
            pageName,
            virtualPagePath
        }
    });
}
