export const getMatchingVariant = (variants, optionSelections) => {
    for (const index in variants) {
        if (variants.hasOwnProperty(index)) {
            let isMatch = true;
            const { attributes, product } = variants[index];

            for (const attrIndex in attributes) {
                if (attributes.hasOwnProperty(attrIndex)) {
                    const { value_index, code } = attributes[attrIndex];

                    if (value_index !== optionSelections.get(code)) {
                        isMatch = false;
                        break;
                    }
                }
            }

            if (isMatch) {
                return {
                    sku: product.sku,
                    product
                };
            }
        }
    }
};
