import React, {useEffect, useState} from 'react';
import style from './Price.less';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
let examineClasses = classNames.bind(style);

export const Price = ({
    product,
    inline,
    subscriptionDiscount,
    embroideryPrice,
    setProductPrice,
    isGroupedProduct
}) => {
    const [isSubscribed, setIsSubscribed] = useState(false);

    const handleOptinChange = () => {
        const optins = window.OG.getOptins(product.id);
        const isProductExists = optins.some(item => item.product === String(product.id));
        setIsSubscribed(isProductExists);
    }

    useEffect(() => {
        if (window.OG && subscriptionDiscount) {
            handleOptinChange();
            window.OG.addOptinChangedCallback(handleOptinChange);
        }
    }, [product]);

    const discountPercent = subscriptionDiscount && isSubscribed ? subscriptionDiscount : 0;

    if (product.price_range) {
        var currencySymbol;
        //TODO: Use this switch-case to add international currency support
        switch (product.price_range.minimum_price.regular_price.currency) {
            case 'USD':
                currencySymbol = '$';
                break;
            case 'GBP':
                currencySymbol = '£';
                break;
            case 'EUR':
                currencySymbol = '€';
                break;
            default:
                currencySymbol = '$';
                break;
        }
        if (embroideryPrice) {
            const finalPrice = (
                parseFloat(
                    product.price_range.minimum_price.final_price.value
                ) + embroideryPrice
            ).toFixed(2);
            setProductPrice(parseFloat(finalPrice));
            return (
                <span className={'original-price'}>
                    {currencySymbol + finalPrice}
                </span>
            );
        } else if (isGroupedProduct) {
            const forLoopMinMax = items => {
                let priceRange = [];

                for (let i = 1; i < items.length; i++) {
                    let valueMin =
                        items[i].product.price_range.minimum_price.regular_price
                            .value;
                    let valueMax =
                        items[i].product.price_range.maximum_price.regular_price
                            .value;
                    priceRange.push(valueMin, valueMax);
                }

                return [Math.min(...priceRange), Math.max(...priceRange)];
            };

            const [min, max] = forLoopMinMax(product.items);

            return (
                <span className={'original-price grouped-price'}>
                    {currencySymbol + min.toFixed(2)}
                    {'-'}
                    {max.toFixed(2)}
                </span>
            );
        } else {
            const finalPriceValue = parseFloat(product.price_range.minimum_price.final_price.value);
            const discountedPrice = (finalPriceValue * (1 - discountPercent / 100)).toFixed(2);
            const originalPrice = product.price_range.minimum_price.final_price.value.toFixed(2);
            const priceClasses = examineClasses('prices', {
                inline: inline
            });

            if (product.price_range.minimum_price.discount.amount_off) {
                return (
                    <div className={priceClasses}>
                        <span className={'sale-price'}>
                            {currencySymbol + discountedPrice}
                        </span>

                        <span className={'old-price'}>
                            {currencySymbol +
                                parseFloat(
                                    product.price_range.minimum_price
                                        .regular_price.value
                                ).toFixed(2)}
                        </span>
                    </div>
                );
            } else if (discountPercent) {
                return (
                    <div className={priceClasses}>
                        <span className={'sale-price'}>
                            {currencySymbol + discountedPrice}
                        </span>

                        <span className={'old-price'}>
                            {currencySymbol + originalPrice}
                        </span>
                    </div>
                );
            } else {
                return (
                    <span className={'original-price'}>
                        {currencySymbol + originalPrice}
                    </span>
                );
            }
        }
    } else {
        return '';
    }
};

Price.propTypes = {
    product: PropTypes.object.isRequired,
    inline: PropTypes.bool
};
