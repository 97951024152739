import React, { useCallback, useState, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { string, object, bool } from 'prop-types';
import { getCurrencySymbol, setPriceZeroes } from '../util';
import { CartItemList } from '../CartItem/CartItemList';
import { useGlobalOptions } from '@corratech/context-provider';
require('./SummaryBlock.less');

export const SummaryBlock = props => {
    const [t] = useTranslation();
    const globalOptions = useGlobalOptions();
    const storeConfig = globalOptions.storeConfig || {};
    const includeTaxSubtotal =
        storeConfig.display_subtotal_shopping_cart === '2' ? true : false;
    const history = useHistory();

    const [openCart, setOpenCart] = useState(false);

    const [openAddress, setOpenAddress] = useState(false);

    const getCouponCodeForDiscountLabel = () => {
        if (props.cart.applied_coupons && props.cart.applied_coupons[0]) {
            return `${t('Discount')} "${props.cart.applied_coupons[0].code}"`;
        }
        return t('Discount');
    };

    const handleClick = useCallback(() => {
        history.push('/checkout');
    }, [history]);

    if (props.cart && props.cart.prices && props.cart.prices.grand_total) {
        const total = props.cart.prices.grand_total;

        const itemsInCart = () => {
            return (
                <div className={'num-items'}>
                    <b>{props.cart.items.length}</b>{' '}
                    {props.cart.items.length === 1
                        ? t('Item in Cart')
                        : t('Items in Cart')}
                </div>
            );
        };

        return (
            <div
                className={'summary-wrapper ' + (props.className || '')}
                css={props.css}
            >
                <div className={'summary-block'}>
                    <strong className={'summary-title'}>{t('Summary')}</strong>

                    {props.checkout &&
                    props.billing &&
                    props.cart.shipping_addresses ? (
                        openAddress ? (
                            <>
                                <div
                                    style={{
                                        borderBottom: '1px solid #cccccc'
                                    }}
                                    className={'address-label'}
                                    onClick={() => setOpenAddress(false)}
                                >
                                    {t('Shipping Address')}
                                </div>
                                <div className={'entered-shipping-address'}>
                                    <span>
                                        {props.cart.shipping_addresses[0]
                                            .firstname +
                                            ' ' +
                                            props.cart.shipping_addresses[0]
                                                .lastname}
                                    </span>
                                    <span>
                                        {props.cart.shipping_addresses[0]
                                            .company || null}
                                    </span>
                                    <span>
                                        {props.cart.shipping_addresses[0].street.map(
                                            street => (
                                                <span key={street}>
                                                    {street}
                                                </span>
                                            )
                                        )}
                                    </span>
                                    <span>
                                        {props.cart.shipping_addresses[0].city +
                                            ', ' +
                                            props.cart.shipping_addresses[0]
                                                .region.label +
                                            ' ' +
                                            props.cart.shipping_addresses[0]
                                                .postcode}
                                    </span>
                                    <span>
                                        {
                                            props.cart.shipping_addresses[0]
                                                .country.label
                                        }
                                    </span>
                                    <span>
                                        {
                                            props.cart.shipping_addresses[0]
                                                .telephone
                                        }
                                    </span>
                                </div>
                            </>
                        ) : (
                            <div
                                style={{
                                    borderBottom: '1px solid #cccccc'
                                }}
                                className={'address-label'}
                                onClick={() => setOpenAddress(true)}
                            >
                                {t('Shipping Address')}
                            </div>
                        )
                    ) : null}

                    {props.checkout ? (
                        openCart ? (
                            <>
                                <span
                                    onClick={() => setOpenCart(false)}
                                    className={'checkout-items'}
                                >
                                    {itemsInCart()}
                                </span>
                                <CartItemList disableControls />
                            </>
                        ) : (
                            <span
                                onClick={() => setOpenCart(true)}
                                className={'checkout-items'}
                            >
                                {itemsInCart()}
                            </span>
                        )
                    ) : null}

                    {includeTaxSubtotal ? (
                        <Fragment>
                            {props.cart.prices.subtotal_including_tax ? (
                                <div className={'cart-subtotal'}>
                                    <div className={'row'}>
                                        <div className={'col-left'}>
                                            {t('Subtotal')}
                                        </div>
                                        <div
                                            className={
                                                'col-right cart-subtotal-value'
                                            }
                                        >
                                            {getCurrencySymbol(
                                                props.cart.prices
                                                    .subtotal_including_tax
                                                    .currency
                                            ) +
                                                setPriceZeroes(
                                                    props.cart.prices
                                                        .subtotal_including_tax
                                                        .value
                                                )}
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </Fragment>
                    ) : (
                        <Fragment>
                            {props.cart.prices.subtotal_excluding_tax ? (
                                <div className={'cart-subtotal'}>
                                    <div className={'row'}>
                                        <div className={'col-left'}>
                                            {t('Subtotal')}
                                        </div>
                                        <div
                                            className={
                                                'col-right cart-subtotal-value'
                                            }
                                        >
                                            {getCurrencySymbol(
                                                props.cart.prices
                                                    .subtotal_excluding_tax
                                                    .currency
                                            ) +
                                                setPriceZeroes(
                                                    props.cart.prices
                                                        .subtotal_excluding_tax
                                                        .value
                                                )}
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </Fragment>
                    )}

                    {!props.cart.is_virtual && (
                        <div className={'cart-shipping-addresses'}>
                            <div className={'row'}>
                                <div className={'col-left'}>
                                    {t('Shipping')}
                                </div>
                                <div className={'col-right'}>
                                    {!(
                                        props.cart.shipping_addresses &&
                                        props.cart.shipping_addresses[0] &&
                                        props.cart.shipping_addresses[0]
                                            .selected_shipping_method
                                    ) ? (
                                        <i>{t('calculated in checkout')}</i>
                                    ) : (
                                        <span>
                                            {`${props.cart.shipping_addresses[0].selected_shipping_method.carrier_title} - `}
                                            {getCurrencySymbol(
                                                props.cart.shipping_addresses[0]
                                                    .selected_shipping_method
                                                    .amount.currency
                                            ) +
                                                setPriceZeroes(
                                                    props.cart
                                                        .shipping_addresses[0]
                                                        .selected_shipping_method
                                                        .amount.value
                                                )}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className={'cart-tax'}>
                        <div className={'row'}>
                            <div className={'col-left'}>{t('Tax')} </div>
                            <div className={'col-right'}>
                                {props.cart.prices.applied_taxes.length > 0 ? (
                                    <span>
                                        {getCurrencySymbol(
                                            props.cart.prices.applied_taxes[0]
                                                .amount.currency
                                        ) +
                                            props.cart.prices.applied_taxes[0].amount.value.toFixed(
                                                2
                                            )}
                                    </span>
                                ) : (
                                    <span>
                                        <i>{t('calculated in checkout')}</i>
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>

                    {props.cart.applied_coupons &&
                    props.cart.applied_coupons[0] ? (
                        <div className={'cart-discount'}>
                            <div className={'row'}>
                                <div className={'col-left'} data-cs-mask>
                                    {getCouponCodeForDiscountLabel()}
                                </div>
                                <div
                                    className={'col-right cart-discount-value'}
                                >
                                    {'-'}
                                    {getCurrencySymbol(
                                        props.cart.prices
                                            .subtotal_with_discount_excluding_tax
                                            .currency
                                    ) +
                                        setPriceZeroes(
                                            (
                                                parseFloat(
                                                    props.cart.prices
                                                        .subtotal_excluding_tax
                                                        .value
                                                ) -
                                                parseFloat(
                                                    props.cart.prices
                                                        .subtotal_with_discount_excluding_tax
                                                        .value
                                                )
                                            ).toFixed(2)
                                        )}
                                </div>
                            </div>
                        </div>
                    ) : null}

                    <div className={'cart-grand-total'}>
                        <div className={'row'}>
                            <div className={'col-left'}>
                                <b>{t('Estimated Total')}</b>
                            </div>
                            <div className={'col-right cart-discount-value'}>
                                <b>
                                    {getCurrencySymbol(total.currency) +
                                        setPriceZeroes(total.value)}
                                </b>
                            </div>
                        </div>
                    </div>

                    {!props.checkout ? (
                        <div className={'cart-link-wrapper'}>
                            <Button
                                size="lg"
                                variant="primary"
                                block
                                onClick={handleClick}
                            >
                                {t('Proceed to Checkout')}
                            </Button>
                        </div>
                    ) : null}
                </div>
            </div>
        );
    } else {
        return null;
    }
};

SummaryBlock.propTypes = {
    billing: bool,
    cart: object,
    checkout: bool,
    className: string,
    css: object
};
