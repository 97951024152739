import React, { Fragment, useContext } from 'react';
import { useMutation } from 'react-apollo';
import { CartStore } from '@corratech/context-provider';
import { Button } from 'react-bootstrap';
import removeGiftCardFromCart from './Queries/removeGiftCardFromCart.graphql';
import { getCurrencySymbol } from './util/getCurrencySymbol';
import { setPriceZeroes } from './util/setPriceZeroes';
import { useTranslation } from 'react-i18next';

export const GiftSummaryBlock = props => {
    const [t] = useTranslation();
    const { cartState, dispatch } = useContext(CartStore);

    const [removeGiftCard, { loading: removingCard }] = useMutation(
        removeGiftCardFromCart,
        {
            onCompleted: ({ removeGiftCardFromCart }) => {
                dispatch({
                    type: 'SET_CART',
                    cart: removeGiftCardFromCart.cart
                });
            }
        }
    );

    const GiftCardApplied = giftCodes =>
        giftCodes.map((value, index) => {
            return (
                <div className={'gift-remove-action row'} key={index}>
                    <div className={'col-left'}>
                        <Button
                            variant="link"
                            disabled={removingCard}
                            onClick={() =>
                                removeGiftCard({
                                    variables: {
                                        cartId: cartState.cartId,
                                        giftCode: value.code,
                                        isSignedIn: !!cartState.cart
                                            .authenticated
                                    }
                                })
                            }
                        >
                            {'Remove'}
                        </Button>
                        <span className={'gift-card-value'} data-cs-mask>
                            {t('Gift Card') + ' ' + value.code}
                        </span>
                    </div>
                    <div className={'col-right'}>
                        <span>
                            {'-'}
                            {getCurrencySymbol(value.applied_balance.currency) +
                                setPriceZeroes(value.applied_balance.value)}
                        </span>
                    </div>
                </div>
            );
        });

    return (
        <Fragment>
            {cartState.cart.applied_gift_cards && (
                <div className={'price-line gift-cart-applied'}>
                    {GiftCardApplied(cartState.cart.applied_gift_cards)}
                </div>
            )}
        </Fragment>
    );
};
