import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { string, object, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { EmptyCartIcon } from 'ModulesPath/Icons/EmptyCartIcon';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import './EmptyCartBlock.less';
import { AuthStore, signOut } from '@corratech/context-provider';

export const EmptyCartBlock = props => {
    const [t] = useTranslation();
    const history = useHistory();
    const { authState, dispatch } = useContext(AuthStore);

    const cartEmptyMessage = () => {
        return (
            <>
                {authState && authState.user.firstname
                    ? authState.user.firstname
                    : 'Hello'}
                {t(`, Your Bag Is Empty`)}
            </>
        );
    };

    return (
        <div
            className={'empty-cart ' + (props.className || '')}
            css={props.css}
        >
            {!props.cartPage && (
                <button className={'close-minicart'} onClick={props.closeCart}>
                    <span>{t('Close')}</span>
                </button>
            )}

            <div className={'empty-cart-contents'}>
                <div className={'empty-cart-face'}>
                    <EmptyCartIcon />
                </div>

                {props.cartPage ? (
                    <p className={'text'}>
                        {t('Click')}{' '}
                        <Link className={'link'} title={t('here')} to={'/'}>
                            {t('here')}
                        </Link>{' '}
                        {t('to continue shopping.')}
                    </p>
                ) : (
                    <>
                        <h4 className={'subtitle'}>{cartEmptyMessage()}</h4>
                        <p className={'text'}>{t('Nothing to see here.')}</p>

                        <div className={'buttons-wrapper'}>
                            <Button
                                size="lg"
                                variant="secondary"
                                block
                                onClick={props.closeCart}
                            >
                                {t('Continue shopping')}
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

EmptyCartBlock.propTypes = {
    className: string,
    closeCart: function(props, propName, componentName) {
        if (
            props['cartPage'] !== true &&
            (props[propName] === undefined ||
                typeof props[propName] != 'function')
        ) {
            return new Error(
                'Invalid prop `' +
                    propName +
                    '` supplied to' +
                    ' `' +
                    componentName +
                    '`. Validation failed.'
            );
        }
    },
    css: object,
    cartPage: bool
};
