export const addScriptFromRaw = (scriptContents, id) => {
    //Check if script is already loaded
    let script = document.querySelector(`script[id="${id}"]`);

    if (!script) {
        script = document.createElement('script');
        if (!!id) {
            script.id = id;
        }
        script.innerHTML = scriptContents;
        document.head.appendChild(script);
    }
    
    return !!script;
}
