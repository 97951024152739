import React, { useRef, useContext } from 'react';
import { useMutation } from 'react-apollo';
import { mutationCreateNewsSubscriber } from '../graphql/mutationCreateNewsSubscriber';
import { Checkbox } from '@corratech/form-components';
import { useTranslation } from 'react-i18next';
import { CartStore } from '@corratech/context-provider';
import { Link } from 'react-router-dom';

export default function NewsletterCheckbox() {
    const [t] = useTranslation();
    const refCheckbox = useRef();
    const { cartState } = useContext(CartStore);

    const [createNewsSubscriber] = useMutation(mutationCreateNewsSubscriber, {
        onError: res => {
            console.warn(res);
        }
    });

    const handleChange = () => {
        if (refCheckbox.current.checked) {
            createNewsSubscriber({
                variables: { subscriberEmail: cartState.cart.email }
            });
        }
    };

    return (
        <>
            <h3 className={'subscription-title'}>
                {t('Sign up for news and exclusive offers from Elemis')}
            </h3>
            <span className={'newsletter-checkbox'}>
                <Checkbox
                    ref={refCheckbox}
                    field="news_letter_subscriber"
                    onChange={() => handleChange()}
                    id={'co-newsletter-subscribe'}
                    label={
                        <span>
                            {t(
                                `I agree to receive email communications from ELEMIS.`
                            )}
                        </span>
                    }
                />
            </span>
        </>
    );
}
