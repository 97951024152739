import { deepValue, formatPrice } from '@corratech/tag-manager';
import { getCategoryPath } from './getCategoryPath';
import { getCategoryName } from './getCategoryName';

export const getMapProductDetails = (
    itemCategoryPath = getCategoryPath,
    categoryName = getCategoryName
) => {
    return (data) => {
        let productsDetails = [];
        let startingPosition = data.currentPage && data.pageSize ? 
            (parseInt(data.currentPage) - 1) * parseInt(data.pageSize) : 0;
        let currentCategoryName = categoryName(data);
        let list =
            typeof data.searchTerm != 'undefined'
                ? `Search Results - ${data.searchTerm}`: (data.list
                ? data.list
                : itemCategoryPath(data, currentCategoryName));
        let items = deepValue(data, ['products', 'items'], data.items);
        items.map((item, index) => {
            let price = deepValue(item, ['price_range', 'minimum_price', 'final_price', 'value']);
            productsDetails.push({
                id: item.sku,
                name: item.name,
                price: formatPrice(price),
                category: currentCategoryName,
                position: startingPosition + index + 1,
                brand: deepValue(item, ['brand_name']),
                list: list
            });
        });

        return productsDetails;
    };
}