import React, { useState } from 'react';
import { array, func, string } from 'prop-types';
import classNames from 'classnames';
import { ColorSwatch } from '@corratech/color-swatch';

export const CartEditItemOption = props => {
    const {
        selectedLabel,
        optionSelections,
        handleVariantChange,
        option,
        colorAttrCode
    } = props;

    const [selected, setSelected] = useState(selectedLabel);

    const selectedOption = (option, value) => {
        optionSelections.set(option.attribute_code, value.value_index);

        handleVariantChange(optionSelections);

        setSelected(value.label);
    };

    const handleClick = value => {
        selectedOption(option, value);
    };

    const optionClass = classNames({
        'option-item': true,
        [`option-${option.attribute_code}`]: true
    });

    const optionBtnClass = classNames({
        'option-button': true,
        [`option-button-${option.attribute_code}`]: true
    });

    return option.values.map(value => {
        const isSelected = value.label === selected;

        return (
            <li
                className={classNames(optionClass, {
                    'option-active': isSelected
                })}
                key={value.value_index}
            >
                {option.attribute_code === colorAttrCode ? (
                    <ColorSwatch
                        color={value.default_label}
                        handleClick={() => handleClick(value)}
                        title={value.label}
                        classes={classNames(optionBtnClass, {
                            'option-button-active': isSelected
                        })}
                    />
                ) : (
                    <button
                        className={classNames(optionBtnClass, {
                            'option-button-active': isSelected
                        })}
                        onClick={() => handleClick(value)}
                        title={value.label}
                    >
                        {value.label}
                    </button>
                )}
            </li>
        );
    });
};

CartEditItemOption.propTypes = {
    onSelectionChange: func,
    selectedValue: array,
    selectedMap: string,
    colorAttrCode: string
};
