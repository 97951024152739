import { Colors } from './Colors';

export const staticColors = colorCode => {
    // default color
    const color = 'rgb(246, 246, 246)';

    if (Colors[colorCode]) {
        return Colors[colorCode].rgb;
    }

    return `${color}`;
};
