import React from 'react';
import PropTypes from 'prop-types';

const FR = props => {
    const { color, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            height={size}
            width={size}
            {...otherProps}
        >
            <circle fill="#F0F0F0" cx="256" cy="256" r="256"></circle>
            <path
                fill="#D80027"
                d="M512 256c0-110.1-69.5-203.9-167-240.1v480.2c97.5-36.2 167-130 167-240.1z"
            ></path>
            <path
                fill="#0052B4"
                d="M0 256c0 110.1 69.5 203.9 167 240.1V15.9C69.5 52.1 0 145.9 0 256z"
            ></path>
        </svg>
    );
};

FR.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

FR.defaultProps = {
    color: 'currentColor',
    size: '18'
};

export default FR;
