import React from 'react';
import { Helmet } from 'react-helmet-async';

export const Category = props => {
    const { amasty_category_data } = props;

    if (!amasty_category_data) {
        return '';
    }

    return (
        <Helmet>
            <script type={'application/ld+json'}>
                {JSON.stringify(amasty_category_data, null, 2)}
            </script>
        </Helmet>
    );
};
