import { getMapProductDetails } from '@corratech/google-tag-manager';

export const SEARCH = (productDetails = getMapProductDetails()) => {
    return {
        searchTerm: ['searchTerm'],
        isSearchResult: ['isSearchResult'],
        currencyCode: [
            'products',
            'items',
            '0',
            'price_range',
            'minimum_price',
            'final_price',
            'currency'
        ],
        productsDetails: productDetails
    };
};
