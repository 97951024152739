import React from 'react';

export const CartStore = React.createContext();

export const MockCartProvider = ({ cartState, children }) => {
    // console.log('cartState', cartState);

    // const stringifiedCartState = JSON.parse(cartState);
    // console.log('stringifiedCartState', stringifiedCartState);
    const dispatch = null;
    return (
        <CartStore.Provider value={{ cartState: cartState, dispatch }}>
            {children}
        </CartStore.Provider>
    );
};
