import React from 'react';
import PropTypes from 'prop-types';

const YoutubeIcon = props => {
    const { color, width, height, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width ? width : size}
            height={height ? height : size}
            fill={color}
            stroke={color}
            viewBox={`0 0 ${width ? width : size} ${height ? height : size}`}
            {...otherProps}
        >
            <path
                fill={color}
                fillRule="evenodd"
                stroke="none"
                strokeWidth="1"
                d="M19.582 5.2a2.513 2.513 0 00-1.768-1.78C16.254 3 10 3 10 3s-6.254 0-7.814.42A2.513 2.513 0 00.418 5.2C0 6.77 0 10.045 0 10.045s0 3.276.418 4.846c.23.866.908 1.52 1.768 1.75 1.56.421 7.814.421 7.814.421s6.254 0 7.814-.42a2.476 2.476 0 001.768-1.751C20 13.32 20 10.045 20 10.045s0-3.275-.418-4.845zM7.955 13.02V7.071l5.227 2.974-5.227 2.973z"
                transform="translate(-1031 -83) translate(968 26) translate(0 54) translate(63)"
            ></path>
        </svg>
    );
};

YoutubeIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

YoutubeIcon.defaultProps = {
    color: 'currentColor',
    size: '24'
};

export default YoutubeIcon;
