import React, { useEffect, useState } from 'react';
import { BacktoTopIcon } from '../../Icons';
import './BackToTop.less';
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

export const BackToTop = props => {
    const [scrollPosition, setSrollPosition] = useState(0);
    const [t] = useTranslation();
    const handleScroll = () => {
        const position = window.pageYOffset;
        setSrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollTop = () => {
        try {
            // trying to use new API -
            // https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
            window.scroll({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        } catch (error) {
            // just a fallback for older browsers
            window.scrollTo(0, 0);
        }
    };

    return (
        <>
            {scrollPosition > 100 && (
                <Button variant="link" className="back-to-top" onClick={scrollTop} aria-label={t('Back to top')}>
                    <BacktoTopIcon aria-hidden="true"/>
                </Button>
            )}
        </>
    );
};
