import React from 'react';
import PropTypes from 'prop-types';

import './CustomRadioButton.less';

export function CustomRadioButton({ children, onClick, active, label }) {
    return (
        <div onClick={onClick} className={active ? 'optionContainer optionContainer-active' : 'optionContainer'}>
            <input className={'optionContainer-radio'} onChange={onClick} type="radio" name="radio" aria-label={label}/>
            {children}
        </div>
    );
}

CustomRadioButton.propTypes = {
    onClick: PropTypes.func,
    label: PropTypes.string,
    active: PropTypes.bool
};
