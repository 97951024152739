import React from 'react';
import PropTypes from 'prop-types';

const Tick = props => {
    const { color, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill={color}
            stroke={color}
            viewBox="0 0 12 9"
            {...otherProps}
        >
            <path
                fill={color}
                fillRule="evenodd"
                stroke="none"
                strokeWidth="1"
                d="M119.073 430a.734.734 0 01-.53-.228l-3.323-3.445a.8.8 0 010-1.102.73.73 0 011.06 0l2.793 2.896 6.647-6.893a.73.73 0 011.06 0 .8.8 0 010 1.102l-7.177 7.442a.734.734 0 01-.53.228"
                transform="translate(-115 -421)"
            ></path>
        </svg>
    );
};

Tick.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Tick.defaultProps = {
    color: 'currentColor',
    size: '24'
};

export default Tick;
