import { useState } from "react";
import stringify from "fast-json-stable-stringify";

const getFieldsHash = (data = {}, fields = [], prefix = '') => {
    let hash = prefix;
    if (fields.length > 0) {
        fields.map( field => {
            if (typeof data[field] !== "undefined") {
                hash += hashCode(
                    stringify(data[field])
                );
            }
        });
    } else {
        hash += hashCode(
            stringify(data)
        );
    }

    return hash;
}

const hashCode = string => {
    var hash = 0;
    for (var i = 0; i < string.length; i++) {
        var char = string.charCodeAt(i);
        hash = ((hash<<5) - hash) + char;
        hash = hash & hash;
    }
    return hash;
}

export const useObjectCompare = (initialObjectState = {}, fieldsToTrack = [], hashPrefix = '') => {
    const [objectHash, setObjectHash] = useState(
            getFieldsHash(
                initialObjectState,
                fieldsToTrack,
                hashPrefix
            )
        );
    
    const isObjectChanged = (newObject, updateStoredState = false) => {
        const newObjectHash = getFieldsHash(
            newObject,
            fieldsToTrack,
            hashPrefix
        );
        
        const isChanged = objectHash !== newObjectHash;
        
        if (updateStoredState && isChanged) {
            setObjectHash(newObjectHash);
        }
        
        return isChanged;
    }
    
    return { isObjectChanged };
}
