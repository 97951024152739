import { PLACE_ORDER, SEARCH, CART_PAGE, PLP_PAGE } from './GTMMapping';
import {
    PDP_PAGE,
    getCartItemPrice,
    getConfigurableVariant,
    cartItemMapping,
    getCategoryName
} from '@corratech/google-tag-manager';
import { getItemCategoryPath } from './function/getItemCategoryPath';
import { getMapProductDetails } from './GTMMapping/functions/getMapProductDetails';
import { deepValue, formatPrice } from '@corratech/tag-manager';
import { CHECKOUT_PAGE } from '@corratech/google-tag-manager/src/mapping';
import ProjectConfig from '../../project.config';

const SHOW_PRICE_WITH_TAX = 2;

const storeConfig = ProjectConfig.storeConfig ? ProjectConfig.storeConfig : {};

const getStoreCartItemPrice = item => {
    if (storeConfig.display_price_shopping_cart == SHOW_PRICE_WITH_TAX) {
        return getCartItemPrice(item, [
            'prices',
            'row_total_including_tax',
            'value'
        ]);
    } else {
        return getCartItemPrice(item);
    }
};

const cartItemMappingValue = cartItemMapping(
    getItemCategoryPath,
    getStoreCartItemPrice,
    getConfigurableVariant
);

const orderItemMappingValue = {
    ...cartItemMappingValue,
    worth: data => {
        let worth = deepValue(data, ['product', 'worth'], 0);
        return formatPrice(worth);
    }
};

const getPDPCategoryName = data => {
    let categories =
        deepValue(data, ['categoryList']) || deepValue(data, ['categories']);
    if (!Array.isArray(categories)) {
        return '';
    }
    let categoryName = '';
    categories.map(item => {
        if (!categoryName) {
            categoryName = deepValue(
                item,
                ['breadcrumbs', '0', 'category_name'],
                ''
            );
        }
        if (!categoryName && !!item.category_name) {
            categoryName = item.category_name;
        }
    });

    return categoryName;
};

const mapProductDetails = getMapProductDetails(getItemCategoryPath);

export const GTMMappingOverride = {
    PLACE_ORDER: {
        ...CART_PAGE(cartItemMappingValue),
        ...PLACE_ORDER(orderItemMappingValue)
    },
    ADD_TO_CART: {
        ...cartItemMappingValue,
        ...CART_PAGE(cartItemMappingValue),
        quantityChange: ['quantityChange']
    },
    REMOVE_TO_CART: {
        ...cartItemMappingValue,
        ...CART_PAGE(cartItemMappingValue),
        quantityChange: ['quantityChange']
    },
    SEARCH: SEARCH(mapProductDetails),
    CART_PAGE: CART_PAGE(cartItemMappingValue),
    PLP_PAGE: PLP_PAGE(mapProductDetails),
    PDP_PAGE: PDP_PAGE(getPDPCategoryName, getPDPCategoryName),
    CHECKOUT_PAGE: CHECKOUT_PAGE(cartItemMappingValue)
};
