const CATEGORY_TREE_TYPE = 'CategoryTree';
const PRODUCT_BREADCRUMB_TYPE = 'ProductBreadcrumb';

const formatUrlKey = (urlKey, suffix) => {
    if (urlKey === '/') {
        return '';
    }
    if (urlKey === '') {
        return urlKey;
    }
    if (!!urlKey && !!suffix && !urlKey.includes(suffix)) {
        return `${urlKey}${suffix}`;
    }
    return urlKey;
};

export const getFormattedBreadcrumbs = (
    model,
    productUrlSuffix,
    categoryUrlSuffix
) => {
    let breadcrumbs = !!model && !!model.breadcrumbs ? model.breadcrumbs : [];
    // PLP case
    if (breadcrumbs.length === 0 && Array.isArray(model.categories)) {
        breadcrumbs = model.categories;
    }
    const processedBreadcrumbs = [];
    const initHomeBreadcrumb = () => {
        processedBreadcrumbs.push({
            position: 1,
            name: 'Home',
            url_key: ''
        });
    };
    const initCurrentPageBreadcrumb = suffix => {
        if (!!model.name && (!!model.url_key || !!model.canonical_url)) {
            const url_key = !!model.canonical_url
                ? model.canonical_url
                : formatUrlKey(model.url_key, suffix);
            processedBreadcrumbs.push({
                position: processedBreadcrumbs.length + 1,
                name: model.name,
                url_key: url_key
            });
        }
    };
    if (
        !!breadcrumbs.__typename &&
        breadcrumbs.__typename === PRODUCT_BREADCRUMB_TYPE
    ) {
        if (breadcrumbs.categories.length > 0) {
            breadcrumbs.categories.map((breadcrumb, index) => {
                let { name: breadcrumbName } = breadcrumb;
                let { name: productName } = model;
                if (
                    index + 1 === breadcrumbs.categories.length &&
                    productName.startsWith(breadcrumbName.replace('...', ''))
                ) {
                    breadcrumbName = productName;
                }
                processedBreadcrumbs.push({
                    position: index + 1,
                    name: breadcrumbName,
                    url_key: formatUrlKey(breadcrumb.url_key, productUrlSuffix)
                });
            });
        } else {
            initHomeBreadcrumb();
            initCurrentPageBreadcrumb(productUrlSuffix);
        }
    } else {
        // PLP and PDP have a different field name
        const isCategoryTree =
            !!model.__typename && model.__typename === CATEGORY_TREE_TYPE;
        const urlPathField = isCategoryTree ? 'category_url_path' : 'url_path';
        const nameField = isCategoryTree ? 'category_name' : 'name';
        initHomeBreadcrumb();
        breadcrumbs.reduce(
            (acc, category) => {
                const urlPath = category[urlPathField];
                if (acc.blockAccumulation || !urlPath) {
                    return acc;
                }
                const subCategories = urlPath.split('/');
                if (subCategories.length > acc.maxLength) {
                    acc.maxLength = subCategories.length;
                    processedBreadcrumbs.push({
                        position: processedBreadcrumbs.length + 1,
                        name: category[nameField],
                        url_key: formatUrlKey(urlPath, categoryUrlSuffix)
                    });
                } else {
                    acc.blockAccumulation = true;
                }
                return acc;
            },
            { maxLength: 0, blockAccumulation: false }
        );
        initCurrentPageBreadcrumb(productUrlSuffix);
    }

    return processedBreadcrumbs;
};
