import React from 'react';
import {VisaIcon} from "./VisaIcon";
import {MasterCardIcon} from "./MasterCardIcon";
import {MaestroIcon} from "./MaestroIcon";
import {AmericanExpressIcon} from "./AmericanExpressIcon";
import {DiscoverIcon} from "./DiscoverIcon";
import {PayPalIcon} from "./PayPalIcon";
import {KlarnaIcon} from "./KlarnaIcon";
import {ApplePayIcon} from "./ApplePayIcon";
import './PaymentIcons.less';

export const PaymentIcons = () => {
    return (
        <div className={'minicart-payment-icons'}>
            <span><VisaIcon /></span>
            <span><MasterCardIcon /></span>
            <span><MaestroIcon /></span>
            <span><AmericanExpressIcon /></span>
            <span><DiscoverIcon /></span>
            <span><PayPalIcon /></span>
            <span><KlarnaIcon /></span>
            <span><ApplePayIcon /></span>
        </div>
    );
}