import React, { useRef, useState, useEffect } from 'react';
import '@corratech/variant-selector/src/variant.less';
import PropTypes from 'prop-types';
import { ConstructorPLPManagerStore } from './ConstructorPLPManager';

export const ConstructorVariantSelector = props => {
    const {
        product,
        defaultOption,
        simpleProductCallback,
        optionSelectCallback
    } = props;
    const { variations, data: productData } = product;

    const variantRef = useRef(null);
    const [simpleProduct, setSimpleProduct] = useState(null);
    const [selectedOption, setSelectedOption] = useState(defaultOption);
    const { plpState } = React.useContext(ConstructorPLPManagerStore);

    useEffect(() => {
        setSelectedOption('');
        if (defaultOption) {
            setSelectedOption(defaultOption);
        }
    }, [plpState?.filters, plpState?.searchQuery]);

    const selectedValue = variationId => selectedOption === variationId;

    const optionSelect = (variationId, childValue, label) => {
        const selectedVariant = variations.find(
            variant => variant.data.variation_id === variationId
        );

        if (selectedVariant) {
            setSelectedOption(variationId);
            setSimpleProduct(selectedVariant.data);
            if (simpleProductCallback) {
                simpleProductCallback(selectedVariant.data);
            }
            if (optionSelectCallback) {
                optionSelectCallback({
                    items: [
                        {
                            variation_id: variationId,
                            value_index: childValue,
                            label
                        }
                    ]
                });
            }
        }
    };

    return (
        <>
            <div className="variants">
                <ul
                    className={`variant-options`}
                    aria-label="Variant options"
                    role="listbox"
                >
                    {variations.map((variant, variantKey) => (
                        <li
                            ref={variantRef}
                            data-variation_id={variant.data.variation_id}
                            key={variantKey}
                            className={`variant-option variant-${variant.data.variation_id}`}
                        >
                            <button
                                role="option"
                                onClick={() =>
                                    optionSelect(
                                        variant.data.variation_id,
                                        variant.data.variation_id,
                                        variant.value
                                    )
                                }
                                className={`variant-option-button swatch-data visual-option ${
                                    selectedValue(variant.data.variation_id)
                                        ? ' selected'
                                        : ''
                                }
                                ${!variant.data.in_stock ? ' oos' : ''}
                                `}
                            >
                                <span
                                    className={`variant-name`}
                                    style={{
                                        cursor: 'pointer'
                                    }}
                                >
                                    {variant.data.bottle_size}
                                </span>
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </>
    );
};

ConstructorVariantSelector.propTypes = {
    product: PropTypes.object.isRequired,
    simpleProductCallback: PropTypes.func,
    defaultOptions: PropTypes.array,
    optionSelectCallback: PropTypes.func
};

ConstructorVariantSelector.defaultProps = {
    defaultOptions: []
};
