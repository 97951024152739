import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import GET_STORE_CONFIG from './getReCaptchaStoreConfig.graphql';

export const useReCaptchaStoreConfig = props => {
    const [actionV3PlaceOrder, setActionV3PlaceOrder] = useState(null);
    const [actionV3PlaceEmailCheck, setActionV3PlaceEmailCheck] = useState(null);
    const [actionV3PlaceSingIn, setActionV3PlaceSingIn] = useState(null);
    const [actionV3PlaceRegister, setActionV3PlaceRegister] = useState(null);
    const [actionV3PlaceAccountSaveCard, setActionV3PlaceAccountSaveCard] = useState(null);
    const [actionV3ForgotPassword, setActionV3ForgotPassword] = useState(null);
    const [actionV3FooterEmailSignup, setActionV3FooterEmailSignup] = useState(null);
    const [actionV3SMSSignup, setActionV3SMSSignup] = useState(null);

    // Function to detect if the user agent is a bot or crawler
    const isBotOrCrawler = () => {
        const userAgent = navigator.userAgent.toLowerCase();
        const botPatterns = new RegExp(
            'prerender|googlebot|bingbot|yandex|baiduspider|facebookexternalhit|twitterbot|linkedinbot|embedly|showyoubot|outbrain|pinterestbot|slackbot|vkshare|w3c_validator|whatsapp|imgproxy|flipboard|tumblr|bitlybot|skype|nuzzel|discordbot|qwantify|pinterest|lighthouse|telegrambot|testprerender|screaming\\sfrog\\sseo\\sspider',
            'i'
        );

        return botPatterns.test(userAgent);
    };

    const { loading, error } = useQuery(GET_STORE_CONFIG, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            if (!!data.storeConfig && !isBotOrCrawler()) {
                setActionV3PlaceOrder(data.storeConfig.is_v3_place_order);
                setActionV3PlaceEmailCheck(data.storeConfig.is_v3_email_check);
                setActionV3PlaceSingIn(data.storeConfig.is_v3_sing_in);
                setActionV3PlaceRegister(data.storeConfig.is_v3_register);
                setActionV3PlaceAccountSaveCard(
                    data.storeConfig.is_v3_account_save_card
                );
                setActionV3ForgotPassword(
                    data.storeConfig.is_v3_forgot_password
                );
                setActionV3FooterEmailSignup(
                    data.storeConfig.is_v3_footer_email_signup
                );
                setActionV3SMSSignup(data.storeConfig.is_v3_sms_signup);
            }
        }
    });

    return {
        actionV3PlaceOrder: actionV3PlaceOrder,
        actionV3PlaceEmailCheck: actionV3PlaceEmailCheck,
        actionV3PlaceSingIn: actionV3PlaceSingIn,
        actionV3PlaceRegister: actionV3PlaceRegister,
        actionV3PlaceAccountSaveCard: actionV3PlaceAccountSaveCard,
        actionV3ForgotPassword: actionV3ForgotPassword,
        actionV3FooterEmailSignup: actionV3FooterEmailSignup,
        actionV3SMSSignup: actionV3SMSSignup
    };
};
