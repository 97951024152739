import { useContext } from 'react';
import { OptionsStore } from '../OptionsContext';

//
// Utility hook to get the global options
// example usage:
//
// const options = useGlobalOptions()
//

export const useGlobalOptions = () => useContext(OptionsStore);
