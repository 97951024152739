import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCurrencySymbol } from '../util/getCurrencySymbol';
import { setPriceZeroes } from '../util/setPriceZeroes';
import { OverlayStore, CartStore } from '@corratech/context-provider';
import { ShoppingCart } from 'react-feather';
require('./CheckoutEstimationBlock.less');

export const CheckoutEstimationBlock = props => {
    const { IconCart } = props;

    const [t] = useTranslation();
    const { cartState } = useContext(CartStore);
    const { callbackDispatch, overlayDispatch } = useContext(OverlayStore);
    const [toggle, setToggle] = useState(false);
    useEffect(() => {
        overlayDispatch({ type: toggle ? 'SHOW' : 'HIDE' });
        if (!toggle) {
            callbackDispatch({
                type: 'PUSH',
                data: () => setToggle(toggle)
            });
        }
    }, [toggle]);
    return (
        <div
            className={`${'checkout-estimated-wrapper'} ${
                toggle ? 'open-order-summary' : ''
            }`}
        >
            <div className={'estimated-block'}>
                <span className={'estimated-label'}>
                    {t('Estimated Total')}
                </span>
                <span className={'estimated-price'}>
                    {getCurrencySymbol(
                        cartState.cart.prices.grand_total.currency
                    ) + setPriceZeroes(cartState.cart.prices.grand_total.value)}
                </span>
            </div>
            <div className={'minicart-wrapper'}>
                <button
                    className="order-summary-toggle"
                    onClick={() => {
                        setToggle(!toggle);
                    }}
                >
                    <div className="shopping-cart">
                        <label className="cart-lbl" title={t('cart')}>
                            {IconCart}
                        </label>
                    </div>
                    <div className="cart-items-wrapper">
                        <span className="cart-items">
                            {cartState.cart.items.length > 0
                                ? cartState.cart.total_quantity &&
                                  cartState.cart.total_quantity > 0
                                    ? cartState.cart.total_quantity
                                    : cartState.cart.items.length
                                : cartState.cart.items.length}
                        </span>
                    </div>
                </button>
            </div>
            <div className={'order-summary-header'}>
                <div className={'title'} role={'heading'} aria-level="2">
                    {t('Order Summary')}
                </div>
                <button
                    className={'close btn btn-link'}
                    onClick={() => {
                        setToggle(!toggle);
                    }}
                >
                    <span className={'sr-only'}>{t('Close')}</span>
                </button>
            </div>
        </div>
    );
};

CheckoutEstimationBlock.defaultProps = {
    IconCart: <ShoppingCart size={24} />
};
