function PLPEvents(tagData) {
    const {
        categoryName,
        currencyCode,
        productsDetails = [],
        type
    } = tagData;
    
    let processedData = {
        event: type ? type : 'category',
        category: categoryName,
        _clear: true
    };
    
    if (productsDetails.length > 0) {
        processedData['ecommerce'] = {
            currencyCode,
            impressions: productsDetails
        };
    }

    return processedData;
}
export { PLPEvents };
