import { deepValue, formatPrice } from '@corratech/tag-manager';
import { getItemCategoryPath } from '../../function/getItemCategoryPath';

export const getMapProductDetails = (
    itemCategoryPath = getItemCategoryPath
) => {
    return data => {
        let productsDetails = [];
        let startingPosition =
            (parseInt(data.currentPage) - 1) * parseInt(data.pageSize);
        let currentCategoryName = deepValue(data, ['category', 'name'], '');
        let list =
            typeof data.searchTerm != 'undefined'
                ? `Search Results - ${data.searchTerm}`
                : `Category - ${itemCategoryPath(data, currentCategoryName)}`;
        let items =
            deepValue(data, ['products', 'items']) ||
            deepValue(data, ['items'], []);
        items.map((item, index) => {
            let price = deepValue(item, [
                'price_range',
                'minimum_price',
                'final_price',
                'value'
            ]);
            let itemCategory =
                item.categories && item.categories[1]
                    ? deepValue(
                          item,
                          ['categories', '1', 'name'],
                          currentCategoryName
                      )
                    : currentCategoryName;
            productsDetails.push({
                id: item.sku,
                name: item.name,
                price: formatPrice(price),
                category: itemCategory,
                position: startingPosition + index + 1,
                brand: '',
                list: list
            });
        });

        return productsDetails;
    };
};
