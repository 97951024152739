import { formatPrice } from '@corratech/tag-manager';

function OrderConfirmationEvents(tagData) {
    const {
        actionField: {
            id,
            affiliation,
            revenue,
            tax,
            shipping,
            coupon,
            discount
        },
        productsDetails = []
    } = tagData;

    return {
        event: 'purchase',
        ecommerce: {
            purchase: {
                actionField: {
                    id,
                    affiliation,
                    revenue: formatPrice(revenue),
                    tax: formatPrice(tax),
                    shipping: formatPrice(shipping),
                    coupon,
                    discount: formatPrice(discount),
                    action: 'purchase'
                },
                products: productsDetails
            }
        },
        _clear: true
    };
}
export { OrderConfirmationEvents };
