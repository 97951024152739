function CheckoutOptionEvents(tagData) {
    let ecommerce = {
        checkout_option: {
            actionField: tagData
        }
    };

    return {
        event: 'checkoutOption',
        ecommerce: ecommerce
    };
}
export { CheckoutOptionEvents };
