import React from 'react';
import { Helmet } from 'react-helmet-async';
import { ProductJson } from './ProductJson';
import {
    getProductUrl,
    getTextValue,
    getProductAttributeValue
} from './functions';
import { defaultProductMetadataConfig } from './defaultProductMetadataConfig';
import { formatPrice } from '@corratech/tag-manager';

const getProductMetadataConfig = projectConfig => {
    if (!projectConfig || !projectConfig.productMetadataConfig) {
        return defaultProductMetadataConfig;
    }
    let config = {
        ...defaultProductMetadataConfig,
        ...projectConfig.productMetadataConfig
    };
    if (!!projectConfig.productMetadataConfig.attributes) {
        config.attributes = {
            ...defaultProductMetadataConfig.attributes,
            ...projectConfig.productMetadataConfig.attributes
        };
    }
    return config;
};

export const ProductMeta = props => {
    const {
        amasty_product_data,
        product,
        urlBase,
        getStoreConfig,
        config,
        projectConfig
    } = props;
    const {
        twitter_site,
        store_name,
        twitter_enabled,
        og_enabled,
        locale,
        product_image_placeholder
    } = config;
    const productMetadataConfig = getProductMetadataConfig(projectConfig);

    const mediaBaseUrl = getStoreConfig(
        'secure_base_media_url',
        `${urlBase}media/`
    );
    // If input is a file name - transform into URL
    const getImageUrl = (url = '') => {
        return url.startsWith('http')
            ? url
            : `${mediaBaseUrl}catalog/product${url}`;
    };

    const imageUrlAttribute = getProductAttributeValue(
        product,
        productMetadataConfig.attributes,
        'image_url',
        product_image_placeholder
    );
    const imageUrl = getImageUrl(imageUrlAttribute);
    const imageAlt = getProductAttributeValue(
        product,
        productMetadataConfig.attributes,
        'image_alt',
        product.name
    );

    const urlSuffix = getStoreConfig('product_url_suffix', '');
    const productUrl = getProductUrl(product, urlBase, urlSuffix);

    const productDescription = getTextValue(
        getProductAttributeValue(
            product,
            productMetadataConfig.attributes,
            'description',
            product.name
        )
    );

    const priceAmount = getProductAttributeValue(
        product,
        productMetadataConfig.attributes,
        'price_amount',
        0
    );
    const priceCurrency = getProductAttributeValue(
        product,
        productMetadataConfig.attributes,
        'price_currency',
        ''
    );

    return (
        <>
            <Helmet>
                {!!twitter_site && !!twitter_enabled && (
                    <>
                        <meta name="twitter:card" content="summary" />
                        <meta name="twitter:site" content={twitter_site} />
                        <meta name="twitter:title" content={product.name} />
                        <meta
                            name="twitter:description"
                            content={productDescription}
                        />
                        <meta name="twitter:image" content={imageUrl} />
                        <meta name="twitter:image:alt" content={imageAlt} />
                    </>
                )}
            </Helmet>
            <Helmet>
                {!!og_enabled && (
                    <>
                        <meta property="og:type" content="product" />
                        <meta property="og:title" content={product.name} />
                        <meta property="og:url" content={productUrl} />
                        <meta property="og:image" content={imageUrl} />
                        <meta property="og:image:alt" content={imageAlt} />
                        <meta
                            property="og:site_name"
                            content={`${store_name} - ${product.name}`}
                        />
                        <meta
                            property="og:description"
                            content={productDescription}
                        />
                        <meta property="og:locale" content={locale} />
                        <meta
                            property="product:price:amount"
                            content={formatPrice(priceAmount)}
                        />
                        <meta
                            property="product:price:currency"
                            content={priceCurrency}
                        />
                    </>
                )}
            </Helmet>
            <ProductJson
                amasty_product_data={amasty_product_data}
                urlBase={urlBase}
                product={product}
                config={config}
                productMetadataConfig={productMetadataConfig}
                getStoreConfig={getStoreConfig}
                baseImage={imageUrl}
                getImageUrl={getImageUrl}
                productUrl={productUrl}
                priceAmount={priceAmount}
                priceCurrency={priceCurrency}
                storeName={store_name}
                urlSuffix={urlSuffix}
            />
        </>
    );
};
