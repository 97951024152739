import React from 'react';
import PropTypes from 'prop-types';

const US = props => {
    const { color, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 17.14 17.1"
            height={size}
            width={size}
            {...otherProps}
        >
            <defs>
                <mask
                    id="a"
                    width="40.33"
                    height="24.85"
                    x="-9.02"
                    y="-3.99"
                    maskUnits="userSpaceOnUse"
                >
                    <circle cx="8.57" cy="8.57" r="8.57" fill="#fff"></circle>
                </mask>
            </defs>
            <g fillRule="evenodd" mask="url(#a)">
                <path
                    fill="#fffffe"
                    d="M-9.03 20.86L31.31 20.86 31.31 -3.99 -9.03 -3.99 -9.03 20.86z"
                ></path>
                <path
                    fill="#c1252d"
                    d="M-9.03 1.76L31.31 1.76 31.31 -0.16 -9.03 -0.16 -9.03 1.76z"
                ></path>
                <path
                    fill="#c1252d"
                    d="M-9.03 5.67L31.31 5.67 31.31 3.76 -9.03 3.76 -9.03 5.67z"
                ></path>
                <path
                    fill="#c1252d"
                    d="M-9.03 9.43L31.31 9.43 31.31 7.52 -9.03 7.52 -9.03 9.43z"
                ></path>
                <path
                    fill="#c1252d"
                    d="M-9.03 13.19L31.31 13.19 31.31 11.28 -9.03 11.28 -9.03 13.19z"
                ></path>
                <path
                    fill="#c1252d"
                    d="M-9.03 17.1L31.31 17.1 31.31 15.19 -9.03 15.19 -9.03 17.1z"
                ></path>
                <path
                    fill="#273770"
                    d="M-9.03 9.43L9.17 9.43 9.17 -3.99 -9.03 -3.99 -9.03 9.43z"
                ></path>
                <path
                    fill="#fffffe"
                    d="M8.49 -0.2L7.96 -0.2 7.78 -0.71 7.78 -0.7 7.78 -0.71 7.61 -0.2 7.07 -0.2 7.51 0.21 7.31 0.8 7.78 0.46 8.25 0.8 8.05 0.21 8.49 -0.2"
                ></path>
                <path
                    fill="#fffffe"
                    d="M2.38 2.51L1.84 2.51 1.67 2 1.66 2 1.66 2 1.49 2.51 0.95 2.51 1.39 2.92 1.2 3.5 1.66 3.16 2.13 3.5 1.93 2.92 2.38 2.51"
                ></path>
                <path
                    fill="#fffffe"
                    d="M5.42 2.51L4.88 2.51 4.71 2 4.7 2 4.7 2 4.53 2.51 3.99 2.51 4.43 2.92 4.24 3.5 4.7 3.16 5.17 3.5 4.97 2.92 5.42 2.51"
                ></path>
                <path
                    fill="#fffffe"
                    d="M8.49 2.51L7.96 2.51 7.78 2 7.78 2 7.78 2 7.61 2.51 7.07 2.51 7.51 2.92 7.31 3.5 7.78 3.16 8.25 3.5 8.05 2.92 8.49 2.51"
                ></path>
                <path
                    fill="#fffffe"
                    d="M3.9 1.16L3.36 1.16 3.18 0.65 3.18 0.66 3.18 0.65 3.01 1.16 2.47 1.16 2.91 1.57 2.71 2.16 3.18 1.81 3.65 2.16 3.45 1.57 3.9 1.16"
                ></path>
                <path
                    fill="#fffffe"
                    d="M6.94 1.16L6.4 1.16 6.22 0.65 6.22 0.66 6.22 0.65 6.05 1.16 5.51 1.16 5.95 1.57 5.75 2.16 6.22 1.81 6.69 2.16 6.49 1.57 6.94 1.16"
                ></path>
                <path
                    fill="#fffffe"
                    d="M2.38 5.16L1.84 5.16 1.67 4.64 1.66 4.65 1.66 4.64 1.49 5.16 0.95 5.16 1.39 5.56 1.2 6.15 1.66 5.81 2.13 6.15 1.93 5.56 2.38 5.16"
                ></path>
                <path
                    fill="#fffffe"
                    d="M5.42 5.16L4.88 5.16 4.71 4.64 4.7 4.65 4.7 4.64 4.53 5.16 3.99 5.16 4.43 5.56 4.24 6.15 4.7 5.81 5.17 6.15 4.97 5.56 5.42 5.16"
                ></path>
                <path
                    fill="#fffffe"
                    d="M8.49 5.16L7.96 5.16 7.78 4.64 7.78 4.65 7.78 4.64 7.61 5.16 7.07 5.16 7.51 5.56 7.31 6.15 7.78 5.81 8.25 6.15 8.05 5.56 8.49 5.16"
                ></path>
                <path
                    fill="#fffffe"
                    d="M3.9 3.81L3.36 3.81 3.18 3.3 3.18 3.3 3.18 3.3 3.01 3.81 2.47 3.81 2.91 4.22 2.71 4.81 3.18 4.46 3.65 4.81 3.45 4.22 3.9 3.81"
                ></path>
                <path
                    fill="#fffffe"
                    d="M6.94 3.81L6.4 3.81 6.22 3.3 6.22 3.3 6.22 3.3 6.05 3.81 5.51 3.81 5.95 4.22 5.75 4.81 6.22 4.46 6.69 4.81 6.49 4.22 6.94 3.81"
                ></path>
                <path
                    fill="#fffffe"
                    d="M2.38 7.86L1.84 7.86 1.67 7.35 1.66 7.35 1.66 7.35 1.49 7.86 0.95 7.86 1.39 8.27 1.2 8.86 1.66 8.51 2.13 8.86 1.93 8.27 2.38 7.86"
                ></path>
                <path
                    fill="#fffffe"
                    d="M5.42 7.86L4.88 7.86 4.71 7.35 4.7 7.35 4.7 7.35 4.53 7.86 3.99 7.86 4.43 8.27 4.24 8.86 4.7 8.51 5.17 8.86 4.97 8.27 5.42 7.86"
                ></path>
                <path
                    fill="#fffffe"
                    d="M8.49 7.86L7.96 7.86 7.78 7.35 7.78 7.35 7.78 7.35 7.61 7.86 7.07 7.86 7.51 8.27 7.31 8.86 7.78 8.51 8.25 8.86 8.05 8.27 8.49 7.86"
                ></path>
                <path
                    fill="#fffffe"
                    d="M0.86 6.51L0.32 6.51 0.14 6 0.14 6.01 0.14 6 -0.03 6.51 -0.57 6.51 -0.13 6.92 -0.33 7.51 0.14 7.17 0.61 7.51 0.41 6.92 0.86 6.51"
                ></path>
                <path
                    fill="#fffffe"
                    d="M3.9 6.51L3.36 6.51 3.18 6 3.18 6.01 3.18 6 3.01 6.51 2.47 6.51 2.91 6.92 2.71 7.51 3.18 7.17 3.65 7.51 3.45 6.92 3.9 6.51"
                ></path>
                <path
                    fill="#fffffe"
                    d="M6.94 6.51L6.4 6.51 6.22 6 6.22 6.01 6.22 6 6.05 6.51 5.51 6.51 5.95 6.92 5.75 7.51 6.22 7.17 6.69 7.51 6.49 6.92 6.94 6.51"
                ></path>
            </g>
        </svg>
    );
};

US.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

US.defaultProps = {
    color: 'currentColor',
    size: '18'
};

export default US;
