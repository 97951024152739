import React from 'react';
import PropTypes from 'prop-types';

const InstagramIcon = props => {
    const { color, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill={color}
            stroke={color}
            viewBox="0 0 20 20"
            {...otherProps}
        >
            <path
                fill={color}
                fillRule="evenodd"
                stroke="none"
                strokeWidth="1"
                d="M10.002 4.872A5.12 5.12 0 004.876 10a5.12 5.12 0 005.126 5.128A5.12 5.12 0 0015.13 10a5.12 5.12 0 00-5.127-5.128zm0 8.462A3.34 3.34 0 016.67 10a3.337 3.337 0 013.333-3.334A3.337 3.337 0 0113.335 10a3.34 3.34 0 01-3.333 3.334zm6.532-8.671c0 .664-.535 1.196-1.195 1.196a1.196 1.196 0 111.196-1.196zm3.396 1.213c-.076-1.602-.442-3.02-1.615-4.19C17.145.516 15.727.15 14.125.07c-1.65-.093-6.6-.093-8.25 0-1.597.076-3.016.442-4.19 1.611C.512 2.851.151 4.27.07 5.871c-.093 1.652-.093 6.601 0 8.253.076 1.602.442 3.02 1.615 4.19 1.174 1.17 2.588 1.535 4.19 1.616 1.65.093 6.6.093 8.25 0 1.602-.076 3.02-.442 4.19-1.616 1.169-1.17 1.534-2.588 1.615-4.19.093-1.652.093-6.596 0-8.248zm-2.133 10.02a3.375 3.375 0 01-1.9 1.9c-1.317.523-4.44.402-5.895.402-1.454 0-4.582.116-5.894-.402a3.375 3.375 0 01-1.9-1.9c-.523-1.317-.402-4.441-.402-5.896s-.116-4.583.401-5.895a3.375 3.375 0 011.901-1.901c1.316-.523 4.44-.402 5.894-.402 1.455 0 4.582-.116 5.894.402a3.375 3.375 0 011.901 1.9c.522 1.317.402 4.441.402 5.896s.12 4.583-.402 5.895z"
                transform="translate(-968 -80) translate(968 26) translate(0 54) translate(0 .037)"
            ></path>
        </svg>
    );
};

InstagramIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

InstagramIcon.defaultProps = {
    color: 'currentColor',
    size: '24'
};

export default InstagramIcon;
