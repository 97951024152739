import React from 'react';

export const PayPalIcon = () => {
    return (
        <svg role="img" aria-label="Payment Method PayPal Icon" xmlns="http://www.w3.org/2000/svg" width="22" height="6" viewBox="0 0 22 6" fill="none">
            <path d="M4.13083 0.547881C3.81117 0.221976 3.36795 0.0607475 2.92188 0.108105H1.2806C1.16695 0.111047 1.07207 0.198844 1.05642 0.315547L0.375893 4.69671C0.371319 4.73911 0.384457 4.78151 0.411995 4.81322C0.439533 4.84494 0.478842 4.86293 0.520006 4.86267H1.32063C1.41942 4.86065 1.50616 4.79406 1.5368 4.69671L1.72094 3.50185C1.72934 3.38464 1.82371 3.29408 1.93711 3.29441H2.45752C2.92235 3.34761 3.38776 3.19969 3.7439 2.88556C4.10004 2.57143 4.31534 2.11893 4.33899 1.63487C4.42917 1.25849 4.35287 0.860039 4.13083 0.547881ZM3.11403 1.70126C3.02596 2.32358 2.56961 2.32358 2.13727 2.32358H1.88908L2.05721 1.17021C2.06498 1.09733 2.12265 1.04107 2.19331 1.03744H2.3054C2.60163 1.03744 2.88185 1.03744 3.02596 1.21169C3.12585 1.35241 3.15828 1.53269 3.11403 1.70126Z" fill="#253B80"/>
            <path d="M7.86967 1.67669H7.06904C7.00143 1.67792 6.94271 1.72525 6.92493 1.79286V2.0252L6.86889 1.93392C6.62737 1.67611 6.28495 1.54761 5.94016 1.58542C5.03483 1.60716 4.28175 2.31349 4.17078 3.24495C4.0856 3.69267 4.19385 4.15659 4.46701 4.51449C4.72082 4.80564 5.09026 4.9588 5.46779 4.92938C5.88052 4.93426 6.27707 4.76326 6.56465 4.45641V4.69704C6.56246 4.73667 6.57559 4.77557 6.60113 4.80515C6.62666 4.83473 6.66251 4.85256 6.70075 4.8547H7.41331C7.52562 4.85589 7.62145 4.77076 7.63749 4.65555L8.06182 1.82605C8.06951 1.77457 8.04919 1.72289 8.00898 1.69163C7.96876 1.66037 7.9152 1.65463 7.86967 1.67669ZM5.87611 4.07472C5.6777 4.08836 5.48382 4.00921 5.3477 3.85898C5.2291 3.6922 5.18536 3.48065 5.2276 3.27814C5.27272 2.80857 5.65298 2.45031 6.10829 2.44838C6.30779 2.43802 6.50141 2.52011 6.6367 2.67241C6.765 2.83447 6.8151 3.04826 6.77281 3.25325C6.72671 3.7311 6.33123 4.0902 5.8681 4.07472H5.87611Z" fill="#253B80"/>
            <path d="M12.081 1.67668H11.2804C11.203 1.67719 11.1309 1.71765 11.0882 1.78455L9.99138 3.44408L9.52702 1.78455C9.49835 1.68101 9.40678 1.60983 9.30284 1.61029H8.50222C8.4574 1.61004 8.41533 1.63267 8.38973 1.6708C8.36414 1.70893 8.3583 1.75768 8.37412 1.80114L9.2468 4.48129L8.44617 5.69274C8.41584 5.73596 8.41167 5.79323 8.4354 5.84067C8.45914 5.88811 8.5067 5.91758 8.55826 5.91682H9.35889C9.43479 5.91814 9.50655 5.88096 9.55104 5.81721L12.1931 1.83433C12.2028 1.75908 12.1532 1.68941 12.081 1.67668Z" fill="#253B80"/>
            <path d="M15.916 0.548264C15.5957 0.223363 15.153 0.0623055 14.707 0.108488H13.0578C12.9458 0.11197 12.8535 0.20053 12.8416 0.315929L12.161 4.69709C12.1565 4.73949 12.1696 4.7819 12.1972 4.81361C12.2247 4.84532 12.264 4.86331 12.3052 4.86305H13.1538C13.2333 4.85933 13.2981 4.79572 13.3059 4.71369L13.4981 3.46074C13.5137 3.34404 13.6086 3.25624 13.7223 3.2533H14.2427C14.7075 3.3065 15.1729 3.15858 15.5291 2.84445C15.8852 2.53033 16.1005 2.07783 16.1241 1.59377C16.198 1.23043 16.1225 0.851641 15.916 0.548264ZM14.8992 1.71823C14.8111 2.34056 14.3548 2.34056 13.9224 2.34056H13.6742L13.8424 1.18718C13.8507 1.11253 13.9141 1.05779 13.9865 1.06272H14.0986C14.3948 1.06272 14.675 1.06272 14.8191 1.23697C14.909 1.37272 14.9382 1.54202 14.8992 1.70164V1.71823Z" fill="#179BD7"/>
            <path d="M19.6551 1.67669H18.8544C18.7858 1.67492 18.7256 1.72352 18.7103 1.79286V2.0252L18.6543 1.93392C18.4128 1.67611 18.0703 1.54761 17.7256 1.58542C16.8202 1.60716 16.0671 2.31349 15.9562 3.24495C15.871 3.69267 15.9792 4.15659 16.2524 4.51449C16.5062 4.80564 16.8757 4.9588 17.2532 4.92938C17.6659 4.93426 18.0625 4.76326 18.35 4.45641V4.69704C18.3455 4.73724 18.3577 4.77754 18.3838 4.8077C18.4098 4.83786 18.4471 4.85498 18.4862 4.8547H19.1987C19.311 4.85589 19.4068 4.77076 19.4229 4.65555L19.8072 1.84265C19.8153 1.79808 19.8023 1.75219 19.7721 1.71931C19.742 1.68643 19.6984 1.67057 19.6551 1.67669ZM17.6615 4.07472C17.4629 4.08957 17.2686 4.01022 17.1331 3.85898C17.0145 3.6922 16.9708 3.48065 17.013 3.27814C17.0545 2.80671 17.4369 2.44642 17.8937 2.44838C18.0932 2.43802 18.2868 2.52011 18.4221 2.67241C18.5463 2.83655 18.5959 3.04835 18.5582 3.25325C18.5123 3.72783 18.1216 4.08577 17.6615 4.07472Z" fill="#179BD7"/>
            <path d="M21.4884 0.0835605H20.6878C20.6185 0.0830652 20.5599 0.136667 20.5517 0.208029L19.9112 4.69706C19.904 4.73856 19.9151 4.78121 19.9415 4.81333C19.9678 4.84545 20.0066 4.86368 20.0473 4.86303H20.7278C20.8389 4.86077 20.9329 4.77729 20.952 4.66387L21.6245 0.24122C21.6291 0.201027 21.6168 0.160725 21.5908 0.130563C21.5648 0.1004 21.5275 0.0832828 21.4884 0.0835605Z" fill="#179BD7"/>
        </svg>
)};