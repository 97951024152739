import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

export const useAnchorNavigation = () => {
    const elementRef = useRef();
    const history = useHistory();
    useEffect(() => {
        if (!elementRef.current) {
            return;
        }
        elementRef.current.querySelectorAll('a').forEach(anchor => {
            try{
                if (!!anchor.target) {
                    return;
                }
                let url = new URL(anchor.href);
                if (url.origin !== window.location.origin) {
                    return;
                }
                anchor.addEventListener('click', event => {
                    event.preventDefault();
                    event.stopPropagation();
                    history.push(
                        url.href.replace(url.origin, '')
                    );
                })
            } catch (e){
            }
        });
    }, [elementRef.current]);
    
    return elementRef;
}