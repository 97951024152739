import { useGlobalOptions } from '@corratech/context-provider';

/**
 * Resolve link properties
 *
 * @param {string} link
 * @param {string} linkType
 */
export default (link, linkType) => {
    let isExternalUrl;
    const linkProps = {};

    const options = useGlobalOptions();

    try {
        const baseUrlObj = new URL(process.env.MAGENTO_BACKEND_URL);
        const urlObj = new URL(link);
        isExternalUrl = baseUrlObj.host !== urlObj.host;

        if (isExternalUrl) {
            linkProps['href'] = link;
        } else {
            linkProps['to'] = urlObj.pathname;

            if (linkType !== 'default' && !/\.html$/.test(linkProps['to'])
                && (typeof options['urlSuffix'] === 'undefined' || options['urlSuffix'])) {
                linkProps['to'] += '.html';
            }
        }
    } catch (e) {
        /**
         * For Page Builder Admin urls without the Domain URL.
         * #CORRA React DOM Route for buttons if new URL() Fails
         * those starts with '/': Considering as local dom route
         */
        if (link.indexOf('/') === 0 && linkType === 'default') {
            linkProps['to'] = link;
        }
        linkProps['href'] = link;
    }

    return linkProps;
};
