import React, { useEffect, useState } from 'react';
import ConstructorIOClient from '@constructor-io/constructorio-client-javascript';
import ConstructorRecommendationItem from 'ModulesPath/ConstructorRecommendation/ConstructorRecommendationItem';
import { useConstructorConfig } from 'ModulesPath/SearchBar/useConstructorConfig';
import './ConstructorRecommendation.less';
import Slider from 'react-slick';

export const ConstructorRecommendation = ({
    pod_id,
    itemsId,
    numResults = 5
}) => {
    const {
        constructorApiKey,
        loading: configLoading
    } = useConstructorConfig();

    const [recommendations, setRecommendations] = useState([]);
    const [recommendationsResultId, setRecommendationsResultId] = useState(0);
    const [recommendationsTitle, setRecommendationsTitle] = useState('');
    const [loading, setLoading] = useState(true);
    const settings = {
        arrows: true,
        dots: true,
        speed: 400,
        slidesToScroll: 3,
        slidesToShow: 3,
        initialSlide: 0,
        focusOnSelect: false,
        draggable: false,
        infinite: false,
        adaptiveHeight: false,
        prevArrow: (
            <button type="button" className="slick-prev">
                Prev
            </button>
        ),
        nextArrow: (
            <button type="button" className="slick-next">
                Next
            </button>
        ),
        responsive: [
            {
                breakpoint: 500,
                settings: {
                    fade: false,
                    draggable: true,
                    slidesToScroll: 1,
                    slidesToShow: 1.3
                }
            },
            {
                breakpoint: 767,
                settings: {
                    fade: false,
                    draggable: true,
                    slidesToScroll: 2,
                    slidesToShow: 2
                }
            }
        ]
    };

    useEffect(() => {
        if (!constructorApiKey) {
            return;
        }

        const constructorio = new ConstructorIOClient({
            apiKey: constructorApiKey
        });

        const fetchRecommendations = async () => {
            try {
                const options = { numResults };

                if (itemsId) {
                    options.itemIds = Array.isArray(itemsId)
                        ? itemsId
                        : [itemsId];
                }

                const results = await constructorio.recommendations.getRecommendations(
                    pod_id,
                    options
                );
                if (results?.response?.results) {
                    setRecommendations(results.response.results);
                    setRecommendationsResultId(results.result_id);
                    setRecommendationsTitle(results.response.pod.display_name);
                } else {
                    console.error('No recommendations found');
                }
            } catch (error) {
                console.error('Error fetching recommendations:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchRecommendations();
    }, [pod_id, itemsId, numResults, constructorApiKey]);

    if (loading || configLoading) {
        return <div>Loading recommendations...</div>;
    }
    const hasPromo = () => 1 > 1;

    const getPreviewSize = index => {
        let preIndex = index + 1;
        let postIndex = hasPromo()
            ? preIndex + Math.floor(preIndex / 5)
            : preIndex;

        return postIndex % 4 === 0 && postIndex % 12 !== 8 ? 300 : 300;
    };

    return (
        <div
            className={'co-recommendations'}
            data-cnstrc-recommendations=""
            data-cnstrc-recommendations-pod-id={pod_id}
            data-cnstrc-num-results={numResults}
            data-cnstrc-result-id={recommendationsResultId}
        >
            <h2 className={'product-slider-title'}>{recommendationsTitle}</h2>
            <Slider {...settings}>
                {recommendations.map((item, index) => {
                    return (
                        <li className={'productItem'} key={item.data.id}>
                            <ConstructorRecommendationItem
                                product={item}
                                imageParam={{
                                    width: getPreviewSize(index)
                                }}
                                category={''}
                            />
                        </li>
                    );
                })}
            </Slider>
        </div>
    );
};
