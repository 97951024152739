import React, { useContext, useEffect } from 'react';
import { useGlobalOptions, CartStore } from '@corratech/context-provider';
import { createScript } from './utils/createScript';
import './useListrak.less';

export default function useOrderConfirmationPage() {
    const options = useGlobalOptions();

    const config = {
        enable: false,
        merchatId: options.storeConfig.listrak_merchant_id
    };

    const { cartState } = useContext(CartStore);

    if (!config.enable || config.merchatId === '') {
        return null;
    }

    const cartItems = JSON.parse(window.localStorage.getItem('listrak_cart_items'));

    const getItems = () => {
        let items = [];
        cartItems.map((item, key) => {
            items.push(`_ltk.Order.AddItem('${item.product.sku}', ${item.quantity}, '${item.product.price.regularPrice.amount.value}');`);
        });

        return items.join('');
    };

    const {
        customer_firstname,
        customer_lastname,
        customer_email,
        increment_id,
        tax_amount,
        shipping_amount,
        grand_total,
        subtotal
    } = cartState.placedOrder.placeOrder.order;

    const scriptBody = `(function(){if(typeof _ltk == 'object'){ltkCode();}else{(function (d) {
    if (document.addEventListener) document.addEventListener('ltkAsyncListener', d); else {
    e = document.documentElement; e.ltkAsyncProperty = 0; e.attachEvent('onpropertychange', function (e) {
    if (e.propertyName == 'ltkAsyncProperty') { d(); } }); } })(function(){ltkCode();});}
    function ltkCode(){_ltk_util.ready(function(){
		/** Handle ORDER **/
		_ltk.Order.SetCustomer('${customer_email}', '${customer_firstname}', '${customer_lastname}');
		_ltk.Order.OrderNumber = '${increment_id}';
		_ltk.Order.ItemTotal = '${subtotal.value}';
		_ltk.Order.ShippingTotal = '${shipping_amount.value}';
		_ltk.Order.TaxTotal = '${tax_amount.value}';
		_ltk.Order.OrderTotal = '${grand_total.value}';
		${getItems()}
		_ltk.Order.Submit();
	})}})();`;

    const getTrackingPixel = () => {
        const img = new Image(1,1);
        img.src = `https://fp.listrakbi.com/fp/${config.merchatId}.jpg`;
        img.className  = 'skip-size';
        return img;
    };

    useEffect(() => {
        const listrakGlobalEl = document.getElementById('listrak-global');

        document.body.insertBefore(createScript(scriptBody, 'listrak-order-confirmation'), listrakGlobalEl);
        document.body.prepend(getTrackingPixel());

        return () => {
            document.getElementById('listrak-order-confirmation').remove();
            window.localStorage.removeItem('listrak_cart_items');
        }
    }, []);
}
