function ShoppingCartEvents(tagData, addStoredData) {
    const {
        productsDetails = [],
        currencyCode
    } = tagData;

    addStoredData({
        cartContents: {
            currencyCode,
            products: productsDetails.reverse()
        }
    });
}
export { ShoppingCartEvents };
