import React, { Fragment } from 'react';
import { func, shape, string, number, object, bool } from 'prop-types';
import { useQuery } from 'react-apollo';
import GET_CMS_PAGE from './getCmsPage.graphql';
import { RichContent } from '@corratech/rich-content';
import { SEO } from '@corratech/seo';

export const CmsPageComponent = props => {
    const { identifier } = props;

    const { loading, error, data } = useQuery(GET_CMS_PAGE, {
        variables: { identifier, onServer: props.onServer },
        fetchPolicy: props.fetchPolicy,
        context: {
            fetchOptions: {
                method: props.requestMethod || 'GET'
            }
        }
    });

    const contentType = {
        tabs: {
            type: 'pb-accordion',
            classes: 'pb-accordion'
        }
    };

    if (!data && loading) {
        return props.loadingIndicator || null;
    }

    if (!data && error) {
        return <div>Data Fetch Error</div>;
    }

    if (data) {
        if (
            data.cmsPage.content &&
            data.cmsPage.content.length > 0 &&
            !data.cmsPage.content.includes('CMS homepage content goes here.')
        ) {
            return (
                <Fragment>
                    <SEO cmsPage={data.cmsPage} />
                    <RichContent
                        html={data.cmsPage.content}
                        extend={true}
                        config={contentType}
                    />
                </Fragment>
            );
        }
    }
    return null;
};

CmsPageComponent.propTypes = {
    children: func,
    classes: shape({
        block: string,
        content: string,
        root: string
    }),
    identifier: string.isRequired,
    loadingIndicator: object,
    onServer: bool,
    fetchPolicy: string
};

CmsPageComponent.defaultProps = {
    onServer: false,
    fetchPolicy: 'cache-and-network'
};
