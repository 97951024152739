import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-apollo';
import { Error } from '../../Icons';
import { Copyright, SubscribeForm } from '@corratech/footer';
import PropTypes from 'prop-types';
import { useCopyright, useNewsletterSubscription } from '@corratech/bll/footer';
import GET_CMS_BLOCKS from './getCmsBlocks.graphql';
import { RichContent } from '@corratech/rich-content';
import { Link } from 'react-router-dom';
import { SMSSignUp } from './SMSSignUp/SMSSignUp';
import { useWindowSize } from '@magento/peregrine';
import projectConfig from 'UtilPath/getProjectConfigs';

require('./Footer.less');

export const Footer = props => {
    const {
        useNewsletterSubscription,
        requestMethod,
        useCopyright,
        fetchPolicy,
        onServer,
        css
    } = props;

    const [t] = useTranslation();
    const identifiers = [
        'newsletter-subscription-title',
        'footer-social-links',
        'footer_links_block'
    ];
    const windowSize = useWindowSize();
    const isMobile = windowSize.innerWidth <= projectConfig.viewport.mobile;

    const { loading, error, data } = useQuery(GET_CMS_BLOCKS, {
        variables: { identifiers, onServer: onServer },
        fetchPolicy,
        context: {
            fetchOptions: {
                method: requestMethod || 'GET'
            }
        }
    });

    const contentType = {
        tabs: {
            type: 'pb-accordion',
            classes: 'pb-accordion'
        }
    };

    const copyright = (
        <Copyright blockId={'footer_copyright'} useCopyright={useCopyright} />
    );

    const agreementContent = (
        <>
            <span>
                {t(`I agree to receive email communications from ELEMIS.`)}
            </span>
            <Link to="/privacy-policy" className={'privacy-link'}>
                {t(` Privacy Policy`)}
            </Link>
        </>
    );

    return (
        <footer
            css={css}
            className={
                window.STORE_CODE == 'us'
                    ? 'footer-container footer-us'
                    : 'footer-container'
            }
        >
            <div className={'footer-content-wrapper'}>
                <div className={'footer-top-wrapper'}>
                    <div className={'footer-inner-content container-width'}>
                        <div className={'footer-top-content left'}>
                            <div className={'subscription-content'}>
                                {data && !loading && (
                                    <RichContent
                                        html={data.cmsBlocks.items[0].content}
                                        extend={true}
                                        config={contentType}
                                    />
                                )}
                            </div>
                            {window.STORE_CODE == 'us' ||
                            window.STORE_CODE == 'uk' ? (
                                <>
                                    {!isMobile && (
                                        <div
                                            className={
                                                'footer-top-content social-wrap'
                                            }
                                        >
                                            <div
                                                className={
                                                    'socail link container'
                                                }
                                            >
                                                {data && !loading && (
                                                    <RichContent
                                                        html={
                                                            data.cmsBlocks
                                                                .items[1]
                                                                .content
                                                        }
                                                        extend={true}
                                                        config={contentType}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <SubscribeForm
                                    title={''}
                                    inputPlaceholder={t('Email Address')}
                                    inputLabel={t(`Email Address`)}
                                    useNewsletterSubscription={
                                        useNewsletterSubscription
                                    }
                                    hasAgreement={true}
                                    ErrorIcon={Error}
                                    subscriptionSuccess={t(
                                        `Cheers. You’re on the list.`
                                    )}
                                    agreementText={agreementContent}
                                />
                            )}
                        </div>
                        {window.STORE_CODE == 'us' ||
                        window.STORE_CODE == 'uk' ? (
                            <div className={'footer-top-content right'}>
                                <SubscribeForm
                                    title={''}
                                    inputPlaceholder={t('Email Address')}
                                    inputLabel={t(`Email Address`)}
                                    useNewsletterSubscription={
                                        useNewsletterSubscription
                                    }
                                    hasAgreement={true}
                                    ErrorIcon={Error}
                                    subscriptionSuccess={t(
                                        `Cheers. You’re on the list.`
                                    )}
                                    agreementText={agreementContent}
                                />
                                <SMSSignUp />
                                {isMobile && (
                                    <div
                                        className={
                                            'footer-top-content social-wrap'
                                        }
                                    >
                                        <div
                                            className={'socail link container'}
                                        >
                                            {data && !loading && (
                                                <RichContent
                                                    html={
                                                        data.cmsBlocks.items[1]
                                                            .content
                                                    }
                                                    extend={true}
                                                    config={contentType}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className={'footer-top-content social-wrap'}>
                                <div className={'socail link container'}>
                                    {data && !loading && (
                                        <RichContent
                                            html={
                                                data.cmsBlocks.items[1].content
                                            }
                                            extend={true}
                                            config={contentType}
                                        />
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className={`footer-links-wrapper container-width`}>
                    {data && !loading && (
                        <RichContent
                            html={data.cmsBlocks.items[2].content}
                            extend={true}
                            config={contentType}
                        />
                    )}
                </div>
            </div>
            {copyright}
        </footer>
    );
};

Footer.propTypes = {
    icons: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.string,
            class: PropTypes.string,
            label: PropTypes.string,
            icon: PropTypes.element
        })
    ),
    useCopyright: PropTypes.func,
    useNewsletterSubscription: PropTypes.func
};

Footer.defaultProps = {
    fetchPolicy: 'cache-and-network',
    useCopyright,
    useNewsletterSubscription
};
