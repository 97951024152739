import React, { Component, Fragment } from 'react';
import { CmsPageComponent } from './PageIdentifier/cmsPage';
import LoadingIndicator from '../LoadingIndicator';
import { SEO } from '@corratech/seo';

const messages = new Map()
    .set('loading', LoadingIndicator)
    .set('notFound', 'That page could not be found. Please try again.')
    .set('internalError', 'Something went wrong. Please try again.')
    .set(
        'outOfStock',
        'This Product is currently out of stock. Please try again later.'
    );

class ErrorView extends Component {
    render() {
        const { loading, notFound, outOfStock } = this.props;
        const message = loading
            ? messages.get('loading')
            : notFound
            ? messages.get('notFound')
            : outOfStock
            ? messages.get('outOfStock')
            : messages.get('internalError');

        return (
            <Fragment>
                {notFound ? (
                    <>
                        <SEO
                            pageTitleOverride={'Not found'}
                            pageTypeOverride={'404'}
                        />
                        <CmsPageComponent
                            identifier={'cms-no-route'}
                            loadingIndicator={<LoadingIndicator />}
                            onServer={true}
                        />
                    </>
                ) : (
                    <h1>{message}</h1>
                )}
            </Fragment>
        );
    }
}

export default ErrorView;
