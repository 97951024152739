import React, {useContext} from 'react';
import { NotificationValueStore } from "@corratech/context-provider";
import { Alert } from "react-bootstrap";

export const GlobalAlert = () => {
    const {variant, content} = useContext(NotificationValueStore)

    if (variant && content)
        return (
            <div className={`global-alert global-alert-${variant}`}>
                <Alert variant={variant}>{content}</Alert>
            </div>
        )
    else {
        return null
    }
};