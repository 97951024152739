import React from 'react';
import PropTypes from 'prop-types';

const SearchIcon = props => {
    const { color, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            data-name="Layer 1"
            viewBox="0 0 17.28 17.28"
        >
            <circle
                cx="7.25"
                cy="7.25"
                r="6.5"
                fill="none"
                stroke={color}
                strokeWidth="1.5"
            ></circle>
            <path
                fill="none"
                stroke={color}
                strokeWidth="1.5"
                d="M11.75 11.75l5 5z"
            ></path>
        </svg>
    );
};

SearchIcon.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

SearchIcon.defaultProps = {
    color: 'currentColor',
    size: '24'
};

export default SearchIcon;
