import React from 'react';
import { useDebounce } from 'react-use';

export const AutoSubmit = ({
    submitForm,
    timeToDebounce,
    values,
    dirty,
    isValid
}) => {
    const [] = useDebounce(
        () => {
            if (dirty && isValid) {
                submitForm();
            }
        },
        timeToDebounce,
        [dirty, values, isValid]
    );

    return null;
};
