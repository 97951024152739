import React, { useContext, Fragment, useState, useEffect } from 'react';
import defaultClasses from './columnGroup.css';
import { mergeClasses } from '../../classify';
import { shape, string } from 'prop-types';
import SlickSlider from 'react-slick';
import { useWindowSize } from '@magento/peregrine';
import { OptionsStore } from '@corratech/context-provider';
import { Accordion, Button, Card } from 'react-bootstrap';
/**
 * Page Builder ColumnGroup component.
 *
 * This component is part of the Page Builder / PWA integration. It can be consumed without Page Builder.
 *
 * @typedef ColumnGroup
 * @kind functional component
 *
 * @param {props} props React component props
 *
 * @returns {React.Element} A React component that wraps {@link Column} components.
 */
const ColumnGroup = props => {
    const classes = mergeClasses(defaultClasses, props.classes);
    const { display, children } = props;
    const windowSize = useWindowSize();
    const [activeIndex, setActiveIndex] = useState(null);
    const projectConfig = useContext(OptionsStore) || {};
    const columnGroupConfig = projectConfig.pageBuilder
        ? projectConfig.pageBuilder.columnGroup
        : {};
    const sliderSettings = columnGroupConfig.slider || {};
    const sliderPort = columnGroupConfig.sliderPort || 767;
    const dynamicStyles = {
        display
    };
    const defaultSettings = {
        dots: true,
        arrows: true,
        infinite: false,
        autoplay: false,
        slidesToShow: 1
    };
    const isMobile = windowSize.innerWidth < sliderPort;

    const hasSliderClass = () => {
        let result = true;
        const sliderClass = columnGroupConfig.sliderClass || 'device-slider';

        for (let child in children) {
            if (!children[child].props.data.cssClasses.includes(sliderClass)) {
                result = false;
                break;
            }
        }
        return result;
    };

    const hasClass = className => {
        let result = true;
        for (let child in children) {
            if (!children[child].props.data.cssClasses.includes(className)) {
                result = false;
                break;
            }
        }
        return result;
    };

    /**
     * Merge slider configuarations
     * @type {{}}
     */
    const mergeSettings = { ...defaultSettings, ...sliderSettings };

    const columnSlider = () => {
        return isMobile ? (
            <SlickSlider {...mergeSettings}>{children}</SlickSlider>
        ) : (
            children
        );
    };

    const accordionTitle = nodes => {
        const nodeChildren = nodes.props.data.children;
        let title = false;
        if (nodeChildren.length) {
            for (let node in nodeChildren) {
                if (
                    nodeChildren[node].contentType === 'heading' &&
                    nodeChildren[node].cssClasses.includes('accordion-title')
                ) {
                    title = nodeChildren[node].text;
                    break;
                }
            }
        }
        return title;
    };

    const onAccordionToogle = index => {
        if (activeIndex === index) {
            setActiveIndex(null);
            return;
        }
        setActiveIndex(index);
    };

    const columnAccordion = () => {
        return isMobile ? (
            <Accordion defaultActiveKey={activeIndex}>
                {children.map((child, index) => {
                    const heading = accordionTitle(child) || index;
                    return (
                        <Fragment key={index}>
                            <Accordion.Toggle
                                className={
                                    activeIndex === index ? 'expanded' : ''
                                }
                                onClick={() => onAccordionToogle(index)}
                                eventKey={index}
                            >
                                {heading && heading}
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={index}>
                                {child}
                            </Accordion.Collapse>
                        </Fragment>
                    );
                })}
            </Accordion>
        ) : (
            children
        );
    };

    return (
        <div
            style={dynamicStyles}
            className={classes.root + ' ' + classes?.custom}
        >
            {hasSliderClass()
                ? columnSlider()
                : hasClass('col-accordion')
                ? columnAccordion()
                : children}
        </div>
    );
};

/**
 * Props for {@link ColumnGroup}
 *
 * @typedef props
 *
 * @property {Object} classes An object containing the class names for the ColumnGroup
 * @property {String} classes.root CSS classes for the root container element
 * @property {String} display CSS display property
 */
ColumnGroup.propTypes = {
    classes: shape({
        root: string
    }),
    display: string
};

export default ColumnGroup;
