function PromotionEvents(tagData) {
    const {
        items,
        eventType
    } = tagData;

    let processedData;
    if(eventType === 'promotionClick'){
        processedData = {
            event: 'promotionClick',
            ecommerce: {
                promoClick: {
                    promotions: [
                        ...items
                    ]
                }
            },
            _clear: true
        }

    } else {
        processedData = {
            event: 'promotionView',
            ecommerce: {
                promoView: {
                    promotions: [
                        ...items
                    ]
                }
            },
            _clear: true
        }
    }

    return processedData;
}
export { PromotionEvents };
