import { useContext } from 'react';
import { LoaderStore } from '../LoaderContext';

//
// Utility hook to get the loading indicator.
// example usage:
//
// const LoadingIndicator = useLoadingIndicator()
// return <LoadingIndicator/>
//

export const useLoadingIndicator = () => useContext(LoaderStore);
