import React from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

export const Organization = props => {
    const {
        urlBase,
        amasty_organization_data,
        amasty_organization_pages,
        config: {
            logo,
            store_name,
            email,
            street_address,
            address_locality,
            address_region,
            postal_code,
            phone,
            contact_type,
            contact_option,
            same_as,
            organization_enabled,
            organization_pages
        }
    } = props;
    const location = useLocation();

    const organizationPages = amasty_organization_pages || organization_pages;

    // Organization can be present only on specified pages
    const allowedPages = !!organizationPages
        ? organizationPages.split(',')
        : [];

    if (
        location.pathname !== '/' &&
        !allowedPages.includes(location.pathname.replace(/\//g, ''))
    ) {
        return '';
    }

    if (amasty_organization_data) {
        return (
            <Helmet>
                <script type={'application/ld+json'}>
                    {JSON.stringify(amasty_organization_data, null, 2)}
                </script>
            </Helmet>
        );
    }

    // Required options
    if (!organization_enabled || !logo || !store_name || !email) {
        return '';
    }

    const jsonData = {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        url: urlBase,
        logo: logo,
        name: store_name,
        email: email
    };

    if (
        !!street_address &&
        !!address_locality &&
        !!address_region &&
        !!postal_code
    ) {
        jsonData['address'] = {
            '@type': 'PostalAddress',
            streetAddress: street_address,
            addressLocality: address_locality,
            addressRegion: address_region,
            postalCode: postal_code
        };
    }
    if (!!phone && !!contact_type) {
        // Contact option may be a single string or an array
        const contactOption =
            !!contact_option && contact_option.includes(',')
                ? contact_option.split(',')
                : contact_option;
        jsonData['contactPoint'] = [
            {
                '@type': 'ContactPoint',
                telephone: phone,
                contactType: contact_type,
                contactOption: contactOption
            }
        ];
    }

    if (!!same_as) {
        jsonData['sameAs'] = same_as.split(',');
    }

    return (
        <Helmet>
            <script type={'application/ld+json'}>
                {JSON.stringify(jsonData, null, 2)}
            </script>
        </Helmet>
    );
};
