import { useGlobalOptions } from '@corratech/context-provider';

/**
 * @typedef {Object} useCopyrightResult
 * @property {Object} error - Apollo Error
 * @property {boolean} loading - is apollo query loading?
 * @property {string} copyrightText - the text result of the query
 *
 */

/**
 * Hook which fetches and processes copyright data on-load
 *
 * @function
 * @param {Object} [apolloOptions]
 *
 * @returns {useCopyrightResult}
 */

export const useCopyright = apolloOptions => {
    const options = useGlobalOptions();

    if (process.env.NODE_ENV === 'development') {
        if (
            !(options && options.storeConfig && options.storeConfig.copyright)
        ) {
            console.log(
                '%c Please add `copyright` to your static data store-config query, or manually add storeConfig.copyright in your project.config.js file',
                'color: orangered;'
            );
        }
    }

    return {
        error: null,
        loading: false,
        copyrightText:
            options && options.storeConfig && options.storeConfig.copyright
    };
};
