import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import {
    US,
    EU,
    UK,
    DE,
    IT,
    FR,
    NL,
    ES,
    HK,
    TW,
    SG,
    BE,
    PL,
    ID,
    AE,
    SA,
    JP,
    KR,
    MY,
    TH,
    ZA,
    CH
} from './Flags';
import { X as CloseIcon } from 'react-feather';
import { OptionsStore } from '@corratech/context-provider';
import { useTranslation } from "react-i18next";

export const StoreSwitcher = props => {
    const storeFlagMap = {
        uk: UK,
        eu: EU,
        us: US,
        de: DE,
        it: IT,
        fr: FR,
        nl: NL,
        es: ES,
        hk: HK,
        tw: TW,
        sg: SG,
        be: BE,
        pl: PL,
        id: ID,
        ae: AE,
        sa: SA,
        jp: JP,
        kr: KR,
        my: MY,
        th: TH,
        za: ZA,
        ch: CH
    };
    const projectConfig = useContext(OptionsStore) || {};
    const storeConfig = projectConfig.storeConfig || {};
    const { storeswitcher_data } = storeConfig;
    const Flag = storeFlagMap[window.STORE_CODE];
    const [t] = useTranslation();
    const storeList =
        typeof storeswitcher_data === 'string'
            ? JSON.parse(storeswitcher_data)
            : storeswitcher_data;

    return (
        <div className={'hdr-country-selector'}>
            <Dropdown>
                <Dropdown.Toggle
                    variant="success"
                    aria-label={'country-selector'}
                >
                    <Flag role='img'
                          aria-label={`Current store is ${window.STORE_CODE}`} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {storeList.length &&
                        storeList.map((list, i) => {
                            const CountryFlag = storeFlagMap[list.code];
                            return (
                                <Dropdown.Item
                                    className={CountryFlag ? '' : 'no-flag'}
                                    href={list.link}
                                    key={i}
                                >
                                    {CountryFlag && <CountryFlag role='img'
                                                                 aria-label={`${list?.storeLabel || list.code} store icon`} />}{' '}
                                    {list.label}
                                </Dropdown.Item>
                            );
                        })}
                    <Dropdown.Item
                        as="button"
                        aria-label={t('Close country selector dropdown')}
                        className={'store-select-close'}
                    >
                        <CloseIcon color={'#0e223a'} aria-hidden="true" size={14} />
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};
