import { formatPrice } from '@corratech/tag-manager';

function PDPEvents(tagData) {
    const {
        category,
        name,
        sku,
        price,
        currencyCode,
        categoryPath,
        brand,
        thumbnail
    } = tagData;
    return {
        event: 'product',
        ecommerce: {
            detail: {
                actionField: {
                    list: !!category ? `Category - ${category}` : ''
                },
                products: [
                    {
                        name,
                        id: sku,
                        price: formatPrice(price),
                        currency_code: currencyCode,
                        category: categoryPath,
                        brand,
                        thumbnailUrl: thumbnail.url ?? ''
                    }
                ]
            }
        },
        _clear: true
    };
}
export { PDPEvents };
