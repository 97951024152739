import React from 'react';
import PropTypes from 'prop-types';

const ES = props => {
    const { color, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            height={size}
            width={size}
            {...otherProps}
        >
            <path
                fill="#FFDA44"
                d="M496.1 167H15.9C5.6 194.7 0 224.7 0 256c0 31.3 5.6 61.3 15.9 89h480.2c10.3-27.7 15.9-57.7 15.9-89 0-31.3-5.6-61.3-15.9-89z"
            ></path>
            <path
                fill="#D80027"
                d="M256 0C145.9 0 52.1 69.5 15.9 167h480.2C459.9 69.5 366.1 0 256 0zm0 512c110.1 0 203.9-69.5 240.1-167H15.9c36.2 97.5 130 167 240.1 167z"
            ></path>
        </svg>
    );
};

ES.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

ES.defaultProps = {
    color: 'currentColor',
    size: '18'
};

export default ES;
