import React from 'react';
import PropTypes from 'prop-types';

const PL = props => {
    const { color, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            height={size}
            width={size}
            {...otherProps}
        >
            <path
                fill="#F0F0F0"
                d="M256 0C114.6 0 0 114.6 0 256h512C512 114.6 397.4 0 256 0z"
            ></path>
            <path
                fill="#D80027"
                d="M512 256H0c0 141.4 114.6 256 256 256s256-114.6 256-256z"
            ></path>
        </svg>
    );
};

PL.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

PL.defaultProps = {
    color: 'currentColor',
    size: '18'
};

export default PL;
