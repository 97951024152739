// Retrieve object value by path
export const deepValue = (obj, path, defaultValue) => {
    let result = obj;
    for (let i=0; i < path.length; i++){
        if (!result || typeof result[path[i]] === 'undefined') {
            return defaultValue;
        }
        result = result[path[i]];
    }
    return result;
};

// Resolve single field value
const resolveFieldValue = (data, fieldMap, key) => {
    let fieldValue;
    let defaultValue = data[key] || '';
    switch (typeof fieldMap) {
        // Sub-object with custom data source (from another field)
        // See productsDetails array, built from cart items
        case "object":
            if (!Array.isArray(fieldMap) && typeof fieldMap['data_source'] !== 'undefined') {
                let sourceData = resolveFieldValue(data, fieldMap['data_source']);
                if (!!sourceData) {
                    fieldValue = applyMapping(sourceData, fieldMap['mapping'], fieldMap['strict']);
                }
                break;
            }
            
        // Simple mapped sub-object
        case "object":
            if (!Array.isArray(fieldMap)) {
                fieldValue = applyMapping(data[key], fieldMap);
                break;
            }
            
        // Multiple field paths in an object - get the first defined value
        case "object":
            if (Array.isArray(fieldMap) && fieldMap.length > 0 && Array.isArray(fieldMap[0])) {
                let finalValue;
                fieldMap.map(path => {
                    let currentValue = deepValue(data, path, undefined);
                    if (typeof finalValue === 'undefined' && typeof currentValue !== 'undefined') {
                        finalValue = currentValue;
                    }
                });
                fieldValue = finalValue || defaultValue;
                break;
            }
            
            
        // Simple field path in an object
        case "object":
            if (Array.isArray(fieldMap)) {
                fieldValue = deepValue(data, fieldMap, defaultValue);
                break;
            }
            
        // Custom resolver function
        case "function":
            fieldValue = fieldMap(data);
            break;
            
        default:
            fieldValue = defaultValue;
    }
    
    return fieldValue;
}

const mapSingleObject = (data, mapping, strict = false) => {
    let mappedData = {};
    Object.entries(mapping).map(([key, fieldMap]) => {
        mappedData[key] = resolveFieldValue(data, fieldMap, key);
    })

    return strict ? mappedData : { ...data, ...mappedData };
}

// Process data input (object|array), where field values need to be resolved from input
// strict - if true, return only mapped fields, otherwise - replace mapped fields in the initial data
const applyMapping = (data, mapping, strict = false) => {
    if (!Array.isArray(data)) {
        return mapSingleObject(data, mapping, strict);
    }
    
    let mappedData = [];
    data.map((item) => {
        mappedData.push(
            mapSingleObject(item, mapping, strict)
        );
    });
    return mappedData;
}

// Apply mapping to the data input, if possible
export const applyDataMapping = (action, mapping) => {
    if (typeof mapping[action.type] === undefined || !mapping[action.type]) {
        return action;
    }

    let mappedAction = { ...action };
    try{
        mappedAction.data = applyMapping(action.data, mapping[action.type])
    } catch (e) {
        console.log(e);
        return action;
    }

    return mappedAction;
}