import React, { Component, Fragment } from 'react';
import { arrayOf, node, shape, string } from 'prop-types';
import { BasicRadioGroup, asField } from 'informed';
import { Message } from '../Message';
import Radio from './radio';
import defaultClasses from './radioGroup.css';

const RadioGroup = props => {
    const { fieldState, items, message, ...rest } = props;

    const options = items.map(({ label, value, img }) => (
        <Radio key={value} label={label} value={value} img={img} />
    ));

    return (
        <Fragment>
            <div className={'root-radio-group'}>
                <BasicRadioGroup {...rest} fieldState={fieldState}>
                    {options}
                </BasicRadioGroup>
            </div>
            <Message fieldState={fieldState}>{message}</Message>
        </Fragment>
    );
};

RadioGroup.propTypes = {
    fieldState: shape({
        value: string
    }),
    items: arrayOf(
        shape({
            label: string,
            value: string
        })
    ),
    message: node
};

export default asField(RadioGroup);
