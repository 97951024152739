function SmsSignUpEvents(tagData, getStoredData) {

    const currentPageTitle = getStoredData(['currentPageTitle']);
    const pageName = currentPageTitle?.currentPageTitle || 'other';
    return {
        event: 'smsOptIn',
        eventMetaData: {
            location: pageName
        }
    };
}
export { SmsSignUpEvents };
