export const isOrderGroovePresent = (cartStore = { cart: {} }) => {
    const ids = getCartIds(cartStore);
    return window.OG ? window.OG.getOptins(ids).length > 0 : false;
};

export const orderGrooveOptins = (ids = []) => {
    return window.OG ? window.OG.getOptins(ids) : [];
};

export const getTrackingOverride = (id) => {
    return orderGrooveOptins(id.toString())?.map(ogProduct => ({
        productId: ogProduct.product,
        ...ogProduct.tracking_override
    })) || [];
}

export const getCartIds = (cartState = { cart: {} }) => {
    return (
        (cartState.cart.items &&
            cartState.cart.items.map(item => {
                if (item.product.__typename === 'ConfigurableProduct')
                    return `${item.product.variants &&
                        item.product.variants.find(
                            variant => variant.product.sku === item.itemsku
                        ).product.id}`;

                return `${item.product.id}`;
            })) ??
        []
    );
};

export const clearOrderGrooveOptins = () => {
    window.OG && window.OG.clear();
};
