import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import GET_STORE_CONFIG from './getStoreConfig.graphql';

export const useStoreConfig = props => {
    const [isVerify3DSecureEnable, setVerify3DSecureEnable] = useState(null);
    const [isAlwaysRequest3DS, setAlwaysRequest3DS] = useState(null);
    const { loading, error } = useQuery(GET_STORE_CONFIG, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            console.log(data);
            if (!!data.storeConfig) {
                setVerify3DSecureEnable(data.storeConfig.verify_3dsecure);
                setAlwaysRequest3DS(data.storeConfig.always_request_3ds);
            }
        }
    });

    return {
        isVerify3DSecureEnable: isVerify3DSecureEnable,
        isAlwaysRequest3DS: isAlwaysRequest3DS
    };
};
