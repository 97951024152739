import React, { useContext, useState, useEffect } from 'react';
import { useStoreConfig } from './utils/useStoreConfig';
import saveGiftMessageToOrder from './Queries/saveOrderGiftMessage.graphql';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { Checkbox, Field, TextArea } from '@corratech/form-components';
import { useMutation } from 'react-apollo';
import { CartStore } from '@corratech/context-provider';

import './OrderGiftMessage.less';

export const OrderGiftMessage = props => {
    const LIMIT = 200;
    const { isOrderGiftMessageAllow } = useStoreConfig();
    const [t] = useTranslation();
    const { cartState, dispatch } = useContext(CartStore);

    const [charLimit, setCharLimit] = useState(LIMIT);
    const [isMessageShown, setIsMessageShown] = useState(false);
    const [isOrderGiftMessageSaved, setIsOrderGiftMessageSaved] = useState(
        false
    );
    const [giftMessage, setGiftMessage] = useState('');
    const [isAddGiftMessageChecked, setIsAddGiftMessageChecked] = useState(
        false
    );

    useEffect(() => {
        const initialMessage = cartState.cart.gift_message?.message;

        if (!!initialMessage && initialMessage.length > 0) {
            setCharLimit(LIMIT - initialMessage.length);
            setIsMessageShown(!!initialMessage);
            setIsOrderGiftMessageSaved(!!initialMessage);
            setGiftMessage(initialMessage);
            setIsAddGiftMessageChecked(!!initialMessage);
        }
    }, [cartState.cart.gift_message]);

    const [saveOrderGiftMessage, { loading }] = useMutation(
        saveGiftMessageToOrder,
        {
            variables: {
                cartId: cartState.cartId,
                giftMessage: giftMessage
            },
            onCompleted: result => {
                dispatch({
                    type: 'SET_CART',
                    cart: {
                        ...result.setGiftOptionsOnCart.cart,
                        requireGiftMessage: false
                    }
                });
                setIsMessageShown(isAddGiftMessageChecked);
                setIsOrderGiftMessageSaved(isAddGiftMessageChecked);
            },
            onError: result => {
                setIsMessageShown(false);
                setIsOrderGiftMessageSaved(false);
            }
        }
    );

    useEffect(() => {
        if (!isAddGiftMessageChecked && giftMessage.length > 0) {
            setGiftMessage('');
            setCharLimit(LIMIT);
            saveOrderGiftMessage({
                variables: {
                    giftMessage: ''
                }
            });
        }
    }, [isAddGiftMessageChecked]);
    const toggleGiftMessageHandler = e => {
        const checked = e.target.checked;
        setIsAddGiftMessageChecked(checked);

        dispatch({
            type: 'SET_CART',
            cart: {
                requireGiftMessage: checked
            }
        });
    };

    const saveOrderGiftMessageHandler = () => {
        if (giftMessage.trim()) {
            saveOrderGiftMessage({
                variables: {
                    giftMessage: giftMessage
                }
            });
        }
    };

    const editOrderGiftMessageHandler = () => {
        setIsOrderGiftMessageSaved(true);
        setIsMessageShown(false);
        dispatch({
            type: 'SET_CART',
            cart: {
                requireGiftMessage: true
            }
        });
    };

    const inputOrderGiftMessageHandler = e => {
        const message = e.target.value;
        const allowed = /[^.,?!\w\s]/gi;

        if (allowed.test(message)) return;

        setGiftMessage(message);
        const charsLeft = LIMIT - message.length;

        setCharLimit(charsLeft);
    };

    const messageBlock = () => (
        <div className={'order-gift-message-container'}>
            <div className={'order-gift-message-text'}>
                <p data-cs-mask>{giftMessage}</p>
                <Button
                    onClick={editOrderGiftMessageHandler}
                    variant="link"
                    type="button"
                >
                    {t('Edit Message')}
                </Button>
            </div>
        </div>
    );
    const giftMessageFormBlock = () => (
        <div className={'order-gift-message-form'}>
            <div className={'order-gift-message-container'}>
                <Field
                    className={'field'}
                    label={t(
                        "Add your message below - we'll print it and include it with your order."
                    )}
                    labelText={'order-gift-message'}
                    focusEventEnabled={true}
                >
                    <TextArea
                        type="text"
                        field="order-gift-message"
                        id={'order-gift-message'}
                        onInput={inputOrderGiftMessageHandler}
                        onKeyUp={inputOrderGiftMessageHandler}
                        placeholder={'Gift Message'}
                        maxLength={LIMIT}
                        value={giftMessage}
                    />
                </Field>
                <p className={'order-gift-message-char-remaining'}>
                    {charLimit} {t('characters remaining')}
                </p>
                <Button
                    onClick={saveOrderGiftMessageHandler}
                    disabled={giftMessage.length === 0 || loading}
                    size="sm"
                    variant="secondary"
                >
                    {loading ? t('Saving') : t('Save Message')}
                </Button>
            </div>
        </div>
    );

    const GiftMessageBlock = () => (
        <div className={'gift-card-checkout'}>
            <h2 className="step-title">{t('Gift Message')}</h2>
            <div className={'order-gift-message-check'}>
                <Checkbox
                    initialValue={isAddGiftMessageChecked}
                    field="order_gift_message"
                    id="order_gift_message"
                    label={t('Add a free gift message')}
                    onChange={toggleGiftMessageHandler}
                />
            </div>
            {isAddGiftMessageChecked &&
                (isMessageShown ? messageBlock() : giftMessageFormBlock())}
        </div>
    );

    return isOrderGiftMessageAllow && GiftMessageBlock();
};
