import React from 'react';
import { array, oneOfType, shape, string, object, bool } from 'prop-types';
import { useQuery } from 'react-apollo';
import { mergeClasses } from './classify';
import { Block } from './block';
import defaultClasses from './cmsBlock.css';
import GET_CMS_BLOCKS from './getCmsBlocks.graphql';

export const CmsBlockGroup = props => {
    const { identifiers, callbackOnError } = props;
    const classes = mergeClasses(defaultClasses, props.classes);

    const { loading, error, data } = useQuery(GET_CMS_BLOCKS, {
        variables: { identifiers, onServer: props.onServer },
        fetchPolicy: props.fetchPolicy,
        context: {
            fetchOptions: {
                method: props.requestMethod || 'GET'
            }
        }
    });

    if (!data && loading) {
        return props.loadingIndicator || null;
    }

    if (!data && error) {
        if (callbackOnError) {
            callbackOnError();
        }
        return <div className="cms-load-error">Data Fetch Error</div>;
    }

    const { items } = data.cmsBlocks;

    if (!Array.isArray(items) || !items.length) {
        return <div>There are no blocks to display</div>;
    }

    const blocks = items.map((item, index) => (
        <Block
            key={item.identifier}
            className={classes.block}
            index={index}
            {...item}
        />
    ));

    return (
        <div className={classes.root}>
            <div className={classes.content}>{blocks}</div>
        </div>
    );
};

CmsBlockGroup.propTypes = {
    classes: shape({
        block: string,
        content: string,
        root: string
    }),
    identifiers: oneOfType([string, array]).isRequired,
    loadingIndicator: object,
    onServer: bool,
    fetchPolicy: string
};

CmsBlockGroup.defaultProps = {
    onServer: false,
    fetchPolicy: 'cache-and-network'
};
