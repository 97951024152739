import React, { useContext } from 'react';
import { AuthStore } from '@corratech/context-provider';
import { Button, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const ConfirmAddress = props => {
    const [t] = useTranslation();
    const { authState } = useContext(AuthStore);

    const { currentAddress, show, handleClose, saveAndContinue } = props;

    const renderAddress = () => {
        let address;
        !!authState.token
            ? (address = (
                  <div className="entered-address">
                      <span>{currentAddress.street[0]} </span>
                      <span>{currentAddress.street[1]} </span>
                      <span>{currentAddress.city} </span>
                      <span>{currentAddress.region} </span>
                      <span>{currentAddress.postcode} </span>
                      <span>{currentAddress.countryCode}</span>
                  </div>
              ))
            : (address = (
                  <div className="entered-address">
                      <span>{currentAddress.street[0]} </span>
                      <span>{currentAddress.street[1]} </span>
                      <span>{currentAddress.city} </span>
                      <span>
                          {currentAddress.region && currentAddress.region.code}{' '}
                      </span>
                      <span>{currentAddress.postcode} </span>
                      <span>
                          {currentAddress.country &&
                              currentAddress.country.code}
                      </span>
                  </div>
              ));
        return address;
    };

    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                className="loqate-address-popup"
            >
                <Modal.Header closeButton>
                    <Modal.Title>CONFIRM YOUR ADDRESS</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        We couldn’t verify the exact address you entered. Please
                        double check to confirm it’s correct or make any
                        necessary changes.
                    </div>
                    {currentAddress ? renderAddress() : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={saveAndContinue}>
                        {t('Save & Continue')}
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        {t('Edit Address')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};
