import {
    getAdvanced,
    getBackgroundImages,
    getVerticalAlignment
} from '../../utils';

export default node => {
    return {
        minHeight: node.style.minHeight ? node.style.minHeight : null,
        display: node.style.display,
        width: node.style.width,
        backgroundColor: node.style.backgroundColor,
        fastlyParamsMob: node.getAttribute('data-mobile-fastly-params')
            ? node.getAttribute('data-mobile-fastly-params')
            : null,
        fastlyParamsDesktop: node.getAttribute('data-desktop-fastly-params')
            ? node.getAttribute('data-desktop-fastly-params')
            : null,
        ...getAdvanced(node),
        ...getBackgroundImages(node),
        ...getVerticalAlignment(node)
    };
};
