import React, { useState, useEffect } from 'react';
import PropTypes, { bool, node, object, func } from 'prop-types';
import './CollapsibleContainer.less';

export const CollapsibleContainer = props => {
    const { children, isOpen = false, title, icon = null } = props;

    const [opened, setOpened] = useState(isOpen);

    useEffect(() => {
        if (opened && props.onOpen) {
            props.onOpen();
        } else if (!opened && props.onClose) {
            props.onClose();
        }
    }, [opened]);

    const toggle = e => {
        setOpened(!opened);
    };

    return (
        <div
            className={`collapsible-wrapper ${opened &&
                'collapsible-wrapper--opened'}`}
        >
            <div className={'collapsible-header'}>
                <button
                    className={'collapsible-toggle'}
                    type="button"
                    onClick={toggle}
                    aria-expanded={opened ? 'false' : 'true'}
                >
                    {icon}
                    <div className="collapsible-title">{title}</div>
                    <div className="collapsible-arrow-wrapper">
                        <span className="collapsible-arrow" />
                    </div>
                </button>
            </div>
            <div className="collapsible-inner">
                <div
                    className="collapsible-content"
                    aria-hidden={opened ? 'false' : 'true'}
                >
                    {children}
                </div>
            </div>
        </div>
    );
};

CollapsibleContainer.propTypes = {
    children: node,
    title: node,
    isOpen: bool,
    icon: object,
    onOpen: func,
    onClosed: func
};
