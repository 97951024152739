import React, { useState, useContext, useEffect, Suspense, lazy } from 'react';
import {
    CartStore,
    LoaderStore,
    AuthStore,
    isSignedIn,
    getUserDetails
} from '@corratech/context-provider';
import { useQuery, useMutation } from 'react-apollo';
import getCountriesAndToken from './Queries/getCountriesAndToken.graphql';
import setShippingAddressesOnCart from '../graphql/setShippingAddressesOnCart.graphql';
import { useTranslation } from 'react-i18next';
import './AddressValidationCO.less';
import { useSetNotification } from '@corratech/context-provider';
export const AddressValidationCO = ({
    AddressValidationMethod,
    setShowPaymentForm
}) => {
    //const AddressValidation = lazy(() => import(`../AddressValidation/${AddressValidationMethod}`));
    const { setNotification, clearNotification } = useSetNotification();
    const { cartState, dispatch } = useContext(CartStore);
    const { dispatch: dispatchAuth } = useContext(AuthStore);
    const shippingAddressFromStep1 = cartState.cart.shipping_addresses[0];
    if (
        !shippingAddressFromStep1 ||
        (shippingAddressFromStep1.country.code != 'US' &&
            shippingAddressFromStep1.country.code != 'CA')
    ) {
        return '';
    }
    const [originalAddress, setOriginalAddress] = useState(false);
    const [t] = useTranslation();
    const LoadingIndicator = useContext(LoaderStore);

    const [setShippingAddress, { loading: loadingMutation }] = useMutation(
        setShippingAddressesOnCart,
        {
            onCompleted: res => {
                /**
                 * Update user's Address block if logged in
                 * based on the suggested address change
                 */
                if (isSignedIn()) {
                    getUserDetails(dispatchAuth);
                }
                /**
                 * Check if selected shipping method is available
                 * after selecting the suggested address and
                 * not to reset the shipping address
                 */
                const updatedCart = res.setShippingAddressesOnCart.cart;
                if (hasSelectedShippingMethod() && !!hasAvailabeMethod(res)) {
                    updatedCart.shipping_addresses[0].selected_shipping_method = hasAvailabeMethod(
                        res
                    );
                } else {
                    setNotification({
                        variant: 'success',
                        content: t(
                            'Shipping address was successfully updated. Your shipping method has been reset. Please choose a shipping method to proceed.'
                        ),
                        duration: 10000
                    });
                    editCallback();
                }
                dispatch({
                    type: 'SET_CART',
                    cart: {
                        ...updatedCart
                    }
                });
            }
        }
    );

    /**
     * Check if shipping method is selected
     * @return {boolean}
     */
    const hasSelectedShippingMethod = () => {
        return !!cartState.cart.shipping_addresses[0].selected_shipping_method;
    };

    /**
     * Validate if selected shipping method available
     * for new address
     * @param res
     * @return {boolean}
     */
    const hasAvailabeMethod = res => {
        const selected =
            cartState.cart.shipping_addresses[0].selected_shipping_method;
        const availabe = res.setShippingAddressesOnCart.cart.shipping_addresses[0].available_shipping_methods.filter(
            method => {
                return method.method_code === selected.method_code;
            }
        );

        return availabe.length ? availabe[0] : false;
    };

    const selectedCallback = address => {
        setShippingAddress({
            variables: {
                cartId: cartState.cartId,
                firstname: address.firstname,
                lastname: address.lastname,
                company: address.company,
                street: address.street,
                city: address.city,
                region: getRegionCode(address.region_id),
                postcode: address.postcode,
                countryCode: address.country_id,
                telephone: address.telephone
            }
        });
    };

    const editCallback = () => {
        setShowPaymentForm(false);
    };

    const { data, loading, error } = useQuery(getCountriesAndToken, {
        variables: {
            countryID: shippingAddressFromStep1.country.code
        }
    });

    const getRegionId = code => {
        if (data.country.available_regions) {
            const result = data.country.available_regions.find(
                value => value.code == code
            );
            if (result) {
                return result.id;
            }
        }
        return code;
    };

    const getRegionCode = id => {
        if (data.country.available_regions) {
            const result = data.country.available_regions.find(
                value => value.id == id
            );
            if (result) {
                return result.code;
            }
        }
        return id;
    };

    useEffect(() => {
        if (data) {
            const shippingAddress2Validator = {
                address: {
                    region: shippingAddressFromStep1.region.label,
                    region_id: getRegionId(
                        shippingAddressFromStep1.region.code
                    ),
                    region_code: shippingAddressFromStep1.region.code,
                    country_id: shippingAddressFromStep1.country.code,
                    street: shippingAddressFromStep1.street,
                    company: shippingAddressFromStep1.company,
                    telephone: shippingAddressFromStep1.telephone,
                    postcode: shippingAddressFromStep1.postcode,
                    city: shippingAddressFromStep1.city,
                    firstname: shippingAddressFromStep1.firstname,
                    lastname: shippingAddressFromStep1.lastname
                }
            };

            setOriginalAddress(shippingAddress2Validator.address);
        }
    }, [data]);

    if (loading) {
        return <LoadingIndicator>{t('Validating address')}</LoadingIndicator>;
    }
    if (originalAddress) {
        return (
            <div
                className={`address-validation-wrapper ${loadingMutation &&
                    'loadingMutation'}`}
            >
                <AddressValidationMethod
                    selectedCallback={selectedCallback}
                    editCallback={editCallback}
                    address={originalAddress}
                    LoadingIndicator={LoadingIndicator}
                />

                {loadingMutation && (
                    <>
                        <div className={'address-validation-loading'}></div>
                        <LoadingIndicator>
                            {t('Updating Shipping address')}
                        </LoadingIndicator>
                    </>
                )}
            </div>
        );
    } else {
        return '';
    }
};
