import { formatPrice } from '@corratech/tag-manager';
function OrderConfirmationEvents(tagData) {
    const {
        items = [],
        actionField,
        productsDetails = [],
        cart = [],
        couponCode = '',
        productDiscount = 0,
        cartDiscount = 0,
        discountAmount = 0
    } = tagData;

    // Old mapping function, for compatibility with the old tracking
    if (productsDetails.length === 0) {
        items.map((item, index) => {
            const filterObj = {
                id: item.id,
                name: item.product.name,
                price: item.prices.price.value,
                quantity: item.quantity,
                brand: '',
                category: '',
                variant: ''
            };
            productsDetails.push(filterObj);
        });
    }

    // If purchase event happens earlier than checkout success step tracking,
    // purchase data will be overridden. We use `purchaseDataCopy` to store the data,
    // and we restore it after checkout success step tracking.
    const purchaseData = {
        purchase: {
            actionField: {
                id: actionField.id,
                revenue: formatPrice(actionField.revenue),
                subtotal: formatPrice(actionField.subtotal),
                tax: formatPrice(actionField.tax),
                shipping: formatPrice(actionField.shipping),
                coupon: couponCode,
                cartDiscount: formatPrice(cartDiscount < 0 ? cartDiscount : 0),
                productDiscount: formatPrice(
                    cartDiscount < 0
                        ? discountAmount - cartDiscount
                        : discountAmount
                ),
                email: cart.email ? cart.email : ''
            },
            products: productsDetails
        }
    };

    return {
        event: 'purchase',
        newToFile: actionField.ordercount > 1 ? 'no' : 'yes',
        ecommerce: purchaseData
    };
}
export { OrderConfirmationEvents };
