import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useGlobalOptions } from '@corratech/context-provider';
import './LoqateTagAddress.less';

export const LoqateTagAddress = props => {
    const {
        visible,
        searchTerm,
        setVisible,
        setLocateValues,
        country,
        limit,
        setAddressFinder,
        searchFieldName
    } = props;
    const [addressItems, setAddressItems] = useState(false);
    const projectConfig = useGlobalOptions();

    const findEndPoint = projectConfig.storeConfig.loqate_tag_find_endpoint;
    const retrieveEndPoint =
        projectConfig.storeConfig.loqate_tag_retrieve_endpoint;
    const apiKey = projectConfig.storeConfig.loqate_tag_api_key;
    const isEnabled = projectConfig.storeConfig.loqate_tag_enabled;

    const rootClassName = visible
        ? 'autocomplete-address-root root_visible'
        : 'autocomplete-address-root root_hidden';

    const getAddressApi = async (apiURL, retrieve = false) => {
        await axios
            .get(apiURL)
            .then(response => {
                if (!retrieve && response.data.Items) {
                    setAddressItems(response.data.Items);
                } else if (retrieve && response.data.Items) {
                    let address = response.data.Items[0];

                    let region = address.Province
                        ? address.Province
                        : address.City;

                    let shippingRegion = address.Province
                        ? address.Province
                        : '';

                    let postcode = address.PostalCode;

                    if (address.CountryName) {
                        postcode = address.PostalCode
                            ? address.PostalCode
                            : '000000';
                    }

                    let shippingAddress =
                        address.Company +
                        ' ' +
                        address.Line1 +
                        ' ' +
                        address.Line2 +
                        ' ' +
                        address.City +
                        ' ' +
                        shippingRegion +
                        ' ' +
                        postcode;

                    let retrieveAddress = {
                        street1: address.Line1,
                        street2: address.Line2,
                        city: address.City,
                        region: region,
                        postcode: postcode,
                        countryCode: address.CountryIso2,
                        countryName: address.CountryName,
                        Id: address.Id,
                        shipping_address: shippingAddress,
                        company: address.Company
                    };
                    let userAddress = {
                        street1: address.Line1,
                        street2: address.Line2,
                        city: address.City,
                        region: region,
                        postcode: postcode,
                        country: address.CountryIso2
                    };
                    window.localStorage.setItem(
                        'userAddress',
                        JSON.stringify(userAddress)
                    );
                    setLocateValues(retrieveAddress);
                    setAddressItems('');
                    setVisible(false);
                } else {
                    setAddressItems('');
                    setVisible(false);
                }
            })
            .catch(error => {});
    };

    const getFindUrl = container => {
        let url = `${findEndPoint}?Key=${apiKey}&Text=${searchTerm}&Countries=${country}`;
        if (container) {
            url = `${url}&Container=${container}`;
        }
        if (limit) {
            url = `${url}&Limit=${limit}`;
        }
        return url;
    };

    const getRetrieveUrl = Id => {
        return `${retrieveEndPoint}?Key=${apiKey}&Id=${Id}`;
    };

    useEffect(() => {
        if (isEnabled && apiKey && retrieveEndPoint && findEndPoint) {
            getAddressApi(getFindUrl());
        }
    }, [searchTerm]);

    const getMoreAddress = container => {
        getAddressApi(getFindUrl(container));
    };

    const retrieveAddress = Id => {
        getAddressApi(getRetrieveUrl(Id), true);
    };

    return (
        <>
            {addressItems.length > 0 && (
                <>
                    <div className={rootClassName}>
                        <div className={'address-suggestions-container'}>
                            <ul className={'address-suggestions-wrapper'}>
                                {addressItems.map((addressLine, key) => (
                                    <li
                                        key={key}
                                        className={`address-suggestion-item ${
                                            addressLine.Type !== 'Address'
                                                ? 'address-suggestion-region'
                                                : ''
                                        }`}
                                        onClick={() => {
                                            addressLine.Type === 'Address'
                                                ? retrieveAddress(
                                                      addressLine.Id
                                                  )
                                                : getMoreAddress(
                                                      addressLine.Id
                                                  );
                                        }}
                                    >
                                        <span>{addressLine.Text}</span>
                                        {addressLine.Description}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    {searchFieldName && searchFieldName == 'shipping_address' && (
                        <div
                            onClick={() => setAddressFinder(false)}
                            className="manual-address"
                        >
                            Enter your address manually
                        </div>
                    )}
                </>
            )}
        </>
    );
};

LoqateTagAddress.defaultProps = {
    limit: 7
};
