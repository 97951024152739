import { deepValue } from "@corratech/tag-manager";

export const MAX_CATEGORY_PATH_DEPTH = 5;

export const formatCategoryPath = (
    path = [],
    appendItem = false,
    maxDepth = MAX_CATEGORY_PATH_DEPTH
) => {
    if (path.length === 0 || path[0] !== 'Home') {
        path.splice(0, 0, 'Home');
    }
    let lengthLimit = (!!appendItem) ? maxDepth - 1 : maxDepth;
    path = path.slice(0, lengthLimit);
    if (!!appendItem) {
        path.push(appendItem);
    }
    return path.join('|');
}

export const getCategoryPath = (data, currentCategoryName = false) => {
    let path = [];
    let categories = deepValue(data, ['product', 'breadcrumbs', 'categories']) ||
        deepValue(data, ['categoryList']) || deepValue(data, ['categories']);
    if (!Array.isArray(categories) || categories.length === 0) {
        return '';
    }
    categories.map((item) => {
        let breadcrumbs = deepValue(item, ['breadcrumbs'], []);
        if (!Array.isArray(breadcrumbs)) {
            return;
        }
        let currentPath = [];
        breadcrumbs.map( breadcrumb => {
            let name = deepValue(breadcrumb, ['category_name']);
            if (!!name) {
                currentPath.push(name);
            }
        });
        // Use the last of the longest category paths
        if (currentPath.length >= path.length) {
            path = currentPath;
        }
    });

    return formatCategoryPath(path, currentCategoryName);
};
