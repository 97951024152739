import React from 'react';
import PropTypes from 'prop-types';
require('./SocialLinks.less');

export const SocialLinks = props => {
    const { title, icons } = props;

    return (
        <div
            className={`footer-social-links ${props.className || ''}`}
            css={props.css}
        >
            {title ? (
                <strong
                    role="heading"
                    aria-level="2"
                    className={'footer-top-heading'}
                >
                    {title}
                </strong>
            ) : (
                ''
            )}

            <div className={'social-icons'}>
                {icons.map(el => {
                    return (
                        <a
                            href={el.link}
                            target="_blank"
                            className={el.class}
                            title={el.label}
                            key={el.link}
                        >
                            {el.icon}
                            <span className={'sr-only'}>{el.label}</span>
                        </a>
                    );
                })}
            </div>
        </div>
    );
};

SocialLinks.propTypes = {
    title: PropTypes.string,
    icons: PropTypes.arrayOf(
        PropTypes.shape({
            link: PropTypes.string,
            class: PropTypes.string,
            label: PropTypes.string,
            icon: PropTypes.element
        })
    ).isRequired
};

SocialLinks.defaultPropTypes = {
    title: ''
};
