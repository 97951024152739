import React from 'react';
import PropTypes from 'prop-types';

const Error = props => {
    const { color, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            fill={color}
            stroke={color}
            viewBox="0 0 9 9"
            {...otherProps}
        >
            <path
                fill={color}
                fillRule="evenodd"
                stroke="none"
                strokeWidth="1"
                d="M125.772 540.67a.779.779 0 01-1.102 1.102l-3.17-3.17-3.17 3.17a.776.776 0 01-1.102 0 .779.779 0 010-1.102l3.17-3.17-3.17-3.17a.779.779 0 011.102-1.102l3.17 3.17 3.17-3.17a.779.779 0 011.102 1.102l-3.17 3.17 3.17 3.17z"
                transform="translate(-117 -533)"
            ></path>
        </svg>
    );
};

Error.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Error.defaultProps = {
    color: 'currentColor',
    size: '24'
};

export default Error;
