import React from 'react';
import { Link } from 'react-router-dom';
import { string, object, bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { EmptyCartIcon } from 'ModulesPath/Icons/EmptyCartIcon';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import './EmptyCartCheckoutBlock.less';

export const EmptyCartCheckoutBlock = props => {
    const [t] = useTranslation();

    return (
        <div className={'empty-cart ' + (props.className || '')}>
            <div className={'empty-cart-face'}>
                <EmptyCartIcon />
            </div>
            <h4 className={'subtitle'}>{t('Your Bag Is Empty')}</h4>

            <p className={'text'}>
                {t('Click')}{' '}
                <Link className={'links'} title={t('here')} to={'/'}>
                    {t('here')}
                </Link>{' '}
                {t('to continue shopping.')}
            </p>
        </div>
    );
};

EmptyCartCheckoutBlock.propTypes = {
    className: string
};
