import React, {useEffect, useState} from 'react';
import {Button} from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import './zenDesk.less';
import LoadingIndicator from "../LoadingIndicator";

export const ZenDeskLifeChat = () => {
    const [t] = useTranslation();
    const [isLauncherBtnShown, setIsLauncherBtnShown] = useState(true);
    const [isBtnClicked, setIsBtnClicked] = useState(false);
    let isScriptAdded = false;
    let script;

    window.zESettings = {
        webWidget: {
            contactForm: {
                attachments: false
            }
        }
    };

    useEffect(() => {
        function onLoad() {
            setTimeout(() => {
               addScript();
            }, 5000);
        }

        window.addEventListener('load', onLoad);

        return () => {
            window.removeEventListener('load', onLoad);
        };
    }, []);

    const addScript = (shouldOpenChat = false) => {
        script = document.createElement('script');
        script.src = 'https://static.zdassets.com/ekr/snippet.js?key=171b23f4-70c8-4280-ab78-51d66dab6e82';
        script.id = 'ze-snippet';
        let interval;

        document.body.appendChild(script);
        isScriptAdded = true;

        script.onload = () => {
            interval = setInterval(checkForZE, 100);
        };

        const checkForZE = () => {
            if (window.zE?.activate) {
                if (shouldOpenChat) {
                    zE.activate();
                }

                setTimeout(() => {
                    setIsLauncherBtnShown(false);
                }, 1000);

                clearInterval(interval);
            }
        };
    };

    const handleClick = () => {
        setIsBtnClicked(true);

        if (!isScriptAdded) {
            addScript(true);
        }
    };

    return (
        <>
            {
                isLauncherBtnShown && (
                    <div className="zendesk-launcher-btn-wrapper">
                        <Button aria-label="Chat" onClick={handleClick} variant='secondary' className='zendesk-launcher-btn'>
                            {!isBtnClicked ? (
                                <>
                                    <svg className='zendesk-launcher-icon' xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 20 20" xmlSpace="preserve" aria-hidden="true"><g></g><g></g><g></g><g><g><g><path d="M11,12.3V13c0,0-1.8,0-2,0v-0.6c0-0.6,0.1-1.4,0.8-2.1c0.7-0.7,1.6-1.2,1.6-2.1c0-0.9-0.7-1.4-1.4-1.4 c-1.3,0-1.4,1.4-1.5,1.7H6.6C6.6,7.1,7.2,5,10,5c2.4,0,3.4,1.6,3.4,3C13.4,10.4,11,10.8,11,12.3z"></path><circle cx="10" cy="15" r="1"></circle></g><path d="M10,2c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S5.6,2,10,2 M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0 L10,0z"></path></g></g></svg>
                                    <span className={"zendesk-launcher-btn-label"}>
                                        {t('Help')}
                                    </span>
                                </>
                            ) : (
                                <LoadingIndicator width={108}/>
                            )}
                        </Button>
                    </div>
                )
            }
        </>
    )
}

