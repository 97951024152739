function CheckoutEvents(tagData) {
    const {
        actionField,
        productsDetails = []
    } = tagData;

    let ecommerce = {
        checkout: {
            actionField: {
                step: actionField.step,
                option: actionField.description
            },
            products: productsDetails
        }
    };

    return {
        event: 'checkout',
        ecommerce: ecommerce,
        _clear: true
    };
}
export { CheckoutEvents };
