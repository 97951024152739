import React, { useEffect, useContext } from 'react';
import { useMutation } from 'react-apollo';
import { CartStore } from '@corratech/context-provider';
import { signifyedHelper } from './utils/helper';
import { Util } from '@magento/peregrine';
import generateSignifydSessionId from './Queries/generateSignifydSessionId.graphql';

const { BrowserPersistence } = Util;
const storage = new BrowserPersistence();

//Default signinfed URL
const SIGNIFYD_CDN_URL = 'https://cdn-scripts.signifyd.com/api/script-tag.js';
const STORAGE_KEY = 'sig_api';

export default function useSignifyd(
    url = SIGNIFYD_CDN_URL,
    query = generateSignifydSessionId
) {
    const { cartState } = useContext(CartStore);
    const [generateSessionId, { data }] = useMutation(query);
    const sigApi = storage.getItem(STORAGE_KEY);

    useEffect(() => {
        if (cartState.cartId) {
            if (!sigApi || cartState.cartId !== sigApi.cartId) {
                storage.removeItem(STORAGE_KEY);
                generateSessionId({
                    variables: {
                        cardId: cartState.cartId
                    }
                });
            } else {
                executeSig(sigApi.sessionOrderId);
            }
        }
    }, [cartState.cartId]);

    /**
     * Execute only if mutation triggers
     * set the data on storage to avoid mutation on each load
     */
    useEffect(() => {
        if (data) {
            const sessionId =
                data.generateSignifydSessionId.data_order_session_id;
            executeSig(sessionId);
            storage.setItem(STORAGE_KEY, {
                cartId: cartState.cartId,
                sessionOrderId: sessionId
            });
        }
    }, [data]);

    /**
     * Inject the signfyd script in to head
     * @param sessionId
     */
    const executeSig = sessionId => {
        signifyedHelper.resetSignifyed(url);
        signifyedHelper.injectScipt(url, {
            id: 'sig-api',
            'data-order-session-id': sessionId
        });
    };
}
