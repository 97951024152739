import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthStore } from '@corratech/context-provider';
import { GlobalAlert } from '@corratech/global-alert';
import { useLocation } from 'react-router-dom';
import './HeaderBlocker.less';
import AccountForm from 'ModulesPath/Shell/Header/AccountForm';

export const HeaderBlocker = ({
    DefaultLogo,
    accountLinksConfig,
    isSecondStep,
    WarningIco,
    ErrorIco,
    SuccessIco
}) => {
    const [t] = useTranslation();
    const { authState } = useContext(AuthStore);

    let location = useLocation();
    let isCheckout = location.pathname.indexOf('checkout') > -1;
    const sampleAccountLinksConfig = [
        {
            label: t('My Account'),
            link: '/my-account'
        },
        {
            label: t('My Orders'),
            link: '/my-wishlist'
        },
        {
            label: t('My Wishlist'),
            link: '/my-account/wishlist'
        },
        {
            label: t('Store Credit'),
            link: '/store-credit'
        }
    ];

    return (
        <header className={'checkout-header'}>
            <div className={'header-logo'}>
                <div className="side-container" />
                <Link className={'logo'} to={'/'} aria-label={t('Homepage')}>
                    {<DefaultLogo />}
                </Link>

                <div className="side-container">
                    {/*!!!authState.token &&*/ !isSecondStep && (
                        <AccountForm
                            displayLabelGuestUser={'Sign In'}
                            hideLinkAfterLoggedIn={false}
                            myAccountLinksConfig={
                                accountLinksConfig || sampleAccountLinksConfig
                            }
                            hideSigninLink={true}
                            removeHeaderAccountLink={true}
                            removeLoyaltyWelcomeButtons={true}
                        />
                    )}
                </div>
            </div>
            <GlobalAlert
                WarningIco={WarningIco}
                ErrorIco={ErrorIco}
                SuccessIco={SuccessIco}
            />
        </header>
    );
};
