// This function does not depend on the official API, but it does reset the tracked data
// in the GTM preview iframe in its current implementation.
// If the implementation will be changed, this code will need to be updated
const resetGTMPreviewDebug = () => {
    if (typeof window['google_tag_manager'] === 'undefined' ||
        typeof window['google_tag_manager']['globalDebugId'] === 'undefined') {
        return;
    }

    window.document.querySelectorAll('iframe').forEach((iframe) => {
        try{
            // The correct iframe should have an Angular controller property
            let span = iframe.contentWindow.document.querySelector('span[data-ng-controller]');
            if (!span) return;

            // Get a controller instance from the element props
            let widget = false;
            Object.entries(span).forEach(([key, value]) => {
                if (/jQuery/.test(key)) {
                    widget = value
                }
            });
            if (!widget) return;

            let container = widget.$ngControllerController.container.container;
            // Clear message list
            container.messages.length = 0;
            // Clear tag list
            Object.entries(container.tagsFired).forEach(([key, value]) => {
                delete container.tagsFired[key];
            });
            // Renew summary
            iframe.contentWindow.document.querySelector('.message-list').firstElementChild.click();
        } catch (e) {
            return;
        }
    })
}

export const resetGTM = (
    resetEnabled,
    dataLayerName,
    eventQueue,
    resetStoredData
) => {
    const canReset = () => {
        if (!resetEnabled) {
            return false;
        }
        if (!Array.isArray(window[dataLayerName]) || window[dataLayerName].length === 0) {
            return false;
        }
        let gtmObject;
        window[dataLayerName].push(function () {
            gtmObject = this.get('gtm');
        });
        return (typeof gtmObject !== 'undefined');
    }

    return () => {
        if (!canReset()) {
            return;
        }
        const { resetQueue } = eventQueue;

        window[dataLayerName].push(function () {
            this.reset();
        });
        resetStoredData();
        resetGTMPreviewDebug();
        resetQueue();
    };
}
