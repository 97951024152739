import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FadeIn } from 'react-lazyload-fadein';
import { forceCheck } from 'react-lazyload';
import classNames from 'classnames/bind';
import defaultClasses from 'ModulesPath/Catalog/ProductItem/ProductItem.less';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import projectConfig from 'SrcPath/project.config';
import addSimpleProductsToCart from 'ModulesPath/App/Queries/addSimpleProductsToCart.graphql';
import addConfigurableProductToCart from 'ModulesPath/App/Queries/addConfigurableProductToCart.graphql';
import addBundleProductsToCart from 'ModulesPath/App/Queries/addBundleProductsToCart.graphql';
import { Badge } from 'ModulesPath/Catalog/Badge';
import { useWindowSize } from '@magento/peregrine';
import BrowserPersistence from '@magento/peregrine/lib/util/simplePersistence';
import { ProductRating } from 'ModulesPath/Catalog/ProductRating';
import { ConstructorVariantSelector } from 'ModulesPath/Catalog/Constructor/ConstructorVariantSelector';
const persistence = new BrowserPersistence();
const routeCacheKey = 'urlResolver';
const ttl = 86400;
const style = classNames.bind(defaultClasses);
const categoryUrlSuffix = '.html';
import { useGlobalOptions } from '@corratech/context-provider';
import { ConstructorPLPManagerStore } from 'ModulesPath/Catalog/Constructor/ConstructorPLPManager';
import { PromoLabel } from '../../Catalog/Constructor/Promo/PromoLabel';

const AddToCart = React.lazy(() =>
    import('@corratech/add-to-cart/src/AddToCart')
);

const ConstructorProductItem = props => {
    const replacements = [
        { mainKey: 'main_entity_id', key: 'entity_id' },
        { mainKey: 'main_sku', key: 'sku' },
        { mainKey: 'main_url_key', key: 'url_key' },
        { mainKey: 'main_bv_rating', key: 'bv_rating' },
        { mainKey: 'main_bv_review_count', key: 'bv_review_count' },
        { mainKey: 'main_subtitle', key: 'subtitle' },
        { mainKey: 'main_category_rollover', key: 'category_rollover' },
        { mainKey: 'main_image_url', key: 'image_url' },
        { mainKey: 'main_product_badge', key: 'product_badge' },
        { mainKey: 'main_in_stock', key: 'in_stock' },
        { mainKey: 'main_bottle_size', key: 'bottle_size' },
        { mainKey: 'main_active', key: 'active' },
        { mainKey: 'main_type_id', key: 'type_id' }
    ];

    replacements.forEach(({ mainKey, key }) => {
        if (
            props.product.data[mainKey] !== undefined &&
            props.product.data[mainKey] !== ''
        ) {
            props.product.data[key] = props.product.data[mainKey];
        }
    });
    if (
        props.product.data.main_item_name !== undefined &&
        props.product.data.main_item_name !== ''
    ) {
        props.product.value = props.product.data.main_item_name;
    }

    const { product, imageParam, category, isPLP } = props;
    const { data, variations } = product;
    const {
        entity_id,
        url,
        url_key,
        price,
        image_url,
        special_price,
        in_stock,
        product_badge
    } = data;
    const { storeConfig } = useGlobalOptions();
    const [t] = useTranslation();

    const getUrlQuery = url => {
        try {
            const urlObj = new URL(url);
            return urlObj.search;
        } catch (error) {
            return url;
        }
    };

    const [optionSelected, setOptionSelected] = useState(getUrlQuery(url));
    const history = useHistory();
    const windowSize = useWindowSize();
    const isMobile = windowSize.innerWidth < projectConfig.viewport.mobile;
    const [image, setImage] = useState(image_url);
    const [productPrice, setProductPrice] = useState(price ? price : 0);
    const [productSpecialPrice, setProductSpecialPrice] = useState(
        special_price
    );
    const [stockStatus, setStockStatus] = useState(in_stock);
    const [productBadge, setProductBadge] = useState(product_badge);
    const [promoLabel, setPromoLabel] = useState([]);
    const [simpleProduct, setSimpleProduct] = useState(null);
    const [altImage, setAltImage] = useState(product.data.category_rollover);
    const currencySymbols = {
        USD: '$',
        GBP: '£',
        EUR: '€'
    };

    const currencySymbol =
        currencySymbols[storeConfig.base_currency_code] || '£';

    const isConfigurableProduct = () => {
        return variations && variations.length > 0;
    };

    const [sku, setSku] = useState(
        isConfigurableProduct() ? data.variation_id : data.id
    );

    const isBundleProduct = product => {
        return product.data.type_id === 'bundle';
    };

    const isSimpleProduct = product => {
        return product.data.type_id === 'simple';
    };

    const isGiftCard = product => {
        return product.data.type_id === 'giftcard';
    };

    const isCustomisableBundleProduct = product => {
        return product.data.exploding_kit !== 1;
    };

    const getDefaultBundleOptions = product => {
        if (product.data.bundle_options) {
            return JSON.parse(product.data.bundle_options);
        }
        return [];
    };

    const ImageUrl = mainImage => {
        return mainImage ? mainImage : '';
    };

    let componentOOS = false;
    const bundleOptions = {};
    const findUrlByVariationId = variationId => {
        for (const variation of variations) {
            if (variation.data.variation_id === variationId) {
                return variation.data.url;
            }
        }
        return '';
    };

    const findDefaultOption = () => {
        let defaultVariant = null;
        if (data.variation_id) {
            defaultVariant = variations.find(
                variant => variant.data?.variation_id === data.variation_id
            );
        } else {
            defaultVariant = variations.find(
                variant =>
                    variant.data?.bottle_size === data?.bottle_size &&
                    variant.data.in_stock
            );
        }

        if (!defaultVariant) {
            defaultVariant = variations.find(variant => variant.data?.in_stock);
        }

        return defaultVariant ? defaultVariant.data?.variation_id : null;
    };

    useEffect(() => {
        if (product) {
            if (!isConfigurableProduct()) {
                setSimpleProduct(data);
            }
            let label = JSON.parse(data.promo_labels || '[]');

            setImage(data.image_url);
            setAltImage(data.category_rollover);
            setProductPrice(data.price ? data.price : 0);
            setStockStatus(data.in_stock);
            setSku(data.id);
            setProductSpecialPrice(data.special_price);
            setProductBadge(data.product_badge);
            setPromoLabel(label);
        }
    }, [product]);

    useEffect(() => {
        if (isConfigurableProduct()) {
            const defaultOption = findDefaultOption();
            if (defaultOption) {
                const selectedVariant = variations.find(
                    variant => variant.data.variation_id === defaultOption
                );
                const variant = selectedVariant.data;

                setSimpleProduct(variant);
                setSku(variant.variation_id);
                if (variant.image_url) {
                    setImage(variant.image_url);
                }
                setProductPrice(variant.price);
                setStockStatus(variant.in_stock);
                setProductSpecialPrice(variant.special_price);
                if (variant.product_badge) {
                    setProductBadge(variant.product_badge);
                }
            }
        }
    }, []);

    const handleLinkClick = (url_key, categoryUrlSuffix) => {
        window.scrollTo(0, 0);
        const routes = persistence.getItem(routeCacheKey) || {};
        routes[`/${url_key}${categoryUrlSuffix}`] = {
            data: {
                urlResolver: {
                    type: 'PRODUCT',
                    id: entity_id,
                    relative_url: `${url_key}${categoryUrlSuffix}`,
                    redirectCode: 0,
                    __typename: 'EntityUrl'
                }
            }
        };
        persistence.setItem(routeCacheKey, routes, ttl);
    };

    /* show images that are in the viewport on first load */
    useEffect(() => {
        setTimeout(forceCheck, 100);
    }, []);

    return (
        <>
            {product && (
                <div
                    className={style('productItemBlock')}
                    data-cnstrc-item-name={product.value}
                    data-cnstrc-item-id={product.data.id}
                    data-cnstrc-item="recommendation"
                    data-cnstrc-strategy-id={product.strategy.id}
                    data-cnstrc-item-variation-id={
                        isConfigurableProduct(product)
                            ? simpleProduct?.variation_id ||
                              product.data.variation_id
                            : null
                    }
                    data-cnstrc-item-price={
                        simpleProduct
                            ? simpleProduct.in_stock
                                ? productPrice
                                : null
                            : stockStatus
                            ? price
                            : null
                    }
                >
                    <div className={style('productImageBlock')}>
                        {productBadge && <Badge img={productBadge} />}
                        <Link
                            onClick={() => {
                                if (!isMobile) {
                                    window.scrollTo(0, 0);
                                }
                                handleLinkClick(url_key, categoryUrlSuffix);
                            }}
                            to={`/${url_key}${categoryUrlSuffix}${
                                optionSelected !== '' ? optionSelected : ''
                            }`}
                            className={style('productImg-link')}
                            aria-label={product.value}
                        >
                            <span className={style('productImageRatio')}>
                                <FadeIn height={250} duration={50} offset={250}>
                                    {onload => (
                                        <img
                                            src={ImageUrl(image)}
                                            alt={product.value}
                                            title={product.value}
                                            onLoad={onload}
                                            onMouseOver={e =>
                                                !isMobile &&
                                                altImage &&
                                                altImage.length
                                                    ? (e.currentTarget.src = altImage)
                                                    : ''
                                            }
                                            onMouseOut={e =>
                                                altImage && altImage.length
                                                    ? (e.currentTarget.src = ImageUrl(
                                                          image
                                                      ))
                                                    : ''
                                            }
                                            width="291"
                                            height="291"
                                            className={'productImg'}
                                            loading={'lazy'}
                                        />
                                    )}
                                </FadeIn>
                            </span>
                        </Link>
                    </div>
                    <div className={style('product-details')}>
                        <h2 className={style('productName')}>
                            <Link
                                onClick={() =>
                                    handleLinkClick(url_key, categoryUrlSuffix)
                                }
                                to={`/${url_key}${categoryUrlSuffix}${
                                    optionSelected !== '' ? optionSelected : ''
                                }`}
                                className={style('productName-link')}
                                title={product.value}
                            >
                                {product.value}
                            </Link>
                        </h2>
                        {product.data.subtitle && (
                            <span className={'subtitle'}>
                                {product.data.subtitle}
                            </span>
                        )}
                        {!isGiftCard(product) && (
                            <>
                                <ProductRating
                                    rating={product.data.bv_rating}
                                    review_count={product.data.bv_review_count}
                                />
                                {!productSpecialPrice ? (
                                    <>
                                        <div className={'productPrice'}>
                                            <div className={'productPrice'}>
                                                <span className="original-price">
                                                    {`${currencySymbol}${(
                                                        productPrice || 0
                                                    ).toFixed(2)}`}
                                                </span>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="prices inline">
                                            <span className={'sale-price'}>
                                                {`${currencySymbol}${productSpecialPrice.toFixed(
                                                    2
                                                )}`}
                                            </span>
                                            <span className={'old-price'}>
                                                {`${currencySymbol}${(
                                                    productPrice || 0
                                                ).toFixed(2)}`}
                                            </span>
                                        </div>
                                    </>
                                )}
                                {!isConfigurableProduct(product) &&
                                    product.data.bottle_size && (
                                        <div className={'product-size'}>
                                            {product.data.bottle_size}
                                        </div>
                                    )}
                            </>
                        )}
                        {promoLabel &&
                            promoLabel.map(labelObj => (
                                <PromoLabel
                                    label={labelObj.label}
                                    code={labelObj.code}
                                />
                            ))}
                        {isConfigurableProduct() && (
                            <div className={'product-list-variants'}>
                                <ConstructorVariantSelector
                                    product={product}
                                    defaultOption={findDefaultOption()}
                                    optionSelectCallback={selectedOptions => {
                                        if (selectedOptions?.items?.[0]) {
                                            let variationUrl = findUrlByVariationId(
                                                selectedOptions.items[0]
                                                    .variation_id
                                            );
                                            setOptionSelected(
                                                getUrlQuery(variationUrl)
                                            );
                                        } else {
                                            setOptionSelected('');
                                        }
                                    }}
                                    simpleProductCallback={simpleProduct => {
                                        if (simpleProduct) {
                                            let promoLabels = JSON.parse(
                                                simpleProduct.promo_labels ||
                                                    '[]'
                                            );
                                            setSimpleProduct(simpleProduct);
                                            setSku(simpleProduct.variation_id);
                                            setImage(simpleProduct.image_url);
                                            setProductPrice(
                                                simpleProduct.price
                                            );
                                            setStockStatus(
                                                simpleProduct.in_stock
                                            );
                                            setProductSpecialPrice(
                                                simpleProduct.special_price
                                            );
                                            setProductBadge(
                                                simpleProduct.product_badge
                                            );
                                            setPromoLabel(promoLabels);
                                        } else {
                                            setSku(sku);
                                            setImage(image);
                                        }
                                    }}
                                />
                            </div>
                        )}

                        <div
                            className={`add-to-cart-btn ${
                                !isConfigurableProduct(product) &&
                                !isBundleProduct(product)
                                    ? 'is-simple-product'
                                    : ''
                            } `}
                        >
                            {stockStatus === true &&
                            (!isBundleProduct(product) || !componentOOS) ? (
                                <>
                                    {isGiftCard(product) ||
                                    (isCustomisableBundleProduct(product) &&
                                        isBundleProduct(product)) ? (
                                        <Button
                                            className="addToCartLink"
                                            data-cnstrc-btn="add_to_cart"
                                            onClick={() => {
                                                if (url_key) {
                                                    history.push(
                                                        `/${url_key}${categoryUrlSuffix}`
                                                    );
                                                }
                                            }}
                                            variant={'primary'}
                                        >
                                            {t('ADD TO BAG')}
                                        </Button>
                                    ) : (
                                        <AddToCart
                                            isPLP={isPLP}
                                            addToCartText={t('ADD TO BAG')}
                                            addToCartLoadingText={t(
                                                'ADDING...'
                                            )}
                                            addedMessage={t('ADDED')}
                                            quantity={1}
                                            sku={sku}
                                            isConfigProduct={
                                                !!isConfigurableProduct(product)
                                            }
                                            isBundleProduct={isBundleProduct(
                                                product
                                            )}
                                            parent_sku={data && data.id}
                                            isBundled={false}
                                            showTick={false}
                                            projectConfig={projectConfig}
                                            categoryName={
                                                category?.display_name ?? ''
                                            }
                                            configProductAddToCartGraphql={
                                                addConfigurableProductToCart
                                            }
                                            simpleProductAddToCartGraphql={
                                                addSimpleProductsToCart
                                            }
                                            addBundleProductsToCart={
                                                addBundleProductsToCart
                                            }
                                            bundleOptions={getDefaultBundleOptions(
                                                product
                                            )}
                                        />
                                    )}
                                </>
                            ) : (
                                <Button variant="primary" disabled>
                                    {t('OUT OF STOCK')}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ConstructorProductItem;
