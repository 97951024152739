import React from 'react';
import PropTypes from 'prop-types';

const TW = props => {
    const { color, size, ...otherProps } = props;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            height={size}
            width={size}
            {...otherProps}
        >
            <path
                fill="#D80027"
                d="M256 0v256H0c0 141.4 114.6 256 256 256s256-114.6 256-256S397.4 0 256 0z"
            ></path>
            <path
                fill="#0052B4"
                d="M256 0C114.6 0 0 114.6 0 256h256V0zm-81.8 111.3l33.8-6.5-16.6 30.2c-3.1-9.6-9.2-17.9-17.2-23.7zm-.2-.1c-7.8-5.7-17.4-9-27.8-9.1L169.7 77l4.3 34.2zm-53.3 38.6c0-14 11.4-25.4 25.4-25.4 14 0 25.4 11.4 25.4 25.4 0 14-11.4 25.4-25.4 25.4-14 0-25.4-11.4-25.4-25.4zm25.3-47.6c-10.4 0-20 3.4-27.8 9.1l4.3-34.2 23.5 25.1zm-28 9.1c-8 5.8-14.1 14.1-17.2 23.7l-16.6-30.2 33.8 6.5zm-48.4 38.5l31.2-14.7c-1.5 4.6-2.3 9.5-2.3 14.7 0 5.1.8 10 2.3 14.7l-31.2-14.7zm31.2 14.8c3.1 9.6 9.2 17.9 17.3 23.7l-33.9 6.5 16.6-30.2zm17.4 23.8c7.8 5.7 17.5 9 27.9 9.1l-23.6 25.1-4.3-34.2zm28 9.1c10.4 0 20-3.4 27.9-9.1l-4.3 34.2-23.6-25.1zm27.9-9.2c8-5.8 14.1-14.1 17.2-23.7l16.6 30.2-33.8-6.5zm17.3-23.8c1.5-4.6 2.3-9.5 2.3-14.7 0-5.1-.8-10-2.3-14.7l31.2 14.7-31.2 14.7z"
            ></path>
            <path
                fill="#F0F0F0"
                d="M118 111.3l-33.8-6.5 16.6 30.2c3.1-9.6 9.2-17.9 17.2-23.7zm28-9.1L122.4 77l-4.3 34.2c7.9-5.7 17.5-9 27.9-9zm28 9L169.7 77l-23.6 25.1c10.5.1 20.1 3.4 27.9 9.1zm-27.8 86.3l23.6 25.1 4.3-34.2c-7.9 5.7-17.5 9.1-27.9 9.1zm-28-9.1l4.3 34.2 23.6-25.1c-10.5-.1-20.1-3.4-27.9-9.1zm56-77.1c8 5.8 14.1 14.1 17.2 23.7l16.6-30.2-33.8 6.5zm-73.4 53.3l-16.6 30.2 33.9-6.5c-8.1-5.8-14.2-14.1-17.3-23.7zm93-14.8c0 5.1-.8 10-2.3 14.7l31.2-14.7-31.2-14.7c1.4 4.7 2.3 9.6 2.3 14.7zm-19.7 38.5l33.8 6.5-16.6-30.2c-3 9.6-9.2 17.9-17.2 23.7zm-75.7-38.5c0-5.1.8-10 2.3-14.7l-31.2 14.7 31.2 14.7c-1.4-4.6-2.3-9.6-2.3-14.7zm73.1 0c0-14-11.4-25.4-25.4-25.4-14 0-25.4 11.4-25.4 25.4s11.4 25.4 25.4 25.4c14 0 25.4-11.4 25.4-25.4z"
            ></path>
        </svg>
    );
};

TW.propTypes = {
    color: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

TW.defaultProps = {
    color: 'currentColor',
    size: '18'
};

export default TW;
