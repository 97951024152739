export { useGTMTracking } from './src/useGTMTracking';
export {
    CART_PAGE,
    CHECKOUT_PAGE,
    PDP_PAGE,
    PLP_PAGE,
    SEARCH,
    cartItemMapping,
    getCategoryName,
    getMapProductDetails,
    formatCategoryPath,
    getConfigurableVariant,
    getCartItemPrice,
    getCategoryPath,
    MAX_CATEGORY_PATH_DEPTH
} from './src/mapping';