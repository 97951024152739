import React, { useEffect, useContext } from 'react';
import { useLazyQuery } from 'react-apollo';
import { removeOutOfStockProductInCart } from './Queries/removeOutOfStockProductInCart.graphql';
import getCartQuery from 'ModulesPath/App/Queries/getCart.graphql';
import { ProductRemoveMessage } from './ProductRemoveMessage';
import { CartStore, useSetNotification } from '@corratech/context-provider';
import { useHistory } from 'react-router-dom';

const useCartOOS = props => {
    const { setNotification, clearNotification } = useSetNotification();
    const { cartState, dispatch } = useContext(CartStore);
    const history = useHistory();

    const [removeOutOfStockProductInCartQry, { loading }] = useLazyQuery(
        removeOutOfStockProductInCart,
        {
            fetchPolicy: 'no-cache',
            onCompleted: res => {
                if (res && res.removeOutOfStockProductInCart.status === true) {
                    const removedItems = JSON.parse(
                        res.removeOutOfStockProductInCart.removedItems
                    );
                    if (removedItems.length) {
                        setNotification(
                            'danger oos-msg-wrapper',
                            <ProductRemoveMessage
                                removedItems={removedItems}
                                cartItems={cartState.cart.items}
                            />
                        );
                    }

                    dispatch({
                        type: 'SET_CART',
                        cart: res.removeOutOfStockProductInCart.cart
                    });
                }
            },
            onError: error => {
                console.warn(error);
            }
        }
    );

    const [cartQuery] = useLazyQuery(getCartQuery, {
        fetchPolicy: 'no-cache',
        variables: {
            cartId: cartState.cartId,
            isSignedIn: !!cartState.cart.authenticated
        },
        onCompleted: res => {
            const updatedCart = res.cart;
            if (res.cart.is_virtual) {
                updatedCart.isBillingSameAsShipping = false;
            }
            dispatch({
                type: 'SET_CART',
                cart: updatedCart
            });
        }
    });

    const checkForOOSProduct = () => {
        let hasOOSProduct = false;
        cartState &&
            cartState.cart.items &&
            cartState.cart.items.map(item => {
                if (
                    item.product.stock_status == 'OUT_OF_STOCK' ||
                    item.item_stock_status == 'OUT_OF_STOCK'
                ) {
                    hasOOSProduct = true;
                }
            });
        return hasOOSProduct;
    };

    useEffect(() => {
        clearNotification();
        if (cartState && cartState.cart.items) {
            if (
                !(
                    history.location.pathname &&
                    history.location.pathname.includes('checkout')
                )
            ) {
                cartQuery();
            }
        }
    }, [history.location]);

    useEffect(() => {
        if (!loading && cartState.cartId && checkForOOSProduct()) {
            removeOutOfStockProductInCartQry({
                variables: {
                    cartId: cartState.cartId,
                    isSignedIn: !!cartState.cart.authenticated
                }
            });
        }
    }, [cartState.cartId, cartState.cart.items, history.location]);
};

export default useCartOOS;
