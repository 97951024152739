import React, { useContext, useEffect } from 'react';
import { LoaderStore } from '@corratech/context-provider';
import { useHistory } from 'react-router-dom';
import { Util } from '@magento/peregrine';

export const PaypalCancel = props => {
    const LoadingIndicator = useContext(LoaderStore);
    const urlParams = new URLSearchParams(window.location.search);
    const history = useHistory();
    const { BrowserPersistence } = Util;
    const storage = new BrowserPersistence();

    useEffect(() => {
        const crossToken = storage.getItem('cross-verify');
        const payaplToken = urlParams.get('token');

        storage.removeItem('cross-verify');

        if (!crossToken || crossToken !== payaplToken) {
            history.push('/checkout');
        }

        if (crossToken === payaplToken) {
            history.push({
                pathname: '/checkout',
                paypalError: {
                    hasError: false,
                    state: 'success',
                    message: 'Payapl Express Checkout has been canceled'
                }
            });
            return;
        }
    }, []);

    return <LoadingIndicator />;
};
