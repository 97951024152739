import React, { Fragment, useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { string, object, func, bool } from 'prop-types';
import { Form } from 'informed';
import {
    combine,
    validateEmail,
    isRequired,
    Field,
    TextInput
} from '@corratech/form-components';
import {
    resetPassword,
    LoaderStore,
    useGlobalOptions
} from '@corratech/context-provider';
import { Alert, Button } from 'react-bootstrap';
//import { FiCheck as CheckIcon, FiX as ErrorIcon } from 'react-icons/fi';
import { useReCaptchaStoreConfig } from 'ModulesPath/Checkout/useReCaptchaStoreConfig';
import { ReCaptcha } from 'react-recaptcha-v3';
import loadReCaptcha from '@corratech/google-recaptcha-v3/src/loadReCaptcha';
import { X as ErrorIcon, Check as CheckIcon } from 'react-feather';
require('./ForgotPasswordForm.less');

export const ForgotPasswordForm = props => {
    const [t] = useTranslation();

    const { enteredEmail, updateEmail, signInClick, className, css } = props;

    const LoadingIndicator = useContext(LoaderStore);

    const [loading, setLoading] = useState(false);

    const [showError, setShowError] = useState(false);

    const [errorMessage, setErrorMessage] = useState(false);

    const [showSuccess, setShowSuccess] = useState(false);

    const onValueChange = value => {
        if (updateEmail) updateEmail(value);
    };

    //ReCaptcha
    const options = useGlobalOptions();
    const { actionV3ForgotPassword } = useReCaptchaStoreConfig();
    const [isReCaptchaLoaded, setIsReCaptchaLoaded] = useState(false);
    const [
        reCaptchaTokenForgotPassword,
        setReCaptchaTokenForgotPassword
    ] = useState(null);

    let recaptchaV3Publickey = '';
    let isV3ForgotPasswordEnable = null;

    if (options.storeConfig && options.storeConfig.recaptcha_v3_public_key) {
        recaptchaV3Publickey = options.storeConfig.recaptcha_v3_public_key;
    }

    if (actionV3ForgotPassword && actionV3ForgotPassword === 'recaptcha_v3') {
        isV3ForgotPasswordEnable = true;
    } else {
        isV3ForgotPasswordEnable = false;
    }

    useEffect(() => {
        const scriptLoaded = loadReCaptcha(recaptchaV3Publickey, () => {
            setIsReCaptchaLoaded(true);
        });
        if (!scriptLoaded) {
            setIsReCaptchaLoaded(true);
        }
    }, [recaptchaV3Publickey]);

    const verifyReCaptchaTokenForgotPasswordCallback = token => {
        setReCaptchaTokenForgotPassword(token);
        setInterval(function() {
            resetReCaptchaTokenForgotPassword();
        }, 100 * 1000);
    };

    const resetReCaptchaTokenForgotPassword = () => {
        if (window.grecaptcha !== undefined) {
            window.grecaptcha
                .execute(recaptchaV3Publickey, { action: 'forgot_password' })
                .then(function(token) {
                    setReCaptchaTokenForgotPassword(token);
                });
        }
    };

    useEffect(() => {
        if (showSuccess && props.onSuccess) {
            props.onSuccess();
        }
    }, [showSuccess]);

    if (loading) {
        return <LoadingIndicator />;
    }

    if (!isReCaptchaLoaded) {
        return <LoadingIndicator />;
    }

    return (
        <Fragment>
            {isV3ForgotPasswordEnable && (
                <ReCaptcha
                    action="forgot_password"
                    sitekey={recaptchaV3Publickey}
                    verifyCallback={verifyReCaptchaTokenForgotPasswordCallback}
                />
            )}
            {props.shouldShowSuccessAlert && showSuccess && (
                <Alert variant={'success'}>
                    <CheckIcon size={14} strokeWidth={'4'} color={'#000'} />
                    {t(props.successTextBeforeEmail)} {enteredEmail}{' '}
                    {t(props.successTextAfterEmail)}
                </Alert>
            )}
            {showError && (
                <Alert variant={'danger'}>
                    <ErrorIcon size={14} strokeWidth={'4'} color={'#B70020'} />
                    <strong>{t(`Error.`)}</strong> {t(`Something went wrong`)}
                </Alert>
            )}
            {isV3ForgotPasswordEnable && errorMessage && (
                <Alert variant="danger">
                    <ErrorIcon size={9} color={'#B70020'} />
                    <div
                        dangerouslySetInnerHTML={{
                            __html: errorMessage
                        }}
                    />
                </Alert>
            )}
            <div
                aria-labelledby={'account-login-form-heading'}
                className={
                    'account-form-forgot-password account-form-wrapper ' +
                    (className || '')
                }
                css={css}
            >
                <Form
                    id="account-login-form-heading"
                    onSubmit={formState => {
                        setLoading(true);
                        setLoading(true);
                        let resetPasswordData = {
                            email: enteredEmail,
                            template: 'email_reset'
                        };
                        if (isV3ForgotPasswordEnable) {
                            resetPasswordData.reCaptchaToken = reCaptchaTokenForgotPassword;
                        }
                        resetPassword(
                            resetPasswordData,
                            ({ success, errorMessage }) => {
                                if (success) {
                                    setLoading(false);
                                    setShowSuccess(true);
                                } else {
                                    setLoading(false);
                                    setErrorMessage(errorMessage);
                                    if (!errorMessage) {
                                        setShowError(true);
                                    }
                                    if (isV3ForgotPasswordEnable) {
                                        resetReCaptchaTokenForgotPassword();
                                    }
                                }
                            }
                        );
                    }}
                >
                    <div id="account-login-form-heading" className={'title'}>
                        {t(props.promptText)}
                    </div>
                    <Field
                        label={t(`Email`)}
                        labelText={t(`email_address`)}
                        required={true}
                    >
                        <TextInput
                            type="email"
                            field="email"
                            autoComplete="email"
                            initialValue={enteredEmail}
                            placeholder={t(props.placeholderText)}
                            id="email_address"
                            aria-required="true"
                            validate={combine([
                                {
                                    fn: isRequired,
                                    text: t(props.requiredText)
                                },
                                {
                                    fn: validateEmail,
                                    text: t(props.invalidEmailText)
                                }
                            ])}
                            validateOnBlur
                            onValueChange={onValueChange}
                        />
                    </Field>
                    <Button type="submit" size="lg" variant="primary" block>
                        {t('Send')}
                    </Button>
                </Form>

                <div className={'signin-link-wrapper'}>
                    <Button
                        onClick={signInClick}
                        className={'signin-link'}
                        variant="link"
                    >
                        {t(props.loginButtonText)}
                    </Button>
                </div>
            </div>
        </Fragment>
    );
};

ForgotPasswordForm.propTypes = {
    className: string,
    css: object,
    enteredEmail: string,
    signInClick: func.isRequired,
    updateEmail: func,
    requiredText: string,
    invalidEmailText: string,
    placeholderText: string,
    promptText: string,
    loginText: string,
    successTextBeforeEmail: string,
    successTextAfterEmail: string,
    loginButtonText: string,
    shouldShowSuccessAlert: bool,
    onSuccess: func
};

ForgotPasswordForm.defaultProps = {
    requiredText: 'This field is required',
    invalidEmailText: 'Please enter a valid email, such as example@example.com',
    placeholderText: 'We will send you an email to reset your password.',
    promptText: 'Recover your password',
    loginText: 'Oops, I remember now!',
    successTextBeforeEmail: 'If there is an account associated with ',
    successTextAfterEmail:
        ' you will receive an email with a link to reset your password.',
    loginButtonText: 'Sign in',
    shouldShowSuccessAlert: true
};
