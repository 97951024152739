(function(history){
    const pushState = history.pushState;
    history.pushState = function(state, title, url) {
        const event = new CustomEvent('historypushstate', {detail: {state, title, url}});
        window.dispatchEvent(event);
        return pushState.apply(history, arguments);
    };

    const replaceState = history.replaceState;
    history.replaceState = function(state, title, url) {
        const event = new CustomEvent('historyreplacestate', {detail: {state, title, url}});
        window.dispatchEvent(event);
        return replaceState.apply(history, arguments);
    };
})(window.history);