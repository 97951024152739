export const defaultProductMetadataConfig = {
    attributes: {
        description: ['description', 'html'],
        image_url: [
            ['image', 'url'],
            ['media_gallery', '0', 'url'],
            ['media_gallery_entries', '0', 'file']
        ],
        image_alt: [
            ['image', 'label'],
            ['media_gallery', '0', 'label'],
            ['media_gallery_entries', '0', 'label']
        ],
        price_amount: [
            ['price_range', 'minimum_price', 'final_price', 'value'],
            ['price', 'regularPrice', 'amount', 'value']
        ],
        price_currency: [
            ['price_range', 'minimum_price', 'final_price', 'currency'],
            ['price', 'regularPrice', 'amount', 'currency']
        ],
        review_count: ['review_details', 'review_count'],
        review_summary: ['review_details', 'review_summary'],
        best_rating: ['review_details', 'best_rating'],
        media_gallery: [
            ['media_gallery'],
            ['media_gallery_entries']
        ]
    },
    gallery: {
        include: false,
    },
    priceValidUntilDays: 14,
    itemConditionMap: {
        "0": "https://schema.org/DamagedCondition",
        "1": "https://schema.org/NewCondition",
        "2": "https://schema.org/RefurbishedCondition",
        "3": "https://schema.org/UsedCondition"
    }
}