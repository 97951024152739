import React, { useState, Fragment, useContext } from 'react';
import { Accordion } from 'react-bootstrap';
import {
    US,
    EU,
    UK,
    DE,
    IT,
    FR,
    NL,
    ES,
    HK,
    TW,
    SG,
    BE,
    PL,
    RU,
    ID,
    AE,
    SA,
    JP,
    KR,
    MY,
    TH,
    ZA,
    CH
} from './Flags';
import { OptionsStore } from '@corratech/context-provider';

export const StoreSwitcherAccordion = props => {
    const storeFlagMap = {
        uk: UK,
        eu: EU,
        us: US,
        de: DE,
        it: IT,
        fr: FR,
        nl: NL,
        es: ES,
        hk: HK,
        tw: TW,
        sg: SG,
        be: BE,
        pl: PL,
        ru: RU,
        id: ID,
        ae: AE,
        sa: SA,
        jp: JP,
        kr: KR,
        my: MY,
        th: TH,
        za: ZA,
        ch: CH
    };
    const projectConfig = useContext(OptionsStore) || {};
    const storeConfig = projectConfig.storeConfig || {};
    const { storeswitcher_data } = storeConfig;
    const [activeIndex, setActiveIndex] = useState(null);
    const storeList =
        typeof storeswitcher_data === 'string'
            ? JSON.parse(storeswitcher_data)
            : storeswitcher_data;
    const Flag = storeFlagMap[window.STORE_CODE];
    const excludedStoreList = storeList.filter(
        list => list.code !== window.STORE_CODE
    );
    const activeStore = storeList.filter(
        list => list.code === window.STORE_CODE
    );

    const onAccordionToogle = index => {
        if (activeIndex === index) {
            setActiveIndex(null);
            return;
        }
        setActiveIndex(index);
    };

    return (
        <div>
            <Accordion defaultActiveKey={activeIndex}>
                <Accordion.Toggle
                    className={activeIndex === 0 ? 'expanded' : ''}
                    onClick={() => onAccordionToogle(0)}
                    variant="link"
                    eventKey="0"
                >
                    <Fragment>
                        <Flag /> {activeStore[0].label}
                    </Fragment>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                    <ul>
                        {excludedStoreList.map(list => {
                            const CountryFlag = storeFlagMap[list.code];
                            return (
                                <li>
                                    {CountryFlag && <CountryFlag />}
                                    <a
                                        className={CountryFlag ? '' : 'no-flag'}
                                        href={list.link}
                                    >
                                        {list.label}
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </Accordion.Collapse>
            </Accordion>
        </div>
    );
};
