import React from 'react';
import { useTranslation } from 'react-i18next';

export const PaymentFailure = props => {
    const [t] = useTranslation();
    return (
        <div className={'payment-error message-error'}>
            {t(
                'Sorry, there was an error processing your payment. Please check your payment details or try another method.'
            )}
        </div>
    );
};
