const DEPTH_LIMIT = 20;
// Values may be: arrays, objects, or other types
const formatValue = (data, level = 0) => {
    if (level > DEPTH_LIMIT) {
        return data;
    }
    if (typeof data === 'undefined' || data === null) {
        return '';
    }
    if (Array.isArray(data)) {
        let formattedData = [];
        data.map((value, key) => {
            formattedData[key] = formatValue(value, level + 1);
        });
        return formattedData;
    }
    if (typeof data === 'object' && Object.keys(data).length > 0) {
        let formattedData = {};
        Object.entries(data).map(([key, value]) => {
            formattedData[key] = formatValue(value, level + 1);
        });
        return formattedData;
    }
    return data;
}

// For any defined values starting from the second nesting level,
// null and undefined should be converted to empty strings
export const formatEmptyValues = (data) => {
    // Skip functions
    if (!data || typeof data === 'function') {
        return data;
    }
    let formattedData = {};
    Object.entries(data).map(([key, value]) => {
        formattedData[key] = (typeof value === 'undefined') ? value : formatValue(value);
    });
    return formattedData;
}