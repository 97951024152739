import React, { useReducer, useEffect, useCallback } from 'react';
import { isSignedIn, getUserDetails } from './accountActions';
import { Util } from '@magento/peregrine';

const { BrowserPersistence } = Util;
const storage = new BrowserPersistence();

// This file sets up a context and reducer to replace Redux.
// For more info on how it is set up, see this article (just part 1):
// https://medium.com/octopus-labs-london/replacing-redux-with-react-hooks-and-context-part-1-11b72ffdb533

// Define a new React Context that will act like a Redux store
export const AuthStore = React.createContext();

// Set an initial state
// This helps prevent `undefined` errors
// and gives developers a model shape for the object
const initialState = {
    token: null,
    user: {},
    isEmailAvailable: true,
    error: null,
    showLogin: false,
    showAccountDrawer: false
};

// This replaces Redux reducers - it examines action objects passed to it
// and returns a new AuthStore state accordingly
function reducer(state, action) {
    switch (action.type) {
        case 'OVERRIDE_AUTH':
            return action.authState;
        case 'SET_AUTH_TOKEN':
            return {
                ...state,
                token: action.token,
                justSignedIn: action.justSignedIn
            };
        case 'SET_USER_DATA':
            return { ...state, user: action.user };
        case 'SET_EMAIL_AVAILABILITY':
            return { ...state, isEmailAvailable: action.isEmailAvailable };
        case 'SIGN_OUT':
            return {
                ...state,
                user: {},
                token: null,
                isEmailAvailable: true,
                justLogout: action.justLogout
            };
        case 'SET_AUTH_ERROR':
            return { ...state, error: action.error };
        case 'TOGGLE_LOGIN_FORM':
            return {
                ...state,
                showLogin: action.showLogin
            };
        case 'SHOW_ACCOUNT_DRAWER':
            return {
                ...state,
                showAccountDrawer: action.showAccountDrawer
            };
        default:
            return state;
    }
}

// Here, we useReducer to create a state and a dispatch to alter the state.
// Then we store the authState and dispatch in our AuthStore context.
// The dispatch is a container for the reducer above.
export const AuthProvider = ({ children }) => {
    const [authState, dispatch] = useReducer(reducer, initialState);

    const value = {
        authState,
        dispatch
    };

    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);

        if (urlParams.has('aToken')) {
            storage.setItem('signin_token', urlParams.get('aToken'));
            getUserDetails(dispatch);
            if (!authState.token) {
                dispatch({
                    type: 'SET_AUTH_TOKEN',
                    token: urlParams.get('aToken')
                });
            }
        } else if (isSignedIn && Object.entries(authState.user).length === 0) {
            // We do this here instead of in initialState because
            // getUserDetails needs the dispatch created by useReducer.
            getUserDetails(dispatch);
            if (!authState.token) {
                dispatch({
                    type: 'SET_AUTH_TOKEN',
                    token: storage.getItem('signin_token')
                });
            }
        }
    }, []);

    return <AuthStore.Provider value={value}>{children}</AuthStore.Provider>;
};
