import React, { Component } from 'react';
import { node, shape, string } from 'prop-types';
import { Radio } from 'informed';
import { FadeIn } from 'react-lazyload-fadein';
import defaultClasses from './radio.css';

const RadioOption = props => {
    const { id, label, value, img, ...rest } = props;

    return (
        <label className={'root-radio'} htmlFor={id}>
            <Radio {...rest} className={'input-radio'} id={id} value={value} />
            <span className={'label-radio'}>
                {label || (value != null ? value : '')}
            </span>
            {img && (
                <FadeIn height={30} duration={50}>
                    {onload => (
                        <img
                            alt={'Selected payment type'}
                            src={img}
                            onLoad={onload}
                        />
                    )}
                </FadeIn>
            )}
        </label>
    );
};

RadioOption.propTypes = {
    classes: shape({
        input: string,
        label: string,
        root: string
    }),
    label: node.isRequired,
    value: node.isRequired
};

export default RadioOption;
