import React, {
    useMemo,
    Component,
    Suspense,
    useEffect,
    useState
} from 'react';
import { string, func, bool, object, number } from 'prop-types';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import { persistCache } from 'apollo-cache-persist';
import { ApolloLink } from 'apollo-link';
import { createHttpLink } from '@corratech/apollo-link-http';
import {
    InMemoryCache,
    IntrospectionFragmentMatcher
} from 'apollo-cache-inmemory';
import { CookiesProvider } from 'react-cookie';
import { OverlayProvider } from './OverlayContext';
import {
    ToastContextProvider,
    WindowSizeContextProvider
} from '@magento/peregrine';
import { MagentoRouter } from '@corratech/peregrine-page';
import { AuthProvider } from './AuthContext';
import { CartProvider, refreshCart, getCustomerCart } from './CartContext';
import { LoaderProvider } from './LoaderContext';
import { ReAuthentication } from './ReAuthentication';
import { cacheKeyFromType } from './utils/apolloCache';
import { OptionsProvider } from './OptionsContext';
import { NotificationProviders } from './NotificationContexts';
import { HelmetProvider } from 'react-helmet-async';
import { onError } from 'apollo-link-error';
import { reauthUser } from './ReAuthentication';
import { isSignedIn } from './accountActions';

const handleGQLError = onError(({ graphQLErrors, networkError }) => {
    if (networkError || graphQLErrors) {
        isMaintenanceEnabled();
    }
    if (graphQLErrors) {
        for (var idx = 0; idx < graphQLErrors.length; idx++) {
            if (
                graphQLErrors[idx].extensions.category ===
                'graphql-authorization'
            ) {
                reauthUser();
                break;
            } else if (
                (graphQLErrors[idx].extensions.category ===
                    'graphql-no-such-entity' ||
                    graphQLErrors[idx].extensions.category ===
                        'graphql-no-active-cart') &&
                // TODO: next line is unsafe. after BE team updates the error handling, use a unique cart-does-not-exist code
                graphQLErrors[idx].message.includes('cart')
            ) {
                if (isSignedIn()) {
                    // get customer cart
                    if (refreshCart) refreshCart();
                    if (getCustomerCart) getCustomerCart();
                } else {
                    // clear and refresh cart?
                    if (refreshCart) refreshCart();
                }
            }
        }
    }
});

/**
 * Maintenance mode enabled checking
 * We are checking the presence of the maintenance flag in media folder
 * We will keep media/healthy.jpg file whenever the site is up
 * This file will be deleted when maintenance mode is enabled and
 * re-created when maintenance mode is disabled.
 */
let isHealthCheckInProgress = false;
let setIsInMaintenance;

const isMaintenanceEnabled = () => {
    // Check that context provider has mounted and injected
    // the setIsInMaintenance state function
    if (typeof setIsInMaintenance === 'function') {
        try {
            if (!isHealthCheckInProgress) {
                isHealthCheckInProgress = true;
                //Time stamp appended to url to overcome the caching
                let timestamp = Date.now();
                const siteHealthFlag = '/media/healthy.jpg?ts=' + timestamp;
                fetch(siteHealthFlag, {
                    method: 'GET',
                    cache: 'no-cache'
                }).then(function(response) {
                    if (response.ok) {
                        // If the site is alive, then error was not caused by maintenance mode
                        // so don't make another maintenance check for next second.
                        setTimeout(() => {
                            isHealthCheckInProgress = false;
                        }, 1000);
                    } else {
                        setIsInMaintenance(true);
                    }
                });
            }
        } catch (error) {
            console.log(error);
        }
    }
};

/**
 * To improve initial load time, create an apollo cache object as soon as
 * this module is executed, since it doesn't depend on any component props.
 * The tradeoff is that we may be creating an instance we don't end up needing.
 */
const fragmentMatcher = new IntrospectionFragmentMatcher({
    // UNION_AND_INTERFACE_TYPES is injected into the bundle by webpack at build time.
    introspectionQueryResultData: UNION_AND_INTERFACE_TYPES
});
const preInstantiatedCache = new InMemoryCache({
    dataIdFromObject: cacheKeyFromType,
    fragmentMatcher
}).restore(
    window.__APOLLO_STATE__
        ? JSON.parse(decodeURIComponent(window.__APOLLO_STATE__))
        : undefined
);

/**
 * We intentionally do not wait for the async function persistCache to complete
 * because that would negatively affect the initial page load.
 *
 * The tradeoff is that any queries that run before the cache is persisted may not be persisted.
 */
persistCache({
    cache: preInstantiatedCache,
    storage: window.localStorage
});

/**
 * This adapter can wrap any React app to add the contexts needed for fwrd-ui components.
 */

export const CorraContextProvider = props => {
    const {
        apiBase,
        apollo = {},
        children,
        store,
        loadingIndicator: LoadingIndicator,
        stopMakeCart,
        cartQuery,
        customerCartQuery,
        mergeCartMutation,
        tokenExpTimeInMilliseconds,
        globalOptions,
        showSubTitle,
        showKeepMeSignedIn,
        ReLoginForm,
        MaintenancePage
    } = props;

    //State created for site maintenance status
    const [isMaintenanceMode, setIsMaintenanceMode] = useState(
        !!process.env.FORCE_MAINTENANCE_MODE
    );

    useEffect(() => {
        if (MaintenancePage) {
            setIsInMaintenance = setIsMaintenanceMode;
        }
    }, []);

    const apolloClient = useMemo(() => {
        // If we already have a client instance, use that.
        if (apollo.client) {
            return apollo.client;
        }

        // We need to instantiate an ApolloClient.
        const link = apollo.link
            ? apollo.link
            : CorraContextProvider.apolloLink(apiBase);

        const cache = apollo.cache ? apollo.cache : preInstantiatedCache;
        const client = new ApolloClient({ cache, link });

        client.apiBase = apiBase;

        return client;
    }, [apiBase, apollo]);

    return (
        <ApolloProvider client={apolloClient}>
            {!!MaintenancePage && isMaintenanceMode ? (
                <MaintenancePage />
            ) : (
                <NotificationProviders>
                    <OptionsProvider config={globalOptions}>
                        <LoaderProvider loadingIndicator={LoadingIndicator}>
                            <AuthProvider>
                                <CartProvider
                                    stopMakeCart={stopMakeCart}
                                    cartQuery={cartQuery}
                                    customerCartQuery={customerCartQuery}
                                    mergeCartMutation={mergeCartMutation}
                                >
                                    <CookiesProvider>
                                        <MagentoRouter
                                            apiBase={apiBase}
                                            storeConfig={globalOptions}
                                        >
                                            <WindowSizeContextProvider>
                                                <ToastContextProvider>
                                                    <OverlayProvider>
                                                        <ReAuthentication
                                                            ReLoginForm={
                                                                ReLoginForm
                                                            }
                                                            tokenExpTimeInMilliseconds={
                                                                tokenExpTimeInMilliseconds
                                                            }
                                                            showSubTitle={
                                                                showSubTitle
                                                            }
                                                            showKeepMeSignedIn={
                                                                showKeepMeSignedIn
                                                            }
                                                        >
                                                            <HelmetProvider>
                                                                <Suspense
                                                                    fallback={
                                                                        LoadingIndicator ? (
                                                                            <LoadingIndicator />
                                                                        ) : (
                                                                            'Loading...'
                                                                        )
                                                                    }
                                                                >
                                                                    {children}
                                                                </Suspense>
                                                            </HelmetProvider>
                                                        </ReAuthentication>
                                                    </OverlayProvider>
                                                </ToastContextProvider>
                                            </WindowSizeContextProvider>
                                        </MagentoRouter>
                                    </CookiesProvider>
                                </CartProvider>
                            </AuthProvider>
                        </LoaderProvider>
                    </OptionsProvider>
                </NotificationProviders>
            )}
        </ApolloProvider>
    );
};

/**
 * We attach this Link as a static method on VeniaAdapter because
 * other modules in the codebase need access to it.
 */
CorraContextProvider.apolloLink = apiBase => {
    return ApolloLink.from([
        handleGQLError,
        createHttpLink({
            uri: apiBase,
            useGETForQueries: true
        })
    ]);
};

CorraContextProvider.propTypes = {
    apiBase: string.isRequired,
    loadingIndicator: func,
    stopMakeCart: bool,
    mergeCartMutation: object,
    cartQuery: object,
    tokenExpTimeInMilliseconds: number, //If you convert the measurement, it should match the auth token max age time set on the backend
    globalOptions: object,
    showSubTitle: bool,
    showKeepMeSignedIn: bool
};

CorraContextProvider.defaultProps = {
    showSubTitle: false,
    showKeepMeSignedIn: false
};
