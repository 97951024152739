import { getMapProductDetails } from '..';
import { getCategoryName } from '..';

export const PLP_PAGE = (
    productDetails = getMapProductDetails(),
    categoryName = getCategoryName
) => {
    return {
        items: ['products', 'items'],
        categoryName: categoryName,
        pageSize: ['pageSize'],
        currencyCode: [
            ['products', 'items', '0', 'price_range', 'minimum_price', 'final_price', 'currency'],
            ['category', 'products', 'items', '0', 'price_range', 'minimum_price', 'final_price', 'currency'],
            ['products', 'items', '0', 'price_range', 'minimum_price', 'regular_price', 'currency'],
            ['category', 'products', 'items', '0', 'price_range', 'minimum_price', 'regular_price', 'currency']
        ],
        currentPage: ['currentPage'],
        productsDetails: productDetails
    };
}