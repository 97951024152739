function SearchEvents(tagData) {
    const {
        searchTerm,
        currencyCode,
        totalCount,
        productsDetails = []
    } = tagData;
    
    let processedData = {
        event: 'search',
        'search query': searchTerm,
        'search results': totalCount || productsDetails.length,
        ecommerce: {
            currencyCode,
            impressions: productsDetails
        },
        _clear: true
    };
    
    if (productsDetails.length > 0) {
        processedData['ecommerce'] = {
            currencyCode,
            impressions: productsDetails
        };
    }
    
    return processedData;
}
export { SearchEvents };
