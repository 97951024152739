import React from 'react';
import { string } from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

export const Searchbox = props => {
    const {
        amasty_website_data,
        urlBase,
        config: {
            store_name,
            alternate_name,
            searchbox_enabled,
            search_target,
            search_query_input
        }
    } = props;
    const location = useLocation();

    // Search box can be present only on the home page
    if (location.pathname !== '/') {
        return '';
    }

    if (amasty_website_data) {
        return (
            <Helmet>
                <script type={'application/ld+json'}>
                    {JSON.stringify(amasty_website_data, null, 2)}
                </script>
            </Helmet>
        );
    }

    // Required options
    if (!searchbox_enabled || !search_target || !search_query_input) {
        return '';
    }

    const jsonData = {
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        name: store_name,
        alternateName: alternate_name,
        url: urlBase,
        potentialAction: {
            '@type': 'SearchAction',
            target: search_target,
            'query-input': search_query_input
        }
    };

    return (
        <Helmet>
            <script type={'application/ld+json'}>
                {JSON.stringify(jsonData, null, 2)}
            </script>
        </Helmet>
    );
};
