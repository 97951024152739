import React, { Component } from 'react';
import { func, number, object, oneOfType, shape, string } from 'prop-types';

import './icon.less';

/**
 * The Icon component allows us to wrap each icon with some default styling.
 */
const Icon = props => {
    const {
        attrs: { width, ...restAttrs } = {},
        size,
        classes,
        src: IconComponent
    } = props;

    // Permit both prop styles:
    // <Icon src={Foo} attrs={{ width: 18 }} />
    // <Icon src={Foo} size={18} />
    return (
        <span className={'icon-root'}>
            <IconComponent size={size || width} {...restAttrs} />
        </span>
    );
};

Icon.propTypes = {
    classes: shape({
        root: string
    }),
    size: number,
    attrs: object,
    src: oneOfType([func, shape({ render: func.isRequired })]).isRequired
};

export default Icon;
