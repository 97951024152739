import React, { useRef, useState } from 'react';
import './variant.less';
import { func, array, string, bool } from 'prop-types';
import { Options } from './Options';
import { CollapsibleOptions } from './CollapsibleOption';
import { VisualSwatch } from './VisualSwatch';

export const VariantSelector = props => {
    const {
        product,
        simpleProductCallback,
        imageGalleryCallback,
        imageGalleryAttribute,
        allOOSCallback,
        defaultOptions,
        optionLimit,
        productUrl,
        moreIcon,
        optionSelectCallback,
        viewMorePDP,
        isCollapsible,
        disableUnselect
    } = props;
    const { variants } = product;
    const variantRef = useRef(null);
    const configurableOptions = product.configurable_options;
    const [simpleProduct, setSimpleProduct] = React.useState(false);
    const [selectedOptions, setSelectedOptions] = React.useState({
        items: defaultOptions
    });

    const [selectedDefaultOption, setSelectedDefaultOption] = useState();
    const [isClick, setIsClick] = useState(false);

    const selectedValue = parentKey => {
        let optionTmp = selectedOptions.items;
        const value = optionTmp.find(element => element.attribute == parentKey);
        if (value) {
            return value.label;
        }
        return false;
    };
    const selectedValueEvent = (parentKey, values) => {
        let optionTmp = selectedOptions.items;
        const value = optionTmp.find(element => element.attribute == parentKey);
        if (value) {
            const swatchVal = values.find(elem => elem.label == value.label);
            const hasVisual = swatchVal.swatch_data != null;
            if (hasVisual) {
                return <VisualSwatch option={swatchVal} />;
            } else {
                return value.label;
            }
        }
        return false;
    };
    const optionSelect = (parentKey, childValue, label) => {
        let optionTmp = selectedOptions.items;
        //Unselect if already selected
        if (isSelected(parentKey, childValue)) {
            if (!disableUnselect) {
                optionTmp.splice(
                    optionTmp.findIndex(
                        element => element.attribute == parentKey
                    ),
                    1
                );
                setSelectedOptions({
                    items: [...optionTmp]
                });
            }
        } else {
            const existsIndex = optionTmp.findIndex(
                element => element.attribute == parentKey
            );
            if (existsIndex >= 0) {
                optionTmp.splice(existsIndex, 1);
            }
            setSelectedOptions({
                items: [
                    ...optionTmp,
                    { attribute: parentKey, value_index: childValue, label }
                ]
            });
        }
    };
    const getGallery = () => {
        let filteredSelection = selectedOptions.items.filter(
            val => imageGalleryAttribute.indexOf(val.attribute) != -1
        );

        if (filteredSelection.length > 0) {
            let images = [];
            variants.map(variant => {
                let foundCount = 0;
                filteredSelection.map(selectedOption => {
                    variant.attributes.map(variantAttribute => {
                        if (
                            variantAttribute.code == selectedOption.attribute &&
                            selectedOption.value_index ==
                                variantAttribute.value_index
                        ) {
                            ++foundCount;
                        }
                    });
                });
                if (filteredSelection.length === foundCount) {
                    images = variant.product.media_gallery;
                }
            });
            return images;
        } else {
            return product.media_gallery;
        }
    };
    const updateSimpleProduct = () => {
        setSimpleProduct(false);
        if (isSimpleProductReady()) {
            variants.map((variant, key) => {
                let foundCount = 0;
                selectedOptions.items.map(selectedOption => {
                    variant.attributes.map(variantAttribute => {
                        if (
                            variantAttribute.code == selectedOption.attribute &&
                            selectedOption.value_index ==
                                variantAttribute.value_index
                        ) {
                            ++foundCount;
                        }
                    });
                });
                if (selectedOptions.items.length === foundCount) {
                    setSimpleProduct(variants[key].product);
                    return;
                }
            });
        }
    };
    React.useEffect(() => {
        if (simpleProductCallback) {
            simpleProductCallback(simpleProduct);
        }
    }, [simpleProduct]);

    React.useEffect(() => {
        updateSimpleProduct();
        if (imageGalleryCallback) {
            imageGalleryCallback(getGallery());
        }
        if (optionSelectCallback) {
            optionSelectCallback(selectedOptions);
        }
    }, [selectedOptions]);

    React.useEffect(() => {
        if (allOOSCallback) {
            let foundInstock = false;
            variants.map(variant => {
                if (variant.product.stock_status == 'IN_STOCK') {
                    foundInstock = true;
                }
            });

            allOOSCallback(!foundInstock);
        }
    }, []);

    const isSelected = (parentKey, childValue) => {
        let optionTmp = selectedOptions.items;
        const existsIndex = optionTmp.findIndex(
            element =>
                element.attribute == parentKey &&
                element.value_index == childValue
        );
        return existsIndex >= 0 ? 'selected' : '';
    };

    const isSimpleProductReady = () => {
        return selectedOptions.items.length === configurableOptions.length;
    };
    const isAvailable = (parentKey, childValue) => {
        const selectedOptionsTmp = selectedOptions.items.filter(
            val => val.attribute != parentKey
        );

        let simpleProduct = false;
        let allOOS = true;
        let preorder = 0;
        let amxnotif_hide_alert = 1;

        variants.map(variant => {
            let optionFound = false;

            let foundCount = 0;
            variant.attributes.map(variantAttribute => {
                selectedOptionsTmp.map(selectedOption => {
                    if (
                        variantAttribute.code == selectedOption.attribute &&
                        selectedOption.value_index ==
                            variantAttribute.value_index
                    ) {
                        ++foundCount;
                    }
                });

                if (
                    variantAttribute.code == parentKey &&
                    variantAttribute.value_index == childValue.value_index
                ) {
                    optionFound = true;
                }
            });

            if (
                optionFound == true &&
                selectedOptionsTmp.length == foundCount
            ) {
                if (variant.product.stock_status != 'OUT_OF_STOCK') {
                    allOOS = false;
                }

                if (variant.product.preorder == 1) {
                    preorder = 1;
                }
                if (variant.product.amxnotif_hide_alert != 1) {
                    amxnotif_hide_alert = 0;
                }
                simpleProduct = variant.product;
            }
        });

        return {
            product: simpleProduct,
            allOOS,
            preorder,
            amxnotif_hide_alert
        };
    };

    const allowSelection = isThisAvailable => {
        if (isThisAvailable.allOOS !== false) {
            let returnData = false;
            if (
                isThisAvailable.preorder == 1 ||
                isThisAvailable.amxnotif_hide_alert == 0
            ) {
                returnData = true;
            }
            return returnData;
        }
        return true;
    };

    return (
        <>
            {isCollapsible ? (
                <CollapsibleOptions
                    {...{
                        configurableOptions,
                        isSelected,
                        isAvailable,
                        optionSelect,
                        allowSelection,
                        selectedValueEvent,
                        optionLimit,
                        productUrl,
                        moreIcon
                    }}
                />
            ) : (
                <div className={'variants'}>
                    {configurableOptions &&
                        configurableOptions.map((parent, parentKey) => {
                            return (
                                <div
                                    ref={variantRef}
                                    data-attribute_code={parent.attribute_code}
                                    data-attribute_id={parent.attribute_id}
                                    data-id={parent.id}
                                    key={parent.attribute_id + parentKey}
                                    className={`variant-${parent.attribute_code}`}
                                >
                                    <div className={`variant-heading`}>
                                        {parent.label}
                                        {' :'}
                                        <span className={'selected-value'}>
                                            {selectedValue(
                                                parent.attribute_code
                                            )}
                                        </span>
                                    </div>
                                    <Options
                                        {...{
                                            parent,
                                            isSelected,
                                            isAvailable,
                                            optionSelect,
                                            allowSelection,
                                            optionLimit,
                                            productUrl,
                                            moreIcon,
                                            viewMorePDP,
                                            variantRef,
                                            product,
                                            setSelectedDefaultOption,
                                            setIsClick
                                        }}
                                    />
                                    {// auto select configurable product according to selected
                                    // price range (0-49)
                                    !isClick &&
                                        selectedDefaultOption &&
                                        product.id ==
                                            selectedDefaultOption?.product_id &&
                                        optionSelect(
                                            selectedDefaultOption.attribute_code,
                                            selectedDefaultOption.value,
                                            selectedDefaultOption.label
                                        )}
                                </div>
                            );
                        })}
                </div>
            )}
        </>
    );
};

export default VariantSelector;

VariantSelector.propTypes = {
    configurableOptions: array,
    selectCallback: func,
    variants: array,
    allOOSCallback: func,
    defaultOptions: array,
    disableUnselect: bool
};

VariantSelector.defaultProps = {
    imageGalleryAttribute: 'color',
    defaultOptions: [],
    optionLimit: false,
    moreIcon: '+',
    viewMorePDP: false,
    disableUnselect: false
};
