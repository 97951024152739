export const ACCOUNT_LINKS = [
    {
        url: '/my-account',
        label: 'My Account',
        isActive: true
    },
    {
        url: '/my-account/orders',
        label: 'My Orders',
        isActive: true
    },
    {
        url: '/my-account/address',
        label: 'Address Book',
        isActive: true
    },
    {
        url: '/my-account/account-information',
        label: 'Account Information',
        isActive: true
    },
    {
        url: '/my-account/gift-card/',
        label: 'Gift Card',
        isActive: true
    },
    {
        url: '/my-account/saved-cards',
        label: 'Saved Payment Methods',
        isActive: true
    }
];
